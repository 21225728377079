import { useMount } from '@voithru/front-core';
import React from 'react';
import { Link } from 'react-router-dom';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { Title } from 'src/components/atoms/styled/element';
import { Column, Row } from 'src/components/atoms/styled/layout';
import AppPaths from 'src/constants/AppPaths';
import { toSortString } from 'src/types/api';
import { JobsResponse, JobsStatus } from 'src/types/api/Job';
import { JobResultResponse } from 'src/types/api/JobResult';
import { ProjectCategory, ProjectResponse, ProjectStatus } from 'src/types/api/Project';
import ContentResultRow from '../DashboardStatusRow/ContentResultRow';
import ContentRow from '../DashboardStatusRow/ContentRow';
import ProjectRow from '../DashboardStatusRow/ProjectRow';
import { DualTableWrapper } from './styled';

const PAGE = 0;
const SIZE = 5;
const MANAGE_STATUS = ['REGISTERED'] as const;
const SORT = toSortString({ direction: 'desc', sortKey: 'createDateTime' });

type ResponseType = JobsResponse | ProjectResponse | JobResultResponse;

export interface DashboardStatus {
  taskType: 'project' | 'contents' | 'result';
  projectName?: string;
  contentName?: string;
  projectId?: number;
  contentId?: number;
  resultId?: number;
  category?: ProjectCategory;
  companyName?: string;
  projectCreateDate?: string;
  contentCreateDate?: string;
  resultCreateDate?: string;
  status?: ProjectStatus | JobsStatus;
}

function DualTable() {
  const [recentProgress, setRecentProgress] = React.useState<ResponseType[]>();
  const [initManage, setInitManage] = React.useState<ResponseType[]>();

  const getRecenetProgress = React.useCallback(async () => {
    const projectRes = await api.project.pages({
      page: PAGE,
      size: SIZE,
      status: ['REQUESTED', 'REQUESTING'],
      sort: SORT,
    });

    const jobRes = await api.jobs.pages({
      page: PAGE,
      size: SIZE,
      status: ['REQUESTED', 'REQUESTING'],
      sort: SORT,
    });

    const jobResultRes = await api.jobResult.pages({
      page: PAGE,
      size: SIZE,
      status: ['REQUESTED'],
      sort: SORT,
    });

    const data = [...projectRes.data.element, ...jobRes.data.element, ...jobResultRes.data.element];

    data.sort((prev: ResponseType, acc: ResponseType) => {
      const prevDateToTime = new Date(prev.createDateTime).getTime();
      const accDateToTime = new Date(acc.createDateTime).getTime();

      if (prevDateToTime > accDateToTime) {
        return -1;
      } else if (prevDateToTime < accDateToTime) {
        return 1;
      } else {
        return 0;
      }
    });

    setRecentProgress(data.slice(0, 5));
  }, []);

  const getInitiationManage = React.useCallback(async () => {
    const projectRes = await api.project.pages({
      page: PAGE,
      size: SIZE,
      status: MANAGE_STATUS,
      sort: SORT,
    });

    const jobRes = await api.jobs.pages({
      page: PAGE,
      size: SIZE,
      status: MANAGE_STATUS,
      sort: SORT,
    });

    const jobResultRes = await api.jobResult.pages({
      page: PAGE,
      size: SIZE,
      status: MANAGE_STATUS,
      sort: SORT,
    });

    const data = [...projectRes.data.element, ...jobRes.data.element, ...jobResultRes.data.element];

    data.sort((prev: ResponseType, acc: ResponseType) => {
      const prevDateToTime = new Date(prev.createDateTime).getTime();
      const accDateToTime = new Date(acc.createDateTime).getTime();

      if (prevDateToTime > accDateToTime) {
        return -1;
      } else if (prevDateToTime < accDateToTime) {
        return 1;
      } else {
        return 0;
      }
    });

    setInitManage(data.slice(0, 5));
  }, []);

  const get = React.useCallback(async () => {
    try {
      await getRecenetProgress();
      await getInitiationManage();
    } catch (err) {
      errorLogger.error(err);
    }
  }, [getInitiationManage, getRecenetProgress]);

  useMount(() => {
    get();
  });

  return (
    <DualTableWrapper>
      <Column>
        <Row className={'titleWrap'}>
          <Title>신규작업 의뢰 현황</Title>
          {/* TODO 더보기 Event */}
          <Row>
            <Link to={`${AppPaths.projects.path}?tab=project&status=REQUESTED`}>더보기 &gt;</Link>
          </Row>
        </Row>
        {recentProgress?.map((it, index) => {
          if (Object.prototype.hasOwnProperty.call(it, 'jobId')) {
            return <ContentResultRow key={index} result={it as JobResultResponse} />;
          }
          if (Object.prototype.hasOwnProperty.call(it, 'projectId')) {
            return <ContentRow key={index} jobs={it as JobsResponse} />;
          }
          return <ProjectRow key={index} project={it as ProjectResponse} />;
        })}
      </Column>

      <Column>
        <Row className={'titleWrap'}>
          <Title>작업착수관리</Title>
          {/* TODO 더보기 Event */}
          <Row>
            <Link to={`${AppPaths.projects.path}?tab=project&status=REGISTERED`}>더보기 &gt;</Link>
          </Row>
        </Row>
        {initManage?.map((it, index) => {
          if (Object.prototype.hasOwnProperty.call(it, 'jobId')) {
            return <ContentResultRow key={index} result={it as JobResultResponse} />;
          }
          if (Object.prototype.hasOwnProperty.call(it, 'projectId')) {
            return <ContentRow key={index} jobs={it as JobsResponse} />;
          }
          return <ProjectRow key={index} project={it as ProjectResponse} />;
        })}
      </Column>
    </DualTableWrapper>
  );
}

export default DualTable;
