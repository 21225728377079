import React from 'react';
import styled from 'styled-components';
import { Card, Row } from '../styled/layout';
import { CheckBox } from '../styled/form';
import ICN_TRIANGLE_DOWN from 'src/assets/icons/icn-triangle-down.svg';
import { SelectWrapper } from './styled';
import { cn, useExceptClick, useMount } from '@voithru/front-core';

interface OptionData {
  value: string;
  display?: string;
  disabled?: boolean;
}

interface Props {
  placeholder?: string;
  disabled?: boolean;

  defaultValue?: string | string[];
  value?: string | string[];
  multiple?: boolean;
  onChange?(value: string | string[]): void;

  className?: string;
  children?: React.ReactNode;
}

function Select(props: Props) {
  const { placeholder, disabled, defaultValue, value, multiple, onChange, className, children } = props;

  const [open, setOpen] = React.useState(false);
  const handleClick = React.useCallback(() => !disabled && setOpen((x) => !x), [disabled]);
  const exceptRef = useExceptClick(null, () => setOpen(false)) as React.RefObject<HTMLDivElement>;

  const data = React.useMemo(() => {
    const values = React.Children.map(children, (it): OptionData => {
      if (!React.isValidElement(it)) {
        throw new Error('DropDown: Use `option` in children');
      }

      const { value, disabled, children } = it.props;

      return {
        value: value ?? children,
        display: children ?? value,
        disabled,
      };
    });
    return values;
  }, [children]);

  const display = React.useMemo(() => {
    const target = data?.filter((it) => (Array.isArray(value) ? value.includes(it.value) : it.value === value));
    if (!target?.[0]) {
      return data?.filter((it) => it.disabled);
    }

    return target;
  }, [data, value]);

  const handleChange = React.useCallback(
    (target: OptionData) => () => {
      if (!multiple) {
        setOpen(false);
        return onChange?.(target.value);
      }

      const arr = Array.isArray(value) ? [...value] : [];
      if (!Array.isArray(value) && value) {
        arr.push(value);
      }

      const idx = arr.indexOf(target.value);
      if (idx < 0) {
        arr.push(target.value);
      } else {
        arr.splice(idx, 1);
      }

      return onChange?.(arr);
    },
    [multiple, onChange, value]
  );

  useMount(() => {
    if (!defaultValue) {
      return;
    }

    if (value || (Array.isArray(value) && value.length > 0)) {
      return;
    }

    onChange?.(defaultValue);
  });

  return (
    <SelectWrapper ref={exceptRef} className={cn(disabled && 'disabled', className)}>
      <Row onClick={handleClick}>
        <span className={cn((!display?.[0] || display[0].disabled) && 'placeholder')}>
          {(display?.map((it) => it.display).join(', ') || placeholder) ?? ''}
        </span>
        <img src={ICN_TRIANGLE_DOWN} alt={'arrow-down'} />
      </Row>
      {open && (
        <Card>
          {data?.map((it, idx) => {
            if (it.disabled) {
              return <React.Fragment key={idx} />;
            }

            return (
              <Row key={idx} onClick={handleChange(it)}>
                {multiple && <CheckBox checked={Boolean(value === it.value || value?.includes(it.value))} disabled />}
                <span>{it.display || ''}</span>
              </Row>
            );
          })}
        </Card>
      )}
    </SelectWrapper>
  );
}

export default styled(Select)``;
