import { Path } from 'src/types/Path';

const login = Object.freeze<Path>({
  name: 'Login',
  path: '/login',
});

const register = Object.freeze<Path>({
  name: 'Register',
  path: '/register',
});

const page404 = Object.freeze<Path>({
  name: 'Page404',
  path: '/404',
});

const page500 = Object.freeze<Path>({
  name: 'Page500',
  path: '/500',
});

const dashboard = Object.freeze<Path>({
  name: 'Dashboard',
  path: '/',
});

const projects = Object.freeze<Path>({
  name: 'Projects',
  path: '/projects',
});

const createProject = Object.freeze<Path>({
  name: 'CreateProject',
  path: '/projects/create',
});

const projectDetail = Object.freeze<Path>({
  name: 'ProjectDetail',
  path: '/projects/:id',
});

const createJob = Object.freeze<Path>({
  name: 'CreateJob',
  path: '/createJob',
});

const detailContent = Object.freeze<Path>({
  name: 'DetailContent',
  path: '/contents/:id',
});

const clientAccounts = Object.freeze<Path>({
  name: 'ClientAccounts',
  path: '/clientAccounts',
});

const clientAccountDetail = Object.freeze<Path>({
  name: 'ClientAccountDetail',
  path: '/clientAccounts/detail',
});

const clientSubAccountDetail = Object.freeze<Path>({
  name: 'ClientSubAccountDetail',
  path: '/clientAccounts/detail/subAccount',
});

const accountSetting = Object.freeze<Path>({
  name: 'AccountSetting',
  path: '/accountSetting/:id',
});

const accountSettingMe = Object.freeze<Path>({
  name: 'AccountSetting',
  path: '/accountSetting/me',
});

const adminAccounts = Object.freeze<Path>({
  name: 'AdminAccounts',
  path: '/adminAccounts',
});

const settlementManagement = Object.freeze<Path>({
  name: 'SettlementManagement',
  path: '/settlementManagement',
});

const settlementList = Object.freeze<Path>({
  name: 'SettlementList',
  path: '/settlementList',
});

const products = Object.freeze<Path>({
  name: 'Products',
  path: '/products',
});

const workerRelay = Object.freeze<Path>({
  name: 'WorkerRelay',
  path: '/workerRelay',
});

const histories = Object.freeze<Path>({
  name: 'Histories',
  path: '/histories',
});

const notifications = Object.freeze<Path>({
  name: 'Notifications',
  path: '/notifications',
});

const minglo = Object.freeze<Path>({
  name: 'minglo login',
  path: '/minglo',
});

const AppPaths = {
  // Auth
  login,
  register,
  page404,
  page500,

  // Projects
  dashboard,
  projects,
  createProject,
  projectDetail,

  // Contents
  createJob,
  detailContent,

  // Account
  clientAccounts,
  clientAccountDetail,
  clientSubAccountDetail,
  adminAccounts,
  accountSetting,
  accountSettingMe,

  // Settlement
  settlementManagement,
  settlementList,

  // Product
  products,

  // WorkerRelay
  workerRelay,

  // Histories
  histories,
  notifications,

  // minglo
  minglo,
};

export default AppPaths;
