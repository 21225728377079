import { CButton, CFormInput, CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react';
import { useMount } from '@voithru/front-core';
import React from 'react';
import { useForm } from 'react-hook-form';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { CompanyResponse } from 'src/types/api/Company';
import { isAxiosError } from 'src/utils/api/axios';

interface FormData {
  search: string;
}

interface IProps {
  setTargetItem: (item: CompanyResponse) => void;
}

function CompanySearchModal(props: IProps) {
  const { setTargetItem } = props;
  const [visible, setVisible] = React.useState(false);
  const [searchList, setSearchList] = React.useState<CompanyResponse[]>([]);
  const [filteredList, setFilteredList] = React.useState<CompanyResponse[]>([]);
  const { handleSubmit, register } = useForm<FormData>();

  const getCompanies = React.useCallback(async () => {
    try {
      const res = await api.company.get();
      if (isAxiosError(res)) {
        throw res;
      }
      setSearchList(res.data);
      setFilteredList(res.data);
    } catch (error) {
      errorLogger.error(error);
    }
  }, []);

  useMount(() => {
    getCompanies();
  });

  const submit = React.useCallback(
    (form: FormData) => {
      setFilteredList(searchList.filter((item) => item.name.includes(form.search)));
    },
    [searchList]
  );

  const handleSelectItem = React.useCallback(
    (item: CompanyResponse) => {
      setTargetItem(item);
      setVisible(false);
    },
    [setTargetItem, setVisible]
  );

  return (
    <div className="align-items-center d-flex">
      <CButton style={{ width: 'max-content' }} onClick={() => setVisible(!visible)}>
        🔎
      </CButton>
      <CModal size={'lg'} visible={visible} onDismiss={() => setVisible(false)}>
        <CModalHeader onDismiss={() => setVisible(false)}>
          <CModalTitle>회사 검색하기</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
            <form onChange={handleSubmit(submit)}>
              <div className="mb-3">
                <CFormInput type={'text'} {...register('search')} />
              </div>
              {filteredList?.map((item, idx) => (
                <div
                  key={idx}
                  style={{ borderBottom: '1px solid black', padding: '2px', cursor: 'pointer' }}
                  onClick={() => handleSelectItem(item)}
                >
                  {item.name}
                </div>
              ))}
            </form>
          </div>
        </CModalBody>
      </CModal>
    </div>
  );
}

export default CompanySearchModal;
