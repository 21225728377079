import { CCard, CCardBody, CCardGroup, CCol, CContainer, CRow } from '@coreui/react';
import React from 'react';
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { useLoading } from 'src/components/context/ApplicationContext';
import { useAuth } from 'src/components/context/AuthContext';
import { TOTUS_GOOGLE_CLIENT_ID } from 'src/constants/configs';
import { APIError } from 'src/types/api';
import { isAxiosError } from 'src/utils/api/axios';
import AccountRegisterDone from './components/AccountRegisterDone';

interface GoogleSignUpData {
  email: string;
  name: string;
  googleId: string;
}

function LoginPage() {
  const auth = useAuth();
  const loading = useLoading();

  const [visible, setVisible] = React.useState(false);

  const handleScriptLoadFailure = React.useCallback((error: unknown) => {
    errorLogger.error(error);
    window.alert('구글 로그인을 준비하는데 문제가 발생했습니다.');
  }, []);

  const handleGoogleSignup = React.useCallback(async (data: GoogleSignUpData) => {
    const { email, name, googleId } = data;
    const res = await api.signUp.googleOpenId({ email, name, phoneNumber: '', role: 'ADMIN', googleOauthId: googleId });
    if (isAxiosError(res)) {
      throw res;
    }

    setVisible(true);
  }, []);

  const handleGoogleLogin = React.useMemo(
    () =>
      loading(async (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
        if ('code' in response) {
          return;
        }

        const { profileObj, accessToken } = response;

        try {
          await auth
            .loginWithGoogleLogin({
              accessToken,
              name: profileObj.name,
            })
            .catch(async (error) => {
              if (isAxiosError(error) && error.response?.status === 403) {
                return window.alert('계정 활성화가 필요합니다. 담당자에게 문의하세요.');
              }

              if (isAxiosError(error) && error.response?.status === 401) {
                return await handleGoogleSignup(profileObj);
              }

              throw error;
            });
        } catch (error) {
          errorLogger.error(error);

          if (isAxiosError<APIError>(error) && /voithru domain/.test(error.response?.data?.message || '')) {
            window.alert('Voithru 사내 계정으로 로그인해주세요.');
          } else if (isAxiosError<APIError>(error) && /already exists/.test(error.response?.data?.message || '')) {
            window.alert('Admin 권한이 없습니다.');
          }
        }
      }),
    [auth, handleGoogleSignup, loading]
  );

  const handleGoogleLoginFailure = React.useCallback((error: unknown) => {
    if (typeof error === 'object' && typeof error?.['error'] === 'string') {
      if (error['error'] === 'popup_closed_by_user') {
        return;
      }
    }

    errorLogger.error(error);
    window.alert('구글 로그인에 실패했습니다.');
  }, []);

  return (
    <>
      {visible && <AccountRegisterDone onClose={() => setVisible(false)} />}
      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={8}>
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">Sign In to your account</p>
                    <CRow>
                      <CCol xs={6}>
                        <GoogleLogin
                          clientId={TOTUS_GOOGLE_CLIENT_ID}
                          onSuccess={handleGoogleLogin}
                          onFailure={handleGoogleLoginFailure}
                          onScriptLoadFailure={handleScriptLoadFailure}
                        />
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </>
  );
}

export default LoginPage;
