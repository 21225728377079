import { CCard } from '@coreui/react';
import { Badge, Title } from 'src/components/atoms/styled/element';
import { Column, Row } from 'src/components/atoms/styled/layout';
import styled from 'styled-components';

export const JobSummaryCountWrapper = styled(Column)`
  padding: 8px 16px;
  box-sizing: border-box;

  & > ${Title} {
    display: block;
    height: 24px;

    font-size: 16px;
    font-weight: 500;
  }

  & > ${Badge} {
    font-size: 12px;
  }

  & > span:not(${Badge}) {
    display: block;

    color: var(--cui-primary);
    font-size: 20px;
    font-weight: 700;
    text-align: right;
    word-break: break-all;
  }
`;

export const JobSummaryWrapper = styled(CCard)`
  padding: 8px 16px;
  width: 100%;
  height: 100%;

  & > ${Row} {
    align-items: stretch;

    & > ${JobSummaryCountWrapper}.total {
      border-right: 1px solid var(--cui-light);
      width: 140px;
    }

    & > ${Column}:not(.total) {
      flex: 1;

      &:not(:first-child) {
        border-left: 1px solid var(--cui-light);
      }

      & > ${JobSummaryCountWrapper} {
        border-bottom: 1px solid var(--cui-light);
      }

      & > ${Row} {
        & > ${Column}.description {
          justify-content: flex-end;
        }

        & span.error {
          color: var(--cui-red);
        }
      }
    }
  }
`;
