import React from 'react';
import { Title } from 'src/components/atoms/styled/element';
import EntityHistoryTable from 'src/components/organisms/EntityHistoryTable';
import { HistoriesWrapper } from './styled';

function HistoriesPage() {
  return (
    <HistoriesWrapper>
      <Title>이력 조회</Title>
      <EntityHistoryTable />
    </HistoriesWrapper>
  );
}

export default HistoriesPage;
