import {
  CButton,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CHeaderBrand,
} from '@coreui/react';
import { useMount } from '@voithru/front-core';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { useLoading } from 'src/components/context/ApplicationContext';
import { LANGUAGES, LANGUAGES_SUPPORT } from 'src/constants/APILanguages';
import {
  PRODUCT_DELIVERY_TYPES,
  PRODUCT_ORDER_OPTION_TYPES,
  PRODUCT_ORDER_TYPES,
  PRODUCT_SOURCE_TYPES,
} from 'src/constants/Product';
import {
  ProductOrder,
  ProductOrderOption,
  ProductOrderOptionType,
  ProductOrderResponse,
} from 'src/types/api/ProductOrder';
import { ProjectResponse } from 'src/types/api/Project';
import { isAxiosError } from 'src/utils/api/axios';
import {
  AdditionalServices,
  ContentType,
  ResultType,
  Source_Language,
  Translate_Language,
  WorkRange,
  WorkTarget,
} from 'src/utils/translate';
import { FormDataOfProductOrder, PageState } from '../../type';

type FormData = Partial<ProductOrder> & { description: string } & {
  productOrders: FormDataOfProductOrder[];
};

const INIT: FormDataOfProductOrder = {
  productType: 'TRANSLATION',
  sourceLanguage: 'KOREAN',
};

interface Props {
  project: ProjectResponse;
}

function ProjectCreateOrder(props: Props) {
  const { project } = props;

  const loading = useLoading();

  const { handleSubmit, reset, register, control, watch, setValue } = useForm<FormData>();

  const [responseOrders, setResponseOrders] = React.useState<ProductOrderResponse[]>([]);
  const loadProductOrders = React.useMemo(
    () =>
      loading(async () => {
        try {
          const res = await api.project.item(project.id).productOrders();
          if (isAxiosError(res)) {
            throw res;
          }

          const orders = res.data.map(
            (it): FormDataOfProductOrder => ({
              id: it.id,
              projectId: it.projectId,

              productType: it.productType,
              productSource: it.productSource,
              sourceLanguage: it.sourceLanguage,
              translateLanguage: it.translateLanguage,
              deliveryType: it.deliveryType,

              options: it.options?.map((it) => {
                return it.type as ProductOrderOptionType | 'NULL';
              }),
              quantity: it.quantity.toString(),
            })
          );

          setResponseOrders(res.data);

          setValue('productOrders', orders);
        } catch (error) {
          errorLogger.error(error);
        }
      }),
    [project.id, setValue, loading]
  );

  useMount(() => {
    reset(project);
    loadProductOrders();
  });

  const { pathname, search, state: pageState } = useLocation<PageState>();
  const history = useHistory<PageState>();

  const { fields, append, remove } = useFieldArray({ control, name: 'productOrders', keyName: 'filedsKey' });

  const submit = React.useMemo(
    () =>
      loading(async (form: FormData) => {
        const { description } = form;

        const productOrdersInfo = form.productOrders
          .map((productorder) => {
            const {
              productType,
              sourceLanguage,
              translateLanguage,
              deliveryType,
              productSource,
              options,
              quantity,
              id,
            } = productorder;

            if (!productType || !sourceLanguage) {
              return undefined;
            }

            const filterdOptions = options
              ?.filter((item) => item !== 'NULL')
              .map((it) => {
                return { type: it };
              });

            const order: ProductOrder = {
              projectId: project.id,
              id: id,
              productType: productType,
              sourceLanguage: sourceLanguage,
              deliveryType: deliveryType,
              quantity: Number(quantity),
              deleted: false,
            };

            if (translateLanguage) {
              order.translateLanguage = translateLanguage;
            }

            if (productSource) {
              order.productSource = productSource;
            }

            if (options && options.length !== 0) {
              order.options = filterdOptions as ProductOrderOption[];
            }

            return order;
          })
          .filter(Boolean) as ProductOrder[];

        const deleted = responseOrders
          .filter((it) => !productOrdersInfo.find((o) => o.id === it.id))
          .map((it) => ({ ...it, deleted: true }));

        if (project) {
          project['description'] = description;
        }

        try {
          if (productOrdersInfo.length === 0) {
            return;
          }
          const res = await api.project.item(project.id).related({
            productOrders: productOrdersInfo.concat(deleted),
            project: project,
          });
          if (isAxiosError(res)) {
            throw res;
          }

          history.replace({ pathname, search, state: { ...pageState, step: 'UPLOAD' } });
        } catch (err) {
          errorLogger.error(err);
        }
      }),
    [loading, responseOrders, project, history, pathname, search, pageState]
  );

  const prev = React.useCallback(() => {
    history.replace({ pathname, search, state: { ...pageState, step: 'INFO' } });
  }, [history, pageState, pathname, search]);

  if (!project?.category) {
    return null;
  }

  return (
    <div className="bg-light d-flex flex-column justify-content-center">
      <CHeaderBrand>프로젝트 등록하기 2/4 - 프로젝트 예상작업</CHeaderBrand>
      <div>작업의 종류에 따라 단가가 책정되며 결과물이 납품됩니다.</div>
      <div>동일한 콘텐츠라도 납품 받으실 작업 결과물이 다른 경우 작업을 추가해 주세요.</div>

      <CContainer onSubmit={handleSubmit(submit)}>
        <form>
          <div style={{ paddingBottom: '20px' }}>
            <div className="m-4">
              <div className="d-flex">
                현재 의뢰하시는 프로젝트의 컨텐츠 타입은{' '}
                <div
                  style={{
                    color: 'blue',
                    marginLeft: '3px',
                  }}
                >
                  {' '}
                  {project?.category && ContentType[project.category]}
                </div>
                입니다.
              </div>
              <CButton
                className="m-2"
                onClick={() => {
                  append(INIT);
                }}
              >
                +작업 추가하기
              </CButton>
              <div
                style={{
                  borderTop: '1px solid black',
                  borderBottom: '1px solid black',
                }}
              >
                {fields.map((item, idx) => (
                  <div key={item.filedsKey} style={{ display: 'flex' }}>
                    {project?.category && (
                      <>
                        <div
                          className="bg-light d-flex justify-content-between p-2"
                          style={{ borderBottom: '1px solid black' }}
                        >
                          <div style={{ width: '150px' }}>
                            <CForm>
                              <div className="mb-3">
                                <CFormSelect {...register(`productOrders.${idx}.productType`, { required: true })}>
                                  {PRODUCT_ORDER_TYPES[project.category]?.map((item, idx) => (
                                    <option key={idx} value={item}>
                                      {WorkRange[item]}
                                    </option>
                                  ))}
                                </CFormSelect>
                              </div>
                            </CForm>
                          </div>
                          <div className="d-block" style={{ width: '450px' }}>
                            <div className="d-flex justify-content-between">
                              <CForm>
                                <div className="mb-3">
                                  <CFormSelect {...register(`productOrders.${idx}.sourceLanguage`, { required: true })}>
                                    {LANGUAGES_SUPPORT.map((item, idx) => (
                                      <option key={idx} value={item}>
                                        {Source_Language[item]}
                                      </option>
                                    ))}
                                  </CFormSelect>
                                </div>
                              </CForm>

                              {(watch(`productOrders.${idx}.productType`) === 'TRANSLATION' ||
                                watch(`productOrders.${idx}.productType`) === 'TYPESETTER_ONLY') && (
                                <>
                                  <div>{'->'}</div>
                                  <CForm>
                                    <div className="mb-3">
                                      <CFormSelect {...register(`productOrders.${idx}.translateLanguage`)}>
                                        {LANGUAGES.filter(
                                          (lan) => lan !== watch(`productOrders.${idx}.sourceLanguage`)
                                        ).map((item) => (
                                          <option key={item} value={item}>
                                            {Translate_Language[item]}
                                          </option>
                                        ))}
                                      </CFormSelect>
                                    </div>
                                  </CForm>
                                </>
                              )}
                            </div>
                            <div>
                              {(project.category === 'VIDEO' || project.category === 'WEBTOON') && (
                                <CForm>
                                  <div className="mb-3">
                                    <CFormSelect {...register(`productOrders.${idx}.deliveryType`)}>
                                      {PRODUCT_DELIVERY_TYPES[project.category]
                                        ?.filter((it) => {
                                          if (project.category === 'VIDEO') {
                                            return (
                                              watch(`productOrders.${idx}.productType`) !== 'BURN_IN' ||
                                              it !== 'NO_BURN_IN'
                                            );
                                          }
                                          if (project.category === 'WEBTOON') {
                                            return (
                                              watch(`productOrders.${idx}.productType`) !== 'TYPESETTER_ONLY' ||
                                              it !== 'NO_TYPESETTER'
                                            );
                                          }

                                          return it;
                                        })
                                        ?.map((item) => (
                                          <option key={item} value={item}>
                                            {ResultType[item]}
                                          </option>
                                        ))}
                                    </CFormSelect>
                                  </div>
                                </CForm>
                              )}
                            </div>
                            <div>
                              {project.category === 'VIDEO' && (
                                <CForm>
                                  <div className="mb-3">
                                    <CFormSelect {...register(`productOrders.${idx}.productSource`)}>
                                      {PRODUCT_SOURCE_TYPES[project.category]?.map((item) => (
                                        <option key={item} value={item}>
                                          {WorkTarget[item]}
                                        </option>
                                      ))}
                                    </CFormSelect>
                                  </div>
                                </CForm>
                              )}
                            </div>
                          </div>
                          <div style={{ width: '400px' }}>
                            <CForm>
                              <div className="mb-3">
                                <CFormSelect {...register(`productOrders.${idx}.options`)} multiple={true}>
                                  {['NULL', ...(PRODUCT_ORDER_OPTION_TYPES[project.category] || [])]?.map((item) => (
                                    <option key={item} value={item}>
                                      {AdditionalServices[item]}
                                    </option>
                                  ))}
                                </CFormSelect>
                              </div>
                            </CForm>
                          </div>
                          <div>
                            <div className="mb-3">
                              <CFormInput
                                type={'text'}
                                placeholder={'작업량'}
                                {...register(`productOrders.${idx}.quantity`)}
                              />
                            </div>
                            <div>웹툰 - 00화</div>
                            <div>웹소설 - 글자 00개</div>
                            <div>영상 - 00분</div>
                          </div>
                        </div>
                        <CButton
                          onClick={() => {
                            remove(idx);
                          }}
                        >
                          X
                        </CButton>
                      </>
                    )}
                  </div>
                ))}
              </div>
              <CForm>
                <div className="mb-3">
                  <CFormLabel>특이사항(선택)</CFormLabel>
                  <CFormTextarea
                    placeholder={'작업 추가 요청 사항, 특이사항 등을 입력해주세요'}
                    {...register('description')}
                  />
                </div>
              </CForm>
            </div>
            <div>
              <CButton color="secondary" onClick={() => prev()}>
                뒤로가기
              </CButton>
              <CButton style={{ width: '80px' }} type={'submit'}>
                다음
              </CButton>
            </div>
          </div>
        </form>
      </CContainer>
    </div>
  );
}

export default ProjectCreateOrder;
