import { CFormSelect } from '@coreui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import FormModal from 'src/components/organisms/FormModal';
import { JobsStatus } from 'src/types/api/Job';
import { ProjectStatus } from 'src/types/api/Project';
import { Status } from 'src/utils/translate';

type StatusValue = ProjectStatus | JobsStatus;

interface FormData {
  status: StatusValue;
}

interface Props {
  title?: React.ReactNode;
  visible: boolean;
  onDismiss(): void;
  onSubmit(status: StatusValue): void | Promise<void>;

  status?: StatusValue;
}

function StatusChangeModal(props: Props) {
  const { title = '상태 업데이트', visible, onDismiss, onSubmit, status } = props;

  const { register, handleSubmit } = useForm<FormData>({ shouldUnregister: true });
  const submit = React.useCallback(
    async (form: FormData) => {
      const { status } = form;
      onSubmit(status);
    },
    [onSubmit]
  );

  const options = React.useMemo(() => {
    const entries = Object.entries(Status);
    if (status) {
      return entries.filter(([key]) => getStatusOptions(status).includes(key));
    }

    return entries;
  }, [status]);

  return (
    <FormModal title={title} visible={visible} onDismiss={onDismiss} onSubmit={handleSubmit(submit)}>
      <CFormSelect {...register('status')}>
        {options.map((it) => {
          const [key, value] = it;

          return (
            <option key={key} value={key}>
              {value}
            </option>
          );
        })}
      </CFormSelect>
    </FormModal>
  );
}

export default StatusChangeModal;

function getStatusOptions(status: StatusValue) {
  switch (status) {
    case 'REQUESTING':
      return ['DROP'];
    case 'REQUESTED':
      return ['REGISTERED', 'DROP'];
    case 'REGISTERED':
      return ['PROCESSING', 'DROP'];
    case 'PROCESSING':
      return ['DONE', 'DROP'];
    case 'DONE':
      return ['PROCESSING'];
    case 'DROP':
      return ['PROCESSING'];
    default:
      return [];
  }
}
