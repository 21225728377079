import { useQuery } from '@apollo/client';
import {
  CButton,
  CCol,
  CContainer,
  CFormLabel,
  CHeaderBrand,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import React from 'react';
import { useHistory } from 'react-router';
import errorLogger from 'src/api/errorLogger';
import { useContentsUploaderService, useGoogleFiles } from 'src/components/context/ContentsUploaderContext';
import AppPaths from 'src/constants/AppPaths';
import { ProjectResponse } from 'src/types/api/Project';
import { dateFormat } from 'src/utils/date';
import {
  AdditionalServices,
  ContentType,
  ResultType,
  Source_Language,
  Translate_Language,
  WorkRange,
} from 'src/utils/translate';
import { ITransactionId } from '../../type';
import { GQL_PROJECT_CREATE_DONE } from './gql';
import { ProjectCreateDoneQuery, ProjectCreateDoneQueryVariables } from './gql.generated';

interface Props {
  projectId: number;
  setProject?: (project: ProjectResponse) => void;
  project?: ProjectResponse;
}

function ProjectCreateDone(props: Props & ITransactionId) {
  const { projectId, transactionId } = props;

  const history = useHistory();

  const service = useContentsUploaderService();
  const googleFiles = useGoogleFiles();

  const { data, loading } = useQuery<ProjectCreateDoneQuery, ProjectCreateDoneQueryVariables>(GQL_PROJECT_CREATE_DONE, {
    variables: {
      id: projectId.toString(),
    },
    onError: errorLogger.error,
  });

  const project = React.useMemo(() => {
    if (!data) return;
    return data.project;
  }, [data]);

  const fileGroups = service.getFileGroups(transactionId);

  const namedFiles = React.useMemo(() => {
    const item = googleFiles.get(transactionId);

    const sorted = [...(fileGroups ?? []), ...(item ?? [])]
      .sort((a, b) => {
        return a.index - b.index;
      })
      .map((it) => {
        return it.name;
      });

    return sorted;
  }, [googleFiles, transactionId, fileGroups]);

  const handleProjctsList = React.useCallback(() => history.push(AppPaths.projects.path), [history]);

  const [visibleArray, setVisibleArray] = React.useState(new Map());

  if (loading) return <div>loading...</div>;

  return (
    <div className="bg-light d-flex flex-column justify-content-center">
      <CHeaderBrand>프로젝트 등록하기 4/4 - 확인 및 추가 정보 입력하기</CHeaderBrand>
      <CContainer>
        <div style={{ paddingBottom: '20px' }}>
          <div>
            <div style={{ borderBottom: '1px solid black', marginTop: '20px' }}>
              <div className="p-3">
                <div className="align-items-center" style={{ marginBottom: '15px' }}>
                  <CRow className="align-items-center">
                    <CCol>
                      <CFormLabel>담당 PM</CFormLabel>
                    </CCol>
                    <CCol sm={8} style={{ position: 'relative' }}>
                      <CFormLabel>{project?.manager?.name}</CFormLabel>
                    </CCol>
                  </CRow>
                </div>
              </div>
            </div>
            <div style={{ borderBottom: '1px solid black', marginTop: '20px' }}>
              <div>담당자 정보</div>
              <div className="p-3">
                <div className="align-items-center" style={{ marginBottom: '15px' }}>
                  <CRow className="align-items-center">
                    <CCol>
                      <CFormLabel>회사명</CFormLabel>
                    </CCol>
                    <CCol sm={8} style={{ position: 'relative' }}>
                      <CFormLabel>{project?.account?.company?.name}</CFormLabel>
                    </CCol>
                  </CRow>
                  <CRow className="align-items-center">
                    <CCol>
                      <CFormLabel>담당자</CFormLabel>
                    </CCol>
                    <CCol sm={8} style={{ position: 'relative' }}>
                      <CFormLabel>{project?.account?.name}</CFormLabel>
                    </CCol>
                  </CRow>
                  <CRow className="align-items-center">
                    <CCol>
                      <CFormLabel>연락처</CFormLabel>
                    </CCol>
                    <CCol sm={8} style={{ position: 'relative' }}>
                      <CFormLabel>{project?.account?.phoneNumber}</CFormLabel>
                    </CCol>
                  </CRow>
                  <CRow className="align-items-center">
                    <CCol>
                      <CFormLabel>이메일</CFormLabel>
                    </CCol>
                    <CCol sm={8} style={{ position: 'relative' }}>
                      <CFormLabel>{project?.account?.email}</CFormLabel>
                    </CCol>
                  </CRow>
                </div>
              </div>
            </div>
            <div style={{ borderBottom: '1px solid black', marginTop: '20px' }}>
              <div>프로젝트 기본 정보</div>
              <div className="p-3">
                <div className="align-items-center" style={{ marginBottom: '15px' }}>
                  <CRow className="align-items-center">
                    <CCol>
                      <CFormLabel>프로젝트명</CFormLabel>
                    </CCol>
                    <CCol sm={8} style={{ position: 'relative' }}>
                      <CFormLabel>{project?.name}</CFormLabel>
                    </CCol>
                  </CRow>
                  <CRow className="align-items-center">
                    <CCol>
                      <CFormLabel>콘텐츠 유형</CFormLabel>
                    </CCol>
                    <CCol sm={8} style={{ position: 'relative' }}>
                      <CFormLabel>{project?.category && ContentType[project?.category]}</CFormLabel>
                    </CCol>
                  </CRow>
                  <CRow className="align-items-center">
                    <CCol>
                      <CFormLabel>희망 작업 기간</CFormLabel>
                    </CCol>
                    <CCol sm={8} style={{ position: 'relative' }}>
                      <CFormLabel>
                        {project?.requestedStartDateTime && dateFormat(project.requestedStartDateTime, 'YYYY.MM.DD')}
                        {project?.requestedDeadlineDateTime &&
                          dateFormat(project.requestedDeadlineDateTime, '~ YYYY.MM.DD')}
                      </CFormLabel>
                    </CCol>
                  </CRow>
                </div>
              </div>
            </div>
            <div style={{ borderBottom: '1px solid black', marginTop: '20px' }}>
              <div>프로젝트 부가 정보</div>
              <div className="p-3">
                {project?.projectFiles &&
                  project?.projectFiles.length > 0 &&
                  project?.projectFiles.map((file, idx) => (
                    <div key={idx} className="align-items-center" style={{ marginBottom: '15px' }}>
                      <CRow className="align-items-center">
                        <CCol>{idx === 0 && <CFormLabel>프로젝트 관련 첨부파일</CFormLabel>}</CCol>
                        <CCol sm={8} style={{ position: 'relative' }}>
                          <CFormLabel>{file?.file?.name}</CFormLabel>
                        </CCol>
                      </CRow>
                    </div>
                  ))}
                {project?.projectRelateLinks &&
                  project?.projectRelateLinks.length > 0 &&
                  project?.projectRelateLinks.map((link, idx) => (
                    <div key={idx} className="align-items-center" style={{ marginBottom: '15px' }}>
                      <CRow className="align-items-center">
                        <CCol>{idx === 0 && <CFormLabel>프로젝트 관련 첨부파일</CFormLabel>}</CCol>
                        <CCol sm={8} style={{ position: 'relative' }}>
                          <CFormLabel>{link.url}</CFormLabel>
                        </CCol>
                      </CRow>
                    </div>
                  ))}
                {project?.description && (
                  <div className="align-items-center" style={{ marginBottom: '15px' }}>
                    <CRow className="align-items-center">
                      <CCol>
                        <CFormLabel>특이사항</CFormLabel>
                      </CCol>
                      <CCol sm={8} style={{ position: 'relative' }}>
                        <CFormLabel>{project?.description}</CFormLabel>
                      </CCol>
                    </CRow>
                  </div>
                )}
              </div>
            </div>
            <div style={{ borderBottom: '1px solid black', marginTop: '20px' }}>
              <div>프로젝트 예상 작업</div>
              <div className="p-3">
                <CTable bordered style={{ width: 'max-content', minWidth: '100%' }}>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell>작업유형</CTableHeaderCell>
                      <CTableHeaderCell>작업언어</CTableHeaderCell>
                      <CTableHeaderCell>추가작업</CTableHeaderCell>
                      <CTableHeaderCell>작업량</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {project?.productOrders?.map((order, idx) => {
                      const { deliveryType, sourceLanguage, translateLanguage, quantity, productOptionOrders } = order;

                      return (
                        <CTableRow key={idx}>
                          <CTableDataCell>{deliveryType && ResultType[deliveryType]}</CTableDataCell>
                          <CTableDataCell>{`${sourceLanguage && Source_Language[sourceLanguage]} ${
                            (translateLanguage && ' -> ' + Translate_Language[translateLanguage]) ?? ''
                          }`}</CTableDataCell>
                          <CTableDataCell>
                            {productOptionOrders
                              ?.map((option) => option.type && AdditionalServices[option.type])
                              .join('/')}
                          </CTableDataCell>
                          <CTableDataCell>{quantity}</CTableDataCell>
                        </CTableRow>
                      );
                    })}
                  </CTableBody>
                </CTable>
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <div>작업 콘텐츠 목록 - 총 {namedFiles?.length}개</div>
              <div className="p-3">
                <div style={{ borderTop: '1px solid black' }}>
                  {namedFiles?.map((namedFile, idx) => {
                    return (
                      <div key={idx} style={{ borderBottom: '1px solid black' }}>
                        <div className="d-flex justify-content-between p-1">
                          <div className="d-flex" style={{ width: '50%', padding: '0 20px' }}>
                            <CCol>{idx + 1}</CCol>
                            <CCol>{project?.name}</CCol>
                            <CCol>{namedFile}</CCol>
                          </div>
                          {visibleArray.get(idx) === true ? (
                            <div
                              style={{
                                alignItems: 'center',
                                display: 'flex',
                                fontSize: '25px',
                              }}
                              onClick={() => setVisibleArray(new Map(visibleArray.set(idx, false)))}
                            >
                              △
                            </div>
                          ) : (
                            <div
                              style={{
                                alignItems: 'center',
                                display: 'flex',
                                fontSize: '25px',
                              }}
                              onClick={() => setVisibleArray(new Map(visibleArray.set(idx, true)))}
                            >
                              ▽
                            </div>
                          )}
                        </div>
                        {visibleArray.get(idx) === true && (
                          <div className="m-3" style={{ borderTop: '1px solid darkGray', padding: '20px 20px 0 20px' }}>
                            {project?.productOrders?.map((order, idx) => {
                              const {
                                productType,
                                deliveryType,
                                sourceLanguage,
                                translateLanguage,
                                productOptionOrders,
                              } = order;

                              return (
                                <div key={idx} className="d-flex">
                                  <CCol>
                                    {productType && WorkRange[productType]}
                                    {deliveryType && `_` + ResultType[deliveryType]}
                                  </CCol>
                                  <CCol>
                                    {sourceLanguage && Source_Language[sourceLanguage]}
                                    {translateLanguage && ` -> ${Translate_Language[translateLanguage]}`}
                                  </CCol>
                                  <CCol>
                                    {productOptionOrders
                                      ?.map((option) => option.type && AdditionalServices[option.type])
                                      .join(' / ')}
                                  </CCol>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div>
            <CButton style={{ width: '80px' }} onClick={handleProjctsList}>
              확인
            </CButton>
          </div>
        </div>
      </CContainer>
    </div>
  );
}

export default ProjectCreateDone;
