import { ButtonBase } from 'src/components/atoms/styled/button';
import { Column, CSSRow, Row } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const JobResultFileDownloadModalWrap = styled(Column)`
  & > ${Row}.buttonWrap {
    justify-content: flex-end;
  }

  & > ${Column} {
    padding: 12px;

    font-size: 15px;
    & > ${Row}.titleWrap {
      padding: 25px 0;

      border-bottom: 1px dotted ${getRGBA(colors.grey80)};
      & > span:nth-child(1) {
        width: 100px;
      }
      & > span:nth-child(2) {
        flex: 1;
      }

      & > span {
        margin-right: 10px;
      }
    }

    & > ${Column} {
      margin-left: 110px;

      & > ${Row}, & > ${ButtonBase} {
        padding: 8px 0;
      }

      & > ${Row} {
        & > span:nth-child(1) {
          width: 100px;
        }

        & > span:nth-child(2) {
          flex: 1;
        }
      }

      & > ${ButtonBase} {
        ${CSSRow};
        align-items: center;
        justify-content: center;

        & > img {
          margin-right: 4px;
        }
      }

      & > ${Column} {
        & > ${Row} {
          margin-top: 16px;

          & > span {
            text-align: center;
          }

          & > span:nth-child(1) {
            flex: 1;

            text-align: left;

            & > input {
              width: 80%;
            }
          }

          & > span:nth-child(2) {
            width: 80px;
          }
          & > span:nth-child(3) {
            width: 80px;
          }
          & > span:nth-child(4) {
            width: 80px;
          }
          & > ${ButtonBase} {
            width: 30px;

            text-align: right;
          }
        }
      }
    }

    ${Row}.files {
      margin-left: 98px;

      & > span:first-child {
        flex: 1;
      }

      & > span {
        margin-right: 25px;
      }
    }
  }
`;
