import axios from 'axios';
import { SLACK_API_SERVER, SLACK_API_SERVER_TIMEOUT } from 'src/constants/API';
import { SlackMembersInfoResponse } from 'src/types/api/Slack';

export interface SlackGetOptions {
  team_id: string;
  token: String;
}

const axiosSlack = axios.create({
  baseURL: SLACK_API_SERVER,
  timeout: SLACK_API_SERVER_TIMEOUT,
});

const slack = {
  instance: axiosSlack,

  userList: (idToken: string, teamId: string) => {
    const params: SlackGetOptions = { team_id: teamId, token: idToken };

    return axiosSlack.get<SlackMembersInfoResponse>('/api/users.list', { params });
  },
};

export default slack;
