import { useQuery } from '@apollo/client';
import React from 'react';
import ICN_DOWNLOAD from 'src/assets/icons/icn-download-24px.svg';
import StatusLabel from 'src/components/atoms/StatusLabel';
import { ButtonBase } from 'src/components/atoms/styled/button';
import { CheckBox } from 'src/components/atoms/styled/form';
import { Row } from 'src/components/atoms/styled/layout';
import { dateFormat } from 'src/utils/date';
import { downloadAPIFile } from 'src/utils/files';
import styled from 'styled-components';
import { JobTaskProgressWrapper } from './styled';
import { generatePath, Link } from 'react-router-dom';
import { ContentType } from 'src/utils/translate';
import AppPaths from 'src/constants/AppPaths';
import { GET_JOBS_QUERY } from './gql';
import { GetJobsQuery, GetJobsQueryVariables } from './gql.generated';

interface Props {
  jobs: { id: number }[];
  onChange?(id?: number): void;
  className?: string;
}

function JobTaskList(props: Props) {
  const { jobs, onChange, className } = props;
  const ids = jobs.map((job) => job.id.toString());

  const { data, loading, error } = useQuery<GetJobsQuery, GetJobsQueryVariables>(GET_JOBS_QUERY, {
    variables: {
      ids,
    },
  });

  const fileDownloads = React.useCallback((files: Array<{ id: string; name: string; fileType: string }>) => {
    if (files.length === 0) return;

    const filteredFiles = files.filter((it) => {
      return it.fileType === 'CONTENTS_FILE';
    });

    for (const item of filteredFiles) {
      downloadAPIFile(item);
    }
  }, []);

  if (loading) return <div>loading</div>;
  if (error) return <div>error</div>;

  return (
    <JobTaskProgressWrapper className={className}>
      <Row>
        <span></span>
        <span>콘텐츠 ID</span>
        <span>콘텐츠 타입</span>
        <span>프로젝트 명</span>
        <span>콘텐츠 명</span>
        <span>진행상태</span>
        <span>회사명</span>
        <span>고객담당자</span>
        <span>최초생성일</span>
        <span>최초수정일</span>
        <span>납품예정일</span>
        <span>담당PM</span>
        <span>작업파일 다운로드</span>
      </Row>
      {Array.from(data?.jobs ?? [])
        ?.sort((a, b) => ids.findIndex((id) => id === a.id) - ids.findIndex((id) => id === b.id))
        .map((it) => {
          const files =
            it.jobFiles
              ?.filter((jf) => jf != null && jf.file != null)
              ?.map((jf) => {
                const jobFile = jf!!;
                return { fileType: jobFile.fileType || '', id: jobFile.file!!.id, name: jobFile.file!!.name || '' };
              }) || [];

          const detailContentPath = generatePath(AppPaths.detailContent.path, { id: it.id });

          return (
            <Row key={it.id}>
              <span>{onChange && <CheckBox onChange={() => onChange(Number(it.id))} />}</span>
              <span>
                <Link to={detailContentPath}>CON-{it.id}</Link>
              </span>
              <span>{it.category == null ? null : ContentType[it.category]}</span>
              <span>{it.project?.name}</span>
              <span>{it.name}</span>
              <span>
                <StatusLabel status={it.status} />
              </span>
              <span>{it.account?.company?.name}</span>
              <span>{it.account?.company?.manager?.name}</span>
              <span>{dateFormat(it.createDateTime, 'YYYY.MM.DD')}</span>
              <span>{it.updateDateTime && dateFormat(it.updateDateTime, 'YYYY.MM.DD')}</span>
              <span>{it.scheduledDeadlineDateTime && dateFormat(it.scheduledDeadlineDateTime, 'YYYY.MM.DD')}</span>
              <span>{it.manager?.name}</span>
              <span>
                <ButtonBase disabled={it.jobFiles?.length === 0 ?? true} onClick={() => fileDownloads(files)}>
                  <img src={ICN_DOWNLOAD} alt={'download'} />
                </ButtonBase>
              </span>
            </Row>
          );
        })}
    </JobTaskProgressWrapper>
  );
}

export default styled(JobTaskList)``;
