import { numberPadding } from '@voithru/front-core';
import { getRelativeDateTranslate } from './translate';

export enum TimeUnit {
  MS = 1,
  S = 1000,
  MIN = TimeUnit.S * 60,
  HOUR = TimeUnit.MIN * 60,
  DAY = TimeUnit.HOUR * 24,
  WEEK = TimeUnit.DAY * 7,
  MONTH = TimeUnit.DAY * 30,
  YEAR = TimeUnit.DAY * 365,
}

interface DateFormatValues<T> {
  YYYY?: T;
  MM?: T;
  DD?: T;
  hh?: T;
  mm?: T;
  ss?: T;
  ms?: T;
}

interface DateFormatOptions {
  isLocale?: boolean;
  defaultPadding?: number;
  padding?: DateFormatValues<number>;
}
export function dateFormat(date: string | number | Date, format: string, option?: DateFormatOptions) {
  const { isLocale = false, defaultPadding = 2, padding } = option ?? {};
  const dateObj = new Date(date);
  if (isNaN(dateObj.getTime())) {
    throw new Error(`Invalid date (${date})`);
  }

  const splitted = (() => {
    if (!isLocale) {
      return dateObj.toISOString().split(/[-T:.Z]/g);
    }

    return [
      dateObj.getFullYear(),
      dateObj.getMonth() + 1,
      dateObj.getDate(),
      dateObj.getHours(),
      dateObj.getMinutes(),
      dateObj.getSeconds(),
      dateObj.getMilliseconds(),
    ];
  })();

  const YYYY = numberPadding(Number(splitted[0] || 0), padding?.YYYY ?? 4);
  const YY = (splitted + '').slice(2, 4);
  const MM = numberPadding(Number(splitted[1] || 0), padding?.MM ?? defaultPadding);
  const DD = numberPadding(Number(splitted[2] || 1), padding?.DD ?? defaultPadding);
  const hh = numberPadding(Number(splitted[3] || 0), padding?.hh ?? defaultPadding);
  const mm = numberPadding(Number(splitted[4] || 0), padding?.mm ?? defaultPadding);
  const ss = numberPadding(Number(splitted[5] || 0), padding?.ss ?? defaultPadding);
  const ms = numberPadding(Number(splitted[6] || 0), padding?.ms ?? 3);

  return format
    .replace(/YYYY/g, YYYY)
    .replace(/YY/g, YY)
    .replace(/MM/g, MM)
    .replace(/DD/g, DD)
    .replace(/hh/g, hh)
    .replace(/mm/g, mm)
    .replace(/ss/g, ss)
    .replace(/ms/g, ms);
}

export function isDueDay(date: string | number | Date) {
  const time = new Date(date);
  const now = Date.now();

  const diff = now - time.getTime();

  return diff / TimeUnit.DAY > 1;
}

export function recentDate(date: string | number | Date, withinDays: number) {
  const time = new Date(dateFormat(date, 'YYYY-MM-DDT24:00:00.000Z', { isLocale: true }));
  const now = Date.now();

  const diff = now - time.getTime();

  if (Math.abs(diff) / TimeUnit.DAY > withinDays) {
    return false;
  }

  return true;
}

interface FormatDueDayOption extends DateFormatOptions {
  format?: string;
  timeFormat?: string;
}

export function formatDueDay(date: string | number | Date, option?: FormatDueDayOption) {
  const { format = 'YYYY.MM.DD', timeFormat = 'hh.mm.ss', ...dateFormatOption } = option ?? {};

  if (isDueDay(date)) {
    return dateFormat(date, format, dateFormatOption);
  }

  return dateFormat(date, timeFormat, dateFormatOption);
}

export function divideDateFromTime(value: Date | undefined | string) {
  if (value !== undefined) {
    const splitDate = String(value).split('T');
    return splitDate[0];
  }
  return undefined;
}

export function lastTimeOfDate(date: string | number | Date) {
  const dateObj = new Date(date);

  return new Date(dateObj.setHours(23, 59, 59)).toISOString();
}

export function toFormattedPastDate(date: number | string | Date) {
  const time = new Date(date).getTime();
  const now = Date.now();

  const diff = now - time;

  if (Math.floor(diff / TimeUnit.YEAR) > 0) {
    return `${Math.floor(diff / TimeUnit.YEAR)}${getRelativeDateTranslate('BEFORE', 'YEAR')}`;
  } else if (Math.floor(diff / TimeUnit.MONTH) > 0) {
    return `${Math.floor(diff / TimeUnit.MONTH)}${getRelativeDateTranslate('BEFORE', 'MONTH')}`;
  } else if (Math.floor(diff / TimeUnit.WEEK) > 0) {
    return `${Math.floor(diff / TimeUnit.WEEK)}${getRelativeDateTranslate('BEFORE', 'WEEK')}`;
  } else if (Math.floor(diff / TimeUnit.DAY) > 0) {
    return `${Math.floor(diff / TimeUnit.DAY)}${getRelativeDateTranslate('BEFORE', 'DAY')}`;
  } else if (Math.floor(diff / TimeUnit.HOUR) > 0) {
    return `${Math.floor(diff / TimeUnit.HOUR)}${getRelativeDateTranslate('BEFORE', 'HOUR')}`;
  } else if (Math.floor(diff / TimeUnit.MIN) > 0) {
    return `${Math.floor(diff / TimeUnit.MIN)}${getRelativeDateTranslate('BEFORE', 'MIN')}`;
  } else if (Math.floor(diff / TimeUnit.S) > 0) {
    return `${Math.floor(diff / TimeUnit.S)}${getRelativeDateTranslate('BEFORE', 'SEC')}`;
  } else {
    return getRelativeDateTranslate('BEFORE', 'MOMENT');
  }
}

export function dateCmp(lhs: string | number | Date, rhs: string | number | Date): number {
  return new Date(lhs).getTime() - new Date(rhs).getTime();
}

interface FormatRecentDateFallbackOption extends DateFormatOptions {
  thresholdInMs: number;
  format: string;
}
export function formatRecentDate(date: string | number | Date, option?: FormatRecentDateFallbackOption) {
  if (option) {
    const { thresholdInMs, format, ...dateFormatOption } = option;
    const diff = dateCmp(date, new Date());
    if (diff > 0 || diff < -thresholdInMs) {
      return dateFormat(date, format, dateFormatOption);
    }
  }
  return toFormattedPastDate(date);
}
