import { ButtonBase } from 'src/components/atoms/styled/button';
import { Description, Title } from 'src/components/atoms/styled/element';
import { Column, Row } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const PreviewSecondModalWrapper = styled(Column)`
  & > ${ButtonBase} {
    padding: 7px 11px;

    background: black;
    border-radius: 4px;

    color: ${getRGBA(colors.white)};
  }
`;

export const PreviewSecondModalTitleWrapper = styled(Column)`
  margin: 12px;

  & > ${Row}.title {
    padding: 12px;

    border-top: 1px solid ${getRGBA(colors.grey80)};
    border-bottom: 1px solid ${getRGBA(colors.grey80)};

    & > ${Title} {
      width: 100%;
      text-align: center;
      font-weight: bold;

      font-size: 18px;
    }
  }

  & > ${Row}.description > ${Description} {
    font-size: 12px;
    & > span {
      margin-left: 14px;
    }
  }

  & > ${Row} + ${Row} {
    padding: 6px;
  }
`;

export const PreviewSecondModalBodyWrapper = styled(Column)`
  justify-content: space-between;

  min-height: 1024px;

  & > ${Column} {
    margin-top: 20px;

    align-items: center;

    & > ${Row} {
      width: 95%;

      font-size: 12px;
    }

    & > ${Row}.listRow {
      padding: 4px 0;

      & > span {
        padding-left: 4px;
      }

      text-align: left;
      span:nth-child(1) {
        width: 20px;
      }
      span:nth-child(2) {
        width: 80px;
      }
      span:nth-child(3) {
        width: 100px;
      }
      span:nth-child(4) {
        width: 140px;
      }
      span:nth-child(5) {
        width: 70px;
      }
      span:nth-child(6) {
        width: 80px;
      }
      span:nth-child(7) {
        width: 70px;
      }
      span:nth-child(8) {
        width: 70px;
      }
      span:nth-child(9) {
        width: 80px;
      }
      span:nth-child(10) {
        width: 80px;
      }
      span:nth-child(11) {
        width: 90px;
      }
      span:nth-child(12) {
        width: 70px;
      }
      span:nth-child(13) {
        width: 60px;

        word-break: break-all;
      }
      span:nth-child(14) {
        width: 60px;

        word-break: break-all;
      }

      & > ${Column}.additionalServices {
        width: 90px;

        & > span {
          width: 90px;
        }
      }
    }

    & > ${Row}:first-child {
      border-bottom: 1px solid ${getRGBA(colors.grey80)};
      box-sizing: border-box;
    }

    & > ${Row} + ${Row} {
      border-bottom: 1px solid ${getRGBA(colors.grey50)};
      box-sizing: border-box;
    }
  }

  & > ${Column}.totalPrice {
    border-bottom: 1px solid ${getRGBA(colors.grey80)};

    & > ${Row} {
      border-bottom: 0px;

      justify-content: space-between;

      span:nth-child(1) {
        margin-left: 30px;
      }
      span:nth-child(2) {
        width: 60px;

        word-break: break-all;
      }
    }
  }
`;
