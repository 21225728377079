import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CFormInput,
  CButton,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import SelectForm from 'src/components/atoms/Select/index.form';
import { LANGUAGES, LANGUAGES_SUPPORT } from 'src/constants/APILanguages';
import { PROJECT_CATEGORY } from 'src/constants/category';
import { PRODUCT_ORDER_TYPES, PRODUCT_DELIVERY_TYPES, PRODUCT_SOURCE_TYPES } from 'src/constants/Product';
import { APILanguages } from 'src/types/api';
import { Product } from 'src/types/api/Product';
import { ProductOrderType, DeliveryType, ProductSourceType } from 'src/types/api/ProductOrder';
import { ProjectCategory } from 'src/types/api/Project';
import { isAxiosError } from 'src/utils/api/axios';
import {
  WorkRange,
  Source_Language,
  Translate_Language,
  ResultType,
  WorkTarget,
  DifficultyType,
  ExpectedQualityType,
  VolumeType,
} from 'src/utils/translate';
import AddProductsAlertModal from '../AddProductsAlertModal';
import { AddProductsModalWrapper } from './styled';

interface ProductFormData {
  requestCategory: ProjectCategory;
  productType: ProductOrderType;
  deliveryType?: DeliveryType | '';
  productSource?: ProductSourceType | '';
  sourceLanguage?: APILanguages | '';
  translateLanguage?: APILanguages | '';
  difficulty: string;
  expectedQuality: string;
  volume: string;
  price: string;
}

interface FormData {
  product: ProductFormData[];
}

const INIT: ProductFormData = {
  requestCategory: 'VIDEO',
  productType: 'TRANSLATION',
  deliveryType: '',
  productSource: '',
  sourceLanguage: '',
  translateLanguage: '',
  difficulty: 'HIGH',
  expectedQuality: 'HIGH',
  volume: 'LARGE',
  price: '0',
};

const FORM_REQUEST_CATEGORY = { required: true };
const FORM_PRODUCT_TYPE = { required: true };
const FORM_DIFFICULTY = { required: true };
const FORM_EXPECTED_QUALITY = { required: true };
const FORM_VOLUME = { required: true };

interface Props {
  getProducts: () => void;
}

function AddProductsModal(props: Props) {
  const { getProducts } = props;

  const [visible, setVisible] = React.useState(false);

  const { register, handleSubmit, getValues, watch, control, setValue } = useForm<FormData>();
  const { fields, append } = useFieldArray({ control, name: 'product' });

  const watchProduct = watch('product');

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (!name) return;

      if (typeof name === 'string') {
        const [key, index, splitType] = name.split('.');

        if (key === 'product' && splitType === 'requestCategory' && type === 'change') {
          setValue(
            'product',
            getValues('product').map((it, idx) => {
              if (idx === parseInt(index)) {
                return {
                  ...INIT,
                  requestCategory: value.product[idx].requestCategory,
                };
              }
              return it;
            })
          );
        }
        if (key === 'product' && splitType === 'productType' && type === 'change') {
          setValue(
            'product',
            getValues('product').map((it, idx) => {
              if (idx === parseInt(index)) {
                return {
                  ...INIT,
                  requestCategory: value.product[idx].requestCategory,
                  productType: value.product[idx].productType,
                };
              }
              return it;
            })
          );
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [getValues, setValue, watch]);

  const handleProductAppend = React.useCallback(() => {
    const { product } = getValues();
    if (!product || product.length === 0) {
      return append(INIT);
    }

    const { requestCategory, productType, difficulty, expectedQuality, volume, price } = product[product.length - 1];

    if (!requestCategory || !productType || !difficulty || !expectedQuality || !volume || !price) {
      return window.alert('필수 값을 채워넣어주세요.');
    }

    append(INIT);
  }, [getValues, append]);

  const onSubmit = React.useCallback(
    async (data: ProductFormData[] = getValues().product) => {
      const products = data.map((it) => {
        const {
          requestCategory,
          productType,
          sourceLanguage,
          translateLanguage,
          deliveryType,
          productSource,
          difficulty,
          expectedQuality,
          volume,
          price,
        } = it;

        if (!requestCategory) {
          window.alert('콘텐츠 타입을 모두 설정해주세요.');
        }

        if (!productType) {
          window.alert('작업 범위를 모두 설정해주세요.');
        }

        const product: Product = {
          requestCategory: requestCategory,
          productType: productType,
          sourceLanguage: sourceLanguage || undefined,
          translateLanguage: translateLanguage || undefined,
          deliveryType: deliveryType || undefined,
          productSource: productSource || undefined,
          difficulty: difficulty,
          expectedQuality: expectedQuality,
          volume: volume,
          price: Number(price),
        };

        return product;
      });
      try {
        const res = await api.product.post(products);
        if (isAxiosError(res)) {
          throw res;
        }

        getProducts();
        setVisible(false);
      } catch (error) {
        errorLogger.error(error);
      }
    },
    [getProducts, getValues]
  );

  return (
    <form onSubmit={handleSubmit((data: ProductFormData[]) => onSubmit(data))}>
      <div className="align-items-center d-flex">
        <CButton style={{ width: 'max-content' }} onClick={() => setVisible(!visible)}>
          신규상품 등록
        </CButton>
        <AddProductsModalWrapper size={'xl'} visible={visible} onDismiss={() => setVisible(false)}>
          <CModalHeader onDismiss={() => setVisible(false)}>
            <CModalTitle>신규상품 등록하기</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <div className="d-flex flex-row  justify-content-end" style={{ marginBottom: '5px' }}>
              <button onClick={() => handleProductAppend()}>+</button>
            </div>
            <CTable bordered>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell>콘텐츠 타입</CTableHeaderCell>
                  <CTableHeaderCell>작업범위</CTableHeaderCell>
                  <CTableHeaderCell>원본언어</CTableHeaderCell>
                  <CTableHeaderCell>번역언어</CTableHeaderCell>
                  <CTableHeaderCell>납품형태</CTableHeaderCell>
                  <CTableHeaderCell>작업대상</CTableHeaderCell>
                  <CTableHeaderCell>난이도</CTableHeaderCell>
                  <CTableHeaderCell>납품퀄리티</CTableHeaderCell>
                  <CTableHeaderCell>볼륨</CTableHeaderCell>
                  <CTableHeaderCell>단가</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {fields.map((it, index) => {
                  const requestCategory = watch(`product.${index}.requestCategory`);
                  const productType = watch(`product.${index}.productType`);
                  const sourceLanguage = watch(`product.${index}.sourceLanguage`);

                  return (
                    <CTableRow key={it.id}>
                      <CTableDataCell>
                        <SelectForm.Single
                          control={control}
                          name={`product.${index}.requestCategory`}
                          placeholder={'콘텐츠 타입'}
                          rules={FORM_REQUEST_CATEGORY}
                        >
                          {Object.keys(PROJECT_CATEGORY).map((it) => (
                            <option key={it} value={it}>
                              {PROJECT_CATEGORY[it as ProjectCategory]}
                            </option>
                          ))}
                        </SelectForm.Single>
                      </CTableDataCell>

                      <CTableDataCell>
                        <SelectForm.Single
                          control={control}
                          name={`product.${index}.productType`}
                          placeholder={'작업범위'}
                          rules={FORM_PRODUCT_TYPE}
                        >
                          {PRODUCT_ORDER_TYPES[requestCategory]?.map((item) => (
                            <option key={item} value={item}>
                              {WorkRange[item]}
                            </option>
                          ))}
                        </SelectForm.Single>
                      </CTableDataCell>
                      <CTableDataCell>
                        <SelectForm.Single
                          control={control}
                          name={`product.${index}.sourceLanguage`}
                          placeholder={'원본언어'}
                        >
                          {LANGUAGES_SUPPORT.map((item) => (
                            <option key={item} value={item}>
                              {Source_Language[item]}
                            </option>
                          ))}
                        </SelectForm.Single>
                      </CTableDataCell>
                      <CTableDataCell>
                        {(watchProduct[index].productType === 'TRANSLATION' ||
                          watchProduct[index].productType === 'TYPESETTER_ONLY') && (
                          <SelectForm.Single
                            control={control}
                            name={`product.${index}.translateLanguage`}
                            placeholder={'번역언어'}
                          >
                            {LANGUAGES.filter((lan) => lan !== sourceLanguage).map((item) => (
                              <option key={item} value={item}>
                                {Translate_Language[item]}
                              </option>
                            ))}
                          </SelectForm.Single>
                        )}
                      </CTableDataCell>
                      <CTableDataCell>
                        {watchProduct[index].productType !== 'SPOTTING_ONLY' &&
                          watchProduct[index].productType !== 'TIME_CODE' && (
                            <SelectForm.Single
                              control={control}
                              name={`product.${index}.deliveryType`}
                              placeholder={'납품형태'}
                            >
                              {PRODUCT_DELIVERY_TYPES[requestCategory]
                                ?.filter((item) => {
                                  if (requestCategory === 'VIDEO') {
                                    if (
                                      productType === 'TRANSLATION' ||
                                      productType === 'TIME_CODE' ||
                                      productType === 'SPOTTING_ONLY'
                                    ) {
                                      return false;
                                    }

                                    return productType !== 'BURN_IN' || item !== 'NO_BURN_IN';
                                  }
                                  if (requestCategory === 'WEBTOON') {
                                    return productType !== 'TYPESETTER_ONLY' || item !== 'NO_TYPESETTER';
                                  }

                                  return item;
                                })
                                ?.map((item) => (
                                  <option key={item} value={item}>
                                    {ResultType[item]}
                                  </option>
                                ))}
                            </SelectForm.Single>
                          )}
                      </CTableDataCell>
                      <CTableDataCell>
                        {watchProduct[index].requestCategory === 'VIDEO' && (
                          <SelectForm.Single
                            control={control}
                            name={`product.${index}.productSource`}
                            placeholder={'작업대상'}
                          >
                            {PRODUCT_SOURCE_TYPES[requestCategory]?.map((item) => (
                              <option key={item} value={item}>
                                {WorkTarget[item]}
                              </option>
                            ))}
                          </SelectForm.Single>
                        )}
                      </CTableDataCell>
                      <CTableDataCell>
                        <SelectForm.Single
                          control={control}
                          name={`product.${index}.difficulty`}
                          placeholder={'난이도'}
                          rules={FORM_DIFFICULTY}
                        >
                          {Object.keys(DifficultyType).map((it) => (
                            <option key={it} value={it}>
                              {DifficultyType[it as DifficultyType]}
                            </option>
                          ))}
                        </SelectForm.Single>
                      </CTableDataCell>
                      <CTableDataCell>
                        <SelectForm.Single
                          control={control}
                          name={`product.${index}.expectedQuality`}
                          placeholder={'납품퀄리티'}
                          rules={FORM_EXPECTED_QUALITY}
                        >
                          {Object.keys(ExpectedQualityType).map((it) => (
                            <option key={it} value={it}>
                              {ExpectedQualityType[it as ExpectedQualityType]}
                            </option>
                          ))}
                        </SelectForm.Single>
                      </CTableDataCell>
                      <CTableDataCell>
                        <SelectForm.Single
                          control={control}
                          name={`product.${index}.volume`}
                          placeholder={'볼륨'}
                          rules={FORM_VOLUME}
                        >
                          {Object.keys(VolumeType).map((it) => (
                            <option key={it} value={it}>
                              {VolumeType[it as VolumeType]}
                            </option>
                          ))}
                        </SelectForm.Single>
                      </CTableDataCell>
                      <CTableDataCell>
                        <CFormInput {...register(`product.${index}.price`, { required: true })} />
                      </CTableDataCell>
                    </CTableRow>
                  );
                })}
              </CTableBody>
            </CTable>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => {
                setVisible(false);
              }}
            >
              취소
            </CButton>
            <AddProductsAlertModal productCount={fields.length} onClick={() => onSubmit()} />
          </CModalFooter>
        </AddProductsModalWrapper>
      </div>
    </form>
  );
}

export default AddProductsModal;
