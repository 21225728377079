import { AxiosInstance } from 'axios';
import { generatePath } from 'react-router';
import { CompanyFileRequest, CompanyFileResponse, CompanyResponse } from 'src/types/api/Company';

const COMPANIES_PATH = '/api/v1/companies';

function apiCompanies(instance: AxiosInstance) {
  return {
    get: () => instance.get<CompanyResponse[]>(COMPANIES_PATH),

    item(id: number) {
      const COMPANY_ITEM_PATH = generatePath(COMPANIES_PATH + '/:id', { id });

      return {
        get: () => instance.get<CompanyResponse>(COMPANY_ITEM_PATH),
        put: (data: Partial<CompanyResponse>) => instance.put<CompanyResponse[]>(COMPANIES_PATH, data),
        delete: () => instance.delete(COMPANIES_PATH),

        files: {
          get: () => instance.get<CompanyFileResponse[]>(COMPANY_ITEM_PATH + '/files'),
          post: (data: CompanyFileRequest[]) =>
            instance.post<CompanyFileResponse[]>(COMPANY_ITEM_PATH + '/files', data),
          item(fileId: string) {
            const COMPANY_FILE_ITEM_PATH = generatePath(COMPANY_ITEM_PATH + '/files/:id', { id: fileId });

            return {
              delete: () => instance.delete(COMPANY_FILE_ITEM_PATH),
            };
          },
        },
      };
    },
  };
}

export default apiCompanies;
