import {
  CButton,
  CCol,
  CFormLabel,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import { useMount } from '@voithru/front-core';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { Row } from 'src/components/atoms/styled/layout';
import CredentialInfo from 'src/components/organisms/CredentialInfo';
import CreateClientAccountModal from 'src/components/pages/ClientAccountDetailPage/components/CreateClientAccountModal';
import AppPaths from 'src/constants/AppPaths';
import { CompanyFileResponse, CompanyResponse } from 'src/types/api/Company';
import { UserResponse } from 'src/types/api/User';
import { isAxiosError } from 'src/utils/api/axios';
import { downloadAPIFile } from 'src/utils/files';
import { BusinessType } from 'src/utils/translate';
import CompanyInfoUpdateModal from './components/CompanyInfoUpdateModal';
import MasterAccountUpdateModal from './components/MasterAccountUpdateModal';

function ClientAccountDetailPage() {
  const history = useHistory();

  const params = new URLSearchParams(history.location.search);
  const accountId = params.get('id');
  const [account, setAccount] = React.useState<UserResponse>();
  const [company, setCompany] = React.useState<CompanyResponse>();
  const [salesAccount, setSalesAccount] = React.useState<UserResponse>();
  const [businessLicenses, setBusinessLicenses] = React.useState<CompanyFileResponse[]>();
  const [guides, setGuides] = React.useState<CompanyFileResponse[]>();
  const [subAccounts, setSubAccounts] = React.useState<UserResponse[]>();

  const init = React.useCallback(async () => {
    if (!accountId) {
      return;
    }
    try {
      const accountRes = await api.account.item(Number(accountId)).get();
      if (isAxiosError(accountRes)) {
        throw accountRes;
      }

      const accountData = accountRes.data;

      setAccount(accountData);

      if (accountData.companyId) {
        const companyRes = await api.company.item(accountData.companyId).get();
        if (isAxiosError(companyRes)) {
          throw companyRes;
        }

        setCompany(companyRes.data);

        if (companyRes.data.managerAccountId) {
          const managerRes = await api.account.item(companyRes.data.managerAccountId).get();
          if (isAxiosError(managerRes)) {
            throw managerRes;
          }

          setSalesAccount(managerRes.data);
        }

        const fileRes = await api.company.item(accountData.companyId).files.get();
        if (isAxiosError(fileRes)) {
          throw fileRes;
        }

        const businessLicenseFiles: CompanyFileResponse[] = [];
        const guideFiles: CompanyFileResponse[] = [];
        fileRes.data.map((file) => {
          if (file.fileType === 'BUSINESS_LICENSE') {
            return businessLicenseFiles.push(file);
          } else {
            return guideFiles.push(file);
          }
        });
        setBusinessLicenses([...businessLicenseFiles]);
        setGuides([...guideFiles]);
      }

      const subAccountRes = await api.account.get({ masterAccountId: Number(accountId) });
      if (isAxiosError(subAccountRes)) {
        throw subAccountRes;
      }

      setSubAccounts(subAccountRes.data);
    } catch (error) {
      errorLogger.error(error);
    }
  }, [accountId]);

  useMount(() => {
    init();
  });

  const [showMasterAccountUpdate, setMasterAccountUpdate] = React.useState(false);

  const [showClientCreate, setClientCreate] = React.useState(false);

  const handleCompanyFileDownload = React.useCallback(async (item: CompanyFileResponse) => {
    if (!item.file) return;

    await downloadAPIFile({
      id: item.file?.id,
      name: item.file?.name,
    });
  }, []);

  const handleDismissOfMasterUpdate = React.useCallback(() => {
    setMasterAccountUpdate(false);
    init();
  }, [setMasterAccountUpdate, init]);

  const handleDismissOfClientCreate = React.useCallback(() => {
    setClientCreate(false);
    init();
  }, [setClientCreate, init]);

  return (
    <div>
      <div className="justify-content-between d-flex">
        <div style={{ borderBottom: '1px solid  #d8dbe0', width: '100%', fontSize: '20px', margin: '40px 0 25px 0' }}>
          {company?.name}
        </div>
        <div className="align-items-center d-flex">
          <CButton style={{ width: 'max-content' }} onClick={() => alert('계정을 비활성화 하시겠습니까?')}>
            계정 비활성화
          </CButton>
        </div>
      </div>
      <div className="justify-content-between d-flex">
        <div style={{ borderBottom: '1px solid  #d8dbe0', width: '100%', fontSize: '20px', margin: '40px 0 25px 0' }}>
          회사 정보
        </div>
        <CompanyInfoUpdateModal
          company={company}
          businessLicenseFiles={businessLicenses}
          salesAccount={salesAccount}
          guideFiles={guides}
          callback={init}
        />
      </div>
      <div style={{ marginBottom: '15px' }}>
        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>구분</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormLabel>{company?.companyType ? BusinessType[company?.companyType] : ''}</CFormLabel>
          </CCol>
        </CRow>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>회사명</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormLabel>{company?.name}</CFormLabel>
          </CCol>
        </CRow>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>국가</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormLabel>{company?.country}</CFormLabel>
          </CCol>
        </CRow>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>사업자 등록번호</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormLabel>{company?.businessRegistrationNumber}</CFormLabel>
          </CCol>
        </CRow>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>사업자 등록증</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <div>
              {businessLicenses?.map((license, idx) => (
                <div
                  key={idx}
                  onClick={() => handleCompanyFileDownload(license)}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  {license.file?.name}
                </div>
              ))}
            </div>
          </CCol>
        </CRow>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>대표전화 번호</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormLabel>{company?.phoneNumber ?? ''}</CFormLabel>
          </CCol>
        </CRow>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>대표 이메일 </CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormLabel>{company?.email ?? ''}</CFormLabel>
          </CCol>
        </CRow>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>세금계산서 담당 이메일 </CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormLabel>{company?.taxInvoiceEmail ?? ''}</CFormLabel>
          </CCol>
        </CRow>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>세일즈 담당자 ID</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormLabel>{salesAccount?.email ?? ''}</CFormLabel>
          </CCol>
        </CRow>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>대표 직업 가이드</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <div>
              {guides?.map((guide, idx) => (
                <div
                  key={idx}
                  onClick={() => handleCompanyFileDownload(guide)}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  {guide.file?.name}
                </div>
              ))}
            </div>
          </CCol>
        </CRow>
      </div>
      <div className="justify-content-between d-flex">
        <Row
          style={{
            justifyContent: 'space-between',
            borderBottom: '1px solid  #d8dbe0',
            width: '100%',
            fontSize: '20px',
            margin: '40px 0 25px 0',
          }}
        >
          마스터 계정 정보
        </Row>

        {showMasterAccountUpdate && account && (
          <MasterAccountUpdateModal masterAccount={account} onDismiss={() => handleDismissOfMasterUpdate()} />
        )}
      </div>
      <div style={{ marginBottom: '15px' }}>
        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>이름</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormLabel>{account?.name}</CFormLabel>
          </CCol>
        </CRow>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>로그인 ID</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormLabel>{account?.email}</CFormLabel>
          </CCol>
        </CRow>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>연락처</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormLabel>{account?.phoneNumber}</CFormLabel>
          </CCol>
        </CRow>
      </div>
      <div className="justify-content-between d-flex">
        <Row
          style={{
            justifyContent: 'space-between',
            borderBottom: '1px solid  #d8dbe0',
            width: '100%',
            fontSize: '20px',
            margin: '40px 0 25px 0',
          }}
        >
          서브 계정 정보
          <CButton color={'primary'} onClick={() => setClientCreate(true)}>
            계정 생성하기
          </CButton>
        </Row>
        {company && showClientCreate && account && (
          <CreateClientAccountModal
            companyId={company.id}
            masterAccountId={account.id}
            onDismiss={() => handleDismissOfClientCreate()}
          />
        )}
      </div>
      <CTable bordered style={{ width: 'max-content', minWidth: '100%' }}>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell>NO</CTableHeaderCell>
            <CTableHeaderCell>이름</CTableHeaderCell>
            <CTableHeaderCell>로그인 계정</CTableHeaderCell>
            <CTableHeaderCell>생성일자</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {subAccounts?.map((subAccount, idx) => {
            const { id, name, email } = subAccount;
            return (
              <CTableRow key={idx}>
                <CTableDataCell>
                  <Link to={`${AppPaths.clientSubAccountDetail.path}?id=${id}`}>{id}</Link>
                </CTableDataCell>
                <CTableDataCell>{name}</CTableDataCell>
                <CTableDataCell>{email}</CTableDataCell>
              </CTableRow>
            );
          })}
        </CTableBody>
      </CTable>
      <CredentialInfo accountId={Number(accountId)} />
    </div>
  );
}

export default ClientAccountDetailPage;
