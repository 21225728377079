import { CFormSwitch } from '@coreui/react';
import React from 'react';
import { useParams } from 'react-router';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import slack from 'src/api/slack';
import { ButtonBase } from 'src/components/atoms/styled/button';
import { Description, Title } from 'src/components/atoms/styled/element';
import { Column, Row } from 'src/components/atoms/styled/layout';
import { useAuth } from 'src/components/context/AuthContext';
import CredentialInfo from 'src/components/organisms/CredentialInfo';
import { AlarmChannelConfigurationResponse, ManagerAlarmConfigurationResponse } from 'src/types/api/EntityHistory';
import { User } from 'src/types/api/User';
import { isAxiosError } from 'src/utils/api/axios';
import { AccountSettingWrapper } from './styled';

interface Params {
  id: string;
}

function AccountSettingPage() {
  const { user } = useAuth();

  const { id } = useParams<Params>();

  const accountId = isNaN(parseInt(id)) ? user?.id : parseInt(id);

  const [slackId, setSlackId] = React.useState<string>();
  const getSlackId = React.useCallback(async (userEmail: string) => {
    try {
      const res = await api.entityHistory.notification.slack.getTokenAndTeamId();
      if (isAxiosError(res)) {
        throw res;
      }

      const { teamId, token } = res.data;
      const slackListRes = await slack.userList(token, teamId);
      if (isAxiosError(slackListRes)) {
        throw slackListRes;
      }

      const slackUserInfo = slackListRes.data.members.filter((it) => it.profile.email === userEmail)[0];

      setSlackId(slackUserInfo.id);
    } catch (error) {
      errorLogger.error(error);
    }
  }, []);

  const [accountInfo, setAccountInfo] = React.useState<User>();
  const getAccountInfo = React.useCallback(async () => {
    if (!accountId) {
      return;
    }

    try {
      const res = await api.account.item(accountId).get();
      if (isAxiosError(res)) {
        throw res;
      }

      getSlackId(res.data.email);
      setAccountInfo(res.data);
    } catch (error) {
      errorLogger.error(error);
    }
  }, [accountId, getSlackId]);

  //managerAlram
  const [managerAlramConfiguration, setManagerAlramConfiguration] = React.useState<ManagerAlarmConfigurationResponse>();
  const getManagerAlramConfiguration = React.useCallback(async () => {
    if (!accountId) {
      return;
    }

    try {
      const managerRes = await api.entityHistory.notification.configuration(accountId).manager.get();
      if (isAxiosError(managerRes)) {
        throw managerRes;
      }

      setManagerAlramConfiguration(managerRes.data);
    } catch (error) {
      errorLogger.error(error);
    }
  }, [accountId]);

  const switchManagerAlramConfiguration = React.useCallback(
    async (switchAllNotification?: boolean, switchNoAssignProjectNotification?: boolean) => {
      if (!accountId || switchAllNotification === undefined || switchNoAssignProjectNotification === undefined) {
        return;
      }

      try {
        const res = await api.entityHistory.notification.configuration(accountId).manager.put({
          allNotification: switchAllNotification,
          noAssignProjectNotification: switchNoAssignProjectNotification,
        });
        if (isAxiosError(res)) {
          throw res;
        }

        getManagerAlramConfiguration();
      } catch (error) {
        errorLogger.error(error);
      }
    },
    [accountId, getManagerAlramConfiguration]
  );

  //channelAlram
  const [alramChannelConfiguration, setAlramChannelConfiguration] = React.useState<AlarmChannelConfigurationResponse>();
  const getChannelAlramConfiguration = React.useCallback(async () => {
    if (!accountId) {
      return;
    }

    try {
      const channelRes = await api.entityHistory.notification.configuration(accountId).get();
      if (isAxiosError(channelRes)) {
        throw channelRes;
      }
      setAlramChannelConfiguration(channelRes.data);
    } catch (error) {
      errorLogger.error(error);
    }
  }, [accountId]);

  const switchChannelAlramConfiguration = React.useCallback(
    async (switchSlack?: boolean, switchEmail: boolean = false, switchSite: boolean = true) => {
      if (!accountId || !slackId || switchSlack === undefined) {
        return;
      }

      try {
        const res = await api.entityHistory.notification.configuration(accountId).put({
          slack: switchSlack,
          email: switchEmail,
          site: switchSite,
          slackChannelId: slackId,
        });
        if (isAxiosError(res)) {
          throw res;
        }

        getChannelAlramConfiguration();
      } catch (error) {
        errorLogger.error(error);
      }
    },
    [accountId, getChannelAlramConfiguration, slackId]
  );

  React.useEffect(() => {
    if (!user) {
      return;
    }

    if (!id) {
      getSlackId(user.email);
      setAccountInfo(user);
    }

    id && getAccountInfo();
    getChannelAlramConfiguration();
    getManagerAlramConfiguration();
  }, [accountId, getAccountInfo, getChannelAlramConfiguration, getManagerAlramConfiguration, getSlackId, id, user]);

  return (
    <AccountSettingWrapper>
      <Title>설정</Title>
      <Column>
        <Title>계정 정보</Title>
        <Row>
          <Title>NO</Title>
          <Description>{accountId}</Description>
        </Row>
        <Row>
          <Title>이름</Title>
          <Description>{accountInfo?.name}</Description>
        </Row>
        <Row>
          <Title>로그인 계정</Title>
          <Description>{accountInfo?.email}</Description>
        </Row>
        <Row>
          <Title>역할</Title>
          <Description>{accountInfo?.role}</Description>
        </Row>
        <Row>
          <Title>활성여부</Title>
          <Description>{accountInfo?.accepted ? 'on' : 'off'}</Description>
        </Row>
      </Column>
      <Column className={'setAlram'}>
        <Title>알림 설정</Title>
        <Column>
          <Title>알림 대상 상세 설정</Title>
          <label>
            <span>전체 작업 알림 받기</span>
            <CFormSwitch
              defaultChecked={managerAlramConfiguration?.allNotification}
              onClick={() =>
                switchManagerAlramConfiguration(
                  !managerAlramConfiguration?.allNotification,
                  managerAlramConfiguration?.noAssignProjectNotification
                )
              }
            />
          </label>
          <label>
            <span>담당자 없는 작업 알림 받기</span>
            <CFormSwitch
              defaultChecked={managerAlramConfiguration?.noAssignProjectNotification}
              onClick={() =>
                switchManagerAlramConfiguration(
                  managerAlramConfiguration?.allNotification,
                  !managerAlramConfiguration?.noAssignProjectNotification
                )
              }
            />
          </label>
        </Column>
        <Column>
          <Title>알림 채널 설정</Title>
          <label>
            <span>슬랙</span>
            <CFormSwitch
              defaultChecked={alramChannelConfiguration?.slack}
              onClick={() => switchChannelAlramConfiguration(!alramChannelConfiguration?.slack)}
            />
          </label>
        </Column>
        <Column>
          <Title>슬랙 멤버 아이디 설정</Title>
          <label>
            <input value={slackId ?? ''} readOnly />
            <ButtonBase>저장</ButtonBase>
          </label>
        </Column>
      </Column>
      {accountId && <CredentialInfo accountId={accountId} />}
    </AccountSettingWrapper>
  );
}

export default AccountSettingPage;
