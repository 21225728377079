import { Parameter } from '@voithru/front-core';
import { AxiosInstance } from 'axios';
import { generatePath } from 'react-router';
import { APIAccountId, APIManagerId, APIPagination } from 'src/types/api';
import { JobsResponse } from 'src/types/api/Job';
import { JobResultRequest, JobResultResponse } from 'src/types/api/JobResult';
import { ProductOrderResponse } from 'src/types/api/ProductOrder';
import {
  ProjectFile,
  ProjectPageRequest,
  ProjectRelateLink,
  ProjectRelateLinkResponse,
  ProjectRequest,
  ProjectResponse,
  ProjectStatus,
  ProjectStatusUpdatePatch,
  ProjectUpdateRequest,
} from 'src/types/api/Project';

const PROJECT_PATH = '/api/v1/projects';

function apiProject(instance: AxiosInstance) {
  return {
    get: (params: APIManagerId & ProjectRequest) => {
      const query = Parameter.toURLSearchParams(params);
      return instance.get<ProjectResponse[]>(PROJECT_PATH, { params: query });
    },
    post: (params: APIAccountId) => {
      return instance.post(PROJECT_PATH + `?accountId=${params.accountId}`);
    },
    pages: (params: ProjectPageRequest) => {
      const query = Parameter.toURLSearchParams(params);
      return instance.get<APIPagination<ProjectResponse>>(PROJECT_PATH + '/page', { params: query });
    },

    item(projectId: number) {
      const PROJECT_ITEM_PATH = generatePath(PROJECT_PATH + '/:projectId', { projectId });
      const FILE_PATH = PROJECT_ITEM_PATH + '/files';
      const RELATE_PATH = PROJECT_ITEM_PATH + '/projectRelateLinks';
      const JOB_RESULT_PATH = PROJECT_ITEM_PATH + '/jobs/jobResults';

      return {
        get: () => instance.get<ProjectResponse>(PROJECT_ITEM_PATH),
        related: (data: ProjectUpdateRequest) => instance.put(PROJECT_ITEM_PATH + '/related', data),
        status: (status: ProjectStatus) => instance.patch(PROJECT_ITEM_PATH + `/status?status=${status}`),
        jobs: () => instance.get<JobsResponse[]>(PROJECT_ITEM_PATH + '/jobs'),
        productOrders: () => instance.get<ProductOrderResponse[]>(PROJECT_ITEM_PATH + '/productOrders'),
        statusAndScheduledDeadlineAndManagerId: (data: ProjectStatusUpdatePatch) =>
          instance.patch(PROJECT_ITEM_PATH + '/statusAndScheduledDeadlineAndManagerId', data),

        jobResult: {
          get: (params: JobResultRequest) => {
            const query = Parameter.toURLSearchParams(params);
            return instance.get<JobResultResponse[]>(JOB_RESULT_PATH, { params: query });
          },
        },

        files: {
          // get: () => instance.get<APIFileResponse[]>(FILE_PATH),
          post: (data: ProjectFile[]) => instance.post<ProjectResponse>(FILE_PATH, data),

          item(fileId: string) {
            const FILE_ITEM_PATH = generatePath(FILE_PATH + '/:fileId', { fileId });

            return {
              detach: () => instance.post<ProjectResponse>(FILE_ITEM_PATH + '/detach'),
            };
          },
        },

        projectRelateLinks: {
          get: () => instance.get<ProjectRelateLinkResponse[]>(RELATE_PATH),
          post: (data: ProjectRelateLink[]) => instance.post<ProjectRelateLinkResponse[]>(RELATE_PATH, data),

          item(relateLinkId: number) {
            const RELATE_ITEM_PATH = generatePath(RELATE_PATH + '/:relateLinkId', { relateLinkId });

            return {
              delete: () => instance.delete(RELATE_ITEM_PATH),
            };
          },
        },
      };
    },
  };
}

export default apiProject;
