import { cn } from '@voithru/front-core';
import React from 'react';
import ICN_DELETE from 'src/assets/icons/icn-delete.svg';
import ICN_HANDLE from 'src/assets/icons/icn-handle.svg';
import { Button } from 'src/components/atoms/styled/button';
import { Description } from 'src/components/atoms/styled/element';
import { InputBase } from 'src/components/atoms/styled/form';
import { Column, Row } from 'src/components/atoms/styled/layout';
import { GoogleFile } from 'src/types/api/File';
import useDragAndDrop from 'src/utils/hooks/useDragAndDrop';
import { FileGroup, FileGroupWithGoogle } from 'src/utils/JobRegisterService';
import styled from 'styled-components';
import { ContentFileListWrapper } from './styled';

function resort<T>(data: T[], from: number, to: number): T[] {
  if (isNaN(from) || isNaN(to) || from < 0 || to < 0) {
    return data;
  }

  const next = Array.from(data);
  next.splice(to - 1, 0, ...next.splice(from - 1, 1));
  return next;
}

interface Props {
  files: FileGroupWithGoogle[];
  onSort(fileGroups: FileGroup[], googleFiles: GoogleFile[]): void;
  onNameChange(target: string, name: string): void;
  onDelete(target: FileGroup): void;
  onGoogleFileDelete(target: GoogleFile): void;
  className?: string;
}

function ContentFileList(props: Props) {
  const { files, onSort, onNameChange, onDelete, onGoogleFileDelete, className } = props;

  const handleNameChange = React.useCallback(
    (target: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value;
      onNameChange(target, value);
    },
    [onNameChange]
  );

  const handleDelete = React.useCallback(
    (target: FileGroupWithGoogle) => () => {
      if ('files' in target.item) {
        onDelete(target.item);
      }

      if (!('files' in target.item)) {
        onGoogleFileDelete(target.item);
      }
    },
    [onDelete, onGoogleFileDelete]
  );

  const handleOrderFiles = React.useCallback(
    (from: number, to: number) => {
      const ordered = resort(files, from, to);

      const changedIndexMap = ordered
        .map((it, index) => {
          return {
            ...it,
            index,
          };
        })
        .map((it) => {
          it.item['index'] = it.index;
          return it.item;
        });

      const fileGroup = changedIndexMap.filter((it) => {
        if ('files' in it) {
          return true;
        }

        return false;
      });

      const googleFileGroups = changedIndexMap.filter((it) => {
        if (!('files' in it)) {
          return true;
        }

        return false;
      });

      onSort(fileGroup as FileGroup[], googleFileGroups as GoogleFile[]);
    },
    [files, onSort]
  );

  const { from, to, handleMouseDown, handleMouseMove } = useDragAndDrop({ onChange: handleOrderFiles });
  const ordered = React.useMemo(() => resort(files, from, to), [files, from, to]);

  return (
    <ContentFileListWrapper className={className}>
      <Description>콘텐츠 파일의 순서변경 및 이름변경이 가능합니다.</Description>
      <Column className={'table'} onMouseMove={handleMouseMove}>
        <Row className={'header'}>
          <span></span>
          <span>순번</span>
          <span>파일명</span>
          <span>용량</span>
          <span></span>
        </Row>
        {ordered.map((it) => {
          const index = files.indexOf(it);

          return (
            <Row key={it.id} className={cn('item', from - 1 === index && 'focus')}>
              <Row onMouseDown={handleMouseDown}>
                <img src={ICN_HANDLE} alt={'handle'} width={24} height={24} />
              </Row>
              <Row>{files.indexOf(it) + 1}</Row>
              <Row>
                <InputBase defaultValue={it.name} placeholder={it.name} onChange={handleNameChange(it.id)} />
              </Row>
              <Row>{it.size}</Row>
              <Row>
                <Button color={'transparent'} onClick={handleDelete(it)}>
                  <img src={ICN_DELETE} alt={'delete'} width={24} height={24} />
                </Button>
              </Row>
            </Row>
          );
        })}
      </Column>
    </ContentFileListWrapper>
  );
}

export default styled(ContentFileList)``;
