import { CButton, CFormInput, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react';
import { useMount } from '@voithru/front-core';
import React from 'react';
import { useForm } from 'react-hook-form';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { UserResponse } from 'src/types/api/User';

interface FormData {
  search: string;
}

interface IProps {
  setTargetItem?: (item: UserResponse) => void;
  setTargetItems?: (items: UserResponse[]) => void;
}

function AdminSearchModal(props: IProps) {
  const { setTargetItem, setTargetItems } = props;
  const [visible, setVisible] = React.useState(false);
  const [searchList, setSearchList] = React.useState<UserResponse[]>([]);
  const [filteredList, setFilteredList] = React.useState<UserResponse[]>([]);
  const [selectItems, setSelectItems] = React.useState<UserResponse[]>([]);
  const { handleSubmit, register } = useForm<FormData>();

  const getAccount = React.useCallback(async () => {
    await api.account
      .get({ roles: 'ADMIN' })
      .then((res) => {
        setSearchList(res.data);
        setFilteredList(res.data);
      })
      .catch(errorLogger.error);
  }, []);

  useMount(() => {
    getAccount();
  });

  const submit = React.useCallback(
    (form: FormData) => {
      setFilteredList(searchList.filter((item) => item.name.includes(form.search)));
    },
    [searchList]
  );

  const handleClickItem = React.useCallback(
    (item: UserResponse) => {
      if (setTargetItem) {
        setSelectItems([item]);
      } else if (setTargetItems) {
        if (selectItems.find((i) => i.id === item.id)) {
          setSelectItems(selectItems.filter((i) => i.id !== item.id));
        } else {
          setSelectItems([item, ...selectItems]);
        }
      }
    },
    [setTargetItem, setSelectItems, setTargetItems, selectItems]
  );

  const handleConfirm = React.useCallback(() => {
    setVisible(false);
    if (setTargetItem && selectItems.length === 1) {
      setTargetItem(selectItems[0]);
    } else if (setTargetItems) {
      setTargetItems(selectItems);
    }
  }, [setVisible, setTargetItems, setTargetItem, selectItems]);

  return (
    <div className="align-items-center d-flex">
      <CButton style={{ width: 'max-content' }} onClick={() => setVisible(!visible)}>
        🔎
      </CButton>
      <CModal size={'lg'} visible={visible} onDismiss={() => setVisible(false)}>
        <CModalHeader onDismiss={() => setVisible(false)}>
          <CModalTitle>Admin 검색하기</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
            <form onChange={handleSubmit(submit)}>
              <div className="mb-3">
                <CFormInput type={'text'} {...register('search')} />
              </div>
              {filteredList?.map((item, idx) => (
                <div
                  key={idx}
                  style={{
                    borderBottom: '1px solid black',
                    padding: '2px',
                    cursor: 'pointer',
                    background: selectItems.find((i) => i.id === item.id) ? 'gray' : undefined,
                  }}
                  onClick={() => handleClickItem(item)}
                >
                  {item.name}
                </div>
              ))}
            </form>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            취소
          </CButton>
          <CButton onClick={() => handleConfirm()}>확인</CButton>
        </CModalFooter>
      </CModal>
    </div>
  );
}

export default AdminSearchModal;
