import {
  CButton,
  CContainer,
  CFormCheck,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import { useMount } from '@voithru/front-core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { Button } from 'src/components/atoms/styled/button';
import { Column } from 'src/components/atoms/styled/layout';
import { useLoading } from 'src/components/context/ApplicationContext';
import AppPaths from 'src/constants/AppPaths';
import { Product } from 'src/types/api/Product';
import { ProductOrder } from 'src/types/api/ProductOrder';
import { ProjectCategory } from 'src/types/api/Project';
import { isAxiosError } from 'src/utils/api/axios';
import {
  DifficultyType,
  ExpectedQualityType,
  ResultType,
  Source_Language,
  Translate_Language,
  VolumeType,
  WorkRange,
  WorkTarget,
} from 'src/utils/translate';

interface FormData {
  productId: string;
}

interface Props {
  productOrder: ProductOrder;
  projectCategory?: ProjectCategory;
  onSubmitHandler?: (product: Product) => void;
  isEdit: Boolean;
}

function SelectPriceModal(props: Props) {
  const { productOrder, projectCategory, onSubmitHandler, isEdit } = props;
  const { productType, deliveryType, productSource, sourceLanguage, translateLanguage, productId, price } =
    productOrder;
  const [visible, setVisible] = React.useState(false);
  const { register, handleSubmit, getValues, reset } = useForm<FormData>();

  const history = useHistory();

  const [, forceUpdate] = React.useReducer((x) => !x, false);

  const [productList, setProductList] = React.useState<Product[]>([]);

  const loading = useLoading();

  const getProductList = React.useCallback(async () => {
    const productRes = await api.product.filter.get({
      productType: productType,
      requestCategory: projectCategory || undefined,
      deliveryType: deliveryType || undefined,
      productSource: productSource || undefined,
      sourceLanguage: sourceLanguage || undefined,
      translateLanguage: translateLanguage || undefined,
    });

    if (isAxiosError(productRes)) {
      throw productRes;
    }

    setProductList(productRes.data);

    return productRes.data;
  }, [deliveryType, productSource, productType, projectCategory, sourceLanguage, translateLanguage]);

  useMount(() => {
    loading(async () => {
      await getProductList();

      if (productId) {
        const form: FormData = { productId: productId.toString() };
        reset(form);
      }
    })()
      .catch((error) => errorLogger.error(error))
      .finally(() => {
        forceUpdate();
      });
  });

  const handleAddPrice = React.useCallback(() => {
    history.push(AppPaths.products.path);
  }, [history]);

  const onSubmit = React.useCallback(
    async (data: FormData = getValues(), products: Product[]) => {
      const { productId } = data;

      if (!productId) {
        errorLogger.error("Don't have submitting data");
      }

      if (!products) {
        throw new Error("Don't have productList");
      }
      if (
        window.confirm('상품 단가를 적용합니다 \n단가를 변경 하는 경우 연관된 작업결과물 상품 단가에 일괄 적용됩니다.')
      ) {
        const product = products.filter((it) => it.id === Number(productId))[0];

        onSubmitHandler && onSubmitHandler(product);
      }

      setVisible(false);
    },
    [getValues, onSubmitHandler]
  );

  return (
    <div className="align-items-center d-flex">
      <Button
        style={{ width: 'max-content', color: '#006BE5' }}
        onClick={() => setVisible(!visible)}
        disabled={!isEdit}
      >
        {price ? (
          <Column style={{ width: 'max-content' }}>
            <span style={{ fontSize: '15px', textDecoration: 'underline' }}>{price}</span>
            <span style={{ color: '#838383', textDecoration: 'none' }}>(P-{productId})</span>
          </Column>
        ) : (
          <span style={{ width: 'max-content', textDecoration: 'underline' }}>{isEdit ? '단가 선택하기' : '-'}</span>
        )}
      </Button>
      <CModal size={'xl'} visible={visible} onDismiss={() => setVisible(false)}>
        <form
          onSubmit={(e) => {
            e.stopPropagation();

            handleSubmit((data: FormData) => onSubmit(data, productList))(e);
          }}
        >
          <CModalHeader onDismiss={() => setVisible(false)}>
            <CModalTitle>단가 선택하기</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <div className="d-flex flex-row  justify-content-end" style={{ marginBottom: '5px' }}>
              <button onClick={() => handleAddPrice()}>단가 추가+</button>
            </div>
            <CContainer style={{ height: '320px', overflow: 'auto' }}>
              <CTable bordered>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell />
                    <CTableHeaderCell>작업범위</CTableHeaderCell>
                    <CTableHeaderCell>원본언어</CTableHeaderCell>
                    <CTableHeaderCell>번역언어</CTableHeaderCell>
                    <CTableHeaderCell>납품형태</CTableHeaderCell>
                    <CTableHeaderCell>작업대상</CTableHeaderCell>
                    <CTableHeaderCell>난이도</CTableHeaderCell>
                    <CTableHeaderCell>납품퀄리티</CTableHeaderCell>
                    <CTableHeaderCell>볼륨</CTableHeaderCell>
                    <CTableHeaderCell>단가</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {productList?.map((it) => (
                    <CTableRow key={it.id}>
                      <CTableDataCell>
                        <CFormCheck type="radio" value={it.id} {...register('productId')} />
                      </CTableDataCell>
                      <CTableDataCell>{WorkRange[it.productType]}</CTableDataCell>
                      <CTableDataCell>{it.sourceLanguage && Source_Language[it.sourceLanguage]}</CTableDataCell>
                      <CTableDataCell>
                        {it.translateLanguage && Translate_Language[it.translateLanguage]}
                      </CTableDataCell>
                      <CTableDataCell>{it.deliveryType && ResultType[it.deliveryType]}</CTableDataCell>
                      <CTableDataCell>{it.productSource && WorkTarget[it.productSource]}</CTableDataCell>
                      <CTableDataCell>{it.difficulty && DifficultyType[it.difficulty]}</CTableDataCell>
                      <CTableDataCell>{it.expectedQuality && ExpectedQualityType[it.expectedQuality]}</CTableDataCell>
                      <CTableDataCell>{it.volume && VolumeType[it.volume]}</CTableDataCell>
                      <CTableDataCell>{it.price}</CTableDataCell>
                    </CTableRow>
                  ))}
                </CTableBody>
              </CTable>
            </CContainer>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => {
                setVisible(false);
              }}
            >
              취소
            </CButton>
            <CButton type={'submit'} color={'primary'}>
              확인
            </CButton>
          </CModalFooter>
        </form>
      </CModal>
    </div>
  );
}

export default SelectPriceModal;
