import { CFormCheck, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import React from 'react';
import { PRODUCT_ORDER_OPTION_TYPES } from 'src/constants/Product';
import { DeliveryType, ProductOptionType, ProductSource, ProductType } from 'src/types/globalTypesFile/graphql';
import { ResultType, Source_Language, Translate_Language, WorkRange, WorkTarget } from 'src/utils/translate';
import { JobResultSelectTableWrapper } from './styled';

interface RowData {
  id: string;
  name: string;

  productType?: ProductType | null;
  productSource?: ProductSource | null;

  sourceLanguage?: string | null;
  translateLanguage?: string | null;
  deliveryType?: DeliveryType | null;

  productOptionOrders?: (ProductOptionType | null | undefined)[];
}

interface Props {
  data: RowData[];

  onChange?(ids: string[]): void;
}

function JobResultSelectTable(props: Props) {
  const { data, onChange } = props;

  const [selected, setSelectedArray] = React.useState<string[]>([]);
  const setSelected = React.useCallback(
    (action: React.SetStateAction<string[]>) => {
      let next: string[] = [];
      setSelectedArray((prev) => {
        if (action instanceof Function) {
          next = action(prev);
          return next;
        }

        return (next = action);
      });
      onChange?.(next);
    },
    [onChange]
  );

  const selectAll = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const checked = e.currentTarget.checked;
      if (checked) {
        return setSelected(data.map((it) => it.id));
      }

      setSelected([]);
    },
    [data, setSelected]
  );

  const change = React.useCallback(
    (id: string) => async () => {
      setSelected((prev) => {
        if (prev.includes(id)) {
          return prev.filter((it) => it !== id);
        }

        return [...prev, id];
      });
    },
    [setSelected]
  );

  return (
    <JobResultSelectTableWrapper>
      <CTableHead>
        <CTableRow>
          <CTableHeaderCell>
            <CFormCheck checked={data.every((it) => selected.includes(it.id))} onChange={selectAll} />
          </CTableHeaderCell>
          <CTableHeaderCell>결과물 ID</CTableHeaderCell>
          <CTableHeaderCell>콘텐츠 명</CTableHeaderCell>
          <CTableHeaderCell>작업범위</CTableHeaderCell>
          <CTableHeaderCell>원본언어</CTableHeaderCell>
          <CTableHeaderCell>번역언어</CTableHeaderCell>
          <CTableHeaderCell>납품형태</CTableHeaderCell>
          <CTableHeaderCell>작업대상</CTableHeaderCell>
          <CTableHeaderCell>부가서비스</CTableHeaderCell>
        </CTableRow>
      </CTableHead>
      <CTableBody>
        {data.map((it) => (
          <CTableRow key={it.id} onClick={change(it.id)}>
            <CTableDataCell>
              <CFormCheck checked={selected.includes(it.id)} onChange={() => change(it.id)} />
            </CTableDataCell>
            <CTableDataCell>{`JR-${it.id}`}</CTableDataCell>
            <CTableDataCell>{it.name}</CTableDataCell>
            <CTableDataCell>{it.productType && WorkRange[it.productType]}</CTableDataCell>
            <CTableDataCell>{it.sourceLanguage && Source_Language[it.sourceLanguage]}</CTableDataCell>
            <CTableDataCell>{it.translateLanguage && Translate_Language[it.translateLanguage]}</CTableDataCell>
            <CTableDataCell>{it.deliveryType && ResultType[it.deliveryType]}</CTableDataCell>
            <CTableDataCell>{it.productSource && WorkTarget[it.productSource]}</CTableDataCell>
            <CTableDataCell>
              {!it.productOptionOrders?.length
                ? '-'
                : it.productOptionOrders[0] && PRODUCT_ORDER_OPTION_TYPES[it.productOptionOrders[0]]}
            </CTableDataCell>
          </CTableRow>
        ))}
      </CTableBody>
    </JobResultSelectTableWrapper>
  );
}

export default JobResultSelectTable;
