import { Parameter } from '@voithru/front-core';
import { AxiosInstance } from 'axios';
import { generatePath } from 'react-router';
import {
  APIFileCompleteMultipartUploadRequest,
  APIFileCompleteMultipartUploadResponse,
  APIFileMultipartUploadParams,
  APIFileMultiPartUploadResponse,
  APIFilePartHeaderValueParams,
  APIFilePartHeaderValueResponse,
  APIFileRequest,
  APIFileResponse,
  APIGoogleCloudFileIdsParams,
  APIGoogleDriveFileUploadParams,
  APIGoogleFolderResponse,
} from 'src/types/api/File';

const FILES_PATH = '/api/v1/files';
function apiFile(instance: AxiosInstance) {
  return {
    post: (data: APIFileRequest) => instance.post<APIFileResponse>(FILES_PATH, data),
    google: {
      post: (params: APIGoogleDriveFileUploadParams) =>
        instance.post<APIFileResponse>(FILES_PATH + '/google', undefined, { params }),
      folder: (params: APIGoogleCloudFileIdsParams) => {
        const search = Parameter.toURLSearchParams(params);
        return instance.get<APIGoogleFolderResponse>(FILES_PATH + '/google/folder', { params: search });
      },
    },
    item(id: string) {
      const PATH = generatePath(FILES_PATH + '/:id', { id });

      return {
        put: (data: Partial<APIFileResponse>) => instance.put(PATH, data),
        namePatch: (name: string) => instance.patch(PATH, undefined, { params: { name } }),
        downloadUrl: () => instance.post(PATH + '/downloadUrl'),
        multipartUpload: (search: APIFileMultipartUploadParams) => {
          const params = Parameter.toURLSearchParams(search);
          return instance.post<APIFileMultiPartUploadResponse>(PATH + '/multipartUpload', undefined, { params });
        },
        uploadPartHeaderValue: (search: APIFilePartHeaderValueParams) => {
          const params = Parameter.toURLSearchParams(search);
          return instance.post<APIFilePartHeaderValueResponse>(PATH + '/uploadPartHeaderValue', undefined, { params });
        },
        completeMultipartUpload: (data: APIFileCompleteMultipartUploadRequest) =>
          instance.post<APIFileCompleteMultipartUploadResponse>(PATH + '/completeMultipartUpload', data),
        previewDownloadUrl: () => instance.post(PATH + '/preview/downloadUrl'),
      };
    },
  };
}

export default apiFile;
