import React from 'react';
import * as rrweb from 'rrweb';
import logger from '../../api/logger';

export const useRRWebLogger = () => {
  React.useEffect(() => {
    rrweb.record({
      emit(event, isCheckout) {
        logger.rrwebLog(event);
      },
      checkoutEveryNms: 10_000, // checkout every 5 minutes
    });
  }, []);
};
