import React from 'react';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import LocalStorageManager from 'src/utils/LocalStorageManager';
import { GoogleRequest, LoginRequest } from 'src/types/api/Auth';
import { User, UserResponse } from 'src/types/api/User';
import { isAxiosError } from 'src/utils/api/axios';
import { useLoading } from '../ApplicationContext';
import { deleteCookie } from 'src/utils/cookie';

function useAuthBuilder() {
  const loading = useLoading();

  const [initialize, setInitialize] = React.useState(false);
  const [user, setUser] = React.useState<User>();
  const setStorageUser = React.useCallback((value?: UserResponse) => {
    LocalStorageManager.setItem('user', value);
    setUser(value);
  }, []);

  const loadUser = React.useCallback(async () => {
    const res = await api.account.myInfo();
    if (isAxiosError(res) || res.status !== 200) {
      throw res;
    }

    setStorageUser(res.data);
  }, [setStorageUser]);

  const login = React.useCallback(
    async (data: LoginRequest) => {
      const res = await api.auth.login(data);
      if (isAxiosError(res) || res.status !== 200) {
        throw res;
      }

      return await loadUser();
    },
    [loadUser]
  );

  const loginWithGoogleLogin = React.useCallback(
    async (data: GoogleRequest) => {
      const res = await api.auth.oAuthGoogleLogin(data);
      if (isAxiosError(res)) {
        throw res;
      }

      return await loadUser();
    },
    [loadUser]
  );

  const logout = React.useCallback(async () => {
    deleteCookie('access_token');
    deleteCookie('refresh_token');

    setStorageUser();
  }, [setStorageUser]);

  React.useEffect(() => {
    api.manager.auth.addEventListener('failed', () => logout());

    loading(async () => {
      await loadUser();
    })()
      .catch(errorLogger.error)
      .finally(() => {
        setInitialize(true);
      });
  }, [loadUser, loading, logout]);

  return React.useMemo(
    () => ({ initialize, user, login, loginWithGoogleLogin, logout }),
    [initialize, login, loginWithGoogleLogin, logout, user]
  );
}

export default useAuthBuilder;
