import { Eventer, EventerRemoveHandler, once } from '@voithru/front-core';
import { NamedFile } from './files';
import MultipartUpload from './MultipartUpload';

type Listener = (uploader: MultipartUpload) => void;
type IdleListener = (isAborted: boolean) => void;

class UploadService {
  public get uploaders(): readonly MultipartUpload[] {
    return this.multipart;
  }

  private eventerProgress = new Eventer<Listener>();
  private eventerStart = new Eventer<Listener>();
  private eventerCancel = new Eventer<Listener>();
  private eventerDone = new Eventer<Listener>();
  private eventerIdle = new Eventer<IdleListener>();

  constructor(private multipart: MultipartUpload[] = []) {
    this.addEventListener = this.addEventListener.bind(this);
    this.register = this.register.bind(this);
  }

  public addEventListener(type: 'progress', listen: Listener): EventerRemoveHandler;
  public addEventListener(type: 'start', listen: Listener): EventerRemoveHandler;
  public addEventListener(type: 'cancel', listen: Listener): EventerRemoveHandler;
  public addEventListener(type: 'done', listen: Listener): EventerRemoveHandler;
  public addEventListener(type: 'idle', listen: IdleListener): EventerRemoveHandler;
  public addEventListener(type: 'progress' | 'start' | 'cancel' | 'done' | 'idle', listen: Listener | IdleListener) {
    switch (type) {
      case 'progress':
        return this.eventerProgress.addEventListener(listen as never);
      case 'start':
        return this.eventerStart.addEventListener(listen as never);
      case 'cancel':
        return this.eventerCancel.addEventListener(listen as never);
      case 'done':
        return this.eventerDone.addEventListener(listen as never);
      case 'idle':
        return this.eventerIdle.addEventListener(listen as never);
    }
  }

  public register(arg: MultipartUpload, startUploading?: boolean): MultipartUpload;
  public register(arg: NamedFile, startUploading?: boolean): MultipartUpload;
  public register(arg: MultipartUpload | NamedFile, startUploading = true) {
    const uploader = arg instanceof MultipartUpload ? arg : new MultipartUpload(arg);
    uploader.addEventListener('progress', () => this.eventerDone.run(uploader));
    uploader.addEventListener('start', () => this.eventerStart.run(uploader));
    uploader.addEventListener('cancel', () => this.eventerDone.run(uploader));
    uploader.addEventListener('done', () => this.eventerDone.run(uploader));
    this.multipart.push(uploader);
    if (startUploading) {
      this.action();
    }

    return uploader;
  }

  public unregister = (fileId: string) => {
    const targetIndex = this.multipart.findIndex((it) => it.namedFile.id === fileId);
    if (targetIndex === undefined) {
      throw new Error('UploadManager.unregister: Not Found');
    }

    const target = this.multipart[targetIndex];
    this.multipart.splice(targetIndex, 1);
    target.cancel();
  };

  private action = once(async () => {
    let isAborted: boolean;
    do {
      isAborted = false;
      while (this.uploaders.length > 0) {
        const target = this.uploaders.find((it) => ['INIT', 'READY', 'CANCEL'].includes(it.status));
        if (!target) {
          break;
        }

        try {
          if (target.status === 'INIT') {
            await target.ready('PROJECT');
          }
          await target.start();
        } catch (error) {
          console.error('UploadManager.action:', error);
          this.stop();
          isAborted = true;
          break;
        }
      }
    } while (isAborted && this.needToRetry());
    setTimeout(() => this.eventerIdle.run(isAborted), 0);
    return !isAborted;
  });
  public startUploading = () => {
    return this.action();
  };

  public stop = () => {
    const target = this.uploaders.find((it) => ['START'].includes(it.status));
    if (!target) {
      return;
    }

    try {
      target.cancel();
    } catch (error) {
      console.log('UploadManager.stop:', error);
    }
  };
  #retryCount = 0;
  private needToRetry = () => {
    this.#retryCount++;
    if (this.#retryCount <= 3) {
      return true;
    }
    if (window.confirm('일부 파일 생성에 실패하였습니다. 네트워크 상태 확인 후 재시도해주세요.')) {
      return true;
    }
    return false;
  };
}

export default UploadService;
