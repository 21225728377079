import {
  CButton,
  CCol,
  CFormLabel,
  CFormSwitch,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import { useMount } from '@voithru/front-core';
import React from 'react';
import { useHistory } from 'react-router';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import AppPagination from 'src/components/molecules/AppPagination';
import ProjectModal from 'src/components/pages/ClientSubAccountDetailPage/components/ProjectModal';
import { ProjectResponse } from 'src/types/api/Project';
import { UserResponse } from 'src/types/api/User';
import CredentialInfo from './components/CredentialInfo';

function ClientSubAccountDetailPage() {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const accountId = params.get('id');

  const [projects, setProject] = React.useState<ProjectResponse[]>();
  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalPage, setTotalPage] = React.useState(0);

  const getProjectByPage = React.useCallback(async () => {
    await api.project
      .pages({ accountId: Number(accountId), size: 20, page: currentPage })
      .then((res) => {
        const { data } = res;
        setProject(data.element);
        setTotalPage(data.totalPage);
      })
      .catch(errorLogger.error);
  }, [accountId, currentPage]);

  React.useEffect(() => {
    getProjectByPage();
  }, [currentPage, getProjectByPage]);

  const [account, setAccount] = React.useState<UserResponse>();

  const init = React.useCallback(async () => {
    if (!accountId) {
      return;
    }
    await api.account
      .item(Number(accountId))
      .get()
      .then((res) => {
        const { data } = res;
        setAccount(data);
      })
      .catch(errorLogger.error);
    getProjectByPage();
  }, [accountId, getProjectByPage]);

  useMount(() => {
    init();
  });

  const updateAcceptedByAccountId = React.useCallback(async () => {
    if (!account || !account.id || account.accepted === undefined) {
      return;
    }

    const { id, accepted } = account;

    await api.account
      .item(id)
      .accept(!accepted)
      .then(() => init())
      .catch(errorLogger.error);
  }, [account, init]);

  const deleteProjectPermission = React.useCallback(
    async (id) => {
      await api.account
        .item(Number(accountId))
        .deletePermission(id)
        .then(() => init())
        .catch(errorLogger.error);
    },
    [accountId, init]
  );

  return (
    <div>
      <div className="justify-content-between d-flex">
        <div style={{ borderBottom: '1px solid  #d8dbe0', width: '100%', fontSize: '20px', margin: '40px 0 25px 0' }}>
          <div>계정 정보</div>
        </div>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>이름</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormLabel>{account?.name}</CFormLabel>
          </CCol>
        </CRow>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>로그인 ID</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormLabel>{account?.email}</CFormLabel>
          </CCol>
        </CRow>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>연락처</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormLabel>{account?.phoneNumber}</CFormLabel>
          </CCol>
        </CRow>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>상태</CFormLabel>
          </CCol>
          <CCol sm={8}>
            <CFormSwitch onClick={() => updateAcceptedByAccountId()} defaultChecked={account?.accepted} />
          </CCol>
        </CRow>
      </div>
      <div className="justify-content-between d-flex">
        <div style={{ borderBottom: '1px solid  #d8dbe0', width: '100%', fontSize: '20px', margin: '40px 0 25px 0' }}>
          프로젝트 권한
        </div>
        <ProjectModal accountId={Number(accountId)} callback={() => init()} />
      </div>
      <CTable bordered style={{ width: 'max-content', minWidth: '100%' }}>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell>Project Id</CTableHeaderCell>
            <CTableHeaderCell>이름</CTableHeaderCell>
            <CTableHeaderCell>삭제</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {projects?.map((project, idx) => (
            <CTableRow key={idx}>
              <CTableDataCell>{`P-${project.id}`}</CTableDataCell>
              <CTableDataCell>{project.name}</CTableDataCell>
              <CTableDataCell>
                <CButton onClick={() => deleteProjectPermission(project.id)}>X</CButton>
              </CTableDataCell>
            </CTableRow>
          ))}
        </CTableBody>
      </CTable>
      <AppPagination currentPage={currentPage} maxPage={totalPage} setCurrentPage={setCurrentPage} />
      <CredentialInfo accountId={Number(accountId)} />
    </div>
  );
}

export default ClientSubAccountDetailPage;
