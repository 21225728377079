import { Parameter } from '@voithru/front-core';
import { AxiosInstance } from 'axios';
import { generatePath } from 'react-router';
import {
  AccountPermissionRequest,
  AccountsPagesRequest,
  AccountsRequest,
  User,
  UserResponse,
} from 'src/types/api/User';
import { APIPagination, APIPaginationRequest } from 'src/types/api';

const PATH_ACCOUNT = '/api/v1/accounts';

function apiAccount(instance: AxiosInstance) {
  return {
    get: (params: AccountsRequest) => {
      const search = Parameter.toURLSearchParams(params);
      return instance.get<UserResponse[]>(PATH_ACCOUNT, { params: search });
    },
    myInfo: () => instance.get<UserResponse>(PATH_ACCOUNT + '/myInfo'),
    pages: (params: APIPaginationRequest & AccountsPagesRequest) => {
      return instance.get<APIPagination<UserResponse>>(PATH_ACCOUNT + '/page', {
        params: Parameter.toURLSearchParams(params),
      });
    },
    item(id: number) {
      const PATH = generatePath(PATH_ACCOUNT + '/:id', { id });
      return {
        get: () => instance.get<UserResponse>(PATH),
        put: (data: User) => instance.put<UserResponse>(PATH, data),
        accept: (data: boolean) => instance.patch<UserResponse>(PATH + `/accepted?isAccepted=${data}`),
        deletePermission: (projectId: number) =>
          instance.delete(PATH + '/projectAccessPermission', { params: { projectId: projectId } }),
        postPermission: (data: AccountPermissionRequest) => instance.post(PATH + '/projectAccessPermission', data),
      };
    },
  };
}

export default apiAccount;
