import { CButton, CModalBody, CModalFooter } from '@coreui/react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { nanoid } from 'nanoid';
import React from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router';
import api from 'src/api';
import { Description, Title } from 'src/components/atoms/styled/element';
import { Column, Row } from 'src/components/atoms/styled/layout';
import AppPaths from 'src/constants/AppPaths';
import { Company } from 'src/types/api/Company';
import { JobsResponse } from 'src/types/api/Job';
import { JobResultResponse } from 'src/types/api/JobResult';
import { ProductOrder } from 'src/types/api/ProductOrder';
import { ProjectResponse } from 'src/types/api/Project';
import { isAxiosError } from 'src/utils/api/axios';
import { dateFormat } from 'src/utils/date';
import { NamedFile } from 'src/utils/files';
import MultipartUpload from 'src/utils/MultipartUpload';
import {
  ContentType,
  WorkRange,
  Source_Language,
  Translate_Language,
  ResultType,
  WorkTarget,
  AdditionalServices,
} from 'src/utils/translate';
import { modalStep } from '../../type';
import { PreviewFirstModalBody } from '../SettlementPreview';
import { PreviewSecondModalBodyWrapper, PreviewSecondModalTitleWrapper } from './styled';

interface Props {
  selectedJobResults: JobResultResponse[];
  productOrderMap: Map<number, ProductOrder>;
  jobsMap: Map<number, JobsResponse>;
  projectMap: Map<number, ProjectResponse>;
  company?: Company;
  handleStep?: (nextStep: modalStep) => void;
}

const html2Pdf2 = async (pdf: jsPDF, container: HTMLDivElement) => {
  const node = document.body.appendChild(container);
  const canvas = await html2canvas(node);

  const imgData = canvas.toDataURL('image/jpeg');

  pdf.addImage(imgData, 'JPEG', 0, 0, 450, 0);

  node.remove();

  return pdf;
};

const reactElement2Pdf = async (pdf: jsPDF, reactElement: React.ReactElement) => {
  const container = document.createElement('div');
  ReactDOM.render(reactElement, container);
  return await html2Pdf2(pdf, container);
};

function SettlementPublish(props: Props) {
  const { selectedJobResults, productOrderMap, jobsMap, projectMap, company, handleStep } = props;

  const history = useHistory();

  const createPdf = React.useCallback(async () => {
    const pdf = new jsPDF('p', 'px', 'a4');

    await reactElement2Pdf(
      pdf,
      <PreviewFirstModalBody
        selectedJobResults={selectedJobResults}
        productOrderMap={productOrderMap}
        jobsMap={jobsMap}
        company={company}
      />
    );
    pdf.addPage('a4', 'p');

    await reactElement2Pdf(
      pdf,
      <PreviewSecondModalBody
        selectedJobResults={selectedJobResults}
        productOrderMap={productOrderMap}
        jobsMap={jobsMap}
        projectMap={projectMap}
        company={company}
      />
    );

    return pdf.output('blob');
  }, [company, jobsMap, productOrderMap, projectMap, selectedJobResults]);

  const handleFileSubmit = React.useCallback(async () => {
    const filePdf = await createPdf();

    if (filePdf) {
      const date = new Date();
      const dateFirstTime = new Date(date.setHours(0, 0, 0)).toISOString();
      const dateLastTime = new Date(date.setHours(23, 59, 59)).toISOString();

      const documentRes = await api.document.pages({
        createDateTimeFrom: dateFirstTime,
        createDateTimeTo: dateLastTime,
      });

      if (isAxiosError(documentRes)) {
        throw documentRes;
      }

      const { totalSize } = documentRes.data;

      const name =
        dateFormat(Date.now(), 'YYYYMMDD').toString() + '_' + company?.name + '_' + (totalSize + 1).toString() + '.pdf';

      const file = new File([filePdf], name);
      const namedFile: NamedFile = {
        id: nanoid(),
        name: name,
        file: file,
      };

      const uploader = new MultipartUpload(namedFile);
      // job 사용하지않음
      await uploader.ready('JOB');

      uploader.addEventListener('done', async () => {
        if (uploader.fileId) {
          await api.document.post({
            jobResults: selectedJobResults,
            companyId: company?.id,
            type: 'DELIVERY_STATEMENT',
            name: name,
            status: 'REGISTERED',
            fileId: uploader.fileId?.toString(),
          });
        }
      });

      await uploader.start();

      history.push(AppPaths.settlementList.path);
    }
  }, [company?.id, company?.name, createPdf, history, selectedJobResults]);

  return (
    <>
      <CModalBody>
        <PreviewSecondModalBody
          selectedJobResults={selectedJobResults}
          productOrderMap={productOrderMap}
          jobsMap={jobsMap}
          projectMap={projectMap}
          company={company}
        />
      </CModalBody>
      <CModalFooter>
        <CButton
          color="secondary"
          onClick={() => {
            handleStep?.('SETTLEMENTPREVIEW');
          }}
        >
          뒤로가기
        </CButton>
        <CButton
          type={'submit'}
          color={'primary'}
          onClick={() => {
            handleFileSubmit();
          }}
        >
          발행하기
        </CButton>
      </CModalFooter>
    </>
  );
}

export default SettlementPublish;

function PreviewSecondModalBody(props: Props) {
  const { selectedJobResults, productOrderMap, jobsMap, projectMap, company } = props;

  let sumTotalPrice = 0;

  return (
    <>
      <PreviewSecondModalTitleWrapper>
        <Row className={'title'}>
          <Title>Panoplay 납품내역서</Title>
        </Row>
        <Row className={'description'}>
          <Description>
            발행일 <span>{dateFormat(Date.now(), 'YYYY년 MM월 DD일')}</span>
          </Description>
        </Row>
        <Row className={'description'}>
          <Description>
            수신 <span>{company?.name} 귀하</span>
          </Description>
        </Row>
      </PreviewSecondModalTitleWrapper>
      <PreviewSecondModalBodyWrapper>
        <Column>
          <Row className={'listRow'}>
            <span />
            <span>콘텐츠 타입</span>
            <span>프로젝트 명</span>
            <span>콘텐츠 명</span>
            <span>결과물 ID</span>
            <span>작업 범위</span>
            <span>원본 언어</span>
            <span>번역 언어</span>
            <span>납품형태</span>
            <span>작업대상</span>
            <span>부가서비스</span>
            <span>단위</span>
            <span>단가</span>
            <span>금액</span>
          </Row>
          {selectedJobResults.map((it, idx) => {
            const productOrder = productOrderMap.get(it.id) ?? null;
            const job = jobsMap.get(it.id) ?? null;
            const project = projectMap.get(it.id) ?? null;

            const serviceSum =
              productOrder?.options?.reduce((acc, cur) => {
                return acc + (cur?.price || 0);
              }, 0) ?? 0;

            const totalUnitPrice = (productOrder?.price || 0) + serviceSum;
            const summary = totalUnitPrice * (job?.workSize || 1);

            sumTotalPrice += summary;

            return (
              <Row key={idx} className={'listRow'}>
                <span>{idx}</span>
                <span>{job && ContentType[job.category]}</span>
                <span>{project?.name}</span>
                <span>{job?.name}</span>
                <span>JR-{it.id}</span>
                <span>{productOrder && WorkRange[productOrder.productType]}</span>
                <span>{productOrder && Source_Language[productOrder.sourceLanguage]}</span>
                <span>{productOrder?.translateLanguage && Translate_Language[productOrder.translateLanguage]}</span>
                <span>{productOrder?.deliveryType && ResultType[productOrder?.deliveryType]}</span>
                <span>{productOrder?.productSource && WorkTarget[productOrder.productSource]}</span>
                <Column className={'additionalServices'}>
                  {productOrder?.options?.map((it) => {
                    return <span key={it.id}>{AdditionalServices[it.type]}</span>;
                  })}
                </Column>

                <span>{job?.workSize}</span>
                <span>{totalUnitPrice.toLocaleString()}원</span>
                <span>{summary.toLocaleString()}원</span>
              </Row>
            );
          })}
        </Column>
        <Column className={'totalPrice'}>
          <Row>
            <span>총 금액</span>
            <span>{sumTotalPrice.toLocaleString()}원</span>
          </Row>
        </Column>
      </PreviewSecondModalBodyWrapper>
    </>
  );
}
