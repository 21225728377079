import { gql } from '@apollo/client';

export const GQL_ENTITY_ITEM = gql`
  query History(
    $project: Boolean = false
    $job: Boolean = false
    $job_result: Boolean = false
    $isAccount: Boolean = true
    $entityId: ID!
    $accountId: ID!
    $publisherId: ID!
  ) {
    project(id: $entityId) @include(if: $project) {
      status
    }

    job(id: $entityId) @include(if: $job) {
      status
    }

    jobResult(id: $entityId) @include(if: $job_result) {
      status
    }

    company: account(id: $accountId) @include(if: $isAccount) {
      company {
        name
      }
    }

    publisherAccount: account(id: $publisherId) {
      name
    }
  }
`;
