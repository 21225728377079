import React from 'react';
import { ColorTemplate } from 'src/theme/colors';
import { JobsStatus } from 'src/types/api/Job';
import { JobResultStatus } from 'src/types/api/JobResult';
import { ProjectStatus } from 'src/types/api/Project';
import styled from 'styled-components';
import { Badge } from '../styled/element';

type Status = ProjectStatus | JobsStatus | JobResultStatus | string;

type StatusColorPair = Record<Status, ColorTemplate>;
const STATUS_COLORS = Object.freeze<StatusColorPair>({
  REQUESTED: 'red',
  REGISTERED: 'yellow',
  PROCESSING: 'green',
  DONE: 'blue',
  REVIEW_REJECTED: 'red',
  REVIEWABLE: 'green',
  REVIEW_APPROVED: 'blue',
});

const STATUS_TRANSLATE = {
  requesting: '작성중',
  requested: '승인대기',
  registered: '작업대기',
  processing: '작업중',
  done: '작업완료',
  drop: '취소',
  active: '활성',
  inactive: '비활성',
  review_rejected: '검수거절',
  review_approved: '검수승인',
  reviewable: '검수대기',
};
interface Props {
  status: Status;
  disabled?: boolean;

  className?: string;
}

function StatusLabel(props: Props) {
  const { status, disabled, className } = props;

  const label = React.useMemo(() => {
    const key = status.toLocaleLowerCase();
    return STATUS_TRANSLATE[key] || '-';
  }, [status]);

  return (
    <Badge className={className} color={STATUS_COLORS[status] || 'grey'} disabled={disabled || status === 'DROP'}>
      {label}
    </Badge>
  );
}

export default styled(StatusLabel)``;
