import { Column, Row } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const ProductStatusWrapper = styled(Column)`
  width: 1840px;

  & > ${Row} {
    justify-content: space-between;
    align-items: flex-end;
    & > span {
      padding: 16px 12px;
      width: fit-content;
      border-bottom: 2px solid ${getRGBA(colors.grey90)};
      box-sizing: border-box;

      font-size: 20px;
      color: ${getRGBA(colors.grey90)};
    }
    & > a {
      color: ${getRGBA(colors.grey90)};
      text-decoration: none;
    }
  }

  & > ${Column} {
    margin-top: 24px;
    width: 100%;
    & > ${Row}:first-child {
      border: 2px solid ${getRGBA(colors.grey90)};
    }

    & > ${Row} {
      padding: 17px 0;

      text-align: left;
      font-size: 15px;
      & > span {
        padding: 0 4px;
      }

      & > span:nth-child(1) {
        width: 100px;
      }
      & > span:nth-child(2) {
        width: 150px;
      }

      & > span:nth-child(3) {
        width: 150px;
      }
      & > span:nth-child(4) {
        width: 200px;
      }
      & > span:nth-child(5) {
        width: 200px;
      }
      & > span:nth-child(6) {
        width: 300px;
      }
      & > span:nth-child(7) {
        width: 200px;
      }
      & > span:nth-child(8),
      span:nth-child(9),
      span:nth-child(10) {
        width: 100px;
      }
      & > span:nth-child(11) {
        width: 120px;
      }
      & > span:nth-child(12) {
        width: 120px;
      }
    }
  }
`;
