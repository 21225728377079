import React from 'react';
import AppFileUploadList from 'src/components/organisms/AppFileUploadList';
import UploadService from 'src/utils/UploadService';

interface UploaderContextValue {
  services: ReadonlyMap<string, UploadService>;
  register(key: string, service: UploadService): void;
  unregister(key: string): void;
  isUiBlocked: boolean;
  startBlockingUi(): void;
  finishBlockingUi(): void;
}

const Context = React.createContext<UploaderContextValue | undefined>(undefined);

interface Props {
  children?: React.ReactNode;
}
export function UploaderContextProvider(props: Props) {
  const { children } = props;
  const [, forceUpdate] = React.useReducer(() => ({}), {});

  const services = React.useMemo(() => new Map<string, UploadService>(), []);
  const [isUiBlocked, setIsUiBlocked] = React.useState(false);

  const register = React.useCallback(
    (key: string, service: UploadService) => {
      if (services.get(key) === service) {
        return;
      }

      services.set(key, service);

      service.addEventListener('progress', forceUpdate);
      service.addEventListener('start', forceUpdate);
      service.addEventListener('cancel', forceUpdate);
      service.addEventListener('done', forceUpdate);
      forceUpdate();
    },
    [services]
  );

  const unregister = React.useCallback(
    (key: string) => {
      services.delete(key);
    },
    [services]
  );
  const startBlockingUi = React.useCallback(() => {
    setIsUiBlocked(true);
  }, []);
  const finishBlockingUi = React.useCallback(() => {
    setIsUiBlocked(false);
  }, []);

  const multipart = Array.from(services.values())
    .map((it) => it.uploaders)
    .flat();

  const memo = React.useMemo(
    () => ({
      services,
      register,
      unregister,

      startBlockingUi,
      finishBlockingUi,

      isUiBlocked,
    }),
    [finishBlockingUi, isUiBlocked, register, services, startBlockingUi, unregister]
  );
  return (
    <Context.Provider value={memo}>
      {children}
      <AppFileUploadList data={multipart} />
    </Context.Provider>
  );
}

export function useUploaderContext() {
  const context = React.useContext(Context);
  if (!context) {
    throw new Error('useAuthContext: Use it after Provider');
  }

  return context;
}
