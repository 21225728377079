import React from 'react';
import FileDropBox from 'src/components/molecules/FileDropBox';
import ContentFileList from 'src/components/organisms/ContentFileList';
import { GoogleFile } from 'src/types/api/File';
import { FileGroup, FileGroupWithGoogle } from 'src/utils/JobRegisterService';

interface Props {
  onSelectFiles(fileGroups: FileGroup[]): void;
  files: FileGroupWithGoogle[];
  onSort(fileGroups: FileGroup[], googleFiles: GoogleFile[]): void;
  onNameChange(target: string, name: string): void;
  onDelete(target: FileGroup): void;
  onGoogleFileDelete(target: GoogleFile): void;
  onGoogleFileGroups(files: GoogleFile, index: number): void;
  projectAccountId?: number;
  className?: string;
}

function ContentFileUpload(props: Props) {
  const {
    onSelectFiles,
    files,
    onSort,
    onNameChange,
    onDelete,
    onGoogleFileDelete,
    onGoogleFileGroups,
    projectAccountId,
  } = props;

  return (
    <>
      <div>콘텐츠 파일 업로드</div>
      <div>
        <FileDropBox
          onSelectFiles={onSelectFiles}
          projectAccountId={projectAccountId}
          onGoogleFileGroups={onGoogleFileGroups}
        />
      </div>
      <div>
        {files.length > 0 && (
          <ContentFileList
            files={files}
            onSort={onSort}
            onNameChange={onNameChange}
            onDelete={onDelete}
            onGoogleFileDelete={onGoogleFileDelete}
          />
        )}
      </div>
    </>
  );
}

export default ContentFileUpload;
