import React from 'react';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import IssuedStatusBadge from 'src/components/atoms/IssuedStatusBadge';
import StatusLabel from 'src/components/atoms/StatusLabel';
import { CheckBox } from 'src/components/atoms/styled/form';
import { Row, Column } from 'src/components/atoms/styled/layout';
import AppPagination from 'src/components/molecules/AppPagination';
import PaymentSettlementModal from 'src/components/organisms/PaymentSettlementModal';
import { CompanyResponse } from 'src/types/api/Company';
import { JobsResponse } from 'src/types/api/Job';
import { ProductOrder } from 'src/types/api/ProductOrder';
import { ProjectResponse } from 'src/types/api/Project';
import { UserResponse } from 'src/types/api/User';
import { isAxiosError } from 'src/utils/api/axios';
import {
  getProjectById,
  getJobById,
  getProductOrderById,
  getDocumentByJobResultId,
  getAccountByAccountId,
} from 'src/utils/api/getter';
import { dateFormat } from 'src/utils/date';
import {
  ContentType,
  WorkRange,
  Source_Language,
  Translate_Language,
  ResultType,
  AdditionalServices,
} from 'src/utils/translate';
import { DocumentResponse, DocumentStatus } from 'src/types/api/Document';
import { JobResultPageRequest, JobResultResponse, JobResultStatus } from 'src/types/api/JobResult';
import SearchFormOfSettlementManagement from './components/SearchFormOfSettlementManagement';
import { JobResultForSettlementWrapper } from './styled';
import { SearchFormFilter } from './type';

function SettlementManagementPage() {
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const [jobResultChecks] = React.useState<Map<number, boolean>>(() => new Map());
  const [allChecked, setAllChecked] = React.useState(() => false);
  const handleAllChecked = React.useCallback(() => {
    const entryJobResultChekcs = jobResultChecks.entries();

    for (const item of entryJobResultChekcs) {
      const [id] = item;
      jobResultChecks.set(id, !allChecked);
    }

    setAllChecked(!allChecked);
  }, [allChecked, jobResultChecks]);

  const handleChecked = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
      if (allChecked) {
        setAllChecked(false);
      }
      jobResultChecks.set(id, e.target.checked);

      if (!allChecked) {
        const entryJobResultChecks = jobResultChecks.entries();
        let flag = true;
        for (const item of entryJobResultChecks) {
          const [, bool] = item;
          flag = bool;
          if (!flag) break;
        }
        setAllChecked(flag);
      }

      forceUpdate();
    },
    [jobResultChecks, allChecked]
  );

  const [filter, setFilter] = React.useState<SearchFormFilter>();
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [totalPage, setTotalPage] = React.useState<number>(0);

  const [project, setProject] = React.useState<Map<number, ProjectResponse>>(() => new Map());
  const [job, setJob] = React.useState<Map<number, JobsResponse>>(() => new Map());
  const [productOrder, setProductOrder] = React.useState<Map<number, ProductOrder>>(() => new Map());
  const [documentStatus, setDocumentStatus] = React.useState<Map<number, DocumentResponse>>(() => new Map());
  const [managerAccount, setManagerAccount] = React.useState<Map<number, UserResponse>>(() => new Map());
  const [company, setCompany] = React.useState<Map<number, CompanyResponse>>(() => new Map());
  const [companyManagers, setCompanyManagers] = React.useState<Map<number, UserResponse>>(() => new Map());
  const [jobResult, setJobResult] = React.useState<JobResultResponse[]>([]);
  const [totalSize, setTotalSize] = React.useState<Number>(0);

  const getJobResult = React.useCallback(async () => {
    try {
      if (!filter?.company) {
        return window.alert('고객 회사를 선택해주세요.');
      }

      const statusData: DocumentStatus[] = [];
      if (filter?.status) {
        filter.status?.map((status) => status !== 'null' && statusData.push(status as DocumentStatus));
      }
      const name = filter?.name;
      const companyId = filter.company?.id ?? undefined;
      const createDateTimeFrom = filter?.createDateTimeFrom && new Date(filter.createDateTimeFrom).toISOString();
      const createDateTimeTo = filter?.createDateTimeTo && new Date(filter.createDateTimeTo).toISOString();
      const updateDateTimeFrom = filter?.updateDateTimeFrom && new Date(filter.updateDateTimeFrom).toISOString();
      const updateDateTimeTo = filter?.updateDateTimeTo && new Date(filter.updateDateTimeTo).toISOString();
      const companyManagerId = filter?.salesId;
      const managerId = filter?.managerId;
      const accountId = filter?.accountId;
      const documentStatus = statusData.length > 0 ? statusData : undefined;
      const includeDocumentNotRegistered = statusData.length !== filter?.status?.length;
      const status = 'DONE' as JobResultStatus;
      const params: JobResultPageRequest = {
        accountId,
        page: currentPage,
        size: 20,
        jobName: name,
        createDateTimeFrom,
        createDateTimeTo,
        updateDateTimeFrom,
        updateDateTimeTo,
        documentStatus,
        includeDocumentNotRegistered,
        companyId,
        managerId,
        companyManagerId,
        status,
      };
      const res = await api.jobResult.pages(params);

      if (isAxiosError(res)) {
        throw res;
      }
      const { data } = res;

      const projectMap = new Map<number, ProjectResponse>();
      const jobMap = new Map<number, JobsResponse>();
      const productOrderMap = new Map<number, ProductOrder>();
      const documentMap = new Map<number, DocumentResponse>();
      const managerAccountMap = new Map<number, UserResponse>();
      const companyMap = new Map<number, CompanyResponse>();
      const companayManagerMap = new Map<number, UserResponse>();

      for (const item of data.element) {
        try {
          jobResultChecks.set(item.id, false);

          const projectRes = await getProjectById(item.projectId);
          const jobRes = await getJobById(item.jobId);
          const productOrderRes = await getProductOrderById(item.productOrderId);
          const documentRes = await getDocumentByJobResultId(item.id);

          if (projectRes.data.managerId) {
            const managerAccountRes = await getAccountByAccountId(projectRes.data.managerId);

            managerAccountMap.set(item.id, managerAccountRes.data);
          }

          if (projectRes.data.accountId) {
            const accountRes = await getAccountByAccountId(projectRes.data.accountId);

            if (accountRes.data.companyId) {
              const companyRes = await api.company.item(accountRes.data.companyId).get();

              companyMap.set(item.id, companyRes.data);

              if (companyRes.data.managerAccountId) {
                const companyManagerUser = await api.account.item(companyRes.data.managerAccountId).get();
                companayManagerMap.set(companyManagerUser.data.id, companyManagerUser.data);
              }
            }
          }

          projectMap.set(item.id, projectRes.data);
          jobMap.set(item.id, jobRes.data);
          productOrderMap.set(item.id, productOrderRes.data);

          if (documentRes.data?.id) {
            documentMap.set(item.id, documentRes.data);
          }
        } catch (err) {
          errorLogger.error(err);
        }
      }
      setJobResult(data.element);
      setProject(projectMap);
      setJob(jobMap);
      setProductOrder(productOrderMap);
      setDocumentStatus(documentMap);
      setManagerAccount(managerAccountMap);
      setTotalSize(data.totalSize);
      setTotalPage(data.totalPage);
      setCompany(companyMap);
      setCompanyManagers(companyManagers);
    } catch (err) {
      errorLogger.error(err);
    }
  }, [companyManagers, currentPage, filter, jobResultChecks]);

  React.useEffect(() => {
    if (filter) {
      getJobResult();
    }
  }, [currentPage, filter, getJobResult]);

  return (
    <div>
      <SearchFormOfSettlementManagement setFilter={(data) => setFilter(data)} defaultVisible={true} />
      <div className="d-flex justify-content-end pb-2">
        <PaymentSettlementModal
          selectedJobResults={jobResult.filter((it) => {
            return (
              (!documentStatus.get(it.id) || documentStatus.get(it.id)?.status === 'CANCELLED') &&
              jobResultChecks.get(it.id)
            );
          })}
          productOrderMap={productOrder}
          jobsMap={job}
          projectMap={project}
          company={filter?.company}
        />
      </div>
      <div style={{ overflow: 'auto' }}>
        <JobResultForSettlementWrapper>
          <Row>
            <span>작업결과물 ({totalSize})</span>
          </Row>
          <Column>
            <Row>
              <span>
                <CheckBox onChange={handleAllChecked} checked={allChecked} />
              </span>
              <span>고객 회사명</span>
              <span>콘텐츠 타입</span>
              <span>프로젝트명</span>
              <span>콘텐츠명</span>
              <span>결과물 ID</span>
              <span>납품내역서 발급상태</span>
              <span>작업범위</span>
              <span>원본언어</span>
              <span>번역언어</span>
              <span>납품형태</span>
              <span>작업대상</span>
              <span>상품단가</span>
              <span>부가서비스</span>
              <span>부가서비스단가</span>
              <span>총 단가</span>
              <span>작업량</span>
              <span>합계</span>
              <span>진행상태</span>
              <span>최초생성일</span>
              <span>납품완료일</span>

              <span>번역 PM</span>
              <span>담당 Sales</span>
            </Row>
            {jobResult &&
              jobResult.map((it) => {
                const projectByJobResults = project?.get(it.id) ?? null;
                const jobByJobResults = job?.get(it.id) ?? null;
                const productOrderByJobResults = productOrder?.get(it.id) ?? null;
                const documentByJobResults = documentStatus?.get(it.id) ?? null;
                const managerAccountByJobResults = managerAccount?.get(it.id) ?? null;
                const companyByJobResults = company?.get(it.id) ?? null;
                const companyManager =
                  companyByJobResults?.managerAccountId && companyManagers?.get(companyByJobResults.managerAccountId!!);

                const serviceSum =
                  productOrderByJobResults?.options?.reduce((acc, cur) => {
                    return acc + (cur?.price || 0);
                  }, 0) ?? 0;

                const totalUnitPrice = (productOrderByJobResults?.price || 0) + serviceSum;
                const summary = totalUnitPrice * (jobByJobResults?.workSize || 1);

                return (
                  <Row key={it.id}>
                    <span>
                      <CheckBox onChange={(e) => handleChecked(e, it.id)} checked={jobResultChecks.get(it.id)} />
                    </span>
                    <span>{companyByJobResults?.name}</span>
                    <span>{jobByJobResults && ContentType[jobByJobResults.category]}</span>
                    <span>{projectByJobResults?.name}</span>
                    <span>{jobByJobResults?.name}</span>
                    <span>JR-{it.id}</span>
                    <span>
                      <IssuedStatusBadge status={documentByJobResults?.status || ''} />
                    </span>
                    <span>{productOrderByJobResults && WorkRange[productOrderByJobResults.productType]}</span>
                    <span>{productOrderByJobResults && Source_Language[productOrderByJobResults.sourceLanguage]}</span>
                    <span>
                      {productOrderByJobResults?.translateLanguage &&
                        Translate_Language[productOrderByJobResults.translateLanguage]}
                    </span>
                    <span>
                      {productOrderByJobResults?.deliveryType && ResultType[productOrderByJobResults.deliveryType]}
                    </span>
                    <span>-</span>
                    <span>{productOrderByJobResults?.price?.toLocaleString()}</span>
                    <span>
                      {productOrderByJobResults?.options &&
                        productOrderByJobResults.options[0]?.type &&
                        AdditionalServices[productOrderByJobResults.options[0].type]}
                    </span>
                    <span>{serviceSum.toLocaleString()}</span>
                    <span>{totalUnitPrice.toLocaleString()}</span>
                    <span>{jobByJobResults?.workSize}</span>
                    <span>{summary.toLocaleString()}</span>
                    <span>
                      <StatusLabel status={it.status} />
                    </span>
                    <span>{dateFormat(it.createDateTime, 'YYYY.MM.DD')}</span>
                    <span>
                      {projectByJobResults?.doneDateTime && dateFormat(projectByJobResults?.doneDateTime, 'YYYY.MM.DD')}
                    </span>
                    <span>{managerAccountByJobResults?.role === 'ADMIN' && managerAccountByJobResults?.name}</span>
                    <span>{companyManager && companyManager.name}</span>
                  </Row>
                );
              })}
          </Column>
        </JobResultForSettlementWrapper>
      </div>
      <AppPagination currentPage={currentPage} maxPage={totalPage} setCurrentPage={setCurrentPage} />
    </div>
  );
}

export default SettlementManagementPage;
