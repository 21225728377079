import { CModal, CModalHeader, CModalTitle } from '@coreui/react';
import React from 'react';
import { ButtonBase } from 'src/components/atoms/styled/button';
import { JobsResponse } from 'src/types/api/Job';
import { JobResultResponse } from 'src/types/api/JobResult';
import { ProductOrderResponse } from 'src/types/api/ProductOrder';
import { UserResponse } from 'src/types/api/User';
import ApprovedJobResult from './components/ApprovedJobResult';
import CreateJobResult from './components/CreateJobResult';
import { modalStep } from './type';

interface Props {
  jobsMap: Map<number, JobsResponse>;
  managerMap: Map<number, UserResponse>;
  productOrderMap: Map<number, ProductOrderResponse>;
  jobResults: JobResultResponse[] | undefined;
  refreshJobResultList: () => void;
}

function AddJobResultModal(props: Props) {
  const { jobsMap, managerMap, productOrderMap, jobResults, refreshJobResultList } = props;

  const [modalType, setCurrentModalType] = React.useState<modalStep>('CREATEJOBRESULT');

  const [visible, setVisible] = React.useState(false);

  const handleCloseModal = React.useCallback(() => {
    setCurrentModalType('CREATEJOBRESULT');
    setVisible(false);
    refreshJobResultList();
  }, [refreshJobResultList]);

  const [targetJobs, setTargetJobs] = React.useState<JobsResponse[]>([]);

  const [targetJobResults, setTargetJobResults] = React.useState<JobResultResponse[]>();

  return (
    <>
      <ButtonBase onClick={() => setVisible(!visible)}>작업 결과물 추가하기</ButtonBase>
      <CModal size={'xl'} visible={visible} onDismiss={() => handleCloseModal()}>
        <CModalHeader onDismiss={() => handleCloseModal()}>
          <CModalTitle>작업결과물 추가하기</CModalTitle>
        </CModalHeader>
        {modalType === 'CREATEJOBRESULT' && (
          <CreateJobResult
            jobResults={jobResults}
            jobsMap={jobsMap}
            managerMap={managerMap}
            productOrderMap={productOrderMap}
            onClose={handleCloseModal}
            setTargetJobs={setTargetJobs}
            handleStep={setCurrentModalType}
            setTargetJobResults={setTargetJobResults}
          />
        )}
        {modalType === 'APPROVEDJOBRESULT' && (
          <ApprovedJobResult
            targetJobs={targetJobs}
            productOrderMap={productOrderMap}
            onClose={handleCloseModal}
            targetJobResults={targetJobResults}
          />
        )}
      </CModal>
    </>
  );
}

export default AddJobResultModal;
