import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react';
import React from 'react';

interface Props {
  onClick: () => void;
}

function ChangeProductAlertModal(props: Props) {
  const { onClick } = props;

  const [visible, setVisible] = React.useState(false);

  const onConfirm = React.useCallback(() => {
    onClick();
    setVisible(false);
  }, [onClick]);

  return (
    <div className="align-items-center d-flex">
      <CButton style={{ width: 'max-content' }} onClick={() => setVisible(!visible)}>
        수정하기
      </CButton>
      <CModal size={'sm'} visible={visible} onDismiss={() => setVisible(false)}>
        <CModalHeader onDismiss={() => setVisible(false)}>
          <CModalTitle>상품 수정</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div>
            <div>단가수정 시 적용되어 있는 모든 프로젝트에 새로운 단가가 적용됩니다. </div>
            <div>정말로 수정하시겠습니까?</div>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            취소
          </CButton>
          <CButton onClick={() => onConfirm()}>확인</CButton>
        </CModalFooter>
      </CModal>
    </div>
  );
}
export default ChangeProductAlertModal;
