import { ButtonBase } from 'src/components/atoms/styled/button';
import { Title } from 'src/components/atoms/styled/element';
import { Column, Row } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const JobResultListWrapper = styled(Column)`
  width: 100%;
  & > ${Row} {
    justify-content: space-between;

    & > ${Title} {
      flex: 1;

      font-size: 18px;
      font-weight: 700;
    }
  }

  & > ${Row}.buttonWrap {
    justify-content: space-between;
    margin-top: 32px;

    & > ${Row}.modalButtonWrap {
      & > ${ButtonBase} {
        padding: 3px 11px;

        background: ${getRGBA(colors.green50)};
        border-radius: 4px;

        color: ${getRGBA(colors.white)};
      }

      & > :not(:first-child) {
        margin-left: 12px;
      }
    }

    & > ${Row}.statusWrap {
      justify-content: flex-end;

      font-size: 15px;

      & > ${ButtonBase} {
        padding: 0 5px;
        margin: 5px 0;
        height: 30px;

        box-sizing: border-box;
      }

      & > ${ButtonBase}.active {
        border-bottom: 2px solid ${getRGBA(colors.blue50)};
      }

      & > ${ButtonBase} + ${ButtonBase} {
        margin-left: 19px;
      }
    }
  }

  & > ${Column} {
    margin-top: 20px;
    & > ${Row} {
      align-items: center;
      padding: 4px 0;

      font-size: 15px;

      & > span {
        padding-left: 4px;
      }

      & > span.buttonWrap {
        & > ${ButtonBase} {
          width: 80px;

          border-radius: 32px;
          box-sizing: border-box;

          font-size: 12px;
          text-align: center;
        }

        & > ${ButtonBase}:not(:last-child) {
          background: ${getRGBA(colors.blue20)};
          color: ${getRGBA(colors.blue50)};
        }

        & > ${ButtonBase}:nth-child(3) {
          background: ${getRGBA(colors.red10)};
          color: ${getRGBA(colors.red50)};
        }

        & > ${ButtonBase}[disabled] {
          background: none;
        }

        & > ${ButtonBase} + ${ButtonBase} {
          margin-left: 8px;
        }
      }

      & > span {
        padding-left: 4px;
        min-height: 22px;
        & > input {
          width: inherit;
        }
        & > label {
          & > input {
            display: none;
          }
        }
      }

      & > span:nth-child(1) {
        width: 30px;
      }
      & > span:nth-child(2) {
        width: 80px;
      }
      & > span:nth-child(3) {
        width: 220px;
      }
      & > span:nth-child(4) {
        width: 100px;
      }
      & > span:nth-child(5) {
        width: 100px;
      }
      & > span:nth-child(6) {
        width: 100px;
      }
      & > span:nth-child(7) {
        width: 100px;
      }
      & > span:nth-child(8) {
        width: 100px;
      }
      & > span:nth-child(9) {
        width: 120px;
      }
      & > span:nth-child(10) {
        width: 80px;
      }
      & > span:nth-child(11) {
        width: 280px;
      }
      & > span:nth-child(12) {
        width: 60px;
        text-align: center;
      }
      & > span:nth-child(13) {
        width: 150px;
        text-align: center;
      }
      & > span:nth-child(14) {
        width: 100px;
      }
      & > span:nth-child(15) {
        width: 100px;
      }
      & > span:nth-child(16) {
        width: 100px;
      }
      & > span:nth-child(17) {
        width: 100px;
      }
      & > span:nth-child(18) {
        width: 130px;
        text-align: center;
      }
      & > span:nth-child(19) {
        width: 80px;
      }
    }
  }
`;
