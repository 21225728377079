import { AxiosInstance } from 'axios';

interface Post {
  projectId: number;
  jobResultId: number;
  hermesAccountId: number;
}

interface Delete {
  jobResultId: number;
  hermesAccountId: number;
}

const PATH = '/api/v1/workerJobResultGrants';

function apiWorkerJobResultGrants(instance: AxiosInstance) {
  return {
    post: (data: Post[]) => instance.post(PATH, data),
    delete: (data: Delete[]) => instance.delete(PATH, { data }),
  };
}

export default apiWorkerJobResultGrants;
