import React from 'react';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { Row } from 'src/components/atoms/styled/layout';
import AppPagination from 'src/components/molecules/AppPagination';
import { toSortString } from 'src/types/api';
import { EntityHistoryAPIResponse, EntityHistoryParams } from 'src/types/api/EntityHistory';
import { isAxiosError } from 'src/utils/api/axios';
import EntityHistoryItem from './components/EntityHistoryItem';
import { EntityHistoryTableWrapper } from './styled';

interface Props {
  params?: EntityHistoryParams;
}

function EntityHistoryTable(props: Props) {
  const { params } = props;

  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [maxPage, setMaxPage] = React.useState<number>(0);
  const [projectHistories, setProjectHistories] = React.useState<EntityHistoryAPIResponse[]>();

  const getProjectHistory = React.useCallback(async () => {
    try {
      const res = await api.entityHistory.get({
        ...params,
        size: 20,
        page: currentPage,
        sort: toSortString({ direction: 'desc', sortKey: 'id' }),
      });
      if (isAxiosError(res)) {
        throw res;
      }

      setProjectHistories(res.data.element);
      setMaxPage(res.data.totalPage);
    } catch (error) {
      errorLogger.error(error);
    }
  }, [currentPage, params]);

  React.useEffect(() => {
    getProjectHistory();
  }, [currentPage, getProjectHistory]);

  return (
    <EntityHistoryTableWrapper>
      <Row>
        <span>ID</span>
        <span>일시</span>
        <span>작업유형</span>
        <span>작업 ID</span>
        <span>진행상태</span>
        <span>회사명</span>
        <span>변경사항</span>
        <span>작업자</span>
      </Row>
      {projectHistories?.map((it, index) => {
        return <EntityHistoryItem key={index} item={it} />;
      })}
      <AppPagination currentPage={currentPage} maxPage={maxPage} setCurrentPage={setCurrentPage} />
    </EntityHistoryTableWrapper>
  );
}

export default EntityHistoryTable;
