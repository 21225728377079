import { CButton } from '@coreui/react';
import { cn, Parameter } from '@voithru/front-core';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import AppPagination from 'src/components/molecules/AppPagination';
import JobResultTaskList from 'src/components/molecules/JobResultTaskList';
import JobTaskList from 'src/components/molecules/JobTaskList';
import ProjectTaskList from 'src/components/molecules/ProjectTaskList';
import JobResultStatusUpdateModal from 'src/components/organisms/JobResultStatusUpdateModal';
import JobStatusUpdateModal from 'src/components/organisms/JobStatusUpdateModal';
import ProjectStatusUpdateModal from 'src/components/organisms/ProjectStatusUpdateModal';
import AppPaths from 'src/constants/AppPaths';
import { APIPaginationSort } from 'src/types/api';
import { JobPageRequest, JobsStatus, JobsStatusUpdate } from 'src/types/api/Job';
import { JobResultPageRequest, JobResultStatus, JobResultStatusUpdate } from 'src/types/api/JobResult';
import { ProjectPageRequest, ProjectStatus, ProjectStatusUpdate } from 'src/types/api/Project';
import {
  JobResultStatus as JobResultStatusEnum,
  JobStatus as JobStatusEnum,
  ProjectStatus as ProjectStatusEnum,
} from 'src/types/globalTypesFile/graphql';
import { isAxiosError } from 'src/utils/api/axios';
import { lastTimeOfDate } from 'src/utils/date';
import { TaskName } from 'src/utils/translate';
import SearchFormOfProjectAndJobList from './components/SearchFormOfProjectAndJobList';
import { ButtonWrapRow, TabItemWrapper } from './styled';
import { SearchFormFilter } from './type';

const tabItems = [TaskName.project, TaskName.content, TaskName.result];

function ProjectsAndContentsListPage() {
  const history = useHistory();

  const location = useLocation<SearchFormFilter>();
  const match = Parameter.parse(location.search);

  const preDefinedFilter: SearchFormFilter = {
    createDateTimeFrom: match.createDateTimeFrom,
    createDateTimeTo: match.createDateTimeTo,
    updateDateTimeFrom: match.updateDateTimeFrom,
    updateDateTimeTo: match.updateDateTimeTo,
    companyId: Number(match.companyId) || undefined,
    status: match.status?.split(',') as (ProjectStatus | JobsStatus | JobResultStatus)[],
    managerId: match.managerId != null ? Number(match.managerId) : undefined,
    name: match.name,
    accountId: match.accountId != null ? Number(match.accountId) : undefined,
  };

  const [focusItem, setFocusItem] = React.useState<string>(match.tab != null ? TaskName[match.tab] : TaskName.project);
  const [ids, setIds] = React.useState<{ id: number }[]>([]);
  const [filter, setFilter] = React.useState<SearchFormFilter>(preDefinedFilter);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [maxPage, setMaxPage] = React.useState<number>(0);
  const [resourceIds, setResourceIds] = React.useState<number[]>([]);
  const getList = React.useCallback(async (page: number, size: number, filter?: SearchFormFilter) => {
    try {
      const name = filter?.name;
      const companyId = filter?.companyId;
      const createDateTimeFrom = filter?.createDateTimeFrom && new Date(filter.createDateTimeFrom).toISOString();
      const createDateTimeTo = filter?.createDateTimeTo && lastTimeOfDate(filter.createDateTimeTo);
      const updateDateTimeFrom = filter?.updateDateTimeFrom && new Date(filter.updateDateTimeFrom).toISOString();
      const updateDateTimeTo = filter?.updateDateTimeTo && lastTimeOfDate(filter.updateDateTimeTo);
      const managerId = filter?.managerId;
      const accountId = filter?.accountId;
      const status = filter?.status;
      // TODO: support column ordering
      const sortInfo: APIPaginationSort = {
        sortKey: 'id',
        direction: 'desc',
      };
      const sort = `${sortInfo.sortKey},${sortInfo.direction}`;
      const params: ProjectPageRequest = {
        accountId,
        page,
        size,
        name,
        createDateTimeFrom,
        createDateTimeTo,
        updateDateTimeFrom,
        updateDateTimeTo,
        companyId,
        managerId,
        sort,
      };

      if (status && status.length > 0) {
        const projectStatusData: ProjectStatus[] = [];

        status.forEach((it) => {
          if (Object.keys(ProjectStatusEnum).includes(it)) {
            projectStatusData.push(it as ProjectStatus);
          }
        });
        params.status = projectStatusData;
      }

      const res = await api.project.pages(params);
      if (isAxiosError(res)) {
        throw res;
      }

      const { data } = res;

      setIds(
        data.element.map((it) => {
          return { id: it.id };
        })
      );
      setMaxPage(data.totalPage);
    } catch (err) {
      errorLogger.error(err);
    }
  }, []);

  const getJobList = React.useCallback(async (page: number, size: number, filter?: SearchFormFilter) => {
    try {
      const name = filter?.name;
      const companyId = filter?.companyId;
      const createDateTimeFrom = filter?.createDateTimeFrom && new Date(filter.createDateTimeFrom).toISOString();
      const createDateTimeTo = filter?.createDateTimeTo && lastTimeOfDate(filter.createDateTimeTo);
      const updateDateTimeFrom = filter?.updateDateTimeFrom && new Date(filter.updateDateTimeFrom).toISOString();
      const updateDateTimeTo = filter?.updateDateTimeTo && lastTimeOfDate(filter.updateDateTimeTo);
      const managerId = filter?.managerId;
      const accountId = filter?.accountId;
      const status = filter?.status;
      // TODO: support column ordering
      const sortInfo: APIPaginationSort = {
        sortKey: 'id',
        direction: 'desc',
      };
      const sort = `${sortInfo.sortKey},${sortInfo.direction}`;
      const params: JobPageRequest = {
        accountId,
        page,
        size,
        name,
        createDateTimeFrom,
        createDateTimeTo,
        updateDateTimeFrom,
        updateDateTimeTo,
        companyId,
        managerId,
        sort,
      };

      if (status && status.length > 0) {
        const jobStatusData: JobsStatus[] = [];

        status.forEach((it) => {
          if (Object.keys(JobStatusEnum).includes(it)) {
            jobStatusData.push(it as JobsStatus);
          }
        });
        params.status = jobStatusData;
      }

      const { data } = await api.jobs.pages(params);

      setIds(
        data.element.map((it) => {
          return { id: it.id };
        })
      );

      setMaxPage(data.totalPage);
    } catch (error) {
      errorLogger.error(error);
    }
  }, []);

  const getJobResultList = React.useCallback(async (page: number, size: number, filter?: SearchFormFilter) => {
    try {
      const name = filter?.name;
      const companyId = filter?.companyId;
      const createDateTimeFrom = filter?.createDateTimeFrom && new Date(filter.createDateTimeFrom).toISOString();
      const createDateTimeTo = filter?.createDateTimeTo && lastTimeOfDate(filter.createDateTimeTo);
      const updateDateTimeFrom = filter?.updateDateTimeFrom && new Date(filter.updateDateTimeFrom).toISOString();
      const updateDateTimeTo = filter?.updateDateTimeTo && lastTimeOfDate(filter.updateDateTimeTo);
      const managerId = filter?.managerId;
      const accountId = filter?.accountId;
      const status = filter?.status;
      // TODO: support column ordering
      const sortInfo: APIPaginationSort = {
        sortKey: 'id',
        direction: 'desc',
      };
      const sort = `${sortInfo.sortKey},${sortInfo.direction}`;
      const params: JobResultPageRequest = {
        accountId,
        page,
        size,
        jobName: name,
        createDateTimeFrom,
        createDateTimeTo,
        updateDateTimeFrom,
        updateDateTimeTo,
        companyId,
        managerId,
        sort,
      };

      if (status && status.length > 0) {
        const jobResultStatusData: JobResultStatus[] = [];

        status.forEach((it) => {
          if (Object.keys(JobResultStatusEnum).includes(it)) {
            jobResultStatusData.push(it as JobResultStatus);
          }
        });
        params.status = jobResultStatusData;
      }

      const { data } = await api.jobResult.pages(params);

      setIds(
        data.element.map((it) => {
          return { id: it.id };
        })
      );

      setMaxPage(data.totalPage);
    } catch (error) {
      errorLogger.error(error);
    }
  }, []);

  const [force, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const ListConverter = React.useCallback(async () => {
    if (TaskName.project === focusItem) {
      await getList(currentPage, 10, filter);
    } else if (TaskName.content === focusItem) {
      await getJobList(currentPage, 10, filter);
    } else if (TaskName.result === focusItem) {
      await getJobResultList(currentPage, 10, filter);
    }
  }, [focusItem, getList, currentPage, filter, getJobList, getJobResultList]);

  const onSubmitProjectsStatusUpdate = React.useCallback((statusUpdatingProjects: ProjectStatusUpdate[]) => {
    try {
      statusUpdatingProjects.forEach(async (statusUpdatingProject) => {
        const { id: projectId, projectStatus, managerId, scheduledDeadlineDateTime } = statusUpdatingProject;

        const res = await api.project
          .item(projectId)
          .statusAndScheduledDeadlineAndManagerId({ projectStatus, managerId, scheduledDeadlineDateTime });
        if (isAxiosError(res)) {
          throw res;
        }
      });

      forceUpdate();
    } catch (error) {
      errorLogger.error(error);
    }
  }, []);

  const onSubmitJobsStatusUpdate = React.useCallback(async (statusUpdatingJobs: JobsStatusUpdate[]) => {
    try {
      const res = await api.jobs.patch(statusUpdatingJobs);
      if (isAxiosError(res)) {
        throw res;
      }

      forceUpdate();
    } catch (error) {
      errorLogger.error(error);
    }
  }, []);

  const onSubmitJobResultsStatusUpdate = React.useCallback(
    async (statusUpdatingJobResults: JobResultStatusUpdate[]) => {
      try {
        const res = await api.jobResult.patch(statusUpdatingJobResults);
        if (isAxiosError(res)) {
          throw res;
        }

        forceUpdate();
      } catch (error) {
        errorLogger.error(error);
      }
    },
    []
  );

  const handleCreateProject = React.useCallback(() => history.push(AppPaths.createProject.path), [history]);

  const handleCreateJob = React.useCallback(() => history.push(AppPaths.createJob.path), [history]);

  React.useEffect(() => {
    ListConverter();
    setResourceIds([]);
  }, [force, focusItem, currentPage, maxPage, filter, ListConverter]);

  return (
    <div>
      <SearchFormOfProjectAndJobList setFilter={(data) => setFilter(data)} />
      <TabItemWrapper>
        {tabItems.map((item, idx) => (
          <div key={idx} className={cn(item === focusItem && 'focus')} onClick={() => setFocusItem(item)}>
            {item}
          </div>
        ))}
      </TabItemWrapper>
      <ButtonWrapRow>
        {focusItem === TaskName.project && (
          <ProjectStatusUpdateModal projectIds={resourceIds} onSubmitHandler={onSubmitProjectsStatusUpdate} />
        )}
        {focusItem === TaskName.content && (
          <JobStatusUpdateModal jobIds={resourceIds} onSubmitHandler={onSubmitJobsStatusUpdate} />
        )}
        {focusItem === TaskName.result && (
          <JobResultStatusUpdateModal jobResultIds={resourceIds} onSubmitHandler={onSubmitJobResultsStatusUpdate} />
        )}
        <div>
          <CButton onClick={handleCreateProject}>프로젝트 생성하기</CButton>
          <CButton onClick={handleCreateJob}>콘텐츠 생성하기</CButton>
        </div>
      </ButtonWrapRow>
      {focusItem === TaskName.project && (
        <ProjectTaskList
          key={force}
          projectIds={ids}
          onChange={(id: number) => {
            if (resourceIds.includes(id)) {
              setResourceIds(resourceIds.filter((it) => it !== id));
            } else {
              setResourceIds([id, ...resourceIds]);
            }
          }}
        />
      )}
      {focusItem === TaskName.content && (
        <JobTaskList
          key={force}
          jobs={ids}
          onChange={(id: number) => {
            if (resourceIds.includes(id)) {
              setResourceIds(resourceIds.filter((it) => it !== id));
            } else {
              setResourceIds([id, ...resourceIds]);
            }
          }}
        />
      )}
      {focusItem === TaskName.result && (
        <JobResultTaskList
          key={force}
          jobResultIds={ids}
          onChange={(id: number) => {
            if (resourceIds.includes(id)) {
              setResourceIds(resourceIds.filter((it) => it !== id));
            } else {
              setResourceIds([id, ...resourceIds]);
            }
          }}
        />
      )}
      <AppPagination currentPage={currentPage} maxPage={maxPage} setCurrentPage={setCurrentPage} />
    </div>
  );
}

export default ProjectsAndContentsListPage;
