import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react';
import React from 'react';
import errorLogger from 'src/api/errorLogger';
import { downloadAPIFile } from 'src/utils/files';
import styled from 'styled-components';

type ExceptProps = 'href' | 'download';
type AnchorProps = Omit<React.ComponentPropsWithoutRef<'a'>, ExceptProps>;

interface Props extends AnchorProps {
  file: { name: string; id: string };
}

function FileAnchor(props: Props, ref: React.Ref<HTMLAnchorElement>) {
  const { file, children, onClick, ...anchorProps } = props;

  const [visible, setVisible] = React.useState(false);
  const handleDismiss = React.useCallback(() => setVisible(false), []);
  const handleClick = React.useCallback(
    async (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      try {
        await downloadAPIFile(file);
      } catch (error) {
        errorLogger.error(error);
        setVisible(true);
      }
    },
    [file]
  );

  return (
    <>
      <a
        {...anchorProps}
        ref={ref}
        target={'_blank'}
        rel={'noopener noreferrer'}
        href={'#file'}
        download={file.name}
        onClick={handleClick}
      >
        {children}
      </a>
      <CModal visible={visible} onDismiss={handleDismiss}>
        <CModalHeader>
          <CModalTitle>오류</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p>다운로드 URL을 가져오는데 실패했습니다. 잠시후 다시 시도해주세요.</p>
        </CModalBody>
        <CModalFooter>
          <CButton color={'primary'} onClick={handleDismiss}>
            확인
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
}

export default styled(React.forwardRef(FileAnchor))``;
