import { useLazyQuery } from '@apollo/client';
import { Parameter } from '@voithru/front-core';
import { nanoid } from 'nanoid';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import errorLogger from 'src/api/errorLogger';
import { useAuth, useMingloAuth } from 'src/components/context/AuthContext';
import AppPaths from 'src/constants/AppPaths';
import JobWithJobResultList from './components/JobWithJobResultList';
import ProjectCurtInfo from './components/ProjectCurtInfo';
import { GQL_AUTH_CHECK } from './gql';
import { AuthCheckQuery } from './gql.generated';

const TRANSACTION_ID = nanoid();
function WorkerRelayPage() {
  const history = useHistory();
  const location = useLocation();
  const { project } = React.useMemo(() => Parameter.parse(location.search), [location.search]);
  const projectId = Number(project);

  const auth = useAuth();
  const mingloAuth = useMingloAuth();

  const [fetch, { loading, error, data }] = useLazyQuery<AuthCheckQuery>(GQL_AUTH_CHECK, {
    variables: {
      hermesAccountId: mingloAuth.user?.id,
      projectId: projectId,
      id: projectId.toString(),
    },
    onError: errorLogger.error,
  });

  React.useEffect(() => {
    if (isNaN(projectId) || typeof mingloAuth.user?.id === 'undefined') {
      return;
    }

    fetch({
      variables: {
        hermesAccountId: mingloAuth.user?.id,
        projectId: projectId,
        id: projectId.toString(),
      },
    });
  }, [fetch, mingloAuth.user?.id, projectId]);

  // For Worker Auth
  React.useEffect(() => {
    if (auth.user || mingloAuth.user) {
      return;
    }

    const search = Parameter.toURLSearchParams({ project }).toString();
    history.replace({ pathname: AppPaths.minglo.path, search });
    return;
  }, [auth.user, history, mingloAuth.user, project]);

  // For Admin Auth
  React.useEffect(() => {
    if (!auth.user || mingloAuth.user?.role === 'ADMIN') {
      return;
    }

    mingloAuth.loginAsPanoplay();
  }, [auth.user, history, mingloAuth, mingloAuth.user, project]);

  const jobResults = data?.workerJobResultGrants;
  const allJobs = data?.project?.jobs;

  const jobIds = React.useMemo(() => {
    if (auth.user?.role === 'ADMIN') {
      return allJobs?.map((it) => {
        return it.id;
      });
    } else {
      return jobResults?.map((it) => it.jobResult.jobId.toString());
    }
  }, [allJobs, auth.user?.role, jobResults]);

  const jobResultIds = React.useMemo((): string[] => {
    if (mingloAuth.user?.role === 'ADMIN') {
      return (
        allJobs?.flatMap((job) => {
          return (
            job.jobResults?.map((jobResult) => {
              return jobResult.id;
            }) ?? []
          );
        }) ?? []
      );
    } else {
      return (
        jobResults?.map((it) => {
          return it.jobResult.id;
        }) ?? []
      );
    }
  }, [mingloAuth.user?.role, allJobs, jobResults]);

  if (loading) return <div>loading...</div>;

  if (isNaN(projectId) || typeof mingloAuth.user?.id === 'undefined') {
    return null;
  }

  if (error) {
    return (
      <>
        <div>{error.name}</div>
        <div>{error.message}</div>
      </>
    );
  }

  return (
    <div>
      {
        <>
          <ProjectCurtInfo projectId={projectId} />
          {jobIds && jobResultIds && (
            <JobWithJobResultList
              projectId={projectId}
              jobIds={jobIds}
              jobResultIds={jobResultIds}
              transactionId={TRANSACTION_ID}
            />
          )}
        </>
      }
    </div>
  );
}

export default WorkerRelayPage;
