import FormModal from 'src/components/organisms/FormModal';
import styled from 'styled-components';

export const WorkerManageModalWrapper = styled(FormModal)`
  & .card:first-child .card-body {
    overflow: auto;

    & > .table {
      white-space: nowrap;
    }
  }

  & .card:nth-child(2) {
    white-space: nowrap;
    overflow: auto;
  }
`;
