import { cn } from '@voithru/front-core';
import React from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';

function AnchorBase<S = unknown>(props: LinkProps<S>) {
  const { className, to, ...rest } = props;
  const location = useLocation<S>();
  const classNameWrapper = React.useMemo(
    () => cn(location.pathname === to && 'accent', className),
    [className, location.pathname, to]
  );

  return <Link {...rest} className={classNameWrapper} to={to} />;
}

export default AnchorBase;
