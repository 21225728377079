import { CDropdown } from '@coreui/react';
import { Column } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const AlramDropDownWrapper = styled(CDropdown)`
  & > a.nav-link {
    margin: 0 6px;
    padding: 4px;

    & > div.badge {
      display: inline-flex;
      padding: 3px;

      right: 7px;
      top: 2px;
      background-color: red;
      border-radius: 50%;
      position: absolute;
    }
  }

  & > ul.dropdown-menu {
    & > li {
      & > ${Column} {
        height: 303px;
        box-sizing: border-box;

        overflow-y: auto;

        & > a {
          cursor: pointer;
          & > span {
            font-weight: bold;
          }

          &.read {
            color: ${getRGBA(colors.grey50)};
          }
        }
      }
      &:last-child {
        cursor: pointer;
      }
    }
  }
`;
