import { CButton, CCol, CFormCheck, CFormInput, CFormLabel, CRow } from '@coreui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import AdminSearchModal from 'src/components/organisms/AdminSearchModal';
import CompanySearchModal from 'src/components/organisms/CompanySearchModal';
import { Company } from 'src/types/api/Company';
import { UserResponse } from 'src/types/api/User';
import { lastTimeOfDate } from 'src/utils/date';
import { DocumentStatus } from 'src/utils/translate';
import { DocumentStatusType, SearchFormFilter } from '../../type';

interface FormData {
  companyName?: string;
  companyManagerNames?: string[];
  issuerNames?: string[];
  createDateTime?: {
    from?: string;
    to?: string;
  };
  status?: DocumentStatusType[];
}

const INIT: FormData = {
  companyName: undefined,
  companyManagerNames: undefined,
  issuerNames: undefined,
  createDateTime: undefined,
  status: undefined,
};

interface ISearchProps {
  setFilter: (data: SearchFormFilter) => void;
}

function SearchFormOfSettlementList(props: ISearchProps) {
  const { setFilter } = props;
  const [visible, setVisible] = React.useState<boolean>(false);
  const [targetCompany, setTargetCompany] = React.useState<Company>();
  const [issuers, setIssuers] = React.useState<UserResponse[]>();
  const [companyManagers, setCompanyManagers] = React.useState<UserResponse[]>();

  const { register, getValues, reset, setValue } = useForm<FormData>({ shouldUnregister: true });
  const [num, increase] = React.useReducer((x) => x + 1, 0);

  React.useEffect(() => {
    if (targetCompany) {
      setValue('companyName', targetCompany.name);
    }
    if (companyManagers) {
      setValue(
        'companyManagerNames',
        companyManagers.map((companyManager) => companyManager.name)
      );
    }
    if (issuers) {
      setValue(
        'issuerNames',
        issuers.map((issuer) => issuer.name)
      );
    }
  }, [targetCompany, issuers, companyManagers, setValue]);

  const clear = React.useCallback(() => {
    setIssuers(undefined);
    setCompanyManagers(undefined);
    setTargetCompany(undefined);

    reset(INIT);
    increase();
  }, [setIssuers, setCompanyManagers, setTargetCompany, reset, increase]);

  const search = React.useCallback(
    (data: FormData = getValues()) => {
      const { status, createDateTime } = data;

      const filter: SearchFormFilter = {
        company: targetCompany,
        companyManagerIds: companyManagers?.map((companyManager) => companyManager.id),
        issuerIds: issuers?.map((issuer) => issuer.id),
        createDateTimeFrom: createDateTime?.from ? createDateTime?.from : undefined,
        createDateTimeTo: createDateTime?.to ? lastTimeOfDate(createDateTime?.to) : undefined,
        status: status,
      };

      setFilter({ ...filter });
    },
    [getValues, targetCompany, companyManagers, issuers, setFilter]
  );
  return (
    // to must do: renewal
    <React.Fragment key={num}>
      <CButton onClick={() => setVisible(!visible)}>{visible ? '-' : '+'}</CButton>
      {visible && (
        <div style={{ width: '900px', height: '350px', backgroundColor: 'white', padding: '20px' }}>
          <div className="align-items-center" style={{ marginBottom: '15px' }}>
            <CRow className="align-items-center">
              <CCol>
                <CFormLabel>고객 회사명</CFormLabel>
              </CCol>
              <CCol sm={8} style={{ position: 'relative' }}>
                <CFormInput type={'text'} {...register('companyName')} disabled={true} />
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                  }}
                >
                  <CompanySearchModal setTargetItem={(item: Company) => setTargetCompany(item)} />
                </div>
              </CCol>
            </CRow>
          </div>
          <div className="align-items-center" style={{ marginBottom: '15px' }}>
            <CRow className="align-items-center">
              <CCol>
                <CFormLabel>담당 Sales</CFormLabel>
              </CCol>
              <CCol sm={8} style={{ position: 'relative' }}>
                <CFormInput type={'text'} {...register('companyManagerNames')} disabled={true} />
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                  }}
                >
                  <AdminSearchModal setTargetItems={(item: UserResponse[]) => setCompanyManagers(item)} />
                </div>
              </CCol>
            </CRow>
          </div>
          <div className="align-items-center" style={{ marginBottom: '15px' }}>
            <CRow className="align-items-center">
              <CCol>
                <CFormLabel>발행자</CFormLabel>
              </CCol>
              <CCol sm={8} style={{ position: 'relative' }}>
                <CFormInput type={'text'} {...register('issuerNames')} disabled={true} />
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                  }}
                >
                  <AdminSearchModal setTargetItems={(item: UserResponse[]) => setIssuers(item)} />
                </div>
              </CCol>
            </CRow>
          </div>
          <div className="align-items-center" style={{ marginBottom: '15px' }}>
            <CRow className="align-items-center">
              <CCol>
                <CFormLabel>발행일</CFormLabel>
              </CCol>
              <CCol sm={8} style={{ position: 'relative' }}>
                <div style={{ display: 'flex' }}>
                  <CFormInput type="date" {...register('createDateTime.from')} />
                  <CFormInput type="date" {...register('createDateTime.to')} />
                </div>
              </CCol>
            </CRow>
          </div>
          <div className="align-items-center" style={{ marginBottom: '15px' }}>
            <CRow>
              <CCol>
                <CFormLabel>발행 상태</CFormLabel>
              </CCol>

              <CCol sm={8}>
                <CRow>
                  {Object.keys(DocumentStatus)
                    .filter((it) => it !== 'null')
                    .map((it) => (
                      <CCol key={it} style={{ minWidth: 'max-content' }}>
                        <CFormCheck value={it} label={DocumentStatus[it]} {...register('status')} />
                      </CCol>
                    ))}
                </CRow>
              </CCol>
            </CRow>
          </div>
          <CButton color="secondary" onClick={() => search()}>
            검색
          </CButton>
          <CButton color="light" onClick={clear}>
            초기화
          </CButton>
        </div>
      )}
    </React.Fragment>
  );
}

export default SearchFormOfSettlementList;
