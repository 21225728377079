import { useQuery } from '@apollo/client';
import {
  CContainer,
  CFormCheck,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';

import React from 'react';
import { useForm } from 'react-hook-form';
import errorLogger from 'src/api/errorLogger';
import StatusLabel from 'src/components/atoms/StatusLabel';
import { ButtonBase } from 'src/components/atoms/styled/button';
import { Row } from 'src/components/atoms/styled/layout';
import { ProjectStatus, ProjectStatusUpdate } from 'src/types/api/Project';
import { User } from 'src/types/api/User';
import { dateFormat } from 'src/utils/date';
import { ContentType } from 'src/utils/translate';
import styled from 'styled-components';
import AdminSearchModal from '../AdminSearchModal';
import FormModal from '../FormModal';
import { GQL_PROJECT_STATUS } from './gql';
import { ProjectStatusQuery, ProjectStatusQueryVariables } from './gql.generated';

interface FormData {
  isStatus: boolean;
  status?: ProjectStatus;
  isScheduledDeadlineDateTime: boolean;
  scheduledDeadlineDateTime?: string;
  isManagerId: boolean;
  managerId?: string;
}

interface Props {
  projectIds: number[];
  onSubmitHandler: (data: ProjectStatusUpdate[]) => void;
}

const PROJECT_STATUSES = [
  { label: '작성 중', value: 'REQUESTING' },
  { label: '승인대기', value: 'REQUESTED' },
  { label: '작업대기', value: 'REGISTERED' },
  { label: '작업 중', value: 'PROCESSING' },
  { label: '작업취소', value: 'DROP' },
  { label: '작업완료', value: 'DONE' },
];

function ProjectStatusUpdateModal(props: Props) {
  const { projectIds, onSubmitHandler } = props;

  const ids = React.useMemo(() => projectIds.map((it) => it.toString()), [projectIds]);

  const { loading, error, data } = useQuery<ProjectStatusQuery, ProjectStatusQueryVariables>(GQL_PROJECT_STATUS, {
    variables: { ids },
  });

  const projects = data?.projects;

  const { register, handleSubmit, getValues, watch, setValue, reset } = useForm<FormData>();

  const [managerItem, setManagerItem] = React.useState<User>();

  const managerTargetHandler = React.useCallback(
    (data: User) => {
      if (watch('isManagerId')) {
        setValue('managerId', data.id?.toString());
        setManagerItem(data);
      }
    },
    [setValue, watch]
  );

  const [visible, setVisible] = React.useState(false);

  const onSubmit = React.useCallback(
    async (form: FormData = getValues(), projectIds: number[]) => {
      const { isStatus, isScheduledDeadlineDateTime, isManagerId, status, scheduledDeadlineDateTime, managerId } = form;

      if (!isStatus && !isScheduledDeadlineDateTime && !isManagerId) {
        window.alert('변경할 데이터가 없습니다.');

        return setVisible(false);
      }

      if (!status && !scheduledDeadlineDateTime && !managerId) {
        errorLogger.error("Don't have submitting data");
      }

      const data = projectIds.map((proejctId) => {
        if (!proejctId) {
          throw new Error("Don't have project id");
        }

        const projectUpdate: ProjectStatusUpdate = {
          id: proejctId,
        };

        if (status) {
          projectUpdate.projectStatus = status;
        }

        if (scheduledDeadlineDateTime) {
          projectUpdate.scheduledDeadlineDateTime = new Date(
            scheduledDeadlineDateTime.replaceAll('.', '-')
          ).toISOString();
        }

        if (managerId) {
          projectUpdate.managerId = Number(managerId);
        }

        return projectUpdate;
      });

      onSubmitHandler(data);

      reset({});
      setManagerItem(undefined);

      setVisible(false);
    },
    [getValues, onSubmitHandler, reset]
  );

  if (loading) return <div>loading...</div>;
  if (error) {
    errorLogger.error(error);

    return (
      <div>
        <h2>{error.name}</h2>
        <h3>{error.message}</h3>
      </div>
    );
  }

  return (
    <>
      <ButtonBase onClick={() => setVisible(!visible)} disabled={!projectIds.length}>
        상태 업데이트
      </ButtonBase>
      <FormModal
        title={'프로젝트 상태 업데이트'}
        visible={visible}
        onDismiss={() => setVisible(false)}
        onSubmit={handleSubmit((data: FormData) => onSubmit(data, projectIds))}
      >
        <span>
          총 {projectIds.length}
          개의 프로젝트 상태를 변경합니다.
        </span>
        <CContainer style={{ height: '320px', overflow: 'auto' }}>
          <CTable bordered>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell>프로젝트 ID</CTableHeaderCell>
                <CTableHeaderCell>콘텐츠 타입</CTableHeaderCell>
                <CTableHeaderCell>프로젝트 명</CTableHeaderCell>
                <CTableHeaderCell>진행상태</CTableHeaderCell>
                <CTableHeaderCell>납품예정일</CTableHeaderCell>
                <CTableHeaderCell>담당 PM</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {projects?.map((it, idx) => (
                <CTableRow key={idx}>
                  <CTableDataCell>P - {it.id}</CTableDataCell>
                  <CTableDataCell>{it.category && ContentType[it.category]}</CTableDataCell>
                  <CTableDataCell>{it.name}</CTableDataCell>
                  <CTableDataCell>
                    <StatusLabel status={it.status} />
                  </CTableDataCell>
                  <CTableDataCell>
                    {it.scheduledDeadlineDateTime && dateFormat(it.scheduledDeadlineDateTime, 'YYYY.MM.DD')}
                  </CTableDataCell>
                  <CTableDataCell>{it.manager && it.manager.name}</CTableDataCell>
                </CTableRow>
              ))}
            </CTableBody>
          </CTable>
        </CContainer>
        <Row>
          <CFormCheck {...register('isStatus')} />
          진행상태
          <Row>
            {PROJECT_STATUSES.map((status, idx) => {
              return (
                <CFormCheck
                  key={idx}
                  type="radio"
                  value={status.value}
                  label={status.label}
                  {...register('status')}
                  disabled={!watch('isStatus')}
                />
              );
            })}
          </Row>
        </Row>
        <Row>
          <CFormCheck {...register('isScheduledDeadlineDateTime')} />
          납품예정일
          <input
            type="date"
            {...register('scheduledDeadlineDateTime')}
            disabled={!watch('isScheduledDeadlineDateTime')}
          />
        </Row>
        <Row>
          <CFormCheck {...register('isManagerId')} />
          담당 PM
          <input value={managerItem && managerItem.name} readOnly disabled={!watch('isManagerId')} />
          <AdminSearchModal setTargetItem={(item: User) => managerTargetHandler(item)} />
        </Row>
      </FormModal>
    </>
  );
}

export default styled(ProjectStatusUpdateModal)``;
