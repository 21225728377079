import { useMount } from '@voithru/front-core';
import React from 'react';
import { Link } from 'react-router-dom';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { StatusCountBadge } from 'src/components/atoms/StatusCountBadge';
import { Title } from 'src/components/atoms/styled/element';
import { useAuth } from 'src/components/context/AuthContext';
import AppPaths from 'src/constants/AppPaths';
import { JobsResponse } from 'src/types/api/Job';
import { ProjectResponse } from 'src/types/api/Project';
import { isAxiosError } from 'src/utils/api/axios';
import { getJobById } from 'src/utils/api/getter';
import { dateFormat, TimeUnit } from 'src/utils/date';
import { AllocationWorkWrapper, StatusCountBadgeWrapper } from './styled';

type ResponseArray = ProjectResponse[] | JobsResponse[];

interface Allocation {
  assigment: number;
  delay: number;
  days: number;
  week: number;
}

function AllocationWork() {
  const { user } = useAuth();

  const [allocationProject, setAllocationProject] = React.useState<Allocation>();
  const [allocationJob, setAllocationJob] = React.useState<Allocation>();
  const [allocationJobResult, setAllocationJobResult] = React.useState<Allocation>();

  const calculateDate = React.useCallback((arr: ResponseArray): Allocation => {
    const result: Allocation = {
      assigment: arr.length,
      delay: 0,
      days: 0,
      week: 0,
    };
    const now = Date.now();

    arr.forEach((it) => {
      let diff: number = 0;
      if (it.scheduledDeadlineDateTime) {
        const time = new Date(
          dateFormat(it['scheduledDeadlineDateTime'] as string, 'YYYY-MM-DDT24:00:00.000Z', { isLocale: true })
        );
        diff = time.getTime() - now;
        if (diff < 0) {
          result.delay++;
        } else if (diff / TimeUnit.DAY <= 3 && diff !== 0) {
          result.days++;
        } else if (diff / TimeUnit.DAY >= 7) {
          result.week++;
        }
      }
    });
    return result;
  }, []);

  const getProjectByAccount = React.useCallback(async () => {
    const res = await api.project.get({
      managerId: user?.id,
    });

    if (isAxiosError(res)) {
      throw res;
    }

    const result = calculateDate(res.data);

    setAllocationProject(result);
  }, [calculateDate, user?.id]);

  const getJobByAccount = React.useCallback(async () => {
    const res = await api.jobs.get({
      managerId: user?.id,
    });

    if (isAxiosError(res)) {
      throw res;
    }

    const result = calculateDate(res.data);
    setAllocationJob(result);
  }, [calculateDate, user?.id]);

  const getJobResultByAccount = React.useCallback(async () => {
    const res = await api.jobResult.get({
      managerId: user?.id,
    });

    if (isAxiosError(res)) {
      throw res;
    }
    const jobs: JobsResponse[] = [];
    for (const item of res.data) {
      const job = await getJobById(item.jobId);

      if (isAxiosError(job)) {
        throw job;
      }

      jobs.push(job.data);
    }

    const result = calculateDate(jobs);
    setAllocationJobResult(result);
  }, [calculateDate, user?.id]);

  const getAllocation = React.useCallback(async () => {
    try {
      await getProjectByAccount();
      await getJobByAccount();
      await getJobResultByAccount();
    } catch (err) {
      errorLogger.error(err);
    }
  }, [getJobByAccount, getJobResultByAccount, getProjectByAccount]);

  useMount(() => {
    getAllocation();
  });

  return (
    <AllocationWorkWrapper>
      <div>
        <StatusCountBadgeWrapper>
          <Title>할당 프로젝트</Title>
          <StatusCountBadge color={'green'}>
            <Link to={`${AppPaths.projects.path}?tab=project&managerId=${user?.id}`}>
              {allocationProject?.assigment}
            </Link>
          </StatusCountBadge>
        </StatusCountBadgeWrapper>
        <StatusCountBadgeWrapper>
          <Title>일정지연</Title>
          <StatusCountBadge color={'red'}>{allocationProject?.delay}</StatusCountBadge>
        </StatusCountBadgeWrapper>
        <StatusCountBadgeWrapper>
          <Title>3일 이내</Title>
          <StatusCountBadge color={'yellow'}>{allocationProject?.days}</StatusCountBadge>
        </StatusCountBadgeWrapper>
        <StatusCountBadgeWrapper>
          <Title>1주 이상</Title>
          <StatusCountBadge color={'blue'}>{allocationProject?.week}</StatusCountBadge>
        </StatusCountBadgeWrapper>
      </div>
      <div>
        <StatusCountBadgeWrapper>
          <Title>할당 작업콘텐츠</Title>
          <StatusCountBadge color={'green'}>
            <Link to={`${AppPaths.projects.path}?tab=content&managerId=${user?.id}`}>{allocationJob?.assigment}</Link>
          </StatusCountBadge>
        </StatusCountBadgeWrapper>
        <StatusCountBadgeWrapper>
          <Title>일정지연</Title>
          <StatusCountBadge color={'red'}>{allocationJob?.delay}</StatusCountBadge>
        </StatusCountBadgeWrapper>
        <StatusCountBadgeWrapper>
          <Title>3일 이내</Title>
          <StatusCountBadge color={'yellow'}>{allocationJob?.days}</StatusCountBadge>
        </StatusCountBadgeWrapper>
        <StatusCountBadgeWrapper>
          <Title>1주 이상</Title>
          <StatusCountBadge color={'blue'}>{allocationJob?.week}</StatusCountBadge>
        </StatusCountBadgeWrapper>
      </div>
      <div>
        <StatusCountBadgeWrapper>
          <Title>할당 작업결과물</Title>
          <StatusCountBadge color={'green'}>
            <Link to={`${AppPaths.projects.path}?tab=result&managerId=${user?.id}`}>
              {allocationJobResult?.assigment}
            </Link>
          </StatusCountBadge>
        </StatusCountBadgeWrapper>
        <StatusCountBadgeWrapper>
          <Title>일정지연</Title>
          <StatusCountBadge color={'red'}>{allocationJobResult?.delay}</StatusCountBadge>
        </StatusCountBadgeWrapper>
        <StatusCountBadgeWrapper>
          <Title>3일 이내</Title>
          <StatusCountBadge color={'yellow'}>{allocationJobResult?.days}</StatusCountBadge>
        </StatusCountBadgeWrapper>
        <StatusCountBadgeWrapper>
          <Title>1주 이상</Title>
          <StatusCountBadge color={'blue'}>{allocationJobResult?.week}</StatusCountBadge>
        </StatusCountBadgeWrapper>
      </div>
    </AllocationWorkWrapper>
  );
}

export default AllocationWork;
