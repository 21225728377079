import React from 'react';
import { useHistory } from 'react-router';
import { ButtonBase } from 'src/components/atoms/styled/button';
import { Title } from 'src/components/atoms/styled/element';
import { useAuth } from 'src/components/context/AuthContext';
import NotificationTable from 'src/components/organisms/NotificationTable';
import AppPaths from 'src/constants/AppPaths';
import { NotificationsWrapper } from './styled';

function NotificationsPage() {
  const { user } = useAuth();

  const history = useHistory();

  const handleAccountSetting = React.useCallback(() => {
    history.push(AppPaths.accountSettingMe.path);
  }, [history]);

  if (!user || !user.id) {
    return null;
  }

  return (
    <NotificationsWrapper>
      <Title>알림 조회</Title>
      <ButtonBase onClick={handleAccountSetting}>내 알림 설정</ButtonBase>
      <NotificationTable />
    </NotificationsWrapper>
  );
}

export default NotificationsPage;
