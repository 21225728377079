const eventListeners: Array<(key: string, value: unknown) => void> = [];

export const addEventListener = (func: (key: string, value: unknown) => void) => {
  eventListeners.push(func);
};

export const setItem = (key: string, value: unknown) => {
  localStorage.setItem(key, JSON.stringify(value));
  for (const listener of eventListeners) {
    new Promise(() => listener(key, value)).then(() => true).catch(() => false);
  }
};

export const getItem = (key: string): unknown => {
  const value = localStorage.getItem(key);
  try {
    if (value) {
      return JSON.parse(value);
    } else {
      return null;
    }
  } catch {
    return null;
  }
};

const LocalStorageManager = {
  setItem,
  getItem,
  addEventListener,
} as const;

export default LocalStorageManager;
