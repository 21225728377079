import { CButton, CCol, CFormCheck, CFormInput, CFormLabel, CRow } from '@coreui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import AdminSearchModal from 'src/components/organisms/AdminSearchModal';
import ClientSearchModal from 'src/components/organisms/ClientSearchModal';
import CompanySearchModal from 'src/components/organisms/CompanySearchModal';
import { Company } from 'src/types/api/Company';
import { UserResponse } from 'src/types/api/User';
import { lastTimeOfDate } from 'src/utils/date';
import { DocumentStatus } from 'src/utils/translate';
import { SearchFormFilter } from '../../type';

interface FormData {
  keyword?: string;
  companyName?: string;
  client?: string;
  manager?: string;
  sales?: string;
  createDateTime?: {
    from?: string;
    to?: string;
  };
  updateDateTime?: {
    from?: string;
    to?: string;
  };
  status?: DocumentStatus[];
}

const INIT: FormData = {
  keyword: '',
  companyName: undefined,
  manager: undefined,
  client: undefined,
  createDateTime: { from: undefined, to: undefined },
  updateDateTime: { from: undefined, to: undefined },
  status: [],
};

interface ISearchProps {
  defaultVisible?: boolean;
  setFilter: (data: SearchFormFilter) => void;
}

function SearchFormOfSettlementManagement(props: ISearchProps) {
  const { setFilter, defaultVisible } = props;
  const [visible, setVisible] = React.useState<boolean>(() => defaultVisible ?? false);
  const [targetCompany, setTargetCompany] = React.useState<Company>();
  const [targetClient, setTargetClient] = React.useState<{ account: UserResponse; company?: Company }>();
  const [targetAdmin, setTargetAdmin] = React.useState<UserResponse>();
  const [targetSales, setTargetSales] = React.useState<UserResponse>();

  const { register, getValues, reset, setValue } = useForm<FormData>({ shouldUnregister: true });
  const [num, increase] = React.useReducer((x) => x + 1, 0);

  React.useEffect(() => {
    if (targetCompany) {
      setValue('companyName', targetCompany.name);
    }
    if (targetClient) {
      setValue('client', targetClient.account.name);
    }
    if (targetAdmin) {
      setValue('manager', targetAdmin.name);
    }
    if (targetSales) {
      setValue('sales', targetSales.name);
    }
  }, [targetCompany, targetAdmin, targetClient, targetSales, setValue]);

  const clear = React.useCallback(() => {
    setTargetAdmin(undefined);
    setTargetClient(undefined);
    setTargetCompany(undefined);
    setTargetSales(undefined);

    reset(INIT);
    increase();
  }, [setTargetAdmin, setTargetClient, setTargetCompany, setTargetSales, reset, increase]);

  const search = React.useCallback(
    (data: FormData = getValues()) => {
      const { status, keyword, createDateTime, updateDateTime } = data;

      const filter: SearchFormFilter = {
        name: keyword === '' ? undefined : keyword,
        company: targetCompany,
        accountId: targetClient?.account.id,
        managerId: targetAdmin?.id,
        salesId: targetSales?.id,
        createDateTimeFrom: createDateTime?.from ? createDateTime?.from : undefined,
        createDateTimeTo: createDateTime?.to ? lastTimeOfDate(createDateTime?.to) : undefined,
        updateDateTimeFrom: updateDateTime?.from ? updateDateTime?.from : undefined,
        updateDateTimeTo: updateDateTime?.to ? lastTimeOfDate(updateDateTime?.to) : undefined,
        status: status,
      };
      setFilter({ ...filter });
    },
    [getValues, targetCompany, targetClient, targetAdmin, targetSales, setFilter]
  );
  return (
    // to must do: renewal
    <React.Fragment key={num}>
      <CButton onClick={() => setVisible(!visible)}>{visible ? '-' : '+'}</CButton>
      {visible && (
        <div style={{ width: '900px', height: '450px', backgroundColor: 'white', padding: '20px' }}>
          <div className="align-items-center" style={{ marginBottom: '15px' }}>
            <CRow className="align-items-center">
              <CCol>
                <CFormLabel>고객 회사</CFormLabel>
              </CCol>
              <CCol sm={8} style={{ position: 'relative' }}>
                <CFormInput type={'text'} {...register('companyName')} disabled={true} />
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                  }}
                >
                  <CompanySearchModal setTargetItem={(item: Company) => setTargetCompany(item)} />
                </div>
              </CCol>
            </CRow>
          </div>
          <div className="align-items-center" style={{ marginBottom: '15px' }}>
            <CRow className="align-items-center">
              <CCol>
                <CFormLabel>고객 담당자</CFormLabel>
              </CCol>
              <CCol sm={8} style={{ position: 'relative' }}>
                <CFormInput type={'text'} {...register('client')} disabled={true} />
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                  }}
                >
                  <ClientSearchModal
                    setTargetItem={(item: { account: UserResponse; company?: Company }) => setTargetClient(item)}
                  />
                </div>
              </CCol>
            </CRow>
          </div>
          <div className="align-items-center" style={{ marginBottom: '15px' }}>
            <CRow className="align-items-center">
              <CCol>
                <CFormLabel>담당 번역 PM</CFormLabel>
              </CCol>
              <CCol sm={8} style={{ position: 'relative' }}>
                <CFormInput type={'text'} {...register('manager')} disabled={true} />
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                  }}
                >
                  <AdminSearchModal setTargetItem={(item: UserResponse) => setTargetAdmin(item)} />
                </div>
              </CCol>
            </CRow>
          </div>
          <div className="align-items-center" style={{ marginBottom: '15px' }}>
            <CRow className="align-items-center">
              <CCol>
                <CFormLabel>담당 Sales</CFormLabel>
              </CCol>
              <CCol sm={8} style={{ position: 'relative' }}>
                <CFormInput type={'text'} {...register('sales')} disabled={true} />
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                  }}
                >
                  <AdminSearchModal setTargetItem={(item: UserResponse) => setTargetSales(item)} />
                </div>
              </CCol>
            </CRow>
          </div>
          <div className="align-items-center" style={{ marginBottom: '15px' }}>
            <CRow className="align-items-center">
              <CCol>
                <CFormLabel>생성일</CFormLabel>
              </CCol>
              <CCol sm={8} style={{ position: 'relative' }}>
                <div style={{ display: 'flex' }}>
                  <CFormInput type="date" {...register('createDateTime.from')} />
                  <CFormInput type="date" {...register('createDateTime.to')} />
                </div>
              </CCol>
            </CRow>
          </div>
          <div className="align-items-center" style={{ marginBottom: '15px' }}>
            <CRow className="align-items-center">
              <CCol>
                <CFormLabel>최종 수정일</CFormLabel>
              </CCol>
              <CCol sm={8} style={{ position: 'relative' }}>
                <div style={{ display: 'flex' }}>
                  <CFormInput type="date" {...register('updateDateTime.from')} />
                  <CFormInput type="date" {...register('updateDateTime.to')} />
                </div>
              </CCol>
            </CRow>
          </div>

          <div className="align-items-center" style={{ marginBottom: '15px' }}>
            <CRow>
              <CCol>
                <CFormLabel>진행상태</CFormLabel>
              </CCol>

              <CCol sm={8}>
                <CRow>
                  {Object.keys(DocumentStatus).map((it) => (
                    <CCol key={it} style={{ minWidth: 'max-content' }}>
                      <CFormCheck value={it} label={DocumentStatus[it]} {...register('status')} />
                    </CCol>
                  ))}
                </CRow>
              </CCol>
            </CRow>
          </div>
          <CButton color="secondary" onClick={() => search()}>
            검색
          </CButton>
          <CButton color="light" onClick={clear}>
            초기화
          </CButton>
        </div>
      )}
    </React.Fragment>
  );
}

export default SearchFormOfSettlementManagement;
