import { Title } from 'src/components/atoms/styled/element';
import { Column, Row } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const DualTableWrapper = styled(Row)`
  margin-top: 40px;
  width: 2270px;

  & > ${Column} {
    flex: 1;

    margin-right: 265px;
    & > ${Row}.titleWrap {
      justify-content: space-between;

      padding-bottom: 7px;

      border-bottom: 1px solid ${getRGBA(colors.grey50)};
      & > ${Title} {
        margin-bottom: 8px;

        color: ${getRGBA(colors.grey90)};
        font-size: 25px;
        font-weight: 700;
      }

      & > ${Row} {
        align-items: flex-end;
        & > a {
          color: ${getRGBA(colors.grey80)};
          font-size: 14px;
          text-decoration: none;
        }
      }
    }
  }
`;
