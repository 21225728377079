import { Title } from 'src/components/atoms/styled/element';
import { Column } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const DashboardWrapper = styled(Column)`
  width: 100%;

  & > ${Column} {
    & > ${Title} {
      color: ${getRGBA(colors.grey90)};
      font-size: 25px;
      font-weight: 700;
    }
  }

  & > ${Column}:not(:first-child) {
    margin-top: 40px;
  }
`;
