import {
  CCol,
  CFormCheck,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { ProductOrderResponse } from 'src/types/api/ProductOrder';
import { ProjectResponse } from 'src/types/api/Project';
import {
  AdditionalServices,
  ContentType,
  ResultType,
  Source_Language,
  Translate_Language,
  WorkRange,
} from 'src/utils/translate';
import { FormDataOfCreateJob } from '../../type';

interface Props {
  project?: ProjectResponse;
  orders: ProductOrderResponse[];
  register: UseFormRegister<FormDataOfCreateJob>;
}

function ProjectOrderSelectForm(props: Props) {
  const { project, orders, register } = props;

  return (
    <div style={{ marginTop: '30px' }}>
      <div className="d-flex">
        현재 의뢰하시는 프로젝트의 컨텐츠 타입은{' '}
        <div style={{ color: 'blue' }}>{project?.category ? ContentType[project.category] : ''}</div> 입니다.
      </div>
      <div>업로드한 콘텐츠 파일에 대하여 의뢰하고자 하는 작업을 선택해주세요.</div>
      <div style={{ padding: '10px', fontSize: '13px', color: 'darkgray' }}>
        <div>콘텐츠별 작업단위로 정산 및 납품이 이루어집니다.</div>
        <div>프로젝트 의뢰완료 후에도 콘텐츠를 추가 생성할 수 있습니다.</div>
      </div>
      <div>
        <CTable bordered style={{ width: 'max-content', minWidth: '100%' }}>
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell>-</CTableHeaderCell>
              <CTableHeaderCell>작업유형</CTableHeaderCell>
              <CTableHeaderCell>작업언어</CTableHeaderCell>
              <CTableHeaderCell>추가작업</CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {orders.map((order, idx) => {
              const { productType, deliveryType, sourceLanguage, translateLanguage, options } = order;

              return (
                <CTableRow key={idx}>
                  <CTableDataCell>
                    <CRow>
                      <CCol sm={8}>
                        <CRow>
                          <CCol style={{ minWidth: 'max-content' }}>
                            <CFormCheck {...register(`orders.${idx}.checked`)} />
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                  </CTableDataCell>
                  <CTableDataCell>{`${WorkRange[productType]}${
                    (deliveryType && '_' + ResultType[deliveryType]) ?? ''
                  }`}</CTableDataCell>
                  <CTableDataCell>{`${Source_Language[sourceLanguage]} ${
                    (translateLanguage && ' -> ' + Translate_Language[translateLanguage]) ?? ''
                  }`}</CTableDataCell>
                  <CTableDataCell>
                    {options?.map((option) => AdditionalServices[option.type]).join('/') || '없음'}
                  </CTableDataCell>
                </CTableRow>
              );
            })}
          </CTableBody>
        </CTable>
      </div>
    </div>
  );
}

export default ProjectOrderSelectForm;
