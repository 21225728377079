import { CRow } from '@coreui/react';
import styled from 'styled-components';

export const MingloAccountSearchWrapper = styled(CRow)`
  & > .col {
    position: relative;

    & > .list-group {
      z-index: 1;
      position: absolute;
      top: calc(100% + 12px);

      min-width: 100%;
      white-space: nowrap;

      & .row {
        flex-wrap: nowrap;
      }
    }
  }
`;
