import FormModal from 'src/components/organisms/FormModal';
import styled from 'styled-components';

export const ApprovedJobResultModalWrapper = styled(FormModal)`
  .modal-dialog {
    max-width: none;
  }
  .modal-content {
    width: 1100px;
    margin: auto;
  }
`;
