import { AxiosInstance } from 'axios';
import { generatePath } from 'react-router';
import { APIPagination, APIPaginationRequest } from 'src/types/api';
import {
  Product,
  ProductAPIPagination,
  ProductFilterType,
  ProductOption,
  ProductOptionPageResponse,
  ProductOptionResponse,
  ProductOptionSearch,
  ProductResponse,
} from 'src/types/api/Product';

const PRODUCT_PATH = '/api/v1/products';
const PRODUCT_OPTION_PATH = '/api/v1/productOptions';

function apiProducts(instance: AxiosInstance) {
  const PRODUCT_PAGE_PATH = generatePath(PRODUCT_PATH + '/page');
  return {
    get: (params: APIPaginationRequest) => instance.get<APIPagination<Product>>(PRODUCT_PAGE_PATH, { params }),
    filter: {
      get: (params: ProductFilterType) => instance.get<Product[]>(PRODUCT_PATH + '/', { params }),
    },
    post: (data: Product[]) => instance.post<Product[]>(PRODUCT_PATH, data),
    item(productId: number) {
      const PRODUCT_ITEM_PATH = generatePath(PRODUCT_PATH + '/:productId', { productId });

      return {
        get: () => instance.get<ProductResponse>(PRODUCT_ITEM_PATH),
        put: (data: Product) => instance.put<ProductResponse>(PRODUCT_ITEM_PATH, data),
        delete: () => instance.delete(PRODUCT_ITEM_PATH),
      };
    },
    option: {
      get: (params: APIPaginationRequest) =>
        instance.get<ProductAPIPagination<ProductOptionPageResponse>>(PRODUCT_OPTION_PATH, { params }),
      post: (data: ProductOption[]) => instance.post<ProductOption[]>(PRODUCT_OPTION_PATH, data),
      search: (data: ProductOptionSearch) => instance.post<ProductOption[]>(PRODUCT_OPTION_PATH + '/search', data),
      item(productOptionId: number) {
        const PRODUCT_OPTION_ITEM_PATH = generatePath(PRODUCT_OPTION_PATH + '/:productOptionId', { productOptionId });

        return {
          get: () => instance.get<ProductOptionResponse>(PRODUCT_OPTION_ITEM_PATH),
          put: (data: ProductOption) => instance.put<ProductOptionResponse>(PRODUCT_OPTION_ITEM_PATH, data),
          delete: () => instance.delete(PRODUCT_OPTION_ITEM_PATH),
        };
      },
    },
  };
}

export default apiProducts;
