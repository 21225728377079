import {
  CButton,
  CCol,
  CContainer,
  CHeaderBrand,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import api from 'src/api';
import { ButtonBase } from 'src/components/atoms/styled/button';
import AppPagination from 'src/components/molecules/AppPagination';
import AccountSelectModal from 'src/components/organisms/AccountSelectModal';
import CompanySearchModal from 'src/components/organisms/CompanySearchModal';
import { toSortString } from 'src/types/api';
import { Company, CompanyResponse } from 'src/types/api/Company';
import { AccountsRequest, UserResponse } from 'src/types/api/User';
import { dateFormat } from 'src/utils/date';
import AccountCreateModal from './components/AccountCreateModal';

const requestData: AccountsRequest = {
  roles: ['CLIENT_MASTER', 'CLIENT_SUB'],
};

function ClientAccountsPage() {
  const [clientAccountList, setClientAccountList] =
    React.useState<{ account: UserResponse; company?: Company; manager?: UserResponse }[]>();
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [maxPage, setMaxPage] = React.useState<number>(0);

  const [targetCompany, setTargetCompany] = React.useState<CompanyResponse | undefined>(undefined);
  const [targetManagers, setTargetManagers] = React.useState<UserResponse[] | undefined>(undefined);

  const [force, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const [searchAdminModalVisible, setSearchAdminModalVisible] = React.useState(false);

  const getClientAccountList = React.useCallback(async () => {
    await api.account
      .pages({
        size: 10,
        page: currentPage,
        roles: ['CLIENT_MASTER', 'CLIENT_SUB'],
        companyId: targetCompany?.id!!,
        accountIds: targetManagers?.map((it) => it.id),
        sort: toSortString({ direction: 'desc', sortKey: 'id' }),
      })
      .then(async (res) => {
        const { data } = res;

        const list: { account: UserResponse; company?: Company; manager?: UserResponse }[] = await Promise.all(
          data.element.map(async (el) => {
            if (el.companyId) {
              const { data } = await api.company.item(el.companyId).get();
              if (data?.managerAccountId) {
                const manager = await api.account.item(data.managerAccountId).get();
                return { account: el, company: data, manager: manager.data };
              } else {
                return { account: el, company: data };
              }
            } else {
              return { account: el };
            }
          })
        );

        setClientAccountList(list);
        setMaxPage(data.totalPage);
      });
  }, [currentPage, targetCompany, targetManagers]);

  const updateAccountList = React.useCallback(() => {
    getClientAccountList();
    forceUpdate();
  }, [getClientAccountList]);

  const resetList = React.useCallback(() => {
    setTargetCompany(undefined);
    setTargetManagers(undefined);
    updateAccountList();
  }, [updateAccountList, setTargetCompany, setTargetManagers]);

  React.useEffect(() => {
    getClientAccountList();
  }, [currentPage, getClientAccountList, maxPage, targetCompany, targetManagers]);

  return (
    <div className="bg-light d-flex flex-column justify-content-center" key={force}>
      <CHeaderBrand>계정관리</CHeaderBrand>
      <CContainer>
        <div className="d-flex flex-row  justify-content-between" style={{ marginBottom: '5px' }}>
          <div className="d-flex">
            <CContainer>
              <CRow>
                <CCol style={{ whiteSpace: 'nowrap', width: '200px', border: 'solid' }}>고객 회사명</CCol>
                {targetCompany && (
                  <CCol style={{ whiteSpace: 'nowrap', width: '200px', border: 'solid' }}>{targetCompany.name}</CCol>
                )}
                <CCol>
                  <CompanySearchModal setTargetItem={(item) => setTargetCompany(item)} />
                </CCol>
              </CRow>
              <CRow>
                <CCol style={{ whiteSpace: 'nowrap', width: '200px', border: 'solid' }}>담당자 명</CCol>
                {targetManagers && (
                  <CCol style={{ whiteSpace: 'nowrap', width: '200px', border: 'solid' }}>
                    {targetManagers?.map((p) => p.name).join(',')}
                  </CCol>
                )}

                <CCol>
                  <ButtonBase onClick={() => setSearchAdminModalVisible(true)}>🔎</ButtonBase>

                  <AccountSelectModal
                    title={'담당자 검색'}
                    visible={searchAdminModalVisible}
                    onDismiss={() => setSearchAdminModalVisible(false)}
                    onSubmit={(data) => {
                      if (data.length > 0) setTargetManagers([...data.map((item) => item.account)]);
                      else setTargetManagers(undefined);
                      setSearchAdminModalVisible(false);
                    }}
                    multiple={true}
                    options={{
                      requestData: requestData,
                      inAbleQuery: true,
                    }}
                  />
                </CCol>
              </CRow>
              <CRow>
                <CButton onClick={() => resetList()} style={{ marginTop: '15px', width: '135px' }}>
                  초기화
                </CButton>
              </CRow>
            </CContainer>
          </div>
          <AccountCreateModal callback={resetList} />
        </div>
        <CTable bordered style={{ width: 'max-content', minWidth: '100%' }}>
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell>NO</CTableHeaderCell>
              <CTableHeaderCell>회사명</CTableHeaderCell>
              <CTableHeaderCell>권한</CTableHeaderCell>
              <CTableHeaderCell>소속부서</CTableHeaderCell>
              <CTableHeaderCell>이름</CTableHeaderCell>
              <CTableHeaderCell>로그인 계정</CTableHeaderCell>
              <CTableHeaderCell>연락처</CTableHeaderCell>
              <CTableHeaderCell>생성일자</CTableHeaderCell>
              <CTableHeaderCell>담당 세일즈</CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {clientAccountList?.map((clientAccount, idx) => {
              const { account, company, manager } = clientAccount;
              const { id, name, email, createDateTime, role, department, phoneNumber } = account;
              return (
                <CTableRow key={idx}>
                  <CTableDataCell>{id}</CTableDataCell>
                  <CTableDataCell>
                    <Link
                      to={`${
                        role === 'CLIENT_MASTER' ? '/clientAccounts/detail' : '/clientAccounts/detail/subAccount'
                      }?id=${id}`}
                    >
                      {company?.name}
                    </Link>
                  </CTableDataCell>
                  <CTableDataCell>{role === 'CLIENT_MASTER' ? '마스터계정' : '서브계정'}</CTableDataCell>
                  <CTableDataCell>{department}</CTableDataCell>
                  <CTableDataCell>{name}</CTableDataCell>
                  <CTableDataCell>{email}</CTableDataCell>
                  <CTableDataCell>{phoneNumber}</CTableDataCell>
                  <CTableDataCell>{createDateTime && dateFormat(createDateTime, 'YYYY.MM.DD')}</CTableDataCell>
                  <CTableDataCell>{manager?.name}</CTableDataCell>
                </CTableRow>
              );
            })}
          </CTableBody>
        </CTable>
        <AppPagination currentPage={currentPage} maxPage={maxPage} setCurrentPage={(page) => setCurrentPage(page)} />
      </CContainer>
    </div>
  );
}

export default ClientAccountsPage;
