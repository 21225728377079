import { ButtonBase } from 'src/components/atoms/styled/button';
import { Column, CSSRow, Row } from 'src/components/atoms/styled/layout';
import styled from 'styled-components';

export const ContentInfoWrapper = styled.div`
  margin-top: 32px;
  & > ${Row}.title {
    justify-content: space-between;
    margin: 0 0 12px;
    & > ${Row}:first-child {
      font-size: 1.2rem;
      font-weight: 700;
    }
    & > ${Row} {
      & > ${ButtonBase} {
        text-decoration: underline;
      }

      & > ${ButtonBase} + ${ButtonBase} {
        margin-left: 16px;
      }
    }
  }

  & > ${Row} {
    & > span:first-child {
      width: 200px;
    }
    & > ${Column} > * {
      &:not(:last-child) {
        margin-top: 2px;
      }
    }

    & > ${Column} {
      & > ${ButtonBase} {
        ${CSSRow};
        align-items: center;
        & > img {
          margin-left: 12px;
        }
      }
    }
    & + ${Row} {
      margin-top: 12px;
    }
  }
`;
