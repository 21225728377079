import { cn } from '@voithru/front-core';
import colors, { ColorTemplate } from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';
import { CSSRow } from './layout';

/**
 * ButtonBase
 */
function attrsButtonBase(props: React.ComponentProps<'button'>) {
  const { type = 'button', tabIndex = -1 } = props;
  return { type, tabIndex };
}
export const ButtonBase = styled.button.attrs(attrsButtonBase)`
  appearance: none;
  background: none;
  margin: 0;
  border: none;
  border-radius: 0;
  padding: 0;
  outline: none;
  cursor: pointer;

  text-align: initial;

  &:disabled {
    cursor: default;
  }
`;

/**
 * Button
 */
interface ButtonProps {
  color?: 'transparent' | ColorTemplate;
  large?: boolean;
}
function attrsButton(props: ButtonProps & React.ComponentProps<'button'>) {
  const { className, large, color = 'grey' } = props;
  return { className: cn(color, large && 'large', className) };
}
export const Button = styled(ButtonBase).attrs(attrsButton)<ButtonProps>`
  ${CSSRow}
  justify-content: center;
  align-items: center;

  border-radius: 6px;
  padding: 0 9px;
  height: 28px;
  box-sizing: border-box;

  font-size: 12px;
  font-weight: 600;
  line-height: 1;

  &.large {
    border-radius: 8px;
    padding: 0 26px;
    height: 54px;

    font-size: 16px;
    font-weight: 700;
  }

  &.transparent {
    color: ${getRGBA(colors.grey50)};
  }

  &.grey {
    background: ${getRGBA(colors.grey10)};
    color: ${getRGBA(colors.grey70)};

    &:disabled {
      color: ${getRGBA(colors.grey40)};
    }
  }

  &.black {
    background: ${getRGBA(colors.grey80)};
    color: ${getRGBA(colors.white)};
  }

  &.red {
    background: ${getRGBA(colors.red50)};
    color: ${getRGBA(colors.white)};
  }

  &.blue {
    background: ${getRGBA(colors.blue50)};
    color: ${getRGBA(colors.white)};
  }

  &.green {
    background: ${getRGBA(colors.green50)};
    color: ${getRGBA(colors.white)};
  }
`;

export const ButtonWithBorder = styled(ButtonBase).attrs(attrsButton)<ButtonProps>`
  ${CSSRow}
  justify-content: center;
  align-items: center;

  padding: 16px 12px;
  width: fit-content;

  font-size: 20px;
  color: ${getRGBA(colors.grey60)};

  box-sizing: border-box;
  &.active {
    border-bottom: 2px solid ${getRGBA(colors.grey90)};
    box-sizing: border-box;

    color: ${getRGBA(colors.grey90)};
  }
`;
