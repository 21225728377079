import { trimFractionDigits } from '@voithru/front-core';
import sha256 from 'fast-sha256';

export const PASSWORD_REGEX =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+={}\-[\]\\|,.<>/?'";:`~])[A-Za-z\d!@#$%^&*()_+={}\-[\]\\|,.<>/?'";:`~]{10,}$/; // 10글자 이상, 영문 대소문자,숫자 및 특수문자 전부 들어감/\

export enum ByteSize {
  BYTE = 1,
  KILO = ByteSize.BYTE * 1000,
  MEGA = ByteSize.KILO * 1000,
  GIGA = ByteSize.MEGA * 1000,
}

export function getByteString(byte: number, length: number = 2) {
  if (byte / ByteSize.GIGA >= 1) {
    return trimFractionDigits(byte / ByteSize.GIGA, length) + ' GB';
  } else if (byte / ByteSize.MEGA >= 1) {
    return trimFractionDigits(byte / ByteSize.MEGA, length) + ' MB';
  } else if (byte / ByteSize.KILO >= 1) {
    return trimFractionDigits(byte / ByteSize.KILO, length) + ' KB';
  }

  return byte + ' bytes';
}

export function sha256Checksum(buffer: ArrayBuffer) {
  const SHA256 = sha256(new Uint8Array(buffer));
  return Buffer.from(SHA256).toString('hex');
}
export function getExtension(name: string) {
  return name.match(/\.(.*)$/)?.[1] || '';
}
