import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react';
import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import AppPaths from 'src/constants/AppPaths';

interface IBreadcrumbs {
  pathname: string;
  name: string;
  active: boolean;
}

function AppBreadcrumb() {
  const location = useLocation();

  const currentLocation = location.pathname;

  const getRouteName = React.useCallback((pathname: string) => {
    const currentRoute = Object.entries(AppPaths).find((appPath) =>
      matchPath(pathname, { path: appPath[1].path, exact: true })
    )?.[1];

    return currentRoute?.name || '';
  }, []);

  const getBreadcrumbs = React.useCallback(
    (location: string) => {
      const breadcrumbs: IBreadcrumbs[] = [];

      location.split('/').reduce((prev, curr, index, array) => {
        const currentPathname = `${prev}/${curr}`;
        breadcrumbs.push({
          pathname: currentPathname,
          name: getRouteName(currentPathname),
          active: index + 1 === array.length ? true : false,
        });
        return currentPathname;
      });
      return breadcrumbs;
    },
    [getRouteName]
  );

  const breadcrumbs = React.useMemo(() => getBreadcrumbs(currentLocation), [currentLocation, getBreadcrumbs]);

  return (
    <CBreadcrumb className="m-0 ms-2">
      <CBreadcrumbItem href="/">Home</CBreadcrumbItem>
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <CBreadcrumbItem {...(breadcrumb.active ? { active: true } : { href: breadcrumb.pathname })} key={index}>
            {breadcrumb.name}
          </CBreadcrumbItem>
        );
      })}
    </CBreadcrumb>
  );
}

export default React.memo(AppBreadcrumb);
