import {Column, Row} from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import {getRGBA} from 'src/utils/styles';
import styled from 'styled-components';

export const JobResultTaskWrapper = styled(Column)`
  margin-top: 24px;
  width: 2270px;
  min-width: 2270px;

  & > ${Row}:first-child {
    border: 2px solid ${getRGBA(colors.grey90)};
  }

  & > ${Row} {
    padding: 17px 0;

    text-align: left;
    font-size: 15px;

    & > span {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & > span:nth-child(1) {
      width: 50px;
    }

    & > span:nth-child(2) {
      width: 100px;
    }

    & > span:nth-child(3) {
      width: 100px;
    }

    & > span:nth-child(4) {
      width: 230px;
    }

    & > span:nth-child(5) {
      width: 230px;
    }

    & > span:nth-child(6) {
      width: 120px;
    }

    & > span:nth-child(7) {
      width: 120px;
    }

    & > span:nth-child(8) {
      width: 120px;
    }

    & > span:nth-child(9) {
      width: 160px;
    }

    & > span:nth-child(10) {
      width: 100px;
    }

    & > span:nth-child(11) {
      width: 100px;
    }

    & > span:nth-child(12) {
      width: 100px;
    }

    & > span:nth-child(13) {
      width: 100px;
    }

    & > span:nth-child(14) {
      width: 80px;
    }

    & > span:nth-child(15) {
      width: 90px;
    }

    & > span:nth-child(16) {
      width: 90px;
    }

    & > span:nth-child(17) {
      width: 90px;
    }

    & > span:nth-child(18) {
      width: 130px;
    }

    & > span:nth-child(19) {
      width: 80px;
    }

    & > span:nth-child(20) {
      flex: 1;
    }
  }
`;
