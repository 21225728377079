import React from 'react';
import { Row } from 'src/components/atoms/styled/layout';
import { useLoading } from 'src/components/context/ApplicationContext';
import { useNotification } from 'src/components/context/NotificationContext';
import AppPagination from 'src/components/molecules/AppPagination';
import { EntityHistoryNotificationAPIResponse } from 'src/types/api/EntityHistory';
import EntityHistoryItem from './components/NotificationItem';
import { NotificationTableWrapper } from './styled';

function NotificationTable() {
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [maxPage, setMaxPage] = React.useState<number>(0);
  const [notifications, setNotifications] = React.useState<EntityHistoryNotificationAPIResponse[]>([]);

  const { fetchPage, totalPage, tryRefresh } = useNotification();

  const loading = useLoading();

  React.useEffect(() => {
    setMaxPage(totalPage);
  }, [totalPage, setMaxPage]);

  React.useEffect(() => {
    loading(async () => {
      await tryRefresh();

      const nextList = await fetchPage(currentPage + 1);

      setNotifications(nextList);
    })();
  }, [currentPage, loading, fetchPage, tryRefresh]);

  return (
    <NotificationTableWrapper>
      <Row>
        <span>ID</span>
        <span>일시</span>
        <span>작업유형</span>
        <span>작업 ID</span>
        <span>진행상태</span>
        <span>회사명</span>
        <span>이벤트 명</span>
        <span>이력 메세지</span>
        <span>변경사항</span>
        <span>작업자</span>
      </Row>
      {notifications.map((it, index) => {
        return <EntityHistoryItem key={index} item={it} />;
      })}
      <AppPagination currentPage={currentPage} maxPage={maxPage} setCurrentPage={setCurrentPage} />
    </NotificationTableWrapper>
  );
}

export default NotificationTable;
