import { StorageBase } from '@voithru/front-core';
import React from 'react';
import { useHistory } from 'react-router';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import minglo, { MingloAccount } from 'src/api/minglo';
import AppPaths from 'src/constants/AppPaths';
import { LoginRequest } from 'src/types/api/Auth';
import { isAxiosError } from 'src/utils/api/axios';
import { useLoading } from '../ApplicationContext';

const STORAGE_MINGLO_USER = new StorageBase('minglo:user', localStorage);

function useMingloAuthBuilder() {
  const history = useHistory();
  const loading = useLoading();

  const [initialize, setInitialize] = React.useState(false);
  const [user, setUser] = React.useState<MingloAccount>();
  const setStorageUser = React.useCallback((value?: MingloAccount) => {
    STORAGE_MINGLO_USER.item = value || null;
    setUser(value);
  }, []);

  const loadUser = React.useCallback(async () => {
    if (!minglo.manager.auth.token) {
      return;
    }

    const res = await minglo.account.get();
    if (isAxiosError(res) || res.status !== 200) {
      throw res;
    }

    setStorageUser(res.data);
  }, [setStorageUser]);

  const login = React.useCallback(
    async (data: LoginRequest) => {
      const res = await minglo.auth.login(data);
      if (isAxiosError(res) || res.status !== 200) {
        throw res;
      }

      minglo.manager.auth.token = res.headers.authorization;
      return await loadUser();
    },
    [loadUser]
  );

  const loginGoogle = React.useCallback(
    async (googleId: string) => {
      const res = await minglo.auth.google(googleId);
      if (isAxiosError(res) || res.status !== 200) {
        throw res;
      }

      minglo.manager.auth.token = res.headers.authorization;
      return await loadUser();
    },
    [loadUser]
  );

  const loginAsPanoplay = React.useCallback(async () => {
    const res = await api.auth.hermes();
    if (isAxiosError(res)) {
      throw res;
    }

    minglo.manager.auth.token = `Bearer ${res.data}`;
    return await loadUser();
  }, [loadUser]);

  const logout = React.useCallback(async () => {
    minglo.manager.auth.token = null;
    setStorageUser();
    history.replace(AppPaths.login.path);
  }, [history, setStorageUser]);

  React.useEffect(() => {
    if (!minglo.manager.auth.token) {
      return;
    }

    loading(async () => {
      const token = await minglo.manager.auth.refreshToken();
      if (!token) {
        throw new Error('useAuthBuilder.mount: Invalid');
      }

      await loadUser();
    })()
      .catch(errorLogger.error)
      .finally(() => {
        setInitialize(true);
      });
  }, [loadUser, loading, logout]);

  return React.useMemo(
    () => ({ initialize, user, login, loginGoogle, loginAsPanoplay, logout }),
    [initialize, login, loginAsPanoplay, loginGoogle, logout, user]
  );
}

export default useMingloAuthBuilder;
