import React from 'react';
import useAuthBuilder from './useAuthBuilder';
import useMingloAuthBuilder from './useMingloAuthBuilder';

interface AuthContextValue {
  auth: ReturnType<typeof useAuthBuilder>;
  mingloAuth: ReturnType<typeof useMingloAuthBuilder>;
  beforeLocation: [string | undefined, (value: string | undefined) => void];
}

const AuthContext = React.createContext<AuthContextValue | undefined>(undefined);

export const AuthContextProvider: React.FunctionComponent = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children } = props;
  const auth = useAuthBuilder();
  const mingloAuth = useMingloAuthBuilder();

  const beforeLocation = React.useState<string>();

  if (!auth.initialize) {
    return null;
  }

  return <AuthContext.Provider value={{ auth, mingloAuth, beforeLocation }}>{children}</AuthContext.Provider>;
};

export function useAuthContext() {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext: Use it after Provider');
  }

  return context;
}

export function useAuth() {
  const { auth } = useAuthContext();
  return auth;
}

export function useMingloAuth() {
  const { mingloAuth } = useAuthContext();
  return mingloAuth;
}

export function useBeforeLocation() {
  const { beforeLocation } = useAuthContext();

  return beforeLocation;
}
