import FileAnchor from 'src/components/atoms/FileAnchor';
import { Row } from 'src/components/atoms/styled/layout';
import styled from 'styled-components';

export const ProjectDescriptionCollapseWrapper = styled.div`
  position: relative;
  height: 60px;

  & > .accordion {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    & .accordion-collapse {
      & > .accordion-body {
        & > .header {
          font-weight: 500;
        }

        & > ${Row} {
          align-items: center;
          padding: 0 8px 0 24px;
          min-height: 42px;

          white-space: pre-wrap;

          & > ${FileAnchor}, & > span.file-name,
          & > a {
            flex: 1;
            color: inherit;
          }

          & > textarea {
            width: 100%;
          }

          & > ${Row} + ${Row} {
            margin: 0 12px;
          }

          &:hover {
            background: var(--cui-light);
          }
        }
      }
    }
  }
`;
