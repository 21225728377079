import { gql } from '@apollo/client';

export const GQL_PROJECT_CURT_INFO = gql`
  query ProjectInfo($projectId: ID!) {
    project(id: $projectId) {
      id
      category
      name

      manager {
        name
        email
      }

      projectFiles {
        file {
          id
          name
        }
      }

      projectRelateLinks {
        url
      }

      description
    }
  }
`;
