import { ButtonBase } from 'src/components/atoms/styled/button';
import { Column, Row } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const JobsWithJobResultsWrapper = styled(Column)`
  margin-top: 25px;
  width: 100%;
  & > ${Row}.buttonWrap {
    & > ${Row}:first-child {
      min-width: 400px;
    }

    & > ${Row} {
      & > ${ButtonBase} {
        padding: 3px 11px;

        background: ${getRGBA(colors.green50)};
        border-radius: 4px;

        color: ${getRGBA(colors.white)};
        white-space: nowrap;
      }

      & > ${ButtonBase} + ${ButtonBase} {
        margin-left: 25px;
      }
    }
  }

  & > ${Column} {
    margin-top: 14px;

    & > ${Row} {
      & > ${Row}.jobs {
        align-items: center;

        min-width: 400px;

        & > span {
          padding-left: 4px;

          word-break: break-all;
          white-space: pre-wrap;
        }

        & > span:nth-child(1) {
          width: 50px;
        }
        & > span:nth-child(2) {
          width: 60px;
        }
        & > span:nth-child(3) {
          width: 210px;
        }
        & > ${Column} {
          & > ${ButtonBase}.addJobFile {
            padding: 3px;

            background: #0b5fff;
            border-radius: 4px;

            color: ${getRGBA(colors.white)};
          }

          & > ${ButtonBase}.editorLink {
            color: #0b5fff;
            text-decoration: underline;
          }
        }
      }

      & > div.tit {
        margin: 0 12px;
        width: 1px;
        height: inherit;

        border: 1px solid ${getRGBA(colors.grey80)};
      }
    }
    ${Row}.job_results {
      align-items: center;
      padding: 4px 0;
      & > span {
        padding-left: 4px;

        word-break: break-all;
        white-space: pre-wrap;
      }

      & > span:nth-child(1) {
        width: 50px;
      }
      & > span:nth-child(2) {
        width: 100px;
      }
      & > span:nth-child(3) {
        width: 100px;
      }
      & > span:nth-child(4) {
        width: 100px;
      }
      & > span:nth-child(5) {
        width: 100px;
      }
      & > span:nth-child(6) {
        width: 130px;
      }
      & > span:nth-child(7) {
        width: 130px;
      }
      & > span:nth-child(8) {
        width: 150px;
      }
      & > span:nth-child(9) {
        width: 100px;
      }
      & > span:nth-child(10) {
        width: 100px;
      }
      & > span:nth-child(11) {
        width: 80px;
      }
      & > span:nth-child(12) {
        width: 100px;
        text-align: center;
      }
      & > span:nth-child(13) {
        width: 100px;
        text-align: center;
      }
      & > span.addFile {
        & > ${ButtonBase} {
          padding: 3px;

          background: ${getRGBA(colors.green50)};
          border-radius: 4px;

          color: ${getRGBA(colors.white)};
        }
      }
    }
  }
`;
