import { CButton, CCard, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react';
import { once, Parameter } from '@voithru/front-core';
import React from 'react';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { Anchor } from 'src/components/atoms/styled/element';
import { Row } from 'src/components/atoms/styled/layout';
import AccountSelectModal from 'src/components/organisms/AccountSelectModal';
import { CompanyResponse } from 'src/types/api/Company';
import { ProjectResponse, ProjectUpdateRequest } from 'src/types/api/Project';
import { AccountResponseWithCompany, User } from 'src/types/api/User';
import { isAxiosError } from 'src/utils/api/axios';
import { dateFormat } from 'src/utils/date';
import { handleEnterKeyDown } from 'src/utils/dom';

import styled from 'styled-components';
import { ProjectDescriptionWrapper, UserInfoModal } from './styled';

interface Props {
  project: ProjectResponse;
  onProjectChange(): void;

  className?: string;
}

function ProjectDescription(props: Props) {
  const { project, onProjectChange, className } = props;
  const [, forceUpdate] = React.useReducer(() => ({}), {});

  /** APIs */
  const users = React.useMemo(() => new Map<number, User>(), []);
  const loadUser = React.useCallback(
    async (id: number) => {
      const res = await api.account.item(id).get();
      if (isAxiosError(res)) {
        throw res;
      }

      users.set(id, res.data);
      forceUpdate();
      return res.data;
    },
    [users]
  );

  const [company, setCompany] = React.useState<CompanyResponse>();
  const [companyUserIds, setCompanyUserIds] = React.useState<number[]>([]);
  const [masterAccountId, setMasterAccountId] = React.useState<number>();
  const loadCompany = React.useCallback(async (id: number) => {
    // get Company
    const res = await api.companies.item(id).get();
    if (isAxiosError(res)) {
      throw res;
    }

    setCompany(res.data);
    return res.data;
  }, []);

  const init = React.useMemo(
    () =>
      once(async (accountId: number) => {
        const user = await loadUser(accountId);
        if (user.companyId) {
          const company = await loadCompany(user.companyId);

          // Find Company Manager Account
          if (company.managerAccountId) {
            await loadUser(company.managerAccountId);
          }

          // Find Master Account of Company
          const accountRes = await api.account.get({ roles: ['CLIENT_MASTER', 'CLIENT_SUB'], companyId: company.id });
          if (isAxiosError(accountRes)) {
            throw accountRes;
          }

          const master = accountRes.data.find((it) => it.role === 'CLIENT_MASTER');
          if (master) {
            users.set(master.id, master);
            setMasterAccountId(master.id);
          }

          accountRes.data.forEach((it) => users.set(it.id, it));
          setCompanyUserIds(accountRes.data.map((it) => it.id));
        }
      }),
    [loadCompany, loadUser, users]
  );

  React.useEffect(() => {
    if (!project.accountId || isNaN(project.accountId)) {
      return;
    }

    if (project.managerId) {
      loadUser(project.managerId).catch(errorLogger.error);
    }
    init(project.accountId).catch(errorLogger.error);
  }, [project.accountId, init, project.managerId, loadUser]);

  const companyLink = React.useMemo(() => {
    if (!masterAccountId) {
      return '';
    }

    return '/clientAccounts/detail?' + Parameter.toURLSearchParams({ id: masterAccountId });
  }, [masterAccountId]);

  const [isAdminSelectOpen, setAdminSelectOpen] = React.useState(false);
  const handleAdminSelectSubmit = React.useCallback(
    async (account: AccountResponseWithCompany[]) => {
      const [target] = account;
      if (!target) {
        return;
      }

      try {
        const res = await api.project
          .item(project.id)
          .statusAndScheduledDeadlineAndManagerId({ managerId: target.account.id });
        if (isAxiosError(res)) {
          throw res;
        }

        setAdminSelectOpen(false);
        onProjectChange();
      } catch (error) {
        errorLogger.error(error);
      }
    },
    [onProjectChange, project]
  );

  const [currentUserId, setCurrentUserId] = React.useState(NaN);
  const currentUser = React.useMemo(() => users.get(currentUserId), [currentUserId, users]);

  const [isEditRequestedStartDateTime, toggleEditRequestedStartDateTime] = React.useState(false);
  const editRequestedStartDateTimeRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (!isEditRequestedStartDateTime) return;

    editRequestedStartDateTimeRef.current?.focus();
  }, [isEditRequestedStartDateTime]);

  const handleRequestedStartDateTimeChange = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>, isEdit: React.Dispatch<React.SetStateAction<boolean>>) => {
      const date = e.currentTarget.valueAsDate;
      if (!date || isNaN(date.getTime())) {
        return;
      }

      const request: ProjectUpdateRequest = {
        project: { ...project, requestedStartDateTime: date?.toISOString() },
      };

      try {
        const res = await api.project.item(project.id).related(request);
        if (isAxiosError(res)) {
          throw res;
        }

        onProjectChange();
        toggleEditRequestedStartDateTime(false);
      } catch (error) {
        errorLogger.error(error);
        window.alert('프로젝트 희망 작업 시작일을 변경하는 동안 문제가 발생했습니다.');
      } finally {
        isEdit(false);
      }
    },
    [onProjectChange, project]
  );

  const [isEditRequestedDeadlineDateTime, toggleEditRequestedDeadlineDateTime] = React.useState(false);
  const editRequestedDeadlineDateTimeRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (!isEditRequestedDeadlineDateTime) return;

    editRequestedDeadlineDateTimeRef.current?.focus();
  }, [isEditRequestedDeadlineDateTime]);

  const handleRequestedDeadlineDateTimeChange = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>, isEdit: React.Dispatch<React.SetStateAction<boolean>>) => {
      const date = e.currentTarget.valueAsDate;
      if (!date || isNaN(date.getTime())) {
        return;
      }

      const request: ProjectUpdateRequest = {
        project: { ...project, requestedDeadlineDateTime: date?.toISOString() },
      };

      try {
        const res = await api.project.item(project.id).related(request);
        if (isAxiosError(res)) {
          throw res;
        }

        onProjectChange();
        toggleEditRequestedDeadlineDateTime(false);
      } catch (error) {
        errorLogger.error(error);
        window.alert('프로젝트 희망 작업 종료일을 변경하는 동안 문제가 발생했습니다.');
      } finally {
        isEdit(false);
      }
    },
    [onProjectChange, project]
  );

  const [isEditScheduledDateTime, toggleEditScheduledDateTime] = React.useState(false);
  const editScheduledDateTimeRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (!isEditScheduledDateTime) return;

    editScheduledDateTimeRef.current?.focus();
  }, [isEditScheduledDateTime]);

  const handleScheduledDateTimeChange = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>, isEdit: React.Dispatch<React.SetStateAction<boolean>>) => {
      const date = e.currentTarget.valueAsDate;
      if (!date || isNaN(date.getTime())) {
        return;
      }

      const request: ProjectUpdateRequest = {
        project: { ...project, scheduledDeadlineDateTime: date?.toISOString() },
      };

      try {
        const res = await api.project.item(project.id).related(request);
        if (isAxiosError(res)) {
          throw res;
        }

        onProjectChange();
        toggleEditScheduledDateTime(false);
      } catch (error) {
        errorLogger.error(error);
        window.alert('납품 예정일을 변경하는 동안 문제가 발생했습니다.');
      } finally {
        isEdit(false);
      }
    },
    [onProjectChange, project]
  );

  return (
    <ProjectDescriptionWrapper className={className}>
      <CCard className={'client'}>
        <Row>
          <span>고객회사</span>
          <Anchor to={companyLink}>{company?.name || '-'}</Anchor>
        </Row>
        <Row>
          <span>고객담당자</span>
          <Row>
            {companyUserIds.map((it) => (
              <CButton key={it} color={'light'} onClick={() => setCurrentUserId(it)}>
                {users.get(it)?.name || '-'}
              </CButton>
            ))}
          </Row>
        </Row>
      </CCard>
      <CCard className={'info'}>
        <Row>
          <span>담당 Sales</span>
          <Anchor to={companyLink}>
            {(company?.managerAccountId && users.get(company.managerAccountId)?.name) || '-'}
          </Anchor>
        </Row>
        <Row>
          <span>담당 PM</span>
          <span>
            <span>{(project.managerId && users.get(project.managerId)?.name) || '-'}</span>
            <CButton onClick={() => setAdminSelectOpen(true)}>찾기</CButton>
          </span>
        </Row>
        <Row>
          <span>프로젝트 생성일</span>
          <span>{dateFormat(project.createDateTime, 'YYYY.MM.DD')}</span>
        </Row>
        <Row>
          <span>프로젝트 최종 수정일</span>
          <span>{project.updateDateTime ? dateFormat(project.updateDateTime, 'YYYY.MM.DD') : '-'}</span>
        </Row>
        <Row>
          <span>프로젝트 희망 작업 시작일</span>
          {!isEditRequestedStartDateTime && (
            <span className={'editable'} onDoubleClick={() => toggleEditRequestedStartDateTime(true)}>
              {project.requestedStartDateTime ? dateFormat(project.requestedStartDateTime, 'YYYY.MM.DD') : '-'}
            </span>
          )}
          {isEditRequestedStartDateTime && (
            <input
              type={'date'}
              max={'9999-12-31'}
              ref={editRequestedStartDateTimeRef}
              defaultValue={project.requestedStartDateTime && dateFormat(project.requestedStartDateTime, 'YYYY-MM-DD')}
              onBlur={(e) => handleRequestedStartDateTimeChange(e, toggleEditRequestedStartDateTime)}
              onKeyDown={(e) => handleEnterKeyDown(e, editRequestedStartDateTimeRef)}
            />
          )}
        </Row>
        <Row>
          <span>프로젝트 희망 작업 종료일</span>
          {!isEditRequestedDeadlineDateTime && (
            <span className={'editable'} onDoubleClick={() => toggleEditRequestedDeadlineDateTime(true)}>
              {project.requestedDeadlineDateTime ? dateFormat(project.requestedDeadlineDateTime, 'YYYY.MM.DD') : '-'}
            </span>
          )}
          {isEditRequestedDeadlineDateTime && (
            <input
              type={'date'}
              max={'9999-12-31'}
              ref={editRequestedDeadlineDateTimeRef}
              defaultValue={
                project.requestedDeadlineDateTime && dateFormat(project.requestedDeadlineDateTime, 'YYYY-MM-DD')
              }
              onBlur={(e) => handleRequestedDeadlineDateTimeChange(e, toggleEditRequestedDeadlineDateTime)}
              onKeyDown={(e) => handleEnterKeyDown(e, editRequestedDeadlineDateTimeRef)}
            />
          )}
        </Row>
        <Row>
          <span>납품 예정일</span>
          {!isEditScheduledDateTime && (
            <span className={'editable'} onDoubleClick={() => toggleEditScheduledDateTime(true)}>
              {project.scheduledDeadlineDateTime ? dateFormat(project.scheduledDeadlineDateTime, 'YYYY.MM.DD') : '-'}
            </span>
          )}
          {isEditScheduledDateTime && (
            <input
              type={'date'}
              max={'9999-12-31'}
              ref={editScheduledDateTimeRef}
              defaultValue={
                project.scheduledDeadlineDateTime && dateFormat(project.scheduledDeadlineDateTime, 'YYYY-MM-DD')
              }
              onBlur={(e) => handleScheduledDateTimeChange(e, toggleEditScheduledDateTime)}
              onKeyDown={(e) => handleEnterKeyDown(e, editScheduledDateTimeRef)}
            />
          )}
        </Row>
        <Row>
          <span>납품 완료일</span>
          <span>{project.doneDateTime ? dateFormat(project.doneDateTime, 'YYYY.MM.DD') : '-'}</span>
        </Row>
      </CCard>

      <AccountSelectModal
        title={'담당 PM 선택'}
        visible={isAdminSelectOpen}
        options={{ requestData: { roles: 'ADMIN' } }}
        onDismiss={() => setAdminSelectOpen(false)}
        onSubmit={handleAdminSelectSubmit}
      />
      <UserInfoModal visible={Boolean(currentUser)} onDismiss={() => setCurrentUserId(NaN)}>
        {currentUser && (
          <>
            <CModalHeader onDismiss={() => setCurrentUserId(NaN)}>
              <CModalTitle>{'고객 담당자 정보'}</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <Row>
                <span>이름</span>
                <span>{currentUser.name}</span>
              </Row>
              <Row>
                <span>소속부서</span>
                <span>{currentUser.department || '-'}</span>
              </Row>
              <Row>
                <span>연락처</span>
                <span>{currentUser.phoneNumber || '-'}</span>
              </Row>
              <Row>
                <span>이메일</span>
                <span>{currentUser.email || '-'}</span>
              </Row>
            </CModalBody>
            <CModalFooter>
              <CButton className={'align-items-center'} color={'primary'} onClick={() => setCurrentUserId(NaN)}>
                확인
              </CButton>
            </CModalFooter>
          </>
        )}
      </UserInfoModal>
    </ProjectDescriptionWrapper>
  );
}

export default styled(ProjectDescription)``;
