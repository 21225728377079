import { gql } from '@apollo/client';

export const GQL_PROJECT_CREATE_DONE = gql`
  query ProjectCreateDone($id: ID!) {
    project(id: $id) {
      name
      category

      requestedStartDateTime
      requestedDeadlineDateTime

      description

      account {
        id
        name
        company {
          name
        }
        department
        phoneNumber
        email
        masterAccountId
      }

      manager {
        id
        name
      }

      productOrders {
        productType
        deliveryType
        sourceLanguage
        translateLanguage
        productOptionOrders {
          type
        }
        quantity
      }

      projectRelateLinks {
        url
      }

      projectFiles {
        id
        fileType
        file {
          id
          name
        }
      }
    }
  }
`;
