import { CContainer, CHeaderBrand } from '@coreui/react';
import { Column, Row } from 'src/components/atoms/styled/layout';
import React from 'react';
import { toSortString } from 'src/types/api';
import errorLogger from 'src/api/errorLogger';
import api from 'src/api';
import AppPagination from 'src/components/molecules/AppPagination';
import DeleteProductAlertModal from 'src/components/pages/ProductsPage/components/DeleteProductAlertModal';
import { Product } from 'src/types/api/Product';
import { isAxiosError } from 'src/utils/api/axios';
import {
  ContentType,
  WorkRange,
  Source_Language,
  Translate_Language,
  ResultType,
  WorkTarget,
  DifficultyType,
  ExpectedQualityType,
  VolumeType,
} from 'src/utils/translate';
import { ProductStatusWrapper } from './styled';
import AddProductsModal from './components/AddProductsModal';
import ChangeProductModal from './components/ChangeProductModal';

function ProductsPage() {
  const [products, setProducts] = React.useState<Product[]>();
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [maxPage, setMaxPage] = React.useState<number>(0);

  const getProducts = React.useCallback(async () => {
    await api.product
      .get({ size: 10, page: currentPage, sort: toSortString({ direction: 'desc', sortKey: 'id' }) })
      .then((res) => {
        const { data } = res;
        const list: Product[] = data.element;
        setProducts(list);
        setMaxPage(data.totalPage);
      })
      .catch((error) => {
        window.alert('기본 상품 리스트를 가져오는데 실패했습니다.');
        errorLogger.error(error);
      });
  }, [currentPage]);

  const handleDelete = React.useCallback(
    (id?: number) => async () => {
      try {
        if (!id || isNaN(Number(id))) {
          throw new Error("delete product: id cant't use");
        }
        const res = await api.product.item(id).delete();
        if (isAxiosError(res)) {
          throw res;
        }

        await getProducts();
      } catch (err) {
        window.alert('삭제하는데 문제가 발생했습니다.');

        errorLogger.error(err);
      }
    },
    [getProducts]
  );

  React.useEffect(() => {
    getProducts();
  }, [currentPage, getProducts, maxPage]);

  return (
    <div className="bg-light d-flex flex-column justify-content-center">
      <CHeaderBrand>기본상품관리</CHeaderBrand>
      <CContainer>
        <div className="d-flex flex-row  justify-content-start" style={{ marginBottom: '5px', marginTop: '5px' }}>
          <AddProductsModal getProducts={getProducts} />
        </div>
        <ProductStatusWrapper>
          <Column>
            <Row>
              <span>상품 ID</span>
              <span>콘텐츠 타입</span>
              <span>작업범위</span>
              <span>원본언어</span>
              <span>번역언어</span>
              <span>납품형태</span>
              <span>작업대상</span>
              <span>난이도</span>
              <span>납품퀄리티</span>
              <span>볼륨</span>
              <span>단가</span>
              <span>관리</span>
            </Row>
            {products?.map((it, idx) => (
              <Row key={idx}>
                <span>{it.id}</span>
                <span>{ContentType[it.requestCategory]}</span>
                <span>{WorkRange[it.productType]}</span>
                <span>{it.sourceLanguage && Source_Language[it.sourceLanguage]}</span>
                <span>{it.translateLanguage && Translate_Language[it.translateLanguage]}</span>
                <span>{it.deliveryType && ResultType[it.deliveryType]}</span>
                <span>{it.productSource && WorkTarget[it.productSource]}</span>
                <span>{DifficultyType[it.difficulty]}</span>
                <span>{ExpectedQualityType[it.expectedQuality]}</span>
                <span>{VolumeType[it.volume]}</span>
                <span>{it.price}</span>
                <span>
                  <Row>
                    <ChangeProductModal productData={it} />
                    <DeleteProductAlertModal id={it.id} onClick={handleDelete(it.id)} />
                  </Row>
                </span>
              </Row>
            ))}
            {!isNaN(maxPage) && (
              <AppPagination currentPage={currentPage} maxPage={maxPage} setCurrentPage={setCurrentPage} />
            )}
          </Column>
        </ProductStatusWrapper>
      </CContainer>
    </div>
  );
}

export default ProductsPage;
