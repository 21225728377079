import { Title } from 'src/components/atoms/styled/element';
import { Column, CSSRow, Row } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const AccountSettingWrapper = styled(Column)`
  & > ${Title} {
    margin-bottom: 24px;

    font-weight: bold;
    font-size: 18px;
  }

  & > ${Column} {
    padding: 18px;

    background-color: ${getRGBA(colors.grey10)};

    & > :not(:first-child) {
      margin: 10px 16px;
    }

    & > ${Row} {
      & > ${Title} {
        width: 120px;
      }
    }
  }

  & > ${Column}.setAlram {
    & > ${Column} {
      & > label {
        ${CSSRow}

        margin: 10px 16px;

        & > span {
          width: 180px;
        }
      }

      & > input {
        width: 350px;
      }
    }
  }

  & > ${Column} + ${Column} {
    margin-top: 24px;
  }
`;
