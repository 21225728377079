import { CButton, CCol, CFormInput, CListGroup, CListGroupItem, CRow } from '@coreui/react';
import { KeyboardCode, once, sleep, useExceptClick } from '@voithru/front-core';
import React from 'react';
import errorLogger from 'src/api/errorLogger';
import minglo, { MingloAccount } from 'src/api/minglo';
import { isAxiosError } from 'src/utils/api/axios';

import { MingloAccountSearchWrapper } from './styled';

interface Props {
  exclude?: number[];

  onClick?(account: MingloAccount): void;
}

function MingloAccountSearch(props: Props) {
  const { exclude = [], onClick } = props;
  const input = React.useRef<HTMLInputElement>(null);

  const [accounts, setAccounts] = React.useState<MingloAccount[]>([]);
  const filtered = React.useMemo(() => accounts.filter((it) => !exclude.includes(it.id)), [accounts, exclude]);

  const search = React.useCallback(async () => {
    const query = input.current?.value;
    if (!query) {
      return setAccounts([]);
    }

    try {
      const res = await minglo.account.search({ query });
      if (isAxiosError(res)) {
        throw res;
      }

      setAccounts(res.data);
      return res.data;
    } catch (error) {
      errorLogger.error(error);
    }
  }, []);

  const handleChange = React.useMemo(
    () =>
      once(async (e: React.ChangeEvent<HTMLInputElement>) => {
        await search();
        await sleep(500);
      }),
    [search]
  );

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (new KeyboardCode(e).toString() === 'enter') {
        e.preventDefault();
        search();
      }
    },
    [search]
  );

  const handleClick = React.useCallback(
    (account: MingloAccount) => () => {
      setAccounts([]);
      onClick?.(account);
    },
    [onClick]
  );

  const wrapper = useExceptClick(null, () => setAccounts([]), {
    root: { current: document.body as HTMLDivElement },
  }) as React.RefObject<HTMLDivElement>;

  return (
    <MingloAccountSearchWrapper ref={wrapper}>
      <CCol>
        <CFormInput ref={input} type={'text'} onChange={handleChange} onKeyDown={handleKeyDown} />
        {filtered.length > 0 && (
          <CListGroup>
            {filtered.map((it) => (
              <CListGroupItem key={it.id} component={'button'} onClick={handleClick(it)}>
                <CRow>
                  <CCol>{it.id}</CCol>
                  <CCol>{it.name}</CCol>
                  <CCol>{`(${it.email} / ${it.phoneNumber})`}</CCol>
                </CRow>
              </CListGroupItem>
            ))}
          </CListGroup>
        )}
      </CCol>
      <CCol>
        <CButton type={'button'} color={'secondary'} onClick={search}>
          Search
        </CButton>
      </CCol>
    </MingloAccountSearchWrapper>
  );
}

export default MingloAccountSearch;
