import { Column, Row } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';
import JobResultList from '../JobResultList';

export const ProjectDetailInfoWrapper = styled(Row)`
  justify-content: space-between;

  padding: 16px;
  width: 3318px;

  border: 1px solid ${getRGBA(colors.grey80)};

  & > ${Column} {
    width: 1100px;
  }

  & > ${JobResultList} {
    width: 100%;
  }

  & > div.tit {
    margin: 12px 24px;
    height: inherit;

    border: 1px solid ${getRGBA(colors.grey50)};
  }
`;
