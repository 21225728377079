import { gql } from '@apollo/client';

export const GQL_JOB_RESULT_FILES = gql`
  query JobResultFiles($jobIds: [ID!], $hermesAccountId: Int!, $projectId: Int!) {
    jobs(ids: $jobIds) {
      id
      name

      jobFiles {
        fileId
        file {
          id
          name
        }
      }

      jobResults {
        id
        status
        jobResultFiles {
          fileId
          index
          file {
            account {
              id
              name
            }
            id
            accountId
            isDirectory
            parentDirectoryId
            name
            s3Path
            volume
            status
            fileUploadStartDateTime
            fileUploadEndDateTime
          }
        }

        productOrder {
          productType
          sourceLanguage
          translateLanguage
          deliveryType
          productSource
          productOptionOrders {
            type
          }
        }
      }
    }

    workerJobResultGrants(hermesAccountId: $hermesAccountId, projectId: $projectId) {
      jobResultId
    }
  }
`;
