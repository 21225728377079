import { Column, Row } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const InspectionProcessingWrapper = styled(Column)`
  width: 2270px;
  & > ${Row} {
    justify-content: space-between;
    align-items: flex-end;
    & > span {
      padding: 16px 12px;
      width: fit-content;
      border-bottom: 2px solid ${getRGBA(colors.grey90)};
      box-sizing: border-box;

      font-size: 20px;
      color: ${getRGBA(colors.grey90)};
    }
    & > a {
      color: ${getRGBA(colors.grey90)};
      text-decoration: none;
    }
  }
`;
