import { cn, useForceUpdate, useMount } from '@voithru/front-core';
import React from 'react';

interface DropState {
  hover: boolean;
}

interface Options {
  onDrop?(data: DataTransfer): Promise<void> | void;
}

function useFileDrop<E extends HTMLElement | SVGElement>(options?: Options) {
  const { onDrop } = options ?? {};
  const dropState = React.useRef<DropState>();
  const forceUpdate = useForceUpdate();

  const target = React.useRef<E>(null);

  const handleDragOverDocument = React.useCallback((e: Event) => {
    e.preventDefault();
    if (target.current?.contains(e.target as Node | null) || document.body.className.includes('prohibition')) {
      return;
    }

    document.body.className = cn(document.body.className, 'prohibition');
  }, []);

  const handleDragLeaveDocument = React.useCallback((e: Event) => {
    e.preventDefault();
    if (target.current?.contains(e.target as Node | null)) {
      return;
    }

    const names = document.body.className.split(' ').filter((it) => it !== 'prohibition');
    const next = cn(...names);
    if (!next) {
      document.body.removeAttribute('class');
    } else {
      document.body.className = next;
    }
  }, []);

  useMount(() => {
    dropState.current = {
      hover: false,
    };

    window.addEventListener('drop', handleDragLeaveDocument);
    window.addEventListener('dragleave', handleDragLeaveDocument);
    window.addEventListener('dragover', handleDragOverDocument);
    return () => {
      window.removeEventListener('drop', handleDragLeaveDocument);
      window.removeEventListener('dragleave', handleDragLeaveDocument);
      window.removeEventListener('dragover', handleDragOverDocument);
    };
  });

  const onDragOver = React.useCallback(() => {
    if (dropState.current?.hover !== false) {
      return;
    }

    dropState.current.hover = true;
    forceUpdate();
  }, [forceUpdate]);

  const onDragLeave = React.useCallback(() => {
    if (dropState.current?.hover !== true) {
      return;
    }

    dropState.current.hover = false;
    forceUpdate();
  }, [forceUpdate]);

  const handleDrop = React.useCallback(
    (e: React.DragEvent<E>) => {
      if (dropState.current) {
        dropState.current.hover = false;
      }
      e.preventDefault();

      onDrop?.(e.dataTransfer);
      forceUpdate();
    },
    [forceUpdate, onDrop]
  );

  return { target, dropState, handleDrop, onDragOver, onDragLeave };
}

export default useFileDrop;
