import { ButtonBase } from 'src/components/atoms/styled/button';
import { Column, Row } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const JobListWrapper = styled(Column)`
  margin-top: 40px;
  width: 100%;
  & > ${Row}.buttonWrap {
    justify-content: space-between;
    margin-top: 32px;

    & > ${Row}.modalButtonWrap {
      & > ${ButtonBase} {
        padding: 3px 11px;

        background: ${getRGBA(colors.green50)};
        border-radius: 4px;

        color: ${getRGBA(colors.white)};
      }

      & > :not(:first-child) {
        margin-left: 12px;
      }
    }

    & > ${Row}.statusWrap {
      justify-content: flex-end;

      font-size: 15px;

      & > ${ButtonBase} {
        padding: 0 5px;
        margin: 5px 0;
        height: 30px;

        box-sizing: border-box;
      }

      & > ${ButtonBase}.active {
        border-bottom: 2px solid ${getRGBA(colors.blue50)};
      }

      & > ${ButtonBase} + ${ButtonBase} {
        margin-left: 19px;
      }
    }
  }

  & > ${Row}.title {
    justify-content: space-between;

    font-size: 18px;

    & > a {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 150px;
      height: 30px;

      border-radius: 4px;
      background: ${getRGBA(colors.green50)};

      color: ${getRGBA(colors.white)};
      font-size: 16px;
      text-decoration: none;
      text-align: center;
    }
  }

  & > ${Column} {
    margin-top: 20px;

    & > ${Row} {
      align-items: center;
      padding: 4px 0;

      font-size: 15px;

      &.isHighLight {
        background: ${getRGBA(colors.blue50, 0.4)};
      }

      & > span {
        padding-left: 4px;
        min-height: 22px;
        & > input {
          width: inherit;
        }
      }

      & > span.warning {
        color: ${getRGBA(colors.red50)};
      }

      & > span:nth-child(1) {
        width: 30px;
      }
      & > span:nth-child(2) {
        width: 80px;
      }
      & > span:nth-child(3) {
        width: 320px;
      }
      & > span:nth-child(4) {
        width: 60px;

        cursor: pointer;

        &.notWorkSize {
          color: red;
        }
      }
      & > span:nth-child(5) {
        width: 80px;
      }
      & > span:nth-child(6) {
        width: 185px;
      }
      & > span:nth-child(7) {
        width: 100px;
      }
      & > span:nth-child(8) {
        width: 100px;
      }
      & > span:nth-child(9) {
        width: 60px;
        text-align: center;
      }

      & > span:nth-child(10) {
        width: 60px;
        text-align: center;
      }

      & > span.buttonWrap {
        & > ${ButtonBase} {
          width: 54px;

          border-radius: 32px;
          box-sizing: border-box;

          font-size: 12px;
          text-align: center;
        }
        & > ${ButtonBase}[color='red'] {
          background: ${getRGBA(colors.red10)};
          color: ${getRGBA(colors.red50)};
        }

        & > ${ButtonBase}[color='blue'] {
          background: ${getRGBA(colors.blue20)};
          color: ${getRGBA(colors.blue50)};
        }

        & > ${ButtonBase}[disabled] {
          background: ${getRGBA(colors.grey20)};
          color: ${getRGBA(colors.grey50)};
        }

        & > ${ButtonBase} + ${ButtonBase} {
          margin-left: 13px;
        }
      }
    }

    & > ${Row}:first-child {
      border-bottom: 1px solid ${getRGBA(colors.grey80)};
      box-sizing: border-box;
    }

    & > ${Row} + ${Row} {
      border-bottom: 1px solid ${getRGBA(colors.grey50)};
      box-sizing: border-box;
    }

    & > ${Row}:last-child {
      border-bottom: 0px;
    }
  }
`;
