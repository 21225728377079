import { CButton, CModalBody, CModalFooter } from '@coreui/react';
import React from 'react';
import api from 'src/api';
import { Description, Title } from 'src/components/atoms/styled/element';
import { Column, Row } from 'src/components/atoms/styled/layout';
import { Company, CompanyFileResponse } from 'src/types/api/Company';
import { JobsResponse } from 'src/types/api/Job';
import { JobResultResponse } from 'src/types/api/JobResult';
import { ProductOrder } from 'src/types/api/ProductOrder';
import { isAxiosError } from 'src/utils/api/axios';
import { dateFormat } from 'src/utils/date';
import {
  WorkRange,
  Source_Language,
  Translate_Language,
  ResultType,
  WorkTarget,
  AdditionalServices,
} from 'src/utils/translate';
import { modalStep } from '../../type';
import { PreviewFirstModalTitleWrapper, PreviewFirstModalBodyWrapper } from './styled';

interface Props {
  selectedJobResults: JobResultResponse[];
  jobsMap: Map<number, JobsResponse>;
  productOrderMap: Map<number, ProductOrder>;
  company?: Company;
  handleStep?: (nextStep: modalStep) => void;
}

function SettlementPreview(props: Props) {
  const { selectedJobResults, productOrderMap, jobsMap, company, handleStep } = props;

  return (
    <>
      <CModalBody>
        <PreviewFirstModalBody
          selectedJobResults={selectedJobResults}
          productOrderMap={productOrderMap}
          company={company}
          jobsMap={jobsMap}
        />
      </CModalBody>
      <CModalFooter>
        <CButton
          color="secondary"
          onClick={() => {
            handleStep?.('PAYMENTSETTLEMENT');
          }}
        >
          뒤로가기
        </CButton>
        <CButton onClick={() => handleStep?.('SETTLEMENTPUBLISH')}>미리보기</CButton>
      </CModalFooter>
    </>
  );
}

export default SettlementPreview;

interface BodyProps {
  selectedJobResults: JobResultResponse[];
  productOrderMap: Map<number, ProductOrder>;
  jobsMap: Map<number, JobsResponse>;
  company?: Company;
}

export function PreviewFirstModalBody(props: BodyProps) {
  const { selectedJobResults, jobsMap, productOrderMap, company } = props;

  let sumTotalPrice = 0;

  const [companyFile, setCompanyFile] = React.useState<CompanyFileResponse[]>();

  const getCompanyFile = React.useCallback(async () => {
    if (!company?.id) {
      return;
    }

    const companyFileRes = await api.company.item(company.id).files.get();

    if (isAxiosError(companyFileRes)) {
      throw companyFileRes;
    }

    setCompanyFile(companyFileRes.data);
  }, [company?.id]);

  React.useEffect(() => {
    getCompanyFile();
  }, [company, getCompanyFile]);

  return (
    <>
      <PreviewFirstModalTitleWrapper>
        <Row className={'title'}>
          <Title>Panoplay 납품내역서</Title>
        </Row>
        <Row className={'titleContent'}>
          <Column>
            <Row className={'description'}>
              <Description>
                발행일 <span>{dateFormat(Date.now(), 'YYYY년 MM월 DD일')}</span>
              </Description>
            </Row>
            <Row className={'description'}>
              <Description>
                수신 <span>{company?.name} 귀하</span>
              </Description>
            </Row>
          </Column>
          <Column className={'supplierInformation'}>
            <Title>공급자 정보</Title>
            <Row>
              <Title>상호</Title>
              <Description>(주) 보이스루</Description>
              <Title>대표자 명</Title>
              <Description>이상헌</Description>
            </Row>
            <Row>
              <Title>등록번호</Title>
              <Description>123 - 85 - 593953</Description>
            </Row>
            <Row>
              <Title>주소</Title>
              <Description>서울시 강남구 강남대로 311, 1301호</Description>
            </Row>
            <Row>
              <Title>업태</Title>
              <Description>정보서비스업</Description>
              <Title>종목</Title>
              <Description>온라인정보제공업</Description>
            </Row>
            <Row>
              <Title>전화번호</Title>
              <Description>070-1234-1234</Description>
            </Row>

            <Title>대표자 날인</Title>
          </Column>
        </Row>
      </PreviewFirstModalTitleWrapper>

      <PreviewFirstModalBodyWrapper>
        <Column>
          <Row className={'listRow'}>
            <span>작업 범위</span>
            <span>원본 언어</span>
            <span>번역 언어</span>
            <span>납품형태</span>
            <span>작업대상</span>
            <span>부가서비스</span>
            <span>단위</span>
            <span>적용단가</span>
            <span>금액 (VAT별도)</span>
          </Row>
          {selectedJobResults
            .sort((prev, acc) => {
              const prevJob = jobsMap.get(prev.id) ?? null;
              const accJob = jobsMap.get(acc.id) ?? null;

              if (!prevJob?.workSize || !accJob?.workSize) {
                return 0;
              }

              if (prevJob?.workSize > accJob?.workSize) {
                return -1;
              } else if (prevJob?.workSize < accJob?.workSize) {
                return 1;
              } else {
                return 0;
              }
            })
            .map((it, idx) => {
              const productOrder = productOrderMap.get(it.id) ?? null;
              const job = jobsMap.get(it.id) ?? null;

              const serviceSum =
                productOrder?.options?.reduce((acc, cur) => {
                  return acc + (cur?.price || 0);
                }, 0) ?? 0;

              const totalUnitPrice = (productOrder?.price || 0) + serviceSum;
              const summary = totalUnitPrice * (job?.workSize || 1);

              sumTotalPrice += summary;

              return (
                <Row key={idx} className={'listRow'}>
                  <span>{productOrder && WorkRange[productOrder.productType]}</span>
                  <span>{productOrder && Source_Language[productOrder.sourceLanguage]}</span>
                  <span>{productOrder?.translateLanguage && Translate_Language[productOrder.translateLanguage]}</span>
                  <span>{productOrder?.deliveryType && ResultType[productOrder.deliveryType]}</span>
                  <span>{productOrder?.productSource && WorkTarget[productOrder.productSource]}</span>
                  <Column className={'additionalServices'}>
                    {productOrder?.options?.map((it) => {
                      return <span key={it.id}>{AdditionalServices[it.type]}</span>;
                    })}
                  </Column>

                  <span>{job?.workSize}</span>
                  <span>{totalUnitPrice.toLocaleString()}원</span>
                  <span>{summary.toLocaleString()}원</span>
                </Row>
              );
            })}
        </Column>
        <Column>
          <Row className={'price'}>
            <Description>(1) 서비스 사용금액 </Description>
            <Description>{sumTotalPrice.toLocaleString()}</Description>
          </Row>
          <Row className={'price'}>
            <Description>(2) 할인 금액 </Description>
            <Description>0</Description>
          </Row>
          <Row className={'price'}>
            <Description>(3) 부가세 (VAT) </Description>
            <Description>{(sumTotalPrice / 10).toLocaleString()}</Description>
          </Row>
          <Row className={'price'}>
            <Description>청구금액 (1) + (2) + (3) </Description>
            <Description>{(sumTotalPrice * 1.1 + 0).toLocaleString()}</Description>
          </Row>
          <Row className={'notice'}>
            <Description>결제 안내 </Description>
            <Description>입금계좌: 국민은행 123401-04-263105 예금주 주식회사 보이스루 </Description>
          </Row>
          <Row className={'notice'}>
            <Description>세금계산서 발행</Description>
            <Description>
              사업자등록증 사본 :{' '}
              {!company || !companyFile || companyFile?.length === 0 ? '담당자 제출 요청' : '등록되어 있음'} / 발행 받을
              이메일 정보 : {company?.email || '담당자 제출 요청'}
            </Description>
          </Row>
        </Column>
      </PreviewFirstModalBodyWrapper>
    </>
  );
}
