import { gql } from '@apollo/client';

export const GQL_JOB_FILES = gql`
  query JobFiles($ids: [ID!]) {
    jobs(ids: $ids) {
      id
      name
      manager {
        name
      }
      jobFiles {
        id
        fileId
        fileType
        index
        file {
          id
          isDirectory
          parentDirectoryId
          name
          s3Path
          volume
          status
          fileUploadStartDateTime
          fileUploadEndDateTime
        }
        editors {
          index
          key
        }
        videoProcessRequest {
          status
        }
      }
    }
  }
`;
