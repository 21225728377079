import { ButtonBase } from 'src/components/atoms/styled/button';
import { Title } from 'src/components/atoms/styled/element';
import { Column, CSSRow, Row } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';
import ContentInfo from './components/ContentInfo';
import ContentResultFiles from './components/ContentResultFiles';

export const DetailContentWrapper = styled.div`
  & > ${Row}:first-child {
    align-items: center;
    justify-content: space-between;
    margin: 0 0 12px;
    & > ${Row} {
      & > ${Title} {
        flex: 1;

        margin: 0 0 0 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        font-weight: 700;
      }

      & > a {
        line-height: 18px;
        color: ${getRGBA(colors.grey80)};
        text-decoration: underline;
      }
    }
  }

  & > ${Row}:nth-child(2) {
    & > ${Column} {
      flex: 1;

      background: white;
      padding: 8px 12px;
    }

    & > ${Column}.client {
      & > ${Row} {
        flex: 1;
        & > span:first-child {
          width: 120px;
          font-weight: 700;
        }
      }
    }

    & > ${Column}.info {
      & > ${Row} {
        align-items: center;
        & > span:first-child {
          width: 200px;
          font-weight: 700;
        }

        &:not(:last-child) {
          margin: 0 0 8px;
        }

        & > ${ButtonBase} {
          ${CSSRow};
          align-items: center;
          margin-left: 8px;
        }
      }
    }
  }

  & > ${ContentInfo}, & > ${ContentResultFiles} {
    margin: 36px 0;
  }
`;
