import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';
import { Row } from '../styled/layout';

interface StatusCountProps {
  color: 'green' | 'yellow' | 'red' | 'blue';
  className?: string;
}

const HexColor = {
  green: '#34C08E',
  yellow: '#FBBC05',
  red: '#CE5050',
  blue: '#24A0ED',
};

export const StatusCountBadge = styled(Row)<StatusCountProps>`
  align-items: center;
  justify-content: center;

  width: 134px;
  height: 64px;

  background-color: ${(props) => HexColor[props.color]};
  border-radius: 32px;

  font-size: 22px;
  color: ${getRGBA(colors.white)};
`;
