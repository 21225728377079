import {
  CButton,
  CContainer,
  CFormCheck,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import { useMount } from '@voithru/front-core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { generatePath, useHistory } from 'react-router-dom';
import errorLogger from 'src/api/errorLogger';
import { Button } from 'src/components/atoms/styled/button';
import { PRODUCT_ORDER_OPTION_TYPES } from 'src/constants/Product';
import { useLoading } from 'src/components/context/ApplicationContext';
import { ProductOrder, ProductOrderOptionType } from 'src/types/api/ProductOrder';
import { ProjectCategory } from 'src/types/api/Project';
import { AdditionalServices } from 'src/utils/translate';

interface Props {
  productOrder: ProductOrder;
  isEdit?: boolean;
  category?: ProjectCategory;

  onSubmitHandler: (productOptionTypes: ProductOrderOptionType[]) => void;
}

interface FormData {
  productOptionTypes: ProductOrderOptionType[];
}

function ProductOrderOptionTypesModal(props: Props) {
  const { productOrder, isEdit = false, category, onSubmitHandler } = props;
  const { options } = productOrder;

  const [visible, setVisible] = React.useState(false);
  const { register, handleSubmit, getValues, reset } = useForm<FormData>();

  const history = useHistory();

  const [, forceUpdate] = React.useReducer((x) => !x, false);

  const loading = useLoading();

  useMount(() => {
    loading(() => {
      if (options) {
        const form: FormData = {
          productOptionTypes: options.map((it) => {
            const { type } = it;

            if (!type) {
              throw new Error("Don't have Product type");
            }

            return type;
          }),
        };

        reset(form);
      }
    })()
      .catch((error) => errorLogger.error(error))
      .finally(() => {
        forceUpdate();
      });
  });

  const handleAddOption = React.useCallback(() => {
    const path = generatePath('/productOptions');
    history.push(path);
  }, [history]);

  const onSubmit = React.useCallback(
    async (data: FormData = getValues()) => {
      const { productOptionTypes } = data;

      if (!productOptionTypes) {
        console.error("Don't have submitting data");
      }

      onSubmitHandler(productOptionTypes);

      setVisible(false);
    },
    [getValues, onSubmitHandler]
  );

  return (
    <div>
      {!isEdit &&
        (options?.length ? (
          options.map((option, idx) => {
            return <p key={idx}>{AdditionalServices[option.type]}</p>;
          })
        ) : (
          <p style={{ width: 'max-content' }}>부가서비스 선택하기</p>
        ))}
      {isEdit && (
        <Button
          style={{
            width: 'max-content',
            height: 'max-content',
            flexDirection: 'column',
            color: '#006BE5',
            textDecoration: 'underline',
          }}
          onClick={() => setVisible(!visible)}
          disabled={!isEdit}
        >
          {options && options.length ? (
            options.map((option, idx) => {
              return <p key={idx}>{AdditionalServices[option.type]}</p>;
            })
          ) : (
            <p style={{ width: 'max-content' }}>부가서비스 선택하기</p>
          )}
        </Button>
      )}
      <CModal size={'lg'} visible={visible} onDismiss={() => setVisible(false)}>
        <form
          onSubmit={(e) => {
            e.stopPropagation();
            // e.preventDefault();
            handleSubmit((data: FormData) => onSubmit(data))(e);
          }}
        >
          <CModalHeader onDismiss={() => setVisible(false)}>
            <CModalTitle>부가 서비스 선택하기</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <div className="d-flex flex-row  justify-content-end" style={{ marginBottom: '5px' }}>
              <button onClick={() => handleAddOption()}>+ 부가서비스 추가</button>
            </div>
            <CContainer style={{ height: '320px', overflow: 'auto' }}>
              <CTable bordered>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell />
                    <CTableHeaderCell>서비스 명</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {category &&
                    PRODUCT_ORDER_OPTION_TYPES[category]?.map((it) => (
                      <CTableRow key={it}>
                        <CTableDataCell>
                          <CFormCheck value={it} {...register('productOptionTypes')} />
                        </CTableDataCell>
                        <CTableDataCell>{AdditionalServices[it]}</CTableDataCell>
                      </CTableRow>
                    ))}
                </CTableBody>
              </CTable>
            </CContainer>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => {
                setVisible(false);
              }}
            >
              취소
            </CButton>
            <CButton type={'submit'} color={'primary'}>
              확인
            </CButton>
          </CModalFooter>
        </form>
      </CModal>
    </div>
  );
}

export default ProductOrderOptionTypesModal;
