import api from 'src/api';
import { isAxiosError } from 'src/utils/api/axios';

export async function getProjectById(projectId: number) {
  const res = await api.project.item(projectId).get();

  if (isAxiosError(res)) {
    throw res;
  }

  return res;
}

export async function getJobsByProjectId(projectId: number) {
  const res = await api.project.item(projectId).jobs();

  if (isAxiosError(res)) {
    throw res;
  }

  return res;
}

export async function getJobById(jobId: number) {
  const res = await api.jobs.item(jobId).get();

  if (isAxiosError(res)) {
    throw res;
  }

  return res;
}

export async function getProductOrderById(productOrderId: number) {
  const res = await api.productOrder.item(productOrderId).get();

  if (isAxiosError(res)) {
    throw res;
  }

  return res;
}

export async function getAccountByAccountId(accountId: number) {
  const res = await api.account.item(accountId).get();

  if (isAxiosError(res)) {
    throw res;
  }

  return res;
}

export async function getDocumentByJobResultId(jobResultId: number) {
  const res = await api.jobResult.item(jobResultId).latestDocument();

  if (isAxiosError(res) && res.response?.status !== 404) {
    throw res;
  }

  return res;
}
