import { CSpinner } from '@coreui/react';
import React from 'react';
import { useUploaderContext } from 'src/components/context/UploaderContext';
import MultipartUpload from 'src/utils/MultipartUpload';

interface IProps {
  data: MultipartUpload[];
}

function AppFileUploadList(props: IProps) {
  const { data } = props;
  const { isUiBlocked } = useUploaderContext();
  const [, forceUpdate] = React.useReducer((x) => !x, false);
  React.useLayoutEffect(() => {
    const remove = data.map((it) => it.addEventListener('done', () => forceUpdate()));
    const progress = data.map((it) => it.addEventListener('progress', () => forceUpdate()));

    return () => {
      remove.forEach((it) => it());
      progress.forEach((it) => it());
    };
  }, [data]);

  const uploadedCount = data.filter((it) => it.status === 'DONE').length;
  const currentUploadInProgress = data.find((it) => it.status === 'START');
  const isUploadInProgress = uploadedCount < data.length;
  const currentUploadInProgressRatio = currentUploadInProgress?.progress
    ? currentUploadInProgress.progress.value / currentUploadInProgress.progress.max
    : NaN;

  return isUiBlocked ? (
    <div
      style={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: '#000A',
        zIndex: 2023,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        textAlign: 'center',
        gap: '4px',
      }}
    >
      <CSpinner color="light" />
      <input
        type="text"
        readOnly
        style={{ opacity: 0, width: 0, height: 0 }}
        autoFocus
        onBlur={(ev) => ev.currentTarget.focus()}
      />
      <div>
        {isUploadInProgress && (
          <>
            <div>파일 업로드 중</div>
            <div>해당 창을 닫거나 새로고침 할 경우 파일 업로드가 중단됩니다.</div>
          </>
        )}
      </div>
      <div className="p-3" style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <div
          style={{
            background: `linear-gradient(to right,#006BE5 ${(uploadedCount / data.length) * 100}%, #1c4d81 0%)`,
            width: '400px',
            height: '30px',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '20px',
          }}
        >
          {uploadedCount} / {data.length}
        </div>
        {isFinite(currentUploadInProgressRatio) ? (
          <div
            style={{
              background: `linear-gradient(to right,#006BE5 ${currentUploadInProgressRatio * 100}%, #1c4d81 0%)`,
              width: '400px',
              height: '20px',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '12px',
              borderRadius: '20px',
            }}
          >
            {(currentUploadInProgressRatio * 100).toFixed(0)}%
          </div>
        ) : (
          <div style={{ height: '20px' }} />
        )}
        <div style={{ fontSize: '12px' }}>{currentUploadInProgress?.namedFile.file.name ?? '처리 중'}</div>
      </div>
    </div>
  ) : null;
}

export default AppFileUploadList;
