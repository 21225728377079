import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react';
import React from 'react';

interface Props {
  id?: number;
  onClick: () => void;
}

function DeleteProductAlertModal(props: Props) {
  const { id, onClick } = props;

  const [visible, setVisible] = React.useState(false);

  const onConfirm = React.useCallback(() => {
    onClick();
    setVisible(false);
  }, [onClick]);

  return (
    <div className="align-items-center d-flex">
      <CButton style={{ width: 'max-content' }} onClick={() => setVisible(!visible)}>
        삭제
      </CButton>
      <CModal size={'sm'} visible={visible} onDismiss={() => setVisible(false)}>
        <CModalHeader onDismiss={() => setVisible(false)}>
          <CModalTitle>상품 삭제</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div>
            <div>상품 [{id}]를 정말로 삭제하시겠습니까? </div>
            <div>삭제 시 기존 적용 되어있던 모든 프로젝트에 모두 적용됩니다.</div>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            취소
          </CButton>
          <CButton onClick={() => onConfirm()}>확인</CButton>
        </CModalFooter>
      </CModal>
    </div>
  );
}
export default DeleteProductAlertModal;
