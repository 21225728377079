import styled, { css } from 'styled-components';

/**
 * Flex
 */
export const CSSRow = css`
  display: flex;
  flex-direction: row;
`;

export const CSSColumn = css`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  ${CSSRow}
`;
export const Column = styled.div`
  ${CSSColumn}
`;

/**
 * Card
 */
export const CSSCard = css`
  background: white;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 3px 12px rgba(0, 0, 0, 0.08);
`;

export const Card = styled(Column)`
  ${CSSCard}
`;
