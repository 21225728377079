import { useMount } from '@voithru/front-core';
import React from 'react';
import { Link } from 'react-router-dom';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import IssuedStatusBadge from 'src/components/atoms/IssuedStatusBadge';
import StatusLabel from 'src/components/atoms/StatusLabel';
import { Column, Row } from 'src/components/atoms/styled/layout';
import AppPaths from 'src/constants/AppPaths';
import { CompanyResponse } from 'src/types/api/Company';
import { DocumentResponse } from 'src/types/api/Document';
import { JobsResponse } from 'src/types/api/Job';
import { JobResultResponse } from 'src/types/api/JobResult';
import { ProductOrder } from 'src/types/api/ProductOrder';
import { ProjectResponse } from 'src/types/api/Project';
import { UserResponse } from 'src/types/api/User';
import { isAxiosError } from 'src/utils/api/axios';
import {
  getProjectById,
  getJobById,
  getProductOrderById,
  getDocumentByJobResultId,
  getAccountByAccountId,
} from 'src/utils/api/getter';
import { formatDueDay } from 'src/utils/date';
import {
  ContentType,
  WorkRange,
  Source_Language,
  Translate_Language,
  ResultType,
  AdditionalServices,
} from 'src/utils/translate';
import { DeliveryStatusWrapper } from './styled';

interface JobResultWithSize {
  totalSize: number;
  element: JobResultResponse[];
}

function DeliveryStatus() {
  const [project, setProject] = React.useState<Map<number, ProjectResponse>>();
  const [job, setJob] = React.useState<Map<number, JobsResponse>>();
  const [productOrder, setProductOrder] = React.useState<Map<number, ProductOrder>>();
  const [documentStatus, setDocuementStatus] = React.useState<Map<number, DocumentResponse>>();
  const [managerAccount, setManagerAccount] = React.useState<Map<number, UserResponse>>();
  const [company, setCompany] = React.useState<Map<number, CompanyResponse>>();
  const [companyManager, setCompanyManager] = React.useState<Map<number, UserResponse>>();

  const [jobResult, setJobResult] = React.useState<JobResultWithSize>();

  const getJobResult = React.useCallback(async () => {
    try {
      const res = await api.jobResult.pages({
        page: 0,
        size: 10,
        status: 'DONE',
      });

      if (isAxiosError(res)) {
        throw res;
      }
      const { data } = res;

      const projectMap = new Map<number, ProjectResponse>();
      const jobMap = new Map<number, JobsResponse>();
      const productOrderMap = new Map<number, ProductOrder>();
      const documentMap = new Map<number, DocumentResponse>();
      const managerAccountMap = new Map<number, UserResponse>();
      const companyMap = new Map<number, CompanyResponse>();
      const companyManagerMap = new Map<number, UserResponse>();

      for (const item of data.element) {
        try {
          const projectRes = await getProjectById(item.projectId);
          const jobRes = await getJobById(item.jobId);
          const productOrderRes = await getProductOrderById(item.productOrderId);
          const documentRes = await getDocumentByJobResultId(item.id);

          if (projectRes.data.managerId) {
            const managerAccountRes = await getAccountByAccountId(projectRes.data.managerId);

            managerAccountMap.set(item.id, managerAccountRes.data);
          }

          if (projectRes.data.accountId) {
            const accountRes = await getAccountByAccountId(projectRes.data.accountId);

            if (accountRes.data.companyId) {
              const companyRes = await api.companies.item(accountRes.data.companyId).get();

              if (isAxiosError(companyRes)) {
                throw companyRes;
              }

              companyMap.set(item.id, companyRes.data);

              if (companyRes.data.managerAccountId) {
                const companyManagerRes = await getAccountByAccountId(companyRes.data.managerAccountId);

                companyManagerMap.set(item.id, companyManagerRes.data);
              }
            }
          }

          projectMap.set(item.id, projectRes.data);
          jobMap.set(item.id, jobRes.data);
          productOrderMap.set(item.id, productOrderRes.data);

          if (documentRes.data?.id) {
            documentMap.set(item.id, documentRes.data);
          }
        } catch (err) {
          errorLogger.error(err);
        }
      }
      setJobResult({
        totalSize: data.totalSize,
        element: data.element,
      });
      setProject(projectMap);
      setJob(jobMap);
      setProductOrder(productOrderMap);
      setDocuementStatus(documentMap);
      setManagerAccount(managerAccountMap);
      setCompany(companyMap);
      setCompanyManager(companyManagerMap);
    } catch (err) {
      errorLogger.error(err);
    }
  }, []);

  useMount(() => {
    getJobResult();
  });

  return (
    <DeliveryStatusWrapper>
      <Row>
        <span>작업결과물 ({jobResult?.totalSize})</span>
        <Link to={AppPaths.settlementManagement.path}>더보기 &gt;</Link>
      </Row>
      <Column>
        <Row>
          <span>고객 회사명</span>
          <span>콘텐츠 타입</span>
          <span>프로젝트명</span>
          <span>콘텐츠명</span>
          <span>결과물 ID</span>
          <span>작업범위</span>
          <span>원본언어</span>
          <span>번역언어</span>
          <span>납품형태</span>
          <span>작업대상</span>
          <span>상품단가</span>
          <span>부가서비스</span>
          <span>부가서비스단가</span>
          <span>총 단가</span>
          <span>작업량</span>
          <span>합계</span>
          <span>진행상태</span>
          <span>최초생성일</span>
          <span>납품완료일</span>
          <span>납품내역서 발급상태</span>
          <span>번역 PM</span>
          <span>담당 Sales</span>
        </Row>
        {jobResult?.element.map((it, idx) => {
          const projectByJobResults = project?.get(it.id) ?? null;
          const jobByJobResults = job?.get(it.id) ?? null;
          const productOrderByJobResults = productOrder?.get(it.id) ?? null;
          const documentByJobResults = documentStatus?.get(it.id) ?? null;
          const managerAccountByJobResults = managerAccount?.get(it.id) ?? null;

          const serviceSum =
            productOrderByJobResults?.options?.reduce((acc, cur) => {
              return acc + (cur?.price || 0);
            }, 0) ?? 0;

          const totalUnitPrice = (productOrderByJobResults?.price || 0) + serviceSum;
          const summary = totalUnitPrice * (jobByJobResults?.workSize || 1);

          return (
            <Row key={idx}>
              <span>{company?.get(it.id)?.name}</span>
              <span>{jobByJobResults && ContentType[jobByJobResults.category]}</span>
              <span>{projectByJobResults?.name}</span>
              <span>{jobByJobResults?.name}</span>
              <span>JR-{it.id}</span>
              <span>{productOrderByJobResults && WorkRange[productOrderByJobResults.productType]}</span>
              <span>{productOrderByJobResults && Source_Language[productOrderByJobResults.sourceLanguage]}</span>
              <span>
                {productOrderByJobResults?.translateLanguage &&
                  Translate_Language[productOrderByJobResults.translateLanguage]}
              </span>
              <span>{productOrderByJobResults?.deliveryType && ResultType[productOrderByJobResults.deliveryType]}</span>
              <span>-</span>
              <span>{productOrderByJobResults?.price?.toLocaleString()}</span>
              <span>
                {productOrderByJobResults?.options && AdditionalServices[productOrderByJobResults.options[0]?.type]}
              </span>
              <span>{serviceSum.toLocaleString()}</span>
              <span>{totalUnitPrice.toLocaleString()}</span>
              <span>{jobByJobResults?.workSize}</span>
              <span>{summary.toLocaleString()}</span>
              <span>
                <StatusLabel status={it.status} />
              </span>
              <span>{formatDueDay(it.createDateTime)}</span>
              <span>{projectByJobResults?.doneDateTime && formatDueDay(projectByJobResults?.doneDateTime)}</span>
              <span>
                <IssuedStatusBadge status={documentByJobResults?.status || ''} />
              </span>
              <span>{managerAccountByJobResults?.role === 'ADMIN' && managerAccountByJobResults?.name}</span>
              <span>{companyManager?.get(it.id)?.name}</span>
            </Row>
          );
        })}
      </Column>
    </DeliveryStatusWrapper>
  );
}

export default DeliveryStatus;
