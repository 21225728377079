import { Parameter } from '@voithru/front-core';
import { AxiosInstance } from 'axios';
import { generatePath } from 'react-router';
import { APIManagerId, APIPagination } from 'src/types/api';
import {
  JobFileDelete,
  JobFileRequest,
  JobFileResponse,
  JobPageRequest,
  JobPostRequest,
  JobRelatedRequest,
  JobsRequest,
  JobsResponse,
  JobsStatus,
  JobsStatusUpdate,
} from 'src/types/api/Job';
import {
  JobResultFileDelete,
  JobResultFileRequest,
  JobResultRequest,
  JobResultResponse,
} from 'src/types/api/JobResult';

const JOBS_PATH = '/api/v1/jobs';

function apiJobs(instance: AxiosInstance) {
  return {
    get: (params: APIManagerId & JobsRequest) => {
      const query = Parameter.toURLSearchParams(params);
      return instance.get<JobsResponse[]>(JOBS_PATH, { params: query });
    },
    patch: (data: JobsStatusUpdate[]) => instance.patch(JOBS_PATH, data),
    post: (data: JobPostRequest) => instance.post<JobsResponse>(JOBS_PATH, data),
    pages: (params: JobPageRequest) => {
      const query = Parameter.toURLSearchParams(params);
      return instance.get<APIPagination<JobsResponse>>(JOBS_PATH + '/page', { params: query });
    },

    item(jobId: number) {
      const JOBS_ITEM_PATH = generatePath(JOBS_PATH + '/:jobId', { jobId });

      return {
        get: () => instance.get<JobsResponse>(JOBS_ITEM_PATH),
        put: (data: Partial<JobsResponse>) => instance.put<JobsResponse>(JOBS_ITEM_PATH, data),
        related: (data: JobRelatedRequest) => instance.put(JOBS_ITEM_PATH + '/related', data),
        status: (status: JobsStatus) => instance.patch(JOBS_ITEM_PATH + '/status', undefined, { params: { status } }),
        workSize: (workSize: number) =>
          instance.patch(JOBS_ITEM_PATH + '/workSize', undefined, { params: { workSize } }),
        jobResult: (params: JobResultRequest) => {
          const query = Parameter.toURLSearchParams(params);
          return instance.get<JobResultResponse[]>(JOBS_ITEM_PATH + '/jobResults', { params: query });
        },

        files: {
          get: () => instance.get<JobFileResponse[]>(JOBS_ITEM_PATH + '/files'),
          post: (data: JobFileRequest[]) => instance.post<JobsResponse>(JOBS_ITEM_PATH + '/files', data),
          item(fileId: string) {
            const FILE_ITEM_PATH = generatePath(JOBS_ITEM_PATH + '/:fileId', { fileId });
            const FILE_FILES_ITEM_PATH = generatePath(JOBS_ITEM_PATH + '/files/:fileId', { fileId });
            return {
              post: () => instance.post<JobsResponse>(FILE_ITEM_PATH),
              delete: (data: JobFileDelete) => instance.delete(FILE_FILES_ITEM_PATH + '/detach', { params: data }),
            };
          },
        },
        jobResultItem(jobResultId: number) {
          const JOB_RESULT_ITEM_PATH = generatePath(JOBS_ITEM_PATH + '/jobResults/:jobResultId', { jobResultId });
          return {
            files: {
              post: (data: JobResultFileRequest[]) => instance.post(JOB_RESULT_ITEM_PATH + '/files', data),

              item(fileId: string) {
                const JOB_RESULT_ITEM_PATH_WITHOUT_JOB_RESULT = generatePath(JOBS_ITEM_PATH + '/:jobResultId', {
                  jobResultId,
                });

                const JOB_RESULT_ITEM_FILE_ITEM_PATH = generatePath(
                  JOB_RESULT_ITEM_PATH_WITHOUT_JOB_RESULT + '/files/:fileId',
                  { fileId }
                );

                return {
                  delete: (data: JobResultFileDelete) =>
                    instance.delete(JOB_RESULT_ITEM_FILE_ITEM_PATH + '/detach', { params: data }),
                };
              },
            },
          };
        },
      };
    },
  };
}

export default apiJobs;
