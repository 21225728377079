import React from 'react';
import { DocumentResponse } from 'src/types/api/Document';
import { JobFileResponse, JobsResponse } from 'src/types/api/Job';
import { JobResultFileResponse, JobResultResponse, JobResultStatus } from 'src/types/api/JobResult';
import { ProductOrderResponse } from 'src/types/api/ProductOrder';
import { UserResponse } from 'src/types/api/User';

interface ContextValues {
  jobs: JobsResponse[] | undefined;
  setJobs: (jobs: JobsResponse[]) => void;
  jobResults: JobResultResponse[] | undefined;
  setJobResults: (jobResults: JobResultResponse[]) => void;
  productOrders: ProductOrderResponse[] | undefined;
  setProductOrders: (productOrders: ProductOrderResponse[]) => void;

  jobsChecks: Map<number, boolean>;
  productOrderChecks: Map<number, boolean>;
  jobResultChecks: Map<number, boolean>;

  jobsMap: Map<number, JobsResponse>;
  productOrderMap: Map<number, ProductOrderResponse>;
  documentMap: Map<number, DocumentResponse>;
  jobFileMap: Map<number, JobFileResponse[]>;
  jobResultFileMap: Map<number, JobResultFileResponse[]>;
  jobManagerMap: Map<number, UserResponse>;

  jobResultFilterStatus: JobResultStatus | 'ALL';
  setJobResultFilterStatus: (filter: JobResultStatus | 'ALL') => void;
}

const ProjectDetailInfoContext = React.createContext<ContextValues | undefined>(undefined);

interface Props {
  children?: React.ReactNode;
}

export function ProjectDetailInfoContextProvider(props: Props) {
  const { children } = props;

  const [jobs, setJobs] = React.useState<JobsResponse[]>();
  const [jobResults, setJobResults] = React.useState<JobResultResponse[]>();
  const [productOrders, setProductOrders] = React.useState<ProductOrderResponse[]>();

  const [jobsChecks] = React.useState<Map<number, boolean>>(() => new Map());
  const [productOrderChecks] = React.useState<Map<number, boolean>>(() => new Map());
  const [jobResultChecks] = React.useState<Map<number, boolean>>(() => new Map());

  const [documentMap] = React.useState<Map<number, DocumentResponse>>(() => new Map());
  const [jobFileMap] = React.useState<Map<number, JobFileResponse[]>>(() => new Map());
  const [jobResultFileMap] = React.useState<Map<number, JobResultFileResponse[]>>(() => new Map());
  const [jobManagerMap] = React.useState<Map<number, UserResponse>>(() => new Map());

  const [jobResultFilterStatus, setJobResultFilterStatus] = React.useState<JobResultStatus | 'ALL'>('ALL');

  const jobsMap = React.useMemo(() => {
    const map = new Map<number, JobsResponse>();

    jobs?.forEach((it) => {
      map.set(it.id, it);
    });

    return map;
  }, [jobs]);

  const productOrderMap = React.useMemo(() => {
    const map = new Map<number, ProductOrderResponse>();

    productOrders?.forEach((it) => {
      map.set(it.id, it);
    });

    return map;
  }, [productOrders]);

  const bundle = {
    jobs,
    setJobs,
    jobResults,
    setJobResults,
    productOrders,
    setProductOrders,

    jobsChecks,
    productOrderChecks,
    jobResultChecks,

    jobsMap,
    productOrderMap,
    documentMap,
    jobFileMap,
    jobResultFileMap,
    jobManagerMap,

    jobResultFilterStatus,
    setJobResultFilterStatus,
  };

  return <ProjectDetailInfoContext.Provider value={bundle}>{children}</ProjectDetailInfoContext.Provider>;
}

export function useProjectDetailInfoContext() {
  const context = React.useContext(ProjectDetailInfoContext);

  if (!context) {
    throw new Error('useProjectDetailInfoContext: Use it after Provider');
  }

  return context;
}
