import { useQuery } from '@apollo/client';
import {
  CAccordion,
  CAccordionBody,
  CAccordionButton,
  CAccordionCollapse,
  CAccordionHeader,
  CAccordionItem,
  CButton,
  CCloseButton,
  CFormInput,
  CHeader,
  CProgress,
  CProgressBar,
} from '@coreui/react';
import { trimFractionDigits, useMount } from '@voithru/front-core';
import React from 'react';
import { useForm } from 'react-hook-form';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import ICN_DOWNLOAD from 'src/assets/icons/icn-download-24px.svg';
import ICN_PREVIEW from 'src/assets/icons/icn-preview-24px.svg';
import FileAnchor from 'src/components/atoms/FileAnchor';
import { ButtonBase } from 'src/components/atoms/styled/button';
import { Title } from 'src/components/atoms/styled/element';
import { Row } from 'src/components/atoms/styled/layout';
import { useContentsUploaderService } from 'src/components/context/ContentsUploaderContext';
import { useUploaderContext } from 'src/components/context/UploaderContext';
import FormModal from 'src/components/organisms/FormModal';
import { ProjectFileType, ProjectUpdateRequest } from 'src/types/api/Project';
import { File } from 'src/types/graphql';
import { isAxiosError } from 'src/utils/api/axios';
import { dateFormat } from 'src/utils/date';
import { handleEnterKeyDown } from 'src/utils/dom';
import useFileUpload from 'src/utils/hooks/useFileUpload';
import { getPreviewUrl, previewToWindow } from 'src/utils/preview';
import { getByteString } from 'src/utils/string';
import styled from 'styled-components';
import { GQL_PROJECT_DESCRIPTION_COLLAPSE } from './gql';
import { ProjectDescriptionCollapseQuery, ProjectDescriptionCollapseQueryVariables } from './gql.generated';
import { ProjectDescriptionCollapseWrapper } from './styled';

interface RelatedLinkFormData {
  url: string;
}

interface Props {
  projectId: number;

  transactionId: string;

  className?: string;
}

function ProjectDescriptionCollapse(props: Props) {
  const { projectId, transactionId, className } = props;

  const registerService = useContentsUploaderService();
  const context = useUploaderContext();

  const manager = React.useMemo(
    () => registerService.createUploadService(transactionId),
    [registerService, transactionId]
  );

  const { data, loading, refetch } = useQuery<
    ProjectDescriptionCollapseQuery,
    ProjectDescriptionCollapseQueryVariables
  >(GQL_PROJECT_DESCRIPTION_COLLAPSE, {
    variables: {
      id: projectId.toString(),
    },
    onError: errorLogger.error,
  });

  const refetched = React.useCallback(() => {
    refetch({
      id: projectId.toString(),
    });
  }, [projectId, refetch]);

  const project = React.useMemo(() => {
    if (!data) return;
    return data.project;
  }, [data]);

  const [isOpen, toggleOpen] = React.useReducer((x) => !x, false);
  const { uploaders, handleSelectFile } = useFileUpload('PROJECT', projectId);
  const handleProjectFile = React.useCallback(async () => {
    const uploaders = await handleSelectFile();
    if (!uploaders) {
      return;
    }

    uploaders.forEach((it) => {
      if (!it) {
        return;
      }

      it.addEventListener('done', async () => {
        try {
          const request: ProjectUpdateRequest = {
            projectFiles: [
              ...(project?.projectFiles?.map((projectFile, index) => ({
                id: parseInt(projectFile.id),
                projectId,
                fileId: projectFile.file.id,
                fileType: 'ATTACHED_FILE' as ProjectFileType,
                index: index,
              })) ?? []),
              {
                projectId: projectId,
                fileId: it.fileId!,
                fileType: 'ATTACHED_FILE' as ProjectFileType,
                index: project?.projectFiles?.length ?? 0,
              },
            ],
          };

          const res = await api.project.item(projectId).related(request);
          if (isAxiosError(res)) {
            throw res;
          }

          // Reload
          refetched();
        } catch (err) {
          errorLogger.error(err);
        }
      });

      manager.register(it);
    });
  }, [handleSelectFile, manager, project?.projectFiles, projectId, refetched]);

  const handleRemoveFile = React.useCallback(
    (id: string) => async () => {
      if (window.confirm('해당 파일을 삭제하시겠습니까')) {
        try {
          const res = await api.project.item(projectId).files.item(id).detach();
          if (isAxiosError(res)) {
            throw res;
          }
          refetched();
        } catch (error) {
          errorLogger.error(error);
        }
      }
    },
    [projectId, refetched]
  );

  const [isRelatedLinkOpen, setRelatedLinkOpen] = React.useState(false);
  const { register, handleSubmit } = useForm<RelatedLinkFormData>({ shouldUnregister: true });
  const handleRelatedLinks = React.useCallback(
    async (form: RelatedLinkFormData) => {
      const { url } = form;

      const httpURL = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;

      try {
        const request: ProjectUpdateRequest = {
          projectRelateLinks: [...(project?.projectRelateLinks ?? []), { url: httpURL }].map((it) => {
            return {
              id: it['id'] && parseInt(it['id']),
              url: it.url,
              projectId,
            };
          }),
        };
        const res = await api.project.item(projectId).related(request);
        if (isAxiosError(res)) {
          throw res;
        }

        refetched();

        setRelatedLinkOpen(false);
      } catch (error) {
        errorLogger.error(error);
      }
    },
    [projectId, setRelatedLinkOpen, project, refetched]
  );

  const handleDeleteRelatedLinks = React.useCallback(
    (id: string) => async () => {
      try {
        const request: ProjectUpdateRequest = {
          projectRelateLinks: project?.projectRelateLinks
            ?.filter((it) => it.id !== id)
            .map((it) => {
              return {
                id: parseInt(it.id),
                url: it.url,
                projectId,
              };
            }),
        };

        const res = await api.project.item(projectId).related(request);
        if (isAxiosError(res)) {
          throw res;
        }

        // Reload
        refetched();
      } catch (error) {
        errorLogger.error(error);
      }
    },
    [projectId, refetched, project]
  );

  const handleFilePreview = React.useCallback(async (file: File) => {
    const data = await getPreviewUrl({
      id: file.id,
      name: file.name ?? '',
    }).catch(errorLogger.error);

    if (!data) return;

    previewToWindow(data);
  }, []);

  const [isEditDescription, toggleEditDescription] = React.useState(false);
  const editDescriptionRef = React.useRef<HTMLTextAreaElement>(null);
  React.useEffect(() => {
    if (!isEditDescription) return;

    editDescriptionRef.current?.focus();
  }, [isEditDescription]);

  const handleDescription = React.useCallback(async () => {
    const description = editDescriptionRef.current?.value;

    if (!description) {
      return;
    }

    try {
      const resProject = await api.project.item(projectId).get();
      if (isAxiosError(resProject)) {
        throw resProject;
      }
      const request: ProjectUpdateRequest = {
        project: {
          ...resProject.data,
          description: description,
        },
      };

      const res = await api.project.item(projectId).related(request);
      if (isAxiosError(res)) {
        throw res;
      }
    } catch (error) {
      errorLogger.error(error);
    } finally {
      // Reload
      refetched();
      toggleEditDescription(false);
    }
  }, [projectId, refetched]);

  useMount(() => {
    context.register(transactionId, manager);
  });

  if (loading) return <div>loading...</div>;

  return (
    <ProjectDescriptionCollapseWrapper className={className}>
      <CAccordion>
        <CAccordionItem>
          <CAccordionHeader>
            <CAccordionButton collapsed={!isOpen} onClick={toggleOpen}>
              프로젝트 관련자료
            </CAccordionButton>
          </CAccordionHeader>

          <CAccordionCollapse visible={isOpen}>
            <CAccordionBody>
              <CHeader>
                <Title>프로젝트 관련 파일</Title>
                <CButton onClick={handleProjectFile}>첨부하기</CButton>
              </CHeader>
              {project?.projectFiles?.length === 0 && '-'}
              {project?.projectFiles?.map((it) => (
                <Row key={it.id} className={'file'}>
                  <FileAnchor
                    file={{
                      id: it.file.id,
                      name: it.file.name ?? '',
                    }}
                  >
                    {it.file.name}
                  </FileAnchor>
                  <Row>
                    {it.file.fileUploadStartDateTime && dateFormat(it.file.fileUploadStartDateTime, 'YYYY.MM.DD')}
                  </Row>
                  <Row>{it.file.volume && getByteString(it.file.volume)}</Row>
                  <Row>
                    <ButtonBase onClick={() => handleFilePreview(it.file)}>
                      <img src={ICN_PREVIEW} alt={'preview'} />
                    </ButtonBase>
                  </Row>
                  <Row>
                    <FileAnchor
                      file={{
                        id: it.file.id,
                        name: it.file.name ?? '',
                      }}
                    >
                      <img src={ICN_DOWNLOAD} alt={'download'} />
                    </FileAnchor>
                  </Row>
                  <CCloseButton onClick={handleRemoveFile(it.file.id)} />
                </Row>
              ))}
              {uploaders
                .filter((it) => it.status !== 'DONE')
                .map((it) => (
                  <Row key={it.namedFile.id} className={'file'}>
                    <span className={'file-name'}>{it.namedFile.name}</span>
                    <CProgress>
                      <CProgressBar value={trimFractionDigits(it.progress.value / it.progress.max, 3)} />
                    </CProgress>
                  </Row>
                ))}
              <CHeader>
                <Title>프로젝트 관련 링크</Title>
                <CButton onClick={() => setRelatedLinkOpen(true)}>추가하기</CButton>
              </CHeader>
              {project?.projectRelateLinks?.length === 0 && '-'}
              {project?.projectRelateLinks?.map((it) => (
                <Row key={it.id} className={'link'}>
                  <a href={`${it.url}`} target={'_blank'} rel={'noopener noreferrer'}>
                    {it.url}
                  </a>
                  <CCloseButton onClick={handleDeleteRelatedLinks(it.id)} />
                </Row>
              ))}
              <CHeader>
                <Title>특이사항</Title>
              </CHeader>
              <Row onDoubleClick={() => toggleEditDescription(true)}>
                {!isEditDescription && <span>{project?.description || '-'}</span>}
                {isEditDescription && (
                  <textarea
                    defaultValue={project?.description || '-'}
                    rows={5}
                    ref={editDescriptionRef}
                    onBlur={() => handleDescription()}
                    onKeyDown={(e) => handleEnterKeyDown(e, editDescriptionRef)}
                  />
                )}
              </Row>
            </CAccordionBody>
          </CAccordionCollapse>
        </CAccordionItem>
      </CAccordion>

      <FormModal
        title={'프로젝트 관련 링크 추가'}
        visible={isRelatedLinkOpen}
        onDismiss={() => setRelatedLinkOpen(false)}
        onSubmit={handleSubmit(handleRelatedLinks)}
      >
        <CFormInput {...register('url', { required: true })} defaultValue={'https://'} placeholder={'https://'} />
      </FormModal>
    </ProjectDescriptionCollapseWrapper>
  );
}

export default styled(ProjectDescriptionCollapse)``;
