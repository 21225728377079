import { gql } from '@apollo/client';

export const GQL_WORKER_MANAGE = gql`
  query WorkerManageModal($id: ID!) {
    jobResult(id: $id) {
      id
      projectId
      jobId
      job {
        name
      }
      productOrder {
        id
        productType
        productSource
        sourceLanguage
        translateLanguage
        deliveryType
        productOptionOrders {
          id
          type
        }
      }
      workerJobResultGrants {
        projectId
        jobResultId
        hermesAccountId
      }
    }
  }
`;
