import { cn, useMount } from '@voithru/front-core';
import React from 'react';
import { Link } from 'react-router-dom';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { ButtonWithBorder } from 'src/components/atoms/styled/button';
import { Row } from 'src/components/atoms/styled/layout';
import JobResultTaskList from 'src/components/molecules/JobResultTaskList';
import JobTaskList from 'src/components/molecules/JobTaskList';
import ProjectTaskList from 'src/components/molecules/ProjectTaskList';
import AppPaths from 'src/constants/AppPaths';
import { JobsResponse } from 'src/types/api/Job';
import { JobResultResponse } from 'src/types/api/JobResult';
import { ProjectResponse } from 'src/types/api/Project';
import { isAxiosError } from 'src/utils/api/axios';
import { ProjectTaskWrapper } from './styled';

interface ProjectWithSize {
  totalSize: number;
  element: ProjectResponse[];
}

interface JobWithSize {
  totalSize: number;
  element: JobsResponse[];
}

interface JobResultWithSize {
  totalSize: number;
  element: JobResultResponse[];
}

function TaskProgress() {
  const [taskType, setTaskType] = React.useState('project');

  const [project, setProject] = React.useState<ProjectWithSize>();
  const [job, setJob] = React.useState<JobWithSize>();
  const [jobResult, setJobResult] = React.useState<JobResultWithSize>();

  const getProject = React.useCallback(async () => {
    const res = await api.project.pages({
      page: 0,
      size: 10,
      status: 'PROCESSING',
    });

    if (isAxiosError(res)) {
      throw res;
    }

    const { data } = res;

    setProject({
      totalSize: data.totalSize,
      element: data.element,
    });
  }, []);

  const getJob = React.useCallback(async () => {
    const res = await api.jobs.pages({
      page: 0,
      size: 10,
      status: 'PROCESSING',
    });

    if (isAxiosError(res)) {
      throw res;
    }

    const { data } = res;

    setJob({
      totalSize: data.totalSize,
      element: data.element,
    });
  }, []);

  const getJobResult = React.useCallback(async () => {
    const res = await api.jobResult.pages({
      page: 0,
      size: 10,
      status: 'PROCESSING',
    });

    if (isAxiosError(res)) {
      throw res;
    }

    const { data } = res;

    setJobResult({
      totalSize: data.totalSize,
      element: data.element,
    });
  }, []);

  const getTasks = React.useCallback(async () => {
    try {
      await getProject();
      await getJob();
      await getJobResult();
    } catch (err) {
      errorLogger.error(err);
    }
  }, [getJob, getJobResult, getProject]);

  useMount(() => {
    getTasks();
  });

  return (
    <ProjectTaskWrapper>
      <Row>
        <Row>
          <ButtonWithBorder className={cn(taskType === 'project' && 'active')} onClick={() => setTaskType('project')}>
            프로젝트 ({project?.totalSize})
          </ButtonWithBorder>
          <ButtonWithBorder className={cn(taskType === 'content' && 'active')} onClick={() => setTaskType('content')}>
            작업콘텐츠 ({job?.totalSize})
          </ButtonWithBorder>
          <ButtonWithBorder className={cn(taskType === 'result' && 'active')} onClick={() => setTaskType('result')}>
            작업결과물 ({jobResult?.totalSize})
          </ButtonWithBorder>
        </Row>
        <Row>
          <Link to={`${AppPaths.projects.path}?tab=${taskType}&status=PROCESSING`}>더보기 &gt;</Link>
        </Row>
      </Row>
      {taskType === 'project' && project && project.element && <ProjectTaskList projectIds={project.element} />}
      {taskType === 'content' && job && job.element && <JobTaskList jobs={job.element} />}
      {taskType === 'result' && jobResult && jobResult.element && (
        <JobResultTaskList jobResultIds={jobResult.element} />
      )}
    </ProjectTaskWrapper>
  );
}

export default TaskProgress;
