import { gql } from '@apollo/client';

export const GQL_CONTENT_RESULT_FILES = gql`
  query ContentResultFiles($id: ID!) {
    job(id: $id) {
      jobFiles {
        id
        index
        fileType
        file {
          id
          name
          volume
        }
      }
    }
  }
`;
