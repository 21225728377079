import { Eventer, UnknownFunction } from '@voithru/front-core';
import React from 'react';

type KeyOfType<T, U> = { [P in keyof T]: T[P] extends U ? P : never }[keyof T];

export function preventEvent(e: React.SyntheticEvent | Event) {
  e.preventDefault();
  e.stopPropagation();
}

type FunctionType<T> = T extends UnknownFunction ? T : never;
type FunctionParameters<T> = Parameters<FunctionType<T>>;

class EventerGroup<E> {
  #eventer = new Map<keyof E, Eventer<UnknownFunction>>();

  public run = <K extends KeyOfType<E, UnknownFunction>>(key: K, ...args: FunctionParameters<E[K]>) => {
    return this.#eventer.get(key)?.run(...args);
  };

  public addEventListener = <K extends KeyOfType<E, UnknownFunction>>(key: K, fn: FunctionType<E[K]>) => {
    const eventer = this.getEventer(key);
    return eventer.addEventListener(fn);
  };

  private getEventer = (key: keyof E) => {
    const instance = this.#eventer.get(key) || new Eventer<UnknownFunction>();
    this.#eventer.set(key, instance);
    return instance;
  };
}

export default EventerGroup;
