import { CButton, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import { useMount } from '@voithru/front-core';
import React from 'react';
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { Title } from 'src/components/atoms/styled/element';
import { Row } from 'src/components/atoms/styled/layout';
import { useLoading } from 'src/components/context/ApplicationContext';
import { useAuth } from 'src/components/context/AuthContext';
import { GOOGLE_CLIENT_ID } from 'src/constants/configs';
import { APICredential } from 'src/types/api/Credential';
import { isAxiosError } from 'src/utils/api/axios';
import { handleEnterKeyDown } from 'src/utils/dom';
import { CredentialInfoWrapper } from './styled';

interface Props {
  accountId: number;
}
function CredentialInfo(props: Props) {
  const { accountId } = props;

  const { user } = useAuth();

  const loading = useLoading();

  const [editCredentialId, setEditCredentialId] = React.useState<number>();

  const editCredentialRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (editCredentialId === undefined) return;

    editCredentialRef.current?.focus();
  }, [editCredentialId]);

  const [credentialList, setCredentialList] = React.useState<APICredential[]>([]);
  const [credentialUser] = React.useState<Map<number, string>>(() => new Map());

  const loadCredentialList = React.useCallback(async () => {
    try {
      const res = await api.credential.get({ accountId });
      if (isAxiosError(res)) {
        throw res;
      }

      for (const item of res.data) {
        const result = await api.account.item(item.accountId).get();
        if (isAxiosError(result)) {
          throw result;
        }

        credentialUser.set(item.accountId, result.data.name);
      }

      setCredentialList(res.data);
      return;
    } catch (error) {
      errorLogger.error(error);
    }
  }, [accountId, credentialUser]);

  const handleGoogleLogin = React.useMemo(
    () =>
      loading(async (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
        const { code } = response;
        if (!code) {
          return;
        }

        try {
          const res = await api.credential.post({ code: code, accountId, redirectUri: window.location.origin });
          if (isAxiosError(res)) {
            throw res;
          }

          await loadCredentialList();

          return;
        } catch (error) {
          errorLogger.error(error);
        }
      }),
    [loading, accountId, loadCredentialList]
  );

  const handleGoogleLoginFailure = React.useCallback((error: unknown) => {
    if (typeof error === 'object' && typeof error?.['error'] === 'string') {
      if (error['error'] === 'popup_closed_by_user') {
        return;
      }
    }

    errorLogger.error(error);
    window.alert('구글 계정 연동에 실패했습니다.');

    return;
  }, []);

  const handleScriptLoadFailure = React.useCallback((error: unknown) => {
    errorLogger.error(error);
    window.alert('구글 계정 연동을 준비하는데 문제가 발생했습니다.');

    return;
  }, []);

  const handleCredentialName = React.useCallback(
    async (userCredentialId?: number) => {
      if (!userCredentialId || !editCredentialRef.current?.value) {
        return;
      }

      try {
        const res = await api.credential.item(userCredentialId).name({ name: editCredentialRef.current?.value });
        if (isAxiosError(res)) {
          throw res;
        }

        setEditCredentialId(undefined);

        await loadCredentialList();

        return;
      } catch (error) {
        errorLogger.error(error);
      }
    },
    [loadCredentialList]
  );

  const deleteCredential = React.useCallback(
    async (userCredentialId?: number) => {
      if (!userCredentialId) {
        return;
      }

      if (!window.confirm('연동이 해제되면 해당 드라이브를 통한 콘텐츠 추가가 불가합니다. 그래도 진행하시겠습니까?')) {
        return;
      }

      try {
        const res = await api.credential.item(userCredentialId).delete();
        if (isAxiosError(res)) {
          throw res;
        }

        await loadCredentialList();

        return;
      } catch (error) {
        errorLogger.error(error);
      }
    },
    [loadCredentialList]
  );

  useMount(() => {
    loadCredentialList();
  });

  return (
    <CredentialInfoWrapper>
      <Row>
        <Title>구글 드라이브 연동 관리</Title>

        {user?.id === accountId && (
          <GoogleLogin
            responseType={'code'}
            clientId={GOOGLE_CLIENT_ID}
            onSuccess={handleGoogleLogin}
            onFailure={handleGoogleLoginFailure}
            onScriptLoadFailure={handleScriptLoadFailure}
            prompt={'select_account'}
            scope={
              'https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/drive.readonly'
            }
            render={({ onClick, disabled }) => (
              <CButton onClick={onClick} disabled={disabled} color={'primary'}>
                구글 드라이브 연동하기
              </CButton>
            )}
          />
        )}
      </Row>

      <CTable bordered style={{ width: 'max-content', minWidth: '100%' }}>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell>드라이브 이름</CTableHeaderCell>
            <CTableHeaderCell>구글 계정</CTableHeaderCell>
            <CTableHeaderCell>연동자</CTableHeaderCell>
            <CTableHeaderCell>접근권한관리</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {credentialList.map((credential) => (
            <CTableRow key={credential.credentialId}>
              <CTableDataCell>
                Google Drive
                {editCredentialId === credential.id && (
                  <span>
                    <input
                      defaultValue={credential.name ?? ''}
                      ref={editCredentialRef}
                      onBlur={() => handleCredentialName(credential.id)}
                      onKeyDown={(e) => handleEnterKeyDown(e, editCredentialRef)}
                    />
                  </span>
                )}
                {editCredentialId !== credential.id && (
                  <span onDoubleClick={() => setEditCredentialId(credential.id)}> - {credential.name}</span>
                )}
              </CTableDataCell>
              <CTableDataCell>{credential.cloudEmail}</CTableDataCell>
              <CTableDataCell>
                {credential.accountId},{credentialUser.get(credential.accountId)}
              </CTableDataCell>
              <CTableDataCell>
                <CButton onClick={() => deleteCredential(credential.id)}>X</CButton>
              </CTableDataCell>
            </CTableRow>
          ))}
        </CTableBody>
      </CTable>
    </CredentialInfoWrapper>
  );
}

export default CredentialInfo;
