import { cilBellExclamation } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton, CModalBody, CModalFooter } from '@coreui/react';
import React from 'react';
import { Column, Row } from 'src/components/atoms/styled/layout';
import { Company } from 'src/types/api/Company';
import { JobsResponse } from 'src/types/api/Job';
import { JobResultResponse } from 'src/types/api/JobResult';
import { ProductOrder } from 'src/types/api/ProductOrder';
import { ProjectResponse } from 'src/types/api/Project';
import {
  ContentType,
  WorkRange,
  Source_Language,
  Translate_Language,
  ResultType,
  WorkTarget,
  AdditionalServices,
} from 'src/utils/translate';
import { modalStep } from '../../type';
import { PaymentSettlementTitleWrapper, PaymentSettlementBodyWrapper } from './styled';

interface Props {
  selectedJobResults: JobResultResponse[];
  productOrderMap: Map<number, ProductOrder>;
  jobsMap: Map<number, JobsResponse>;
  projectMap: Map<number, ProjectResponse>;
  company?: Company;
  handleStep: (nextStep: modalStep) => void;
  onClose: () => void;
}

function PaymentSettlement(props: Props) {
  const { selectedJobResults, productOrderMap, jobsMap, projectMap, company, handleStep, onClose } = props;

  return (
    <>
      <CModalBody className={'modalBody'}>
        <PaymentSettlementTitleWrapper>
          <CIcon icon={cilBellExclamation} size={'xxl'} />
          <Column>
            <span>
              <strong>{company?.name}</strong>으로 아래 총 {selectedJobResults.length}건에 대한 납품내역서를
              발행하시겠습니까?
            </span>
            <span>납품 내역서와 견적서를 확인해주세요 (미리보기).</span>
          </Column>
        </PaymentSettlementTitleWrapper>
        <PaymentSettlementBodyWrapper>
          <Row>
            <span>NO</span>
            <span>콘텐츠 타입</span>
            <span>프로젝트 명</span>
            <span>콘텐츠 명</span>
            <span>결과물 ID</span>
            <span>작업 범위</span>
            <span>원본 언어</span>
            <span>번역 언어</span>
            <span>납품형태</span>
            <span>작업대상</span>
            <span>부가서비스</span>
          </Row>
          {selectedJobResults.map((it, idx) => {
            const job = jobsMap.get(it.id) ?? null;
            const project = projectMap.get(it.id) ?? null;
            const productOrder = productOrderMap?.get(it.id) ?? null;

            return (
              <Row key={idx}>
                <span>{idx}</span>
                <span>{job && ContentType[job.category]}</span>
                <span>{project?.name}</span>
                <span>{job?.name}</span>
                <span>JR-{it.id}</span>
                <span>{productOrder && WorkRange[productOrder.productType]}</span>
                <span>{productOrder && Source_Language[productOrder.sourceLanguage]}</span>
                <span>{productOrder?.translateLanguage && Translate_Language[productOrder.translateLanguage]}</span>
                <span>{productOrder?.deliveryType && ResultType[productOrder?.deliveryType]}</span>
                <span>{productOrder?.productSource && WorkTarget[productOrder.productSource]}</span>
                <Column className={'additionalServices'}>
                  {productOrder?.options?.map((it) => {
                    return <span key={it.id}>{AdditionalServices[it.type]}</span>;
                  })}
                </Column>
              </Row>
            );
          })}
        </PaymentSettlementBodyWrapper>
      </CModalBody>
      <CModalFooter>
        <CButton
          color="secondary"
          onClick={() => {
            onClose();
          }}
        >
          취소하기
        </CButton>
        <CButton onClick={() => handleStep('SETTLEMENTPREVIEW')}>미리보기</CButton>
      </CModalFooter>
    </>
  );
}

export default PaymentSettlement;
