import { gql } from '@apollo/client';

export const GET_JOB_RESULTS = gql`
  query GetJobResults($ids: [ID!]) {
    jobResults(ids: $ids) {
      id
      status
      createDateTime
      project {
        id
        name
      }
      job {
        name
        category
        scheduledDeadlineDateTime
        doneDateTime
      }
      productOrder {
        productType
        sourceLanguage
        translateLanguage
        deliveryType
        productOptionOrders {
          type
        }
      }
      project {
        account {
          company {
            name
            manager {
              name
            }
          }
        }
        manager {
          name
        }
      }
      documents {
        status
      }
      jobResultFiles {
        file {
          id
          name
        }
      }
    }
  }
`;
