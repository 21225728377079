import { CButton, CFormCheck, CTableDataCell, CTableRow } from '@coreui/react';
import React from 'react';
import { MingloAccount } from 'src/api/minglo';

type GrantStatus = 'ALL' | 'PARTIAL' | 'NONE';

interface Props {
  data: MingloAccount;
  status: GrantStatus;

  onChangeStatus(status: GrantStatus): void;
  onClickDelete?(account: MingloAccount): void;
}

function MingloWorkerTableRow(props: Props) {
  const { data, status, onChangeStatus, onClickDelete } = props;

  const handleChange = React.useCallback(
    (status: GrantStatus) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const checked = e.currentTarget.checked;
      if (!checked) {
        return;
      }

      onChangeStatus(status);
    },
    [onChangeStatus]
  );

  return (
    <CTableRow>
      <CTableDataCell>{data.id}</CTableDataCell>
      <CTableDataCell>{data.name}</CTableDataCell>
      <CTableDataCell>{data.email}</CTableDataCell>
      <CTableDataCell>{data.phoneNumber}</CTableDataCell>
      <CTableDataCell>
        {status !== 'NONE' && (
          <>
            {status === 'ALL' && (
              <CButton color={'primary'} disabled>
                전체할당
              </CButton>
            )}
            {status === 'PARTIAL' && (
              <CButton color={'warning'} disabled>
                일부할당
              </CButton>
            )}
            <span>{'->'}</span>
          </>
        )}
      </CTableDataCell>
      <CTableDataCell>
        <CFormCheck
          name={`check-${data.id}`}
          type={'radio'}
          label={'전체할당'}
          disabled={status === 'ALL'}
          onChange={handleChange('ALL')}
        />
        <CFormCheck
          name={`check-${data.id}`}
          type={'radio'}
          label={'할당삭제'}
          disabled={status === 'NONE'}
          onChange={handleChange('NONE')}
        />
      </CTableDataCell>
      <CTableDataCell>
        {status === 'NONE' && (
          <CButton color={'danger'} onClick={() => onClickDelete?.(data)}>
            삭제
          </CButton>
        )}
      </CTableDataCell>
    </CTableRow>
  );
}

export default MingloWorkerTableRow;
