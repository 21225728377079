import { CFormCheck, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import { useForceUpdate, useMount } from '@voithru/front-core';
import React from 'react';
import { useForm } from 'react-hook-form';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import FormModal from 'src/components/organisms/FormModal';
import { CompanyResponse } from 'src/types/api/Company';
import { AccountResponseWithCompany, AccountsRequest, UserResponse } from 'src/types/api/User';
import { isAxiosError } from 'src/utils/api/axios';
import { AccountSelectTableWrapper } from './styled';

type AccountTableTypes = `account.${keyof UserResponse}`;
type CompanyTableTypes = `company.${keyof CompanyResponse}`;
const ACCOUNT_COLUMN_NAME: Partial<Record<AccountTableTypes, string>> = {
  'account.id': 'ID',
  'account.name': '이름',
  'account.email': '이메일',
  'account.phoneNumber': '전화번호',
  'account.department': '소속부서',
};

const COMPANY_COLUMN_NAME: Partial<Record<CompanyTableTypes, string>> = {
  'company.name': '회사이름',
  'company.country': '국가',
  'company.email': '담당자 이메일',
};

interface FormData {
  radio: string;
  checkbox: boolean[];
  query: string;
}
const FORM_SELECTED = { required: true };

interface Options {
  requestData: AccountsRequest;
  tableColumns?: (AccountTableTypes | CompanyTableTypes)[];
  inAbleQuery?: boolean;
  defaultQuery?: string;
}
const DEFAULT_TABLE_COLUMNS: (AccountTableTypes | CompanyTableTypes)[] = [
  'account.id',
  'account.name',
  'account.email',
];

interface Props {
  title: React.ReactNode;
  visible: boolean;
  onDismiss(): void;

  /**
   * @param target has only one array when multiple is false.
   */
  onSubmit(target: AccountResponseWithCompany[]): void;

  multiple?: boolean;
  options: Options;
}

let timer: ReturnType<typeof setTimeout>;

function AccountSelectModal(props: Props) {
  const { title, visible, onDismiss, onSubmit, multiple, options } = props;
  const { requestData, tableColumns = DEFAULT_TABLE_COLUMNS, inAbleQuery, defaultQuery = '' } = options;
  const forceUpdate = useForceUpdate();

  const companies = React.useMemo(() => new Map<number, CompanyResponse>(), []);
  const loadCompany = React.useCallback(
    async (id: number) => {
      const res = await api.companies.item(id).get();
      if (isAxiosError(res)) {
        throw res;
      }

      companies.set(id, res.data);
      forceUpdate();
      return res.data;
    },
    [companies, forceUpdate]
  );

  const [accounts, setAccounts] = React.useState<UserResponse[]>([]);

  const { register, getValues, setValue, handleSubmit, watch } = useForm<FormData>({ shouldUnregister: true });

  const loadAccounts = React.useCallback(async () => {
    const res = await api.account.get(requestData);
    if (isAxiosError(res)) {
      throw res;
    }

    res.data = res.data.sort((a, b) => a.id - b.id);
    res.data.map((it) => it.companyId && loadCompany(it.companyId).catch(errorLogger.error));

    setValue('checkbox', [
      ...res.data.map((it) => {
        return false;
      }),
    ]);

    setAccounts(res.data);
    return res.data;
  }, [loadCompany, requestData, setValue]);

  const loadAccountsByQuery = React.useCallback(
    async (query: string) => {
      const queryNameRes = await api.account.get({
        ...requestData,
        name: query,
      });
      if (isAxiosError(queryNameRes)) {
        throw queryNameRes;
      }

      const queryEmailRes = await api.account.get({
        ...requestData,
        email: query,
      });
      if (isAxiosError(queryEmailRes)) {
        throw queryEmailRes;
      }

      const accountMap = new Map<number, UserResponse>();

      for (const item of queryNameRes.data) {
        accountMap.set(item.id, item);
      }

      for (const item of queryEmailRes.data) {
        accountMap.set(item.id, item);
      }

      const accountArray = Array.from(accountMap.values());
      accountArray.sort((a, b) => a.id - b.id);
      for (const item of accountArray) {
        item.companyId && (await loadCompany(item.companyId).catch(errorLogger.error));
      }
      // accountArray.map((it) => it.companyId && loadCompany(it.companyId).catch(errorLogger.error));

      setValue('checkbox', [
        ...accountArray.map((it) => {
          return false;
        }),
      ]);

      setAccounts(accountArray);
    },
    [requestData, setValue, loadCompany]
  );

  const handleClickRow = React.useCallback(
    (target: string, index: number) => () => {
      if (!multiple) {
        setValue('radio', target);
        return;
      }

      const checkbox = getValues('checkbox') ?? [];
      checkbox[index] = !checkbox[index];
      setValue('checkbox', checkbox);
      forceUpdate();
    },
    [forceUpdate, getValues, multiple, setValue]
  );

  useMount(() => {
    if (defaultQuery) {
      loadAccountsByQuery(defaultQuery).catch(errorLogger.error);
      return;
    }

    loadAccounts().catch(errorLogger.error);
  });

  React.useEffect(() => {
    const subscription = watch((value, info) => {
      if (info.name !== 'query') return;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        if (!value.query) {
          loadAccounts().catch(errorLogger.error);
          return;
        }
        loadAccountsByQuery(value.query).catch(errorLogger.error);
      }, 200);
    });
    return () => subscription.unsubscribe();
  }, [loadAccounts, loadAccountsByQuery, watch]);

  const submit = React.useCallback(
    (form: FormData) => {
      const { radio, checkbox = [] } = form;

      if (!multiple) {
        const numbered = Number(radio);
        const target = accounts.find((it) => it.id === numbered);
        if (!target) {
          throw new Error('Invalid Selected');
        }

        const submitValue: AccountResponseWithCompany = { account: target };
        if (target.companyId) {
          submitValue.company = companies.get(target.companyId);
        }

        return onSubmit([submitValue]);
      }

      const selected = accounts.filter((_, index) => checkbox[index] === true);
      const submitValue = selected.map((it) => {
        if (it.companyId) {
          return { account: it, company: companies.get(it.companyId) };
        }

        return { account: it };
      });
      return onSubmit(submitValue);
    },
    [accounts, companies, multiple, onSubmit]
  );

  return (
    <FormModal title={title} visible={visible} onDismiss={onDismiss} onSubmit={handleSubmit(submit)}>
      {inAbleQuery && (
        <CTableRow>
          <input {...register('query')} defaultValue={defaultQuery} autoComplete={'off'} placeholder={'검색'} />
        </CTableRow>
      )}
      <AccountSelectTableWrapper>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell>#</CTableHeaderCell>
            {tableColumns.map((it) => (
              <React.Fragment key={it}>
                {it.startsWith('account') && <CTableHeaderCell>{ACCOUNT_COLUMN_NAME[it]}</CTableHeaderCell>}
                {it.startsWith('company') && <CTableHeaderCell>{COMPANY_COLUMN_NAME[it]}</CTableHeaderCell>}
              </React.Fragment>
            ))}
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {accounts.map((it, index) => (
            <CTableRow key={it.id} onClick={handleClickRow(`${it.id}`, index)}>
              <CTableDataCell>
                {!multiple && <CFormCheck {...register('radio', FORM_SELECTED)} type={'radio'} value={`${it.id}`} />}
                {multiple && <CFormCheck {...register(`checkbox.${index}`)} />}
              </CTableDataCell>
              {tableColumns.map((key) => (
                <React.Fragment key={key}>
                  {key.startsWith('account') && (
                    <CTableDataCell>
                      <span>{it[key.replace('account.', '')]}</span>
                    </CTableDataCell>
                  )}
                  {key.startsWith('company') && (
                    <CTableDataCell>
                      <span>{it.companyId && companies.get(it.companyId)?.[key.replace('company.', '')]}</span>
                    </CTableDataCell>
                  )}
                </React.Fragment>
              ))}
            </CTableRow>
          ))}
        </CTableBody>
      </AccountSelectTableWrapper>
    </FormModal>
  );
}

export default AccountSelectModal;
