import { CFormInput, CFormLabel } from '@coreui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { Row } from 'src/components/atoms/styled/layout';
import { User } from 'src/types/api/User';
import { isAxiosError } from 'src/utils/api/axios';
import { MasterAccountUpdateModalWrapper } from './styled';

interface FormData {
  name: string;
  department: string;
  phoneNumber: string;
  email: string;
}

interface Props {
  masterAccount: User;

  onDismiss(): void;
}

function MasterAccountUpdateModal(props: Props) {
  const { masterAccount, onDismiss } = props;

  const { register, handleSubmit, formState } = useForm<FormData>();
  const { errors } = formState;
  const [error, setError] = React.useState<unknown>();

  const submit = React.useCallback(
    async (form: FormData) => {
      if (!masterAccount.id) {
        return;
      }

      const { name, department, phoneNumber, email } = form;

      const request: User = {
        ...masterAccount,
        name: name,
        department: department,
        phoneNumber: phoneNumber,
        email: email,
      };

      try {
        if (masterAccount.email !== email) {
          const res = await api.signUp.isExistEmail(email);
          if (isAxiosError(res)) {
            throw res;
          }

          if (res.data) {
            return window.alert('중복된 이메일입니다.');
          }
        }

        const res = await api.account.item(masterAccount.id).put(request);

        if (isAxiosError(res)) {
          throw res;
        }

        onDismiss();
      } catch (error) {
        errorLogger.error(error);
        setError(error);
      }
    },
    [masterAccount, onDismiss]
  );

  return (
    <MasterAccountUpdateModalWrapper
      title={'마스터 계정 정보 수정하기'}
      visible
      onDismiss={onDismiss}
      onSubmit={handleSubmit(submit)}
    >
      <Row>
        <CFormLabel {...{ htmlFor: 'name' }}>이름</CFormLabel>
        <CFormInput
          {...register('name', { required: true })}
          defaultValue={masterAccount.name}
          invalid={Boolean(errors.name?.type)}
        />
      </Row>
      <Row>
        <CFormLabel {...{ htmlFor: 'department' }}>소속부서</CFormLabel>
        <CFormInput
          {...register('department', { required: true })}
          defaultValue={masterAccount.department}
          invalid={Boolean(errors.department?.type)}
        />
      </Row>
      <Row>
        <CFormLabel {...{ htmlFor: 'phoneNumber' }}>연락처</CFormLabel>
        <CFormInput
          {...register('phoneNumber', { required: true })}
          defaultValue={masterAccount.phoneNumber}
          invalid={Boolean(errors.phoneNumber?.type)}
        />
      </Row>
      <Row>
        <CFormLabel {...{ htmlFor: 'email' }}>로그인 ID</CFormLabel>
        <CFormInput
          {...register('email', { required: true })}
          defaultValue={masterAccount.email}
          invalid={Boolean(errors.email?.type)}
        />
      </Row>
      {error && <span className={'error'}>오류가 발생했습니다.</span>}
    </MasterAccountUpdateModalWrapper>
  );
}

export default MasterAccountUpdateModal;
