import { cilLockLocked, cilUser } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react';
import { Parameter, useMount } from '@voithru/front-core';
import React from 'react';
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import { MINGLO_GOOGLE_ID } from 'src/api/config';
import errorLogger from 'src/api/errorLogger';
import { useLoading } from 'src/components/context/ApplicationContext';
import { useAuth, useMingloAuth } from 'src/components/context/AuthContext';
import AppPaths from 'src/constants/AppPaths';

interface LoginFormData {
  id: string;
  pw: string;
}

function LoginMingloPage() {
  const auth = useAuth();
  const mingloAuth = useMingloAuth();
  const history = useHistory();
  const loading = useLoading();

  const location = useLocation();
  const { project } = React.useMemo(() => Parameter.parse(location.search), [location.search]);

  useMount(() => {
    if (auth.user?.role !== 'ADMIN') {
      return;
    }

    mingloAuth.loginAsPanoplay().then(() => {
      history.goBack();
    });
  });

  const { register, handleSubmit } = useForm<LoginFormData>({
    mode: 'onChange',
  });

  const submit = React.useMemo(
    () =>
      loading(async (data: LoginFormData) => {
        const { id, pw } = data;
        try {
          const request = { email: id, password: pw };
          await mingloAuth.login(request);

          const search = Parameter.toURLSearchParams({ project }).toString();
          history.replace({ pathname: AppPaths.workerRelay.path, search });
        } catch (error) {
          window.alert('로그인에 실패했습니다.');
        }
      }),
    [mingloAuth, history, loading, project]
  );

  const handleGoogleLogin = React.useCallback(
    async (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
      try {
        if ('code' in res) {
          throw new Error('Offline Response');
        }

        await mingloAuth.loginGoogle(res.getAuthResponse().id_token);

        const search = Parameter.toURLSearchParams({ project }).toString();
        history.replace({ pathname: AppPaths.workerRelay.path, search });
      } catch (error) {
        window.alert('로그인에 실패했습니다.');
        errorLogger.error(error);
      }
    },
    [mingloAuth, history, project]
  );

  if (auth.user) {
    return <div>관리자 계정으로 로그인한 상태입니다. 이전 페이지로 이동합니다.</div>;
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <form onSubmit={handleSubmit(submit)}>
                    <h1>Minglo</h1>
                    <p className="text-medium-emphasis">Sign In to your account</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        placeholder="Username"
                        autoComplete="username"
                        {...register('id', { required: true })}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        {...register('pw', {
                          required: true,
                        })}
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <CButton color="primary" className="px-4" type={'submit'}>
                          Login
                        </CButton>
                      </CCol>
                      <CCol xs={6} className="text-right">
                        <CButton
                          color="link"
                          className="px-0"
                          onClick={() => window.open('https://hermes.voithrucorp.com', '_blank')}
                        >
                          Forgot password?
                        </CButton>
                      </CCol>
                    </CRow>
                    <GoogleLogin onSuccess={handleGoogleLogin} onFailure={console.log} clientId={MINGLO_GOOGLE_ID} />
                  </form>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
}

export default LoginMingloPage;
