import {
  CButton,
  CCol,
  CContainer,
  CModalBody,
  CModalFooter,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import React from 'react';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { JobsResponse } from 'src/types/api/Job';
import { JobResultResponse, JobResultStatus } from 'src/types/api/JobResult';
import { ProductOrderResponse } from 'src/types/api/ProductOrder';
import { isAxiosError } from 'src/utils/api/axios';
import {
  WorkRange,
  Source_Language,
  Translate_Language,
  ResultType,
  WorkTarget,
  AdditionalServices,
} from 'src/utils/translate';

interface Props {
  targetJobs: JobsResponse[] | undefined;
  productOrderMap: Map<number, ProductOrderResponse>;
  onClose: () => void;
  targetJobResults?: JobResultResponse[];
}

function ApprovedJobResult(props: Props) {
  const { targetJobs, productOrderMap, onClose, targetJobResults } = props;

  const updateStatus = React.useCallback(async (id: number, status: JobResultStatus) => {
    try {
      const res = await api.jobResult.item(id).status(status);
      if (isAxiosError(res)) {
        throw res;
      }
    } catch (err) {
      errorLogger.error(err);
    }
  }, []);

  const updateJobResultStatus = React.useCallback(async () => {
    targetJobResults &&
      (await Promise.all(
        targetJobResults?.map(async (it) => {
          await updateStatus(it.id, 'REGISTERED');
        })
      ));
    onClose();
  }, [targetJobResults, onClose, updateStatus]);

  return (
    <>
      <CModalBody>
        <CContainer style={{ width: '1000px' }}>
          <CCol style={{ height: '700px', overflow: 'auto' }}>
            <CTable bordered>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell>작업 콘텐츠 ID</CTableHeaderCell>
                  <CTableHeaderCell>콘텐츠 명</CTableHeaderCell>
                  <CTableHeaderCell>작업 결과물 ID</CTableHeaderCell>
                  <CTableHeaderCell>작업범위</CTableHeaderCell>
                  <CTableHeaderCell>원본언어</CTableHeaderCell>
                  <CTableHeaderCell>번역언어</CTableHeaderCell>
                  <CTableHeaderCell>납품형태</CTableHeaderCell>
                  <CTableHeaderCell>작업대상</CTableHeaderCell>
                  <CTableHeaderCell>부가서비스</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {targetJobResults?.map((it, idx) => {
                  const targetJob = targetJobs?.filter((item) => item.id === it.jobId)[0];
                  const targetOrder = productOrderMap.get(it.productOrderId);
                  return (
                    <CTableRow key={idx}>
                      <CTableDataCell>CON - {it?.jobId}</CTableDataCell>
                      <CTableDataCell>{targetJob?.name}</CTableDataCell>
                      <CTableDataCell>JR - {it?.id}</CTableDataCell>
                      <CTableDataCell>{targetOrder && WorkRange[targetOrder.productType]}</CTableDataCell>
                      <CTableDataCell>{targetOrder && Source_Language[targetOrder.sourceLanguage]}</CTableDataCell>
                      <CTableDataCell>
                        {targetOrder?.translateLanguage && Translate_Language[targetOrder.translateLanguage]}
                      </CTableDataCell>
                      <CTableDataCell>
                        {targetOrder?.deliveryType && ResultType[targetOrder?.deliveryType]}
                      </CTableDataCell>
                      <CTableDataCell>
                        {targetOrder?.productSource && WorkTarget[targetOrder.productSource]}
                      </CTableDataCell>
                      <CTableDataCell style={{ display: 'block' }}>
                        {targetOrder?.options?.map((option, idx) => (
                          <div key={idx}>{AdditionalServices[option.type]}</div>
                        ))}
                      </CTableDataCell>
                    </CTableRow>
                  );
                })}
              </CTableBody>
            </CTable>
          </CCol>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton type={'button'} color={'secondary'} onClick={() => onClose()}>
          취소하기
        </CButton>
        <CButton className={'align-items-center'} color={'primary'} onClick={() => updateJobResultStatus()}>
          생성하기
        </CButton>
      </CModalFooter>
    </>
  );
}

export default ApprovedJobResult;
