import React from 'react';
import useNotificationManager from './useNotificationManager';

interface NotificationContextValue {
  notification: ReturnType<typeof useNotificationManager>;
}

const NotificationContext = React.createContext<NotificationContextValue | undefined>(undefined);

export const NotificationContextProvider: React.FunctionComponent = (props) => {
  const { children } = props;
  const notification = useNotificationManager();

  return <NotificationContext.Provider value={{ notification }}>{children}</NotificationContext.Provider>;
};

export function useNotificationContext() {
  const context = React.useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotificationContext: Use it after Provider');
  }

  return context;
}

export function useNotification() {
  const { notification } = useNotificationContext();
  return notification;
}
