import 'react-app-polyfill/stable';
import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './routes/AppRoutes';
import { ApplicationContextProvider } from './components/context/ApplicationContext';
import { AuthContextProvider } from './components/context/AuthContext';
import ContextProviders from './components/context/ContextProviders';
import { ContentsUploaderContextProvider } from './components/context/ContentsUploaderContext';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { UploaderContextProvider } from './components/context/UploaderContext';
import { API_GRAPHQL } from './constants/API';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from './components/atoms/ErrorBoundary';
import { NotificationContextProvider } from './components/context/NotificationContext';

const CONTEXTS = [
  ContentsUploaderContextProvider,
  NotificationContextProvider,
  AuthContextProvider,
  ApplicationContextProvider,
  UploaderContextProvider,
];

const client = new ApolloClient({
  uri: (arg) => API_GRAPHQL + `?name=${arg.operationName}`,
  cache: new InMemoryCache(),
  credentials: 'include',
});

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <ContextProviders contexts={CONTEXTS}>
            <App />
          </ContextProviders>
        </BrowserRouter>
      </ApolloProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
