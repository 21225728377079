import { CButton, CFormInput, CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react';
import { useMount } from '@voithru/front-core';
import React from 'react';
import { useForm } from 'react-hook-form';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { useLoading } from 'src/components/context/ApplicationContext';
import { Company } from 'src/types/api/Company';
import { UserResponse } from 'src/types/api/User';

interface FormData {
  search: string;
}

interface IProps {
  setTargetItem: (item: { account: UserResponse; company?: Company }) => void;
}

function ClientSearchModal(props: IProps) {
  const { setTargetItem } = props;
  const [visible, setVisible] = React.useState(false);
  const [searchList, setSearchList] = React.useState<{ account: UserResponse; company?: Company }[]>([]);
  const [filteredList, setFilteredList] = React.useState<{ account: UserResponse; company?: Company }[]>([]);
  const { handleSubmit, register } = useForm<FormData>();

  const loading = useLoading();

  useMount(() => {
    loading(async () => {
      await api.account.get({ roles: ['CLIENT_SUB', 'CLIENT_MASTER'] }).then(async (res) => {
        const list: { account: UserResponse; company?: Company }[] = await Promise.all(
          res.data.map(async (account) => {
            if (account.companyId) {
              return await api.company
                .item(Number(account.companyId))
                .get()
                .then(async (res) => {
                  const { data } = res;
                  return { account: account, company: data };
                });
            } else {
              return { account: account };
            }
          })
        );
        setSearchList(list);
        setFilteredList(list);
      });
    })().catch(errorLogger.error);
  });

  const submit = React.useCallback(
    (form: FormData) => {
      setFilteredList(searchList.filter((item) => item.account.name.includes(form.search)));
    },
    [searchList, setFilteredList]
  );

  const handleSelectItem = React.useCallback(
    (item: { account: UserResponse; company?: Company }) => {
      setTargetItem(item);
      setVisible(false);
    },
    [setTargetItem, setVisible]
  );

  return (
    <div className="align-items-center d-flex">
      <CButton style={{ width: 'max-content' }} onClick={() => setVisible(!visible)}>
        🔎
      </CButton>
      <CModal size={'lg'} visible={visible} onDismiss={() => setVisible(false)}>
        <CModalHeader onDismiss={() => setVisible(false)}>
          <CModalTitle>Client 검색하기</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
            <form onChange={handleSubmit(submit)}>
              <div className="mb-3">
                <CFormInput type={'text'} {...register('search')} />
              </div>
              {filteredList?.map((item, idx) => (
                <div
                  key={idx}
                  style={{ borderBottom: '1px solid black', padding: '2px', cursor: 'pointer' }}
                  onClick={() => handleSelectItem(item)}
                >{`${item.account.name}${item.company && `(${item.company.name}/${item.account.department})`}`}</div>
              ))}
            </form>
          </div>
        </CModalBody>
      </CModal>
    </div>
  );
}
export default ClientSearchModal;
