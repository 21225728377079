import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';
import { CheckBox } from '../styled/form';
import { Card, Row } from '../styled/layout';

export const SelectWrapper = styled(Row)`
  position: relative;

  background: ${getRGBA(colors.grey5)};
  border: 1px solid ${getRGBA(colors.grey20)};
  border-radius: 8px;
  min-width: 128px;
  height: 48px;
  box-sizing: border-box;

  &.disabled {
    & > ${Row} {
      cursor: default;
    }

    & > ${Card} {
      display: none;
    }
  }

  & > ${Row} {
    justify-content: center;
    align-items: center;

    padding: 0 15px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    cursor: pointer;

    & > span {
      flex: 1;
      appearance: none;
      background: transparent;
      border: none;
      width: 100%;

      color: ${getRGBA(colors.grey70)};
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &.placeholder {
        color: ${getRGBA(colors.grey50)};
      }
    }

    & > img {
      margin: 0 0 0 8px;
    }
  }

  & > img {
    z-index: 1;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }

  & > ${Card} {
    z-index: 1;
    position: absolute;
    top: calc(100% + 6px);
    left: 0;
    right: 0;

    display: block;
    padding: 6px 0;
    max-height: 227px;
    box-sizing: border-box;
    overflow: auto;

    & > ${Row} {
      justify-content: flex-start;
      align-items: center;

      padding: 7px 0;
      min-height: 36px;
      box-sizing: border-box;

      color: ${getRGBA(colors.grey60)};
      font-size: 14px;
      letter-spacing: -0.2px;

      cursor: pointer;

      &:hover {
        background: ${getRGBA(colors.grey20)};
        color: ${getRGBA(colors.grey80)};
      }

      & > ${CheckBox} {
        margin: 0 0 0 12px;
      }

      & > span {
        flex: 1;
        margin: 0 15px 0 5px;

        &:first-child {
          margin: 0 15px;
        }
      }
    }
  }
`;
