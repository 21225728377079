import React from 'react';
import { ColorTemplate } from 'src/theme/colors';
import { DocumentStatus } from 'src/types/api/Document';
import styled from 'styled-components';
import { Badge } from '../styled/element';

type Status = DocumentStatus | string;

type StatusColorPair = Record<Status, ColorTemplate>;
const STATUS_COLORS = Object.freeze<StatusColorPair>({
  CANCELLED: 'red',
  REGISTERED: 'green',
  PROCESSED: 'blue',
});

const STATUS_TRANSLATE = {
  CANCELLED: '발행취소',
  REGISTERED: '발행완료',
  PROCESSED: '정산완료',
};

interface Props {
  status: Status;
  disabled?: boolean;

  className?: string;
}

function IssuedStatusBadge(props: Props) {
  const { status, disabled, className } = props;

  const label = React.useMemo(() => {
    const key = status.toLocaleUpperCase();
    return STATUS_TRANSLATE[key] || '미발행';
  }, [status]);

  return (
    <Badge className={className} color={STATUS_COLORS[status] || 'grey'} disabled={disabled || status === 'DROP'}>
      {label}
    </Badge>
  );
}

export default styled(IssuedStatusBadge)``;
