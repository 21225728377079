import { useController, UseControllerProps } from 'react-hook-form';
import Select from '.';

type SelectProps = Omit<React.ComponentProps<typeof Select>, 'multiple' | 'value' | 'onChange'>;

function SelectSingle<T>(props: UseControllerProps<T> & SelectProps) {
  const { children, name, control, rules, shouldUnregister, defaultValue, ...restProps } = props;
  const { field } = useController({ name, control, rules, shouldUnregister, defaultValue });
  const { onChange, value } = field;

  return (
    <Select {...restProps} onChange={onChange} value={value as any}>
      {children}
    </Select>
  );
}

function SelectMultiple<T>(props: UseControllerProps<T> & SelectProps) {
  const { children, name, control, rules, shouldUnregister, defaultValue, ...restProps } = props;
  const { field } = useController({ name, control, rules, shouldUnregister, defaultValue });
  const { onChange, value } = field;

  return (
    <Select {...restProps} onChange={onChange} value={value as any} multiple>
      {children}
    </Select>
  );
}

const SelectForm = {
  Single: SelectSingle,
  Multiple: SelectMultiple,
};

export default SelectForm;
