import { useQuery } from '@apollo/client';
import { CCloseButton, CLink } from '@coreui/react';
import { selectFile, useMount } from '@voithru/front-core';
import { nanoid } from 'nanoid';
import React from 'react';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import ICN_PLUS from 'src/assets/icons/icn-circle-plus.svg';
import ICN_UPLOAD from 'src/assets/icons/icn-upload-24px.svg';
import { ButtonBase } from 'src/components/atoms/styled/button';
import { Column, Row } from 'src/components/atoms/styled/layout';
import { useAuth } from 'src/components/context/AuthContext';
import { useContentsUploaderService } from 'src/components/context/ContentsUploaderContext';
import { useUploaderContext } from 'src/components/context/UploaderContext';
import { JobFileRequest, JobRelateLink } from 'src/types/api/Job';
import { RequestFileType } from 'src/types/globalTypesFile/graphql';
import { JobFile } from 'src/types/graphql';
import { isAxiosError } from 'src/utils/api/axios';
import { NamedFile, downloadAPIFile } from 'src/utils/files';
import MultipartUpload from 'src/utils/MultipartUpload';
import { WorkSizeUnit } from 'src/utils/translate';
import styled from 'styled-components';
import { GQL_CONTENT_INFO } from './gql';
import { ContentInfoQuery, ContentInfoQueryVariables } from './gql.generated';
import { ContentInfoWrapper } from './styled';

interface Props {
  jobId: number;

  transactionId: string;

  className?: string;
}

type JobFileRequstWithName = { name: string; file: JobFileRequest };

function ContentInfo(props: Props) {
  const { jobId, transactionId, className } = props;

  const { user } = useAuth();

  const registerService = useContentsUploaderService();
  const context = useUploaderContext();

  const manager = React.useMemo(
    () => registerService.createUploadService(transactionId),
    [registerService, transactionId]
  );

  const [attachedFiles, setAttachedFiles] = React.useState<Omit<JobFile, 'job' | 'used' | 'fileId'>[]>([]);

  const [uploadFiles, setUploadFiles] = React.useState<JobFileRequstWithName[]>([]);

  const [relatedLinks, setRelatedLinks] = React.useState<JobRelateLink[]>([]);

  const [updateLinks, setUpdateLinks] = React.useState<JobRelateLink[]>([]);

  const [updatedWorkSize, setUpdatedWorkSize] = React.useState<number>(1);

  const linkRef = React.useRef<HTMLInputElement>(null);

  const { data, loading, error, refetch } = useQuery<ContentInfoQuery, ContentInfoQueryVariables>(GQL_CONTENT_INFO, {
    variables: {
      id: jobId.toString(),
    },
    onError: errorLogger.error,
  });

  const job = data?.job;

  React.useEffect(() => {
    if (job?.workSize) {
      setUpdatedWorkSize(job.workSize);
    } else {
      setUpdatedWorkSize(1);
    }
  }, [job?.workSize]);

  const [updateDescription, setUpdateDescription] = React.useState<string>(job?.description ?? '');

  const [isEdit, setIsEdit] = React.useState(false);

  const handleRemoveFile = React.useCallback(
    (item: Omit<JobFile, 'job' | 'used' | 'fileId'> | JobFileRequstWithName, type: 'ATTACHED' | 'UPLOAD') => {
      if (type === 'ATTACHED') {
        setAttachedFiles(attachedFiles.filter((it) => it.id !== (item as Omit<JobFile, 'job' | 'used' | 'fileId'>).id));
      }

      if (type === 'UPLOAD') {
        setUploadFiles(uploadFiles.filter((it) => it.file.fileId !== (item as JobFileRequstWithName).file.fileId));
      }
    },
    [attachedFiles, uploadFiles]
  );

  const handleRemoveLink = React.useCallback(
    (index: number, type: 'RELATED' | 'UPDATE') => {
      if (type === 'RELATED') {
        setRelatedLinks(relatedLinks.filter((it, idx) => idx !== index));
      }

      if (type === 'UPDATE') {
        setUpdateLinks(updateLinks.filter((it, idx) => idx !== index));
      }
    },
    [relatedLinks, updateLinks]
  );

  const handleFileUpload = React.useCallback(async () => {
    if (user?.role !== 'ADMIN') {
      window.alert('파일 업로드 권한이 없습니다.');
      return;
    }

    const file = await selectFile({ multiple: false });

    if (file) {
      const namedFile: NamedFile = {
        id: nanoid(),
        name: file[0].name,
        file: file[0],
      };

      const uploader = new MultipartUpload(namedFile);

      await uploader.ready('JOB');

      uploader.addEventListener('done', () => {
        const lastIndex = Math.max(...(job?.jobFiles?.map((a) => a.index ?? 0) ?? []));

        const data: JobFileRequstWithName = {
          name: file[0].name,
          file: {
            jobId,
            fileId: uploader.fileId ?? '',
            fileType: RequestFileType['ATTACHED_FILE'],
            index: lastIndex + 1,
          },
        };

        setUploadFiles([...(uploadFiles ?? []), data]);
      });

      manager.register(uploader);

      uploader.start();
    }
  }, [user?.role, manager, job?.jobFiles, jobId, uploadFiles]);

  const handleFileDownload = React.useCallback(async (item: Omit<JobFile, 'job' | 'used' | 'fileId'>) => {
    await downloadAPIFile({
      id: item.file?.id ?? '',
      name: item.file?.name ?? '',
    });
  }, []);

  const submit = React.useCallback(async () => {
    const jobFiles: JobFileRequest[] = attachedFiles?.map((it) => {
      return {
        jobId,
        fileId: it.file?.id ?? '',
        fileType: 'ATTACHED_FILE',
        index: it.index ?? 0,
      };
    });
    const contentFiles: JobFileRequest[] | undefined | null =
      job?.jobFiles
        ?.filter((it) => it.fileType === 'CONTENTS_FILE')
        .map((it) => {
          return {
            jobId,
            fileId: it.file?.id ?? '',
            fileType: 'CONTENTS_FILE',
            index: it.index ?? 0,
          };
        }) ?? undefined;

    const jobRelateLinks: JobRelateLink[] = relatedLinks?.map((it) => {
      return {
        id: it.id,
        jobId,
        url: it.url,
      };
    });

    const jobRes = await api.jobs.item(jobId).get();

    const res = await api.jobs.item(jobId).related({
      job: {
        ...jobRes.data,
        description: updateDescription,
      },
      jobFiles: contentFiles ? [...jobFiles, ...contentFiles] : jobFiles,
      jobRelateLinks: [...jobRelateLinks, ...updateLinks],
    });

    if (updatedWorkSize && updatedWorkSize !== job?.workSize) {
      await api.jobs.item(jobId).workSize(updatedWorkSize);
    }

    if (isAxiosError(res)) {
      throw res;
    }

    const fileUploadRes = await api.jobs.item(jobId).files.post(uploadFiles.map((it) => it.file));
    if (isAxiosError(fileUploadRes)) {
      throw fileUploadRes;
    }

    await refetch({
      id: jobId.toString(),
    });
    setIsEdit(false);
  }, [
    attachedFiles,
    job?.jobFiles,
    job?.workSize,
    relatedLinks,
    jobId,
    updateDescription,
    updateLinks,
    updatedWorkSize,
    uploadFiles,
    refetch,
  ]);

  const handleAddLink = React.useCallback(() => {
    if (!linkRef.current?.value) {
      return;
    }

    setUpdateLinks([
      ...updateLinks,
      {
        jobId,
        url:
          linkRef.current?.value.startsWith('http://') || linkRef.current?.value.startsWith('https://')
            ? linkRef.current?.value
            : `https://${linkRef.current?.value}`,
      },
    ]);
    if (linkRef.current) {
      linkRef.current.value = 'https://';
    }
  }, [updateLinks, jobId]);

  const handleCancle = React.useCallback(() => {
    refetch({
      id: jobId.toString(),
    });

    setIsEdit(false);
  }, [jobId, refetch, setIsEdit]);

  React.useEffect(() => {
    const typeCheck =
      data?.job?.jobFiles?.filter((it) => {
        return it.fileType === 'ATTACHED_FILE';
      }) ?? [];

    setAttachedFiles(typeCheck);
    setRelatedLinks(
      data?.job?.jobRelateLinks?.map((it) => {
        const link = {
          id: Number(it.id),
          jobId,
          url: it.url,
        };

        if (it.id) {
          link.id = Number(it.id);
        }

        return link;
      }) ?? []
    );
  }, [data, jobId]);

  React.useEffect(() => {
    setUpdateLinks([]);
    setUploadFiles([]);
  }, [isEdit]);

  useMount(() => {
    context.register(transactionId, manager);
  });

  if (loading) return <div>loading...</div>;
  if (error) {
    return (
      <div>
        <h2>{error.name}</h2>
        <h3>{error.message}</h3>
      </div>
    );
  }

  if (job) {
    return (
      <ContentInfoWrapper className={className}>
        <Row className={'title'}>
          <Row>작업 콘텐츠 기본 정보</Row>
          <Row>
            {isEdit && (
              <>
                <ButtonBase onClick={handleCancle}>취소하기</ButtonBase>
                <ButtonBase onClick={submit}>완료하기</ButtonBase>
              </>
            )}
            {!isEdit && <ButtonBase onClick={() => setIsEdit(true)}>수정하기</ButtonBase>}
          </Row>
        </Row>
        <Row>
          <span>작업 분량</span>
          {!isEdit && (
            <span>
              {job.workSize || '-'}
              {job.workSizeType && WorkSizeUnit[job.workSizeType]}
            </span>
          )}
          {isEdit && (
            <input
              onChange={(e) => {
                let workSize = Number(e.target.value);
                if (isNaN(workSize)) {
                  workSize = 1;
                } else if (workSize < 0) {
                  workSize = 1;
                }
                setUpdatedWorkSize(workSize);
              }}
              defaultValue={Number(job.workSize)}
              value={updatedWorkSize}
            />
          )}
        </Row>
        {!isEdit && (
          <>
            <Row>
              <span>작업 콘텐츠 관련 첨부파일</span>
              <Column>
                {attachedFiles?.map((it, index) => (
                  <ButtonBase key={index} onClick={() => handleFileDownload(it)}>
                    <span>{it.file?.name}</span>
                  </ButtonBase>
                ))}
              </Column>
            </Row>
            <Row>
              <span>작업 콘텐츠 관련 링크</span>
              <Column>
                {relatedLinks?.map((it, index) => (
                  <CLink key={index} href={it.url} target={'_blank'} rel={'noreferrer noopener'}>
                    {it.url}
                  </CLink>
                ))}
              </Column>
            </Row>
          </>
        )}
        {isEdit && (
          <>
            <Row>
              <span>작업 콘텐츠 관련 첨부파일</span>
              <Column>
                {attachedFiles?.map((it, index) => (
                  <Row key={it.id}>
                    <span>{it.file?.name}</span>
                    <CCloseButton onClick={() => handleRemoveFile(it, 'ATTACHED')} />
                  </Row>
                ))}
                {uploadFiles?.map((it, index) => (
                  <Row key={index}>
                    <span>{it.name}</span>
                    <CCloseButton onClick={() => handleRemoveFile(it, 'UPLOAD')} />
                  </Row>
                ))}
                <ButtonBase onClick={handleFileUpload}>
                  <span>파일 추가하기</span>
                  <img src={ICN_UPLOAD} alt={'upload'} />
                </ButtonBase>
              </Column>
            </Row>
            <Row>
              <span>작업 콘텐츠 관련 링크</span>
              <Column>
                <Row>
                  <input ref={linkRef} defaultValue={'https://'} placeholder={'https://'} />
                  <ButtonBase onClick={handleAddLink}>
                    <img src={ICN_PLUS} alt={'plus'} />
                  </ButtonBase>
                </Row>
                {relatedLinks?.map((it, index) => (
                  <Row key={index}>
                    <CLink key={index} href={it.url} target={'_blank'} rel={'noreferrer noopener'}>
                      {it.url}
                    </CLink>
                    <CCloseButton onClick={() => handleRemoveLink(index, 'RELATED')} />
                  </Row>
                ))}
                {updateLinks?.map((it, index) => (
                  <Row key={index}>
                    <CLink key={index} href={it.url} target={'_blank'} rel={'noreferrer noopener'}>
                      {it.url}
                    </CLink>
                    <CCloseButton onClick={() => handleRemoveLink(index, 'UPDATE')} />
                  </Row>
                ))}
              </Column>
            </Row>
            <Row></Row>
          </>
        )}
        <Row>
          <span>특이사항</span>
          {!isEdit && <span>{job.description}</span>}
          {isEdit && (
            <textarea
              defaultValue={job.description || '-'}
              rows={5}
              onChange={(e) => setUpdateDescription(e.target.value)}
            />
          )}
        </Row>
      </ContentInfoWrapper>
    );
  }

  return null;
}

export default styled(ContentInfo)``;
