import {StorageBase} from '@voithru/front-core';
import {ProductOrderId} from 'src/types/api/ProductOrder';
import {UserResponse} from 'src/types/api/User';

export const STORAGE_AUTH = new StorageBase<string>('partners:auth');
export const STORAGE_USER = new StorageBase<UserResponse['id']>('partners:user');

export function getTransactionOrdersStorage(key: string) {
  return new StorageBase<ProductOrderId[]>(`partners:project.orders.${key}`);
}

export function getTransactionJobResultsStorage(key: string) {
  return new StorageBase<{ jobId: number, jobResultId: number, fileId: string }[]>(`partners:jobResult.files.${key}`);
}

