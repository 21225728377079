import { once, PromiseReturnType, UnknownFunction } from '@voithru/front-core';
import React from 'react';

function useAwaiter() {
  const [isLoading, setLoading] = React.useState((): Promise<unknown>[] => []);

  const loading = React.useCallback(<F extends UnknownFunction>(func: F) => {
    const oneFunc = once(func);

    return async (...args: Parameters<F>): Promise<PromiseReturnType<F>> => {
      async function runner(): Promise<PromiseReturnType<F>> {
        return await oneFunc(...args);
      }

      const promise = runner().finally(() => setLoading((prev) => prev.filter((it) => it !== promise)));
      setLoading((prev) => prev.concat([promise]));

      return await promise;
    };
  }, []);

  return { isLoading, loading };
}

export default useAwaiter;
