import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useAuth } from 'src/components/context/AuthContext';
import 'src/scss/style.scss';
import AuthRoute from './AuthRoute';
import NoneAuthRoute from './NoneAuthRoute';
import DefaultLayout from 'src/components/templates/DefaultLayout';
import AppPaths from 'src/constants/AppPaths';
import { CSpinner } from '@coreui/react';
import Page404 from 'src/components/pages/Page404';
import Page500 from 'src/components/pages/Page500';
import AdminAccountsPage from 'src/components/pages/AdminAccountsPage';
import ClientAccountDetailPage from 'src/components/pages/ClientAccountDetailPage';
import ClientAccountsPage from 'src/components/pages/ClientAccountsPage';
import ClientSubAccountDetailPage from 'src/components/pages/ClientSubAccountDetailPage';
import ContentDetailPage from 'src/components/pages/ContentDetailPage';
import CreateJobPage from 'src/components/pages/CreateJobPage';
import CreateProjectPage from 'src/components/pages/CreateProjectPage';
import DashBoardPage from 'src/components/pages/DashboardPage';
import LoginMingloPage from 'src/components/pages/LoginMingloPage';
import LoginPage from 'src/components/pages/LoginPage';
import ProductsPage from 'src/components/pages/ProductsPage';
import ProjectDetailPage from 'src/components/pages/ProjectDetailPage';
import ProjectsAndContentsListPage from 'src/components/pages/ProjectsAndContentsListPage';
import RegisterPage from 'src/components/pages/RegisterPage';
import SettlementListPage from 'src/components/pages/SettlementListPage';
import SettlementManagementPage from 'src/components/pages/SettlementManagementPage';
import WorkerRelayPage from 'src/components/pages/WorkerRelayPage';
import HistoriesPage from 'src/components/pages/HistoriesPage';
import NotificationsPage from 'src/components/pages/NotificationsPage';
import AccountSettingPage from 'src/components/pages/AccountSettingPage';
import { useRRWebLogger } from '../utils/hooks/useRRWebLogger';

function App() {
  const auth = useAuth();
  useRRWebLogger();

  return (
    <React.Suspense fallback={<CSpinner color="primary" />}>
      <Switch>
        <Route exact path={AppPaths.minglo.path} component={LoginMingloPage} />
        <NoneAuthRoute exact path={AppPaths.login.path} component={LoginPage} />
        <NoneAuthRoute exact path={AppPaths.register.path} component={RegisterPage} />
        <NoneAuthRoute exact path={AppPaths.page404.path} component={Page404} />
        <NoneAuthRoute exact path={AppPaths.page500.path} component={Page500} />
        {!auth.user && <Route exact path={AppPaths.workerRelay.path} component={WorkerRelayPage} />}

        <DefaultLayout>
          <Switch>
            {/* Projects */}
            <AuthRoute exact path={AppPaths.dashboard.path} component={DashBoardPage} />
            <AuthRoute exact path={AppPaths.projects.path} component={ProjectsAndContentsListPage} />
            <AuthRoute exact path={AppPaths.createProject.path} component={CreateProjectPage} />
            <AuthRoute exact path={AppPaths.projectDetail.path} component={ProjectDetailPage} />

            {/* Contents */}
            <AuthRoute exact path={AppPaths.createJob.path} component={CreateJobPage} />
            <AuthRoute exact path={AppPaths.detailContent.path} component={ContentDetailPage} />

            {/* Account */}
            <AuthRoute exact path={AppPaths.clientAccounts.path} component={ClientAccountsPage} />
            <AuthRoute exact path={AppPaths.clientAccountDetail.path} component={ClientAccountDetailPage} />
            <AuthRoute exact path={AppPaths.clientSubAccountDetail.path} component={ClientSubAccountDetailPage} />
            <AuthRoute exact path={AppPaths.adminAccounts.path} component={AdminAccountsPage} />
            <AuthRoute exact path={AppPaths.accountSetting.path} component={AccountSettingPage} />
            <AuthRoute exact path={AppPaths.accountSettingMe.path} component={AccountSettingPage} />

            {/* Settlement */}
            <AuthRoute exact path={AppPaths.settlementManagement.path} component={SettlementManagementPage} />
            <AuthRoute exact path={AppPaths.settlementList.path} component={SettlementListPage} />

            {/* Product */}
            <AuthRoute exact path={AppPaths.products.path} component={ProductsPage} />

            {/* WorkerRelay */}
            <AuthRoute exact path={AppPaths.workerRelay.path} component={WorkerRelayPage} />

            {/* Histories */}
            <AuthRoute exact path={AppPaths.histories.path} component={HistoriesPage} />
            <AuthRoute exact path={AppPaths.notifications.path} component={NotificationsPage} />
          </Switch>
        </DefaultLayout>
      </Switch>
    </React.Suspense>
  );
}

export default App;
