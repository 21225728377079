import React from 'react';
import { CPagination, CPaginationItem } from '@coreui/react';

interface IPagination {
  currentPage: number;
  maxPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

function AppPagination(props: IPagination) {
  const { currentPage, maxPage, setCurrentPage } = props;

  const generateNumberArray = React.useCallback((start: number, end: number) => {
    const rt: number[] = [];
    for (let idx: number = start; idx <= end; idx++) {
      rt.push(idx);
    }
    return rt;
  }, []);

  const calculateShownPage = React.useCallback(
    (maxPage: number, currentPage: number) => {
      let startPage: number, endPage: number;

      if (maxPage <= 5) {
        startPage = 1;
        endPage = maxPage;
      } else {
        if (currentPage <= 2) {
          startPage = 1;
          endPage = 5;
        } else if (currentPage + 2 >= maxPage) {
          startPage = maxPage - 4;
          endPage = maxPage;
        } else {
          startPage = currentPage - 1;
          endPage = currentPage + 3;
        }
      }
      return generateNumberArray(startPage, endPage);
    },
    [generateNumberArray]
  );

  const handlePrevPage = React.useCallback(() => {
    if (currentPage <= 0) {
      return;
    }

    setCurrentPage((prev) => prev - 1);
  }, [currentPage, setCurrentPage]);

  const handleNextPage = React.useCallback(() => {
    if (currentPage >= maxPage - 1) {
      return;
    }

    setCurrentPage((prev) => prev + 1);
  }, [currentPage, maxPage, setCurrentPage]);

  return (
    <CPagination aria-label="Page navigation example">
      <CPaginationItem onClick={handlePrevPage}>Previous</CPaginationItem>
      {calculateShownPage(maxPage, currentPage).map((num) => {
        return (
          <CPaginationItem key={num} active={currentPage === num - 1} onClick={() => setCurrentPage(num - 1)}>
            {num}
          </CPaginationItem>
        );
      })}
      <CPaginationItem onClick={handleNextPage}>Next</CPaginationItem>
    </CPagination>
  );
}

export default AppPagination;
