/**
 * Theme Colors as RGB
 *
 * Usage
 * ```
 * styled.div`background: rgba(${grey5})`;
 * styled.div`background: rgba(${grey5}, 0.5)`;
 *
 * import { getRGBA } from 'src/utils/styles';
 * styled.div`background: ${getRGBA(grey5, 0.5)}`;
 * ```
 */

const white = '255, 255, 255';
const black = '0, 0, 0';

const grey5 = '249, 250, 251'; // F9FAFB
const grey10 = '242, 244, 246'; // F2F4F6
const grey20 = '229, 232, 235'; // E5E8EB
// const grey30 = '';
const grey40 = '176, 184, 193'; // B0B8C1
const grey50 = '139, 149, 161'; // 8B95A1
const grey60 = '107, 118, 132'; // 6B7684
const grey70 = '78, 89, 104'; // 4E5968
const grey80 = '51, 61, 75'; // 333D4B
const grey90 = '44, 44, 44'; //2C2C2C
// const grey90 = '';

const red10 = '255, 229, 229'; // FFE5E5
const red20 = '255, 199, 195'; // FFC7C3
const red50 = '255, 87, 87'; // FF5757
const red60 = '255, 65, 53'; // FF4135

const blue20 = '218, 227, 255'; // DAE3FF
const blue50 = '101, 126, 255'; // 657EFF

const yellow20 = '255, 240, 189'; // FFF0BD
const yellow80 = '220, 145, 0'; // DC9100

const green20 = '195, 248, 210'; // C3F8D2
const green50 = '42, 215, 132'; // 2AD784
const green60 = '19, 189, 68'; // 13BD44

const colors = Object.freeze({
  white,
  black,

  grey5,
  grey10,
  grey20,
  // grey30,
  grey40,
  grey50,
  grey60,
  grey70,
  grey80,
  grey90,

  red10,
  red20,
  red50,
  red60,

  blue20,
  blue50,

  yellow20,
  yellow80,

  green20,
  green50,
  green60,
});

export default colors;

// Union for design system color template
export type ColorTemplate = 'red' | 'yellow' | 'green' | 'blue' | 'grey' | 'black';
