import { useQuery } from '@apollo/client';
import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CHeaderBrand,
  CInputGroup,
  CRow,
} from '@coreui/react';
import { Parameter, useMount } from '@voithru/front-core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { useAuth } from 'src/components/context/AuthContext';
import AccountSelectModal from 'src/components/organisms/AccountSelectModal';
import { PROJECT_CATEGORY } from 'src/constants/category';
import { ProjectCategory, ProjectFile, ProjectRelateLinkResponse, ProjectUpdateRequest } from 'src/types/api/Project';
import { AccountResponseWithCompany, UserResponse } from 'src/types/api/User';
import { ProjectFile as ProjectFileGqlType } from 'src/types/graphql';
import { isAxiosError } from 'src/utils/api/axios';
import { divideDateFromTime } from 'src/utils/date';
import useFileUpload from 'src/utils/hooks/useFileUpload';
import { PageState } from '../../type';
import { GQL_BASIC_INFO_PROJECT } from './gql';
import { ProjectCreateInfoQuery, ProjectCreateInfoQueryVariables } from './gql.generated';

interface FormData {
  accountId: string;
  clientName: string | null;
  companyName?: string;
  companyMajor?: string | null;
  phoneNumber?: string | null;
  email?: string | null;

  projectName: string;
  contentsType: ProjectCategory;

  startDateTime?: string;
  endDateTime?: string;

  projectManager?: string;

  relatedLinks?: string;

  description?: string;

  projectManagerId?: string;
}

interface IProps {
  projectId?: number;
}

function ProjectCreateInfo(props: IProps) {
  const { projectId } = props;

  const [manager, setManager] = React.useState<UserResponse>();
  const [client, setClient] = React.useState<AccountResponseWithCompany>();
  const [origFiles, setOrigFiles] =
    React.useState<Omit<ProjectFileGqlType, 'projectId' | 'fileId' | 'index' | 'project'>[]>();
  const [links, setLinks] = React.useState<{ id?: number; origId?: number; url: string; name: string }[]>([]);

  const { pathname, search, state: pageState = {} } = useLocation<PageState>();
  const history = useHistory<PageState>();

  const { register, handleSubmit, reset, getValues, setValue, watch } = useForm<FormData>();
  const { uploaders, handleSelectFile } = useFileUpload('PROJECT', projectId);

  const { data, loading } = useQuery<ProjectCreateInfoQuery, ProjectCreateInfoQueryVariables>(GQL_BASIC_INFO_PROJECT, {
    variables: {
      id: projectId?.toString() || '',
    },
    onError: errorLogger.error,
    skip: !projectId || isNaN(projectId),
  });

  const projectGql = React.useMemo(() => {
    if (!data) return;
    return data.project;
  }, [data]);

  const { user } = useAuth();

  React.useEffect(() => {
    if (!projectGql) return;
    if (projectGql && projectGql.projectFiles) {
      setOrigFiles(projectGql.projectFiles);
    }

    if (projectGql && projectGql?.manager) {
      setValue('projectManagerId', projectGql?.manager.id);
      setValue('projectManager', projectGql?.manager.name || '');
    }

    if (projectGql && projectGql?.account && projectGql?.account.id !== user?.id?.toString()) {
      const clientAccount = projectGql?.account;

      setValue('accountId', clientAccount.id);
      setValue('clientName', clientAccount.name ?? '');
      setValue('phoneNumber', clientAccount.phoneNumber);
      setValue('email', clientAccount.email);
      setValue('companyName', clientAccount.company?.name);
      setValue('companyMajor', clientAccount.department);
    }
  }, [data, projectGql, setValue, user?.id]);

  useMount(() => {
    if (!projectId) return;
    getByProjectId(projectId);
  });

  React.useEffect(() => {
    if (manager) {
      setValue('projectManagerId', manager.id.toString());
      setValue('projectManager', manager.name);
    }
    if (client) {
      setValue('accountId', client.account.id.toString());
      setValue('clientName', client.account.name);
      setValue('phoneNumber', client.account.phoneNumber);
      setValue('email', client.account.email);
      setValue('companyName', client.company?.name);
      setValue('companyMajor', client.account.department);
    }
  }, [manager, client, setValue]);

  const loadLinks = React.useCallback(async (projectId: number) => {
    const res = await api.project.item(projectId).projectRelateLinks.get();
    if (isAxiosError(res)) {
      throw res;
    }

    const data = res.data.map((it) => ({ origId: it.id, url: it.url, name: it.url }));
    setLinks([...data]);
  }, []);

  const getByProjectId = React.useCallback(
    async (projectId: number) => {
      const res = await api.project.item(projectId).get();
      if (isAxiosError(res)) {
        throw res;
      }

      const { data } = res;

      if (data.managerId) {
        const managerRes = await api.account.item(data.managerId).get();
        if (isAxiosError(managerRes)) {
          throw managerRes;
        }
        setManager(managerRes.data);
      }

      const project: FormData = {
        accountId: data.accountId?.toString() || '',
        projectName: data.name || '',
        description: data.description,
        contentsType: data.category || 'VIDEO',
        startDateTime: divideDateFromTime(data.requestedStartDateTime),
        endDateTime: divideDateFromTime(data.requestedDeadlineDateTime),
        clientName: '',
        phoneNumber: '',
        email: '',
        projectManager: '',
      };

      reset(project);

      // loadFiles();
      loadLinks(projectId);

      if (data.accountId) {
        const accoutRes = await api.account.item(data.accountId).get();
        if (isAxiosError(accoutRes)) {
          throw accoutRes;
        }
        setClient({ ...client, account: accoutRes.data });
        if (accoutRes.data.companyId) {
          const companyRes = await api.company.item(accoutRes.data.companyId).get();
          if (isAxiosError(companyRes)) {
            throw companyRes;
          }
          setClient({ account: accoutRes.data, company: companyRes.data });
        }
      }
    },
    [client, loadLinks, reset]
  );
  // const loadFiles = async () => {
  //   await api.project
  //     .item(projectId)
  //     .files.get()
  //     .then((res) => setOrigFiles(res.data));
  // };

  const addLinks = React.useCallback(() => {
    const newLink = getValues('relatedLinks');

    if (!newLink) {
      return;
    }

    setLinks([
      ...links,
      {
        name: newLink,
        url: newLink.startsWith('http://') || newLink.startsWith('https://') ? newLink : `https://${newLink}`,
      },
    ]);
  }, [getValues, links, setLinks]);

  const onSubmit = React.useCallback(
    async (data: FormData) => {
      if (!data.accountId || isNaN(Number(data.accountId))) {
        window.alert('고객 정보를 입력해주세요.');
        return;
      }

      let targetProjectId: number;
      if (!projectId || isNaN(projectId)) {
        const initProject = await api.project.post({ accountId: Number(data.accountId) });
        // id is not null
        targetProjectId = initProject.data.id!! as number;
      } else {
        targetProjectId = projectId;
      }

      const projectData: ProjectUpdateRequest = {
        project: {
          accountId: Number(data.accountId),
          id: targetProjectId,
          name: data.projectName,
          description: data.description,
          managerId: data.projectManagerId ? Number(data.projectManagerId) : undefined,
          category: data.contentsType,
          requestedStartDateTime: data.startDateTime && new Date(data.startDateTime).toISOString(),
          requestedDeadlineDateTime: data.endDateTime && new Date(data.endDateTime).toISOString(),
        },
        projectRelateLinks: links.map((it) => {
          const link: Partial<ProjectRelateLinkResponse> = {
            projectId: targetProjectId,
            url: it.url,
          };

          if (it.origId) {
            link.id = it.origId;
          }

          return link as ProjectRelateLinkResponse;
        }),
        projectFiles: projectGql?.projectFiles?.map(
          (it, index): ProjectFile => ({
            projectId: targetProjectId,
            index: index,
            fileId: it.file.id.toString(),
            fileType: 'ATTACHED_FILE',
          })
        ),
      };

      const uploadedFiles = uploaders.map(
        (it, index): ProjectFile => ({
          projectId: targetProjectId,
          index: (projectData.projectFiles?.length ?? 1) - 1 + index,
          fileId: it.fileId!.toString(),
          fileType: 'ATTACHED_FILE',
        })
      );
      projectData.projectFiles = [...(projectData.projectFiles || []), ...uploadedFiles];
      if (projectData.projectFiles.length === 0) {
        delete projectData.projectFiles;
      }

      try {
        const res = await api.project.item(targetProjectId).related(projectData);
        if (isAxiosError(res)) {
          throw res;
        }

        const nextSearch = Parameter.toURLSearchParams(search);
        nextSearch.append('id', targetProjectId.toString());
        history.replace({ pathname, search: nextSearch.toString(), state: { ...pageState, step: 'ORDER' } });
      } catch (err) {
        errorLogger.error(err);
      }
    },
    [projectId, links, projectGql?.projectFiles, uploaders, search, history, pathname, pageState]
  );

  const [showClientModal, toggleClientModal] = React.useState(false);
  const handleClientClick = React.useCallback(() => toggleClientModal(true), []);
  const handleClientSubmit = React.useCallback((users: AccountResponseWithCompany[]) => {
    if (!users[0]) {
      return;
    }

    setClient(users[0]);
    toggleClientModal(false);
  }, []);

  const [showPMModal, togglePMModal] = React.useState(false);
  const handlePMClick = React.useCallback(() => togglePMModal(true), []);
  const handlePMSubmit = React.useCallback((users: AccountResponseWithCompany[]) => {
    if (!users[0]) {
      return;
    }

    setManager(users[0].account);
    togglePMModal(false);
  }, []);

  const cancel = React.useCallback(() => {
    history.goBack();
  }, [history]);

  if (loading) return <div>loading...</div>;

  const clientName = watch('clientName');
  return (
    <div className="bg-light d-flex flex-column justify-content-center">
      <CHeaderBrand>프로젝트 등록하기 1/4 - 프로젝트 기본 정보 입력</CHeaderBrand>
      <CContainer>
        <div style={{ width: '500px', paddingBottom: '20px' }}>
          <form onSubmit={(e) => e.preventDefault()}>
            <div>
              <div style={{ fontSize: '18px' }}>고객 정보</div>
              <div style={{ padding: '10px' }}>
                <div style={{ position: 'relative' }}>
                  <CRow className={'mb-3'}>
                    <CFormLabel className={'col-sm-2 col-form-label'}>담당자</CFormLabel>
                    <CCol>
                      <CInputGroup>
                        <CFormInput {...register('clientName')} placeholder={'담당자 명'} />
                        <CButton type={'button'} color={'secondary'} onClick={handleClientClick}>
                          🔎
                        </CButton>
                      </CInputGroup>
                    </CCol>
                  </CRow>
                  {showClientModal && (
                    <AccountSelectModal
                      title={'Client 검색하기'}
                      visible
                      onDismiss={() => toggleClientModal(false)}
                      onSubmit={handleClientSubmit}
                      options={{
                        requestData: { roles: ['CLIENT_MASTER', 'CLIENT_SUB'] },
                        tableColumns: ['account.id', 'account.name', 'company.name', 'account.department'],
                        inAbleQuery: true,
                        defaultQuery: clientName || '',
                      }}
                    />
                  )}
                </div>
                <CRow className={'mb-3'}>
                  <CFormLabel className={'col-sm-2 col-form-label'}>회사명</CFormLabel>
                  <CCol>
                    <CFormInput {...register('companyName')} disabled placeholder={'회사명'} />
                  </CCol>
                </CRow>
                <CRow className={'mb-3'}>
                  <CFormLabel className={'col-sm-2 col-form-label'}>소속부서</CFormLabel>
                  <CCol>
                    <CFormInput {...register('companyMajor')} disabled placeholder={'소속부서'} />
                  </CCol>
                </CRow>
                <CRow className={'mb-3'}>
                  <CFormLabel className={'col-sm-2 col-form-label'}>연락처</CFormLabel>
                  <CCol>
                    <CFormInput {...register('phoneNumber')} disabled placeholder={'연락처'} />
                  </CCol>
                </CRow>
                <CRow className={'mb-3'}>
                  <CFormLabel className={'col-sm-2 col-form-label'}>이메일</CFormLabel>
                  <CCol>
                    <CFormInput {...register('email')} disabled placeholder={'이메일'} />
                  </CCol>
                </CRow>
              </div>
            </div>
            <div>
              <div style={{ fontSize: '18px' }}>프로젝트 정보</div>
              <div style={{ padding: '10px' }}>
                <div className="align-items-center" style={{ marginBottom: '15px' }}>
                  <div className="mb-3">
                    <CFormLabel>프로젝트명(필수)</CFormLabel>
                    <CFormInput type={'text'} {...register('projectName', { required: true })} />
                  </div>
                </div>
                <div className="align-items-center" style={{ marginBottom: '15px' }}>
                  <CForm>
                    <div className="mb-3">
                      <CFormLabel>콘텐츠 유형(필수)</CFormLabel>
                      <CFormSelect {...register('contentsType', { required: true })}>
                        {Object.keys(PROJECT_CATEGORY).map((it) => (
                          <option key={it} value={it}>
                            {PROJECT_CATEGORY[it as ProjectCategory]}
                          </option>
                        ))}
                      </CFormSelect>
                    </div>
                  </CForm>
                </div>
                <div className="align-items-center" style={{ marginBottom: '15px' }}>
                  <div className="mb-3">
                    <CFormLabel>희망 작업 시작일/완료일</CFormLabel>
                    <CFormInput type={'date'} {...register('startDateTime')} />
                  </div>
                </div>
                <div className="align-items-center" style={{ marginBottom: '15px' }}>
                  <div className="mb-3">
                    <CFormInput type={'date'} {...register('endDateTime')} />
                  </div>
                </div>
                <div style={{ position: 'relative' }}>
                  <CCol className={'mb-3'}>
                    <CFormLabel className={'col-sm-2 col-form-label'}>담당 PM</CFormLabel>
                    <CInputGroup>
                      <CFormInput {...register('projectManager')} disabled placeholder={'PM 검색하기'} />
                      <CButton type={'button'} color={'secondary'} onClick={handlePMClick}>
                        🔎
                      </CButton>
                    </CInputGroup>
                  </CCol>
                  {showPMModal && (
                    <AccountSelectModal
                      title={'Admin 담당자 검색'}
                      visible
                      onDismiss={() => togglePMModal(false)}
                      onSubmit={handlePMSubmit}
                      options={{ requestData: { roles: ['ADMIN'] } }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div>
              <div style={{ fontSize: '18px' }}>프로젝트 부가정보</div>
              <div style={{ padding: '10px' }}>
                <div>작업 첨부파일(선택)</div>
                <CButton onClick={handleSelectFile}>첨부파일</CButton>
                {origFiles?.map((it, idx) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        minWidth: '400px',
                        justifyContent: 'space-between',
                        padding: '2px',
                        borderBottom: '1px solid black',
                      }}
                      key={idx}
                    >
                      <div>{it.file.name}</div>
                      <div
                        onClick={() => setOrigFiles(origFiles.filter((origFile) => origFile.file.id !== it.file.id))}
                      >
                        X
                      </div>
                    </div>
                  );
                })}
                {uploaders.map((it) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        minWidth: '400px',
                        justifyContent: 'space-between',
                        padding: '2px',
                        borderBottom: '1px solid black',
                      }}
                      key={it.fileId}
                    >
                      <div>{it.namedFile.name}</div>
                      <div onClick={() => it.cancel()}>X</div>
                    </div>
                  );
                })}
                <div style={{ position: 'relative' }}>
                  <div className="align-items-center" style={{ marginBottom: '15px' }}>
                    <div className="mb-3">
                      <CFormLabel>작업 관련 링크(선택)</CFormLabel>
                      <CFormInput
                        type={'text'}
                        placeholder={'https://'}
                        defaultValue={'https://'}
                        {...register('relatedLinks')}
                      />
                    </div>
                  </div>
                  <CButton
                    style={{
                      backgroundColor: 'lightgray',
                      border: '0',
                      position: 'absolute',
                      right: '-35px',
                      bottom: '1px',
                      outline: '0',
                    }}
                    onClick={addLinks}
                  >
                    +
                  </CButton>
                </div>
                {links?.map((it, idx) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        minWidth: '400px',
                        justifyContent: 'space-between',
                        padding: '2px',
                        borderBottom: '1px solid black',
                      }}
                      key={idx}
                    >
                      <div>{it.url}</div>
                      <div onClick={() => setLinks(links.filter((link) => link.url !== it.url))}>X</div>
                    </div>
                  );
                })}
                <div className="align-items-center" style={{ marginBottom: '15px' }}>
                  <CForm>
                    <div className="mb-3">
                      <CFormLabel>특이사항(선택)</CFormLabel>
                      <CFormTextarea
                        placeholder={'작업 추가 요청 사항, 특이사항 등을 입력해주세요'}
                        {...register('description')}
                      />
                    </div>
                  </CForm>
                </div>
              </div>
            </div>
            <div>
              <CButton color="secondary" onClick={() => cancel()}>
                취소하기
              </CButton>
              <CButton style={{ width: '80px' }} onClick={handleSubmit(onSubmit)}>
                다음
              </CButton>
            </div>
          </form>
        </div>
      </CContainer>
    </div>
  );
}

export default ProjectCreateInfo;
