import { gql } from '@apollo/client';

export const GQL_JOBRESULT_STATUS = gql`
  query JobResultStatus($ids: [ID!]) {
    jobResults(ids: $ids) {
      id
      job {
        name
        scheduledDeadlineDateTime
        manager {
          name
        }
      }
      productOrder {
        productType
        sourceLanguage
        translateLanguage
        deliveryType
        productSource
        productOptionOrders {
          type
        }
      }
      status
      requestedReviewDeadlineDateTime
    }
  }
`;
