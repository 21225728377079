import { gql } from '@apollo/client';

export const GQL_ALARM_ITEM = gql`
  query Notification($job: Boolean = false, $job_result: Boolean = false, $entityId: ID!) {
    job(id: $entityId) @include(if: $job) {
      name

      id

      projectId
    }

    jobResult(id: $entityId) @include(if: $job_result) {
      job {
        name
      }

      projectId
    }
  }
`;
