import { ButtonBase } from 'src/components/atoms/styled/button';
import { Column } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const PaymentSettlementModalWrapper = styled(Column)`
  & > ${ButtonBase} {
    padding: 3px 11px;

    background: ${getRGBA(colors.green60)};
    border-radius: 4px;

    color: ${getRGBA(colors.white)};
  }
`;
