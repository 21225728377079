import { useQuery } from '@apollo/client';
import React from 'react';
import { generatePath, useHistory } from 'react-router';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import StatusLabel from 'src/components/atoms/StatusLabel';
import { Row } from 'src/components/atoms/styled/layout';
import AppPaths from 'src/constants/AppPaths';
import { EntityHistoryNotificationAPIResponse, EntityHistoryState } from 'src/types/api/EntityHistory';
import { isAxiosError } from 'src/utils/api/axios';
import { dateFormat } from 'src/utils/date';
import {
  EntityHistoryEnum,
  EntityStateEnum,
  EntityStatuMSGEnum,
  EntityStatusMSGTitleEnum,
  getTargetSet,
} from 'src/utils/entityHistory';
import { Status, TaskCode } from 'src/utils/translate';
import { GQL_NOTIFICATION_ITEM } from './gql';
import { NotificationItemQuery, NotificationItemQueryVariables } from './gql.generated';

interface Props {
  item: EntityHistoryNotificationAPIResponse;
}

function NotificationItem(props: Props) {
  const { createDateTime, entityType, variable, fromValue, toValue, state, id, publishAccountId, entity } = props.item;

  const history = useHistory();

  const { targetId, targetType } = getTargetSet(entityType, entity);

  const { data, loading } = useQuery<NotificationItemQuery, NotificationItemQueryVariables>(GQL_NOTIFICATION_ITEM, {
    variables: {
      ...Object.fromEntries([[(targetType ?? '').toLocaleLowerCase(), true]]),
      entityId: targetId?.toString() || '',
      publisherId: publishAccountId?.toString() || '',
    },
    onError: errorLogger.error,
  });

  const { job, jobResult, publisherAccount } = React.useMemo(() => {
    return {
      job: data?.job,
      jobResult: data?.jobResult,
      publisherAccount: data?.publisherAccount,
    };
  }, [data]);

  const [workStatus, workName, eventName, linkId, companyName] = React.useMemo(() => {
    switch (entityType) {
      case 'JOB':
        return [
          job?.status,
          job?.name,
          `콘텐츠 ${toValue ? EntityStatusMSGTitleEnum[toValue] : EntityStateEnum[state]}`,
          job?.projectId,
          job?.account?.company?.name,
        ];
      case 'JOB_FILE':
        return [job?.status, job?.name, `콘텐츠 파일변경`, targetId];
      case 'JOB_RESULT':
        return [
          jobResult?.status,
          jobResult?.job.name,
          `작업결과물 ${toValue ? EntityStatusMSGTitleEnum[toValue] : EntityStateEnum[state]}`,
          jobResult?.projectId,
          jobResult?.job.account?.company?.name,
        ];
      default:
        return ['-'];
    }
  }, [entityType, targetId, job, jobResult, toValue, state]);

  const workType = React.useMemo(() => {
    switch (entityType) {
      case 'JOB':
      case 'JOB_FILE':
        return '콘텐츠';

      case 'JOB_RESULT':
        return '작업 결과물';

      default:
        return '-';
    }
  }, [entityType]);

  const getDetailMSGContent = React.useCallback(
    (state: EntityHistoryState, fromValue?: string, toValue?: string) => {
      if (state === 'CREATE') {
        return EntityHistoryEnum[entityType] + ' 추가됨.';
      }

      if (state === 'UPDATE') {
        return fromValue + ' → ' + toValue + ' 변경됨.';
      }

      if (state === 'DELETE') {
        return EntityHistoryEnum[entityType] + ' 삭제됨.';
      }

      return '-';
    },
    [entityType]
  );

  const [alramMSGTitle, alramMSGContent] = React.useMemo(() => {
    if (variable === 'status' && toValue) {
      return [EntityStatusMSGTitleEnum[toValue], EntityStatuMSGEnum[toValue]];
    }
    if (entityType !== 'JOB_FILE') {
      return [
        EntityStatusMSGTitleEnum['REQUESTED'],
        `${EntityHistoryEnum[entityType]} ${EntityStatuMSGEnum['REQUESTED']}`,
      ];
    }

    return ['파일변경', `콘텐츠 파일이 ${EntityStateEnum[state]}되었습니다.`];
  }, [variable, toValue, entityType, state]);

  const [detailMSGtitle, detailMSGContent] = React.useMemo(() => {
    const detailMSGContent = getDetailMSGContent(state, fromValue, toValue);

    if (variable === 'status' && fromValue && toValue) {
      return ['진행상태', getDetailMSGContent(state, Status[fromValue], Status[toValue])];
    }

    if (entityType !== 'JOB_FILE') {
      return [EntityHistoryEnum[entityType], detailMSGContent];
    }

    return ['작업 콘텐츠 첨부파일', detailMSGContent];
  }, [getDetailMSGContent, state, fromValue, toValue, variable, entityType]);

  const pathName = React.useMemo(() => {
    if (!linkId) {
      return '';
    }

    if (entityType === 'JOB_RESULT' || entityType === 'JOB') {
      const path = generatePath(AppPaths.projectDetail.path, { id: linkId });

      return path;
    }
    if (entityType === 'JOB_FILE') {
      const path = generatePath(AppPaths.detailContent.path, { id: linkId });

      return path;
    }

    return '';
  }, [entityType, linkId]);

  const linkToEntityInfo = React.useCallback(async () => {
    try {
      const res = await api.entityHistory.notification.site.item(id).read({ status: 'READ' });
      if (isAxiosError(res)) {
        throw res;
      }

      history.push(pathName);
    } catch (error) {
      errorLogger.error(error);
    }
  }, [history, id, pathName]);

  if (loading) return <div>loading</div>;

  return (
    <Row>
      <span>{id}</span>
      <span>{dateFormat(createDateTime, 'YYYY.MM.DD hh:mm:ss', { isLocale: true })}</span>
      <span>{workType}</span>
      <span>
        {targetType?.toUpperCase() && TaskCode[targetType?.toUpperCase()]}-{targetId}
      </span>
      <span>
        <StatusLabel status={workStatus ?? ''} />
      </span>
      <span>{companyName}</span>
      <span>{eventName}</span>
      <span onClick={linkToEntityInfo}>
        <span>
          [{alramMSGTitle}] {workName}
        </span>
        <br /> {dateFormat(createDateTime, '{YYYY.MM.DD} ') + alramMSGContent}
      </span>
      <span>
        [{detailMSGtitle}] : {detailMSGContent}
      </span>
      <span>{publisherAccount?.name || 'SYSTEM'}</span>
    </Row>
  );
}

export default NotificationItem;
