import { CButton, CContainer, CHeaderBrand } from '@coreui/react';
import { Parameter, useMount } from '@voithru/front-core';
import { nanoid } from 'nanoid';
import React from 'react';
import { useForm } from 'react-hook-form';
import { generatePath, useHistory, useLocation } from 'react-router';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { useLoading } from 'src/components/context/ApplicationContext';
import { useContentsUploaderService } from 'src/components/context/ContentsUploaderContext';
import { useUploaderContext } from 'src/components/context/UploaderContext';
import AppPaths from 'src/constants/AppPaths';
import { GoogleFile } from 'src/types/api/File';
import { ProductOrderResponse } from 'src/types/api/ProductOrder';
import { ProjectResponse } from 'src/types/api/Project';
import { isAxiosError } from 'src/utils/api/axios';
import { dateFormat } from 'src/utils/date';
import { getWithoutExtensionFromFileName, NamedFile } from 'src/utils/files';
import { FileGroup, FileGroupWithGoogle } from 'src/utils/JobRegisterService';
import { getTransactionOrdersStorage } from 'src/utils/storages';
import { getByteString } from 'src/utils/string';
import { ContentType } from 'src/utils/translate';
import ContentFileUpload from './components/ContentFileUpload';
import ProjectInfo from './components/ProjectInfo';
import ProjectOrderSelectForm from './components/ProjectOrderSelectForm';
import { FormDataOfCreateJob } from './type';

const INIT: FormDataOfCreateJob = { files: [] };
const TRANSACTION_ID = nanoid();

function CreateJobPage() {
  const history = useHistory();

  const { search } = useLocation();
  const { project: projectId } = Parameter.parse(search);

  const loading = useLoading();

  const { finishBlockingUi, startBlockingUi } = useUploaderContext();
  const registerService = useContentsUploaderService();
  const context = useUploaderContext();

  const manager = React.useMemo(() => registerService.createUploadService(TRANSACTION_ID), [registerService]);

  const { register, handleSubmit, setValue, getValues } = useForm<FormDataOfCreateJob>({ defaultValues: INIT });

  const [project, setProject] = React.useState<ProjectResponse>();
  const getProject = React.useCallback(async () => {
    if (!projectId) return;
    const res = await api.project.item(parseInt(projectId)).get();
    if (isAxiosError(res)) {
      throw res;
    }

    setProject(res.data);
  }, [projectId]);

  const [jobMaxIndex, setJobMaxIndex] = React.useState<number>(1);

  const getJob = React.useCallback(async () => {
    if (projectId) {
      const jobs = await api.project.item(parseInt(projectId)).jobs();
      setJobMaxIndex(Math.max(...jobs.data.map((j) => j.index), 0));
    }
  }, [projectId]);

  useMount(() => {
    getJob();
    context.register(TRANSACTION_ID, manager);

    getProject().catch(errorLogger.error);
  });

  const [orders, setOrders] = React.useState<ProductOrderResponse[]>([]);
  const getOrders = React.useCallback(async () => {
    if (!project?.id) {
      return;
    }

    await api.project
      .item(project.id)
      .productOrders()
      .then((res) => {
        setOrders([...res.data]);
      })
      .catch(errorLogger.error);
  }, [project]);

  const getByProjectId = React.useCallback(async () => {
    if (!project?.id) {
      return;
    }
    await api.project
      .item(project.id)
      .get()
      .then(async (res) => {
        const { data } = res;
        registerService.registerOptions(TRANSACTION_ID, { project: data });
      })
      .catch(errorLogger.error);
  }, [project?.id, registerService]);

  const getManagerAccount = React.useCallback(
    async (managerId?: number) => {
      if (!managerId) {
        return;
      }

      await api.account
        .item(managerId)
        .get()
        .then((res) => setValue('projectManager', res.data.name))
        .catch(errorLogger.error);
    },
    [setValue]
  );

  React.useEffect(() => {
    if (!project) {
      return;
    }

    getOrders();
    getByProjectId();
    setValue('projectName', project.name);
    project.category && setValue('contentsType', ContentType[project.category]);
    project.requestedStartDateTime &&
      project.requestedDeadlineDateTime &&
      setValue(
        'dateTime',
        `${dateFormat(new Date(project.requestedStartDateTime), 'YYYY.MM.DD')} ~ ${dateFormat(
          new Date(project.requestedDeadlineDateTime),
          'YYYY.MM.DD'
        )}`
      );
    if (project.managerId) {
      getManagerAccount(project.managerId);
    }
  }, [getByProjectId, getManagerAccount, getOrders, project, setValue]);

  const [orderedFileGroups, setOrderedFileGroups] = React.useState<FileGroup[]>([]);

  const [googleFileGroups, setGoogleFileGroups] = React.useState<GoogleFile[]>([]);

  const onSelectFiles = React.useCallback(
    (fgs: FileGroup[]) => {
      const files = fgs
        .flatMap((fg) => fg.files)
        .sort((lhs, rhs) => {
          const lhsNumberMatched = getWithoutExtensionFromFileName(lhs.name).match(/\d+/g);
          const rhsNumberMatched = getWithoutExtensionFromFileName(rhs.name).match(/\d+/g);

          const lhsLastIndex = parseInt(lhsNumberMatched?.pop() ?? '0');
          const rhsLastIndex = parseInt(rhsNumberMatched?.pop() ?? '0');
          return lhsLastIndex - rhsLastIndex;
        });

      for (const namedFile of files) {
        manager.register(namedFile, false);
      }
      fgs.forEach((value, index) => (value.index = orderedFileGroups.length + googleFileGroups.length + index));
      setOrderedFileGroups((prev) =>
        prev.concat(
          fgs.map((it) => {
            it.files.sort((lhs, rhs) => {
              const lhsNumberMatched = lhs.name.split('.')[0].match(/\d+/g);
              const rhsNumberMatched = rhs.name.split('.')[0].match(/\d+/g);

              const lhsLastIndex = parseInt(lhsNumberMatched?.pop() ?? '0');
              const rhsLastIndex = parseInt(rhsNumberMatched?.pop() ?? '0');
              return lhsLastIndex - rhsLastIndex;
            });
            return it;
          })
        )
      );
    },
    [googleFileGroups.length, manager, orderedFileGroups.length]
  );

  const handleGoogleFileGroups = React.useCallback(
    (file: GoogleFile, index: number) => {
      setGoogleFileGroups((prev) =>
        prev.concat({
          ...file,
          index: prev.length + orderedFileGroups.length + index,
        })
      );
    },
    [orderedFileGroups.length]
  );

  const handleRename = React.useCallback(
    (targetId: string, name: string) => {
      const formFiles = getValues('files');
      const targetIndex = formFiles.findIndex((it) => it.id === targetId);
      setValue(`files.${targetIndex < 0 ? formFiles?.length || 0 : targetIndex}`, { id: targetId, name });
    },
    [getValues, setValue]
  );

  const handleSort = React.useCallback((fileGroups: FileGroup[], googleFiles: GoogleFile[]) => {
    setOrderedFileGroups(fileGroups);
    setGoogleFileGroups(googleFiles);
  }, []);

  const handleGoogleFileRemove = React.useCallback((googleFile: GoogleFile) => {
    setGoogleFileGroups((prev) => {
      return prev.filter((it) => {
        return it.id !== googleFile.id;
      });
    });
  }, []);

  const handleRemove = React.useCallback(
    (namedFile: FileGroup) => {
      namedFile.files.forEach((file) => manager.unregister(file.id));
      setOrderedFileGroups((prev) => {
        const targetIndex = prev.findIndex((it) => it.id === namedFile.id);
        if (targetIndex < 0) {
          return prev;
        }

        const arr = Array.from(prev);
        arr.splice(targetIndex, 1);
        return arr;
      });
    },
    [manager]
  );

  const submit = React.useMemo(
    () =>
      loading(async (form: FormDataOfCreateJob) => {
        if ((!manager || manager.uploaders.length === 0) && googleFileGroups.length === 0) {
          return;
        }

        if (!form.orders || form.orders.length <= 0 || form.orders.filter((it) => it.checked).length === 0) {
          return;
        }

        try {
          startBlockingUi();
          const storage = getTransactionOrdersStorage(TRANSACTION_ID);
          const orderIds = orders
            .filter((_, idx) => {
              return form.orders?.[idx].checked;
            })
            .map((it) => it.id);

          storage.item = orderIds;

          const namedFiles = orderedFileGroups.map((file) => ({
            ...file,
            name: form.files?.find((it) => it.id === file.id)?.name || file.name,
            index: file.index + jobMaxIndex + 1,
          }));

          if (!(await manager.startUploading())) {
            return;
          }
          if (namedFiles.length > 0) {
            const isSuccess = await registerService.start(TRANSACTION_ID, namedFiles, 'CREATE_JOB');
            if (!isSuccess) {
              window.alert('처리에 실패했습니다. 네트워크를 확인 후 다시 시도해주세요.');
              return;
            }
          }

          if (googleFileGroups.length > 0) {
            if (!project) {
              return;
            }

            for (const it of googleFileGroups) {
              const name = form.files?.find((item) => item.id === it.id)?.name || it.name;
              it.name = name;
              await api.jobs.post({
                job: {
                  name: form.files?.find((item) => item.id === it.id)?.name || it.name,
                  projectId: project?.id,
                  index: it.index + jobMaxIndex + 1,
                  status: 'REQUESTED',
                  category: project?.category!,
                  accountId: project?.accountId,
                  managerId: project?.managerId,
                },
                productOrdersIds: orderIds,
                jobFiles: it.jobFiles.map((jobFile) => {
                  return {
                    fileId: jobFile.id,
                    fileType: 'CONTENTS_FILE',
                    index: jobFile.index,
                  };
                }),
              });
            }
          }

          window.alert('파일 업로드가 완료되었습니다.');

          const path = generatePath(AppPaths.projectDetail.path, { id: project?.id });
          history.replace(path);
        } catch (error) {
          errorLogger.error(error);
        } finally {
          finishBlockingUi();
        }
      }),
    [
      loading,
      manager,
      googleFileGroups,
      startBlockingUi,
      orders,
      orderedFileGroups,
      project,
      history,
      jobMaxIndex,
      registerService,
      finishBlockingUi,
    ]
  );

  const compareFiles = React.useMemo(() => {
    const compare = [...orderedFileGroups, ...googleFileGroups];
    return compare
      .sort((a, b) => {
        return a.index - b.index;
      })
      .map((it): FileGroupWithGoogle => {
        const { id, name, index } = it;
        const size = it['files']
          ? it['files'].map((f: NamedFile) => f.file.size).reduce((prev: number, cur: number) => prev + cur, 0)
          : it['volume'];
        return {
          id,
          name,
          index,
          size: getByteString(size),
          item: it,
        };
      });
  }, [orderedFileGroups, googleFileGroups]);

  return (
    <div className="bg-light d-flex flex-column justify-content-center">
      <CHeaderBrand>콘텐츠 추가하기</CHeaderBrand>
      <CContainer>
        <form onSubmit={handleSubmit(submit, (...args) => console.log('onInvalid', { args }))}>
          <div style={{ paddingBottom: '20px' }}>
            <ProjectInfo setProject={setProject} register={register} />
            <ContentFileUpload
              files={compareFiles}
              onSelectFiles={onSelectFiles}
              onGoogleFileGroups={handleGoogleFileGroups}
              onSort={handleSort}
              onNameChange={handleRename}
              onDelete={handleRemove}
              onGoogleFileDelete={handleGoogleFileRemove}
              projectAccountId={project?.accountId}
            />
            <ProjectOrderSelectForm project={project} orders={orders} register={register} />

            <div>
              <CButton
                color="secondary"
                onClick={() => {
                  history.goBack();
                }}
              >
                취소하기
              </CButton>
              <CButton style={{ width: '80px' }} type={'submit'}>
                확인
              </CButton>
            </div>
          </div>
        </form>
      </CContainer>
    </div>
  );
}

export default CreateJobPage;
