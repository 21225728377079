import { Column, Row } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const ProjectTaskProgressWrapper = styled(Column)`
  margin-top: 24px;
  width: 2270px;
  min-width: 2270px;

  & > ${Row} {
    padding: 17px 0;

    text-align: left;
    font-size: 15px;
    & > span {
      padding-left: 4px;
    }
    & > span:nth-child(1) {
      width: 50px;
    }
    & > span:nth-child(2) {
      width: 120px;
    }
    & > span:nth-child(3) {
      width: 100px;
    }
    & > span:nth-child(4) {
      width: 400px;
    }
    & > span:nth-child(5) {
      width: 100px;
    }
    & > span:nth-child(6) {
      width: 650px;

      & > span + span {
        margin-left: 17px;
      }
    }
    & > span:nth-child(7) {
      width: 140px;
    }
    & > span:nth-child(8) {
      width: 140px;
    }
    & > span:nth-child(9) {
      width: 140px;
    }
    & > span:nth-child(10) {
      width: 140px;
    }
    & > span:nth-child(11) {
      width: 140px;
    }
    & > span:nth-child(12) {
      flex: 1;
    }
  }

  & > ${Row}:first-child {
    border: 2px solid ${getRGBA(colors.grey90)};
  }
`;
