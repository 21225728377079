export function getRGBA(value: string, opacity?: number) {
  if (!value) {
    return '';
  }

  if (typeof opacity !== 'number' || isNaN(opacity) || opacity >= 1) {
    return `rgb(${value})`;
  }

  return `rgba(${value}, ${opacity})`;
}
