import { CCard } from '@coreui/react';
import { ButtonBase } from 'src/components/atoms/styled/button';
import { Title } from 'src/components/atoms/styled/element';
import { CSSRow, Row } from 'src/components/atoms/styled/layout';
import styled from 'styled-components';

export const ContentResultFilesWrapper = styled(CCard)`
  & > .card-body {
    & > ${Row}:first-child {
      justify-content: space-between;
      align-items: center;

      margin: 0 0 24px;

      & > ${Title} {
        font-size: 1.2rem;
        font-weight: 700;
      }
    }
  }

  .uploadBtn {
    justify-content: center;

    & > ${ButtonBase} {
      ${CSSRow};
      align-items: center;

      & > img {
        margin-left: 16px;
      }
    }
  }
`;
