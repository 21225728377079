import { AxiosInstance } from 'axios';
import { generatePath } from 'react-router';
import {
  ProductOrder,
  ProductOrderOption,
  ProductOrderPrice,
  ProductOrderRequest,
  ProductOrderResponse,
} from 'src/types/api/ProductOrder';

const PRODUCT_ORDER_PATH = '/api/v1/productOrders';
const PRODUCT_OPTION_ORDER_PATH = '/api/v1/productOptionOrders';

function apiProductOrder(instance: AxiosInstance) {
  return {
    get: (params: ProductOrderRequest) => instance.get<ProductOrder[]>(PRODUCT_ORDER_PATH, { params }),

    item(productOrderId: number) {
      const PRODUCT_ORDER_ITEM_PATH = generatePath(PRODUCT_ORDER_PATH + '/:productOrderId', { productOrderId });
      const PRODUCT_OPTION_ORDER_ITEM_PATH = generatePath(PRODUCT_OPTION_ORDER_PATH + '/:productOptionOrderId', {
        productOptionOrderId: productOrderId,
      });
      return {
        get: () => instance.get<ProductOrder>(PRODUCT_ORDER_ITEM_PATH),
        patch: (data: ProductOrderPrice) => instance.patch(PRODUCT_ORDER_ITEM_PATH, data),
        put: (data: Partial<ProductOrderResponse>) => instance.put<ProductOrderResponse>(PRODUCT_ORDER_ITEM_PATH, data),

        productOrderOptions: {
          post: (data: ProductOrderOption[]) => instance.post(PRODUCT_OPTION_ORDER_ITEM_PATH, data),

          patch: (data: ProductOrderOption) => instance.patch(PRODUCT_OPTION_ORDER_ITEM_PATH, data),
        },
      };
    },
  };
}

export default apiProductOrder;
