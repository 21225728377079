import { ButtonBase } from 'src/components/atoms/styled/button';
import { Title } from 'src/components/atoms/styled/element';
import { Column, Row } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const SettlementListWrapper = styled(Column)`
  width: 1250px;

  & > ${Row} {
    justify-content: space-between;
    align-items: flex-end;
    & > span {
      padding: 16px 12px;
      width: fit-content;
      border-bottom: 2px solid ${getRGBA(colors.grey90)};
      box-sizing: border-box;

      font-size: 20px;
      color: ${getRGBA(colors.grey90)};
    }
    & > a {
      color: ${getRGBA(colors.grey90)};
      text-decoration: none;
    }
  }

  & > ${Row}.title {
    & > ${Title} {
      font-weight: bold;
      font-size: 18px;
    }
  }

  & > ${Column} {
    margin-top: 24px;
    width: 100%;
    & > ${Row}:first-child {
      border: 2px solid ${getRGBA(colors.grey90)};
    }

    & > ${Row} {
      padding: 17px 0;

      text-align: left;
      font-size: 15px;
      & > span {
        padding-left: 4px;
      }

      & > span:nth-child(1) {
        width: 40px;
      }
      & > span:nth-child(2) {
        width: 100px;
      }
      & > span:nth-child(3) {
        width: 120px;
      }
      & > span:nth-child(4) {
        width: 280px;
      }
      & > span:nth-child(5) {
        width: 180px;
      }

      & > span:nth-child(6) {
        width: 200px;

        & > ${ButtonBase} {
          margin-right: 4px;
          padding: 2px 12px;

          border-radius: 4px;

          color: white;
        }

        & > ${ButtonBase}:first-child {
          background: #0b5fff;
        }

        & > ${ButtonBase}:last-child {
          background: #f50000;
        }

        & > ${ButtonBase}:disabled {
          background: #b9b9b9;
        }
      }
      & > span:nth-child(7) {
        width: 120px;
      }
      & > span:nth-child(8) {
        width: 90px;
      }
      & > span:nth-child(9) {
        width: 120px;
      }
    }
  }
`;
