import { CFormInput, CFormLabel } from '@coreui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { Row } from 'src/components/atoms/styled/layout';
import { User } from 'src/types/api/User';
import { isAxiosError } from 'src/utils/api/axios';
import { CreateClientAccountModalWrapper } from './styled';

interface FormData {
  name: string;
  department: string;
  phoneNumber: string;
  email: string;
}

interface Props {
  companyId: number;
  masterAccountId: number;

  onDismiss(): void;
}

function CreateClientAccountModal(props: Props) {
  const { companyId, masterAccountId, onDismiss } = props;

  const { register, handleSubmit, formState } = useForm<FormData>();
  const { errors } = formState;
  const [error, setError] = React.useState<unknown>();

  const submit = React.useCallback(
    async (form: FormData) => {
      const request: User = {
        ...form,
        companyId,
        masterAccountId,
        role: 'CLIENT_SUB',
      };

      try {
        const res = await api.signUp.requestClientAccount(request);
        if (isAxiosError(res)) {
          throw res;
        }

        onDismiss();
      } catch (error) {
        errorLogger.error(error);
        setError(error);
      }
    },
    [companyId, masterAccountId, onDismiss]
  );

  return (
    <CreateClientAccountModalWrapper
      title={'신규 계정 생성하기'}
      visible
      onDismiss={onDismiss}
      onSubmit={handleSubmit(submit)}
    >
      <Row>
        <CFormLabel {...{ htmlFor: 'name' }}>이름</CFormLabel>
        <CFormInput {...register('name', { required: true })} invalid={Boolean(errors.name?.type)} />
      </Row>
      <Row>
        <CFormLabel {...{ htmlFor: 'department' }}>소속부서</CFormLabel>
        <CFormInput {...register('department', { required: true })} invalid={Boolean(errors.department?.type)} />
      </Row>
      <Row>
        <CFormLabel {...{ htmlFor: 'phoneNumber' }}>연락처</CFormLabel>
        <CFormInput {...register('phoneNumber', { required: true })} invalid={Boolean(errors.phoneNumber?.type)} />
      </Row>
      <Row>
        <CFormLabel {...{ htmlFor: 'email' }}>로그인 ID</CFormLabel>
        <CFormInput {...register('email', { required: true })} invalid={Boolean(errors.email?.type)} />
      </Row>
      {error && <span className={'error'}>오류가 발생했습니다.</span>}
    </CreateClientAccountModalWrapper>
  );
}

export default CreateClientAccountModal;
