import { ButtonBase } from 'src/components/atoms/styled/button';
import { Row } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const ButtonWrapRow = styled(Row)`
  padding: 15px 2px;

  justify-content: space-between;

  & > ${ButtonBase} {
    padding: 3px 11px;

    background: ${getRGBA(colors.green50)};
    border-radius: 4px;

    color: ${getRGBA(colors.white)};
  }
`;

export const TabItemWrapper = styled.div`
  display: flex;

  padding: 8px;

  & > div {
    padding: 5px;

    color: #ced2d8;

    cursor: pointer;

    &.focus {
      border-bottom: 1px solid #321fdb;

      color: #321fdb;
    }
  }
`;
