import { gql } from '@apollo/client';

export const GQL_JOBS_WITH_JOBRESULTS = gql`
  query JobsWithJobResults($ids: [ID!]) {
    jobs(ids: $ids) {
      id
      name
      doneDateTime
      manager {
        name
      }

      jobFiles {
        editors {
          index
          key
        }
      }

      jobResults {
        id
        createDateTime
        productOrder {
          productType
          sourceLanguage
          translateLanguage
          deliveryType
          productSource
          productOptionOrders {
            type
          }
        }
      }
    }
  }
`;
