import { useMount } from '@voithru/front-core';
import React from 'react';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import StatusLabel from 'src/components/atoms/StatusLabel';
import { Column, Row } from 'src/components/atoms/styled/layout';
import { CompanyResponse } from 'src/types/api/Company';
import { ProjectResponse } from 'src/types/api/Project';
import { isAxiosError } from 'src/utils/api/axios';
import { getAccountByAccountId } from 'src/utils/api/getter';
import { dateFormat } from 'src/utils/date';
import { ContentType } from 'src/utils/translate';
import styled from 'styled-components';
import { DashboardStatusRowWrapper } from './styled';

interface Props {
  project: ProjectResponse;

  className?: string;
}

function ProjectRow(props: Props) {
  const { project, className } = props;

  const [company, setCompany] = React.useState<CompanyResponse>();

  const getCompany = React.useCallback(async () => {
    if (project.accountId) {
      const accountRes = await getAccountByAccountId(project.accountId);

      if (accountRes.data.companyId) {
        const companyRes = await api.companies.item(accountRes.data.companyId).get();

        if (isAxiosError(companyRes)) {
          throw companyRes;
        }

        setCompany(companyRes.data);
      }
    }
  }, [project]);

  useMount(() => {
    getCompany().catch(errorLogger.error);
  });

  return (
    <DashboardStatusRowWrapper className={className}>
      <span>
        <StatusLabel status={project.status} />
      </span>
      <Column>
        <Row>
          <Row>[프로젝트]</Row>
          <Row>[P-{project.id}]</Row>
          <Row>[{project.category && ContentType[project.category]}]</Row>
          <Row>{project.name}</Row>
        </Row>
        <Row>{company?.name}</Row>
      </Column>
      <Row>{dateFormat(project.createDateTime, 'YYYY.MM.DD')}</Row>
    </DashboardStatusRowWrapper>
  );
}

export default styled(ProjectRow)``;
