import { Title } from 'src/components/atoms/styled/element';
import { Column, Row } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';
import JobResultSummary from './components/JobResultSummary';
import JobSummary from './components/JobSummary';
import ProjectDescription from './components/ProjectDescription';
import ProjectDescriptionCollapse from './components/ProjectDescriptionCollapse';

export const ProjectDetailWrapper = styled.div`
  display: inline-block;

  & > ${Row}:nth-child(1) {
    align-items: center;
    & > ${Title} {
      margin: 0 16px;
      min-width: 320px;

      font-size: 18px;
      font-weight: 600;
    }
  }

  & > ${Row}:nth-child(2) {
    margin: 16px 0;
    width: 3200px;

    & > ${Column} {
      flex: 1;

      & > ${ProjectDescription} {
      }

      & > ${ProjectDescriptionCollapse} {
        margin: 8px 0 0;
      }
    }

    & > ${JobSummary}, & > ${JobResultSummary} {
      flex: 1;
      margin: 0 0 0 8px;
      height: 100%;
    }
  }

  & > div {
    & > button.active {
      background-color: ${getRGBA(colors.blue50)};

      color: white;
    }

    & > button + button {
      margin-left: 4px;
    }
  }
`;
