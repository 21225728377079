import { ButtonBase } from 'src/components/atoms/styled/button';
import { Title } from 'src/components/atoms/styled/element';
import { Column } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const NotificationsWrapper = styled(Column)`
  & > ${Title} {
    margin-bottom: 12px;

    font-weight: bold;
    font-size: 18px;
  }

  & > ${ButtonBase} {
    margin-bottom: 12px;
    padding: 7px 15px;

    width: 120px;

    background: ${getRGBA(colors.green50)};
    border-radius: 4px;

    text-align: center;
    color: ${getRGBA(colors.white)};
  }

  & > ${Column} {
    width: 1550px;
  }
`;
