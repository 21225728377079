import { CButton, CCardTitle, CModal, CModalBody, CModalFooter } from '@coreui/react';
import React from 'react';

interface Props {
  onClose(): void;
}

function AccountRegisterDone(props: Props) {
  const { onClose } = props;

  return (
    <CModal visible onDismiss={onClose}>
      <CModalBody>
        <CCardTitle>요청하신 이메일로 사용 신청이 완료되었습니다.</CCardTitle>
        <p className="text-medium-emphasis">계정이 활성화 상태가 되면 사용이 가능합니다.</p>
      </CModalBody>
      <CModalFooter>
        <CButton color={'primary'} onClick={onClose}>
          확인
        </CButton>
      </CModalFooter>
    </CModal>
  );
}

export default AccountRegisterDone;
