import { useQuery } from '@apollo/client';
import React from 'react';
import { useForm } from 'react-hook-form';
import errorLogger from 'src/api/errorLogger';
import ICN_PERSON from 'src/assets/icons/icn-person-24px.svg';
import { ButtonBase } from 'src/components/atoms/styled/button';
import { CheckBox } from 'src/components/atoms/styled/form';
import { Column, Row } from 'src/components/atoms/styled/layout';
import { JAMAKE_EDITOR } from 'src/constants/API';
import { useAuth } from 'src/components/context/AuthContext';
import { JobsWithJobResultsWrapper } from './styled';
import { dateFormat } from 'src/utils/date';
import JobFileDownloadModal from 'src/components/pages/WorkerRelayPage/components/JobFileDownloadModal';
import WorkerManageModal from 'src/components/organisms/WorkerManageModal';
import {
  WorkRange,
  Source_Language,
  Translate_Language,
  ResultType,
  WorkTarget,
  AdditionalServices,
} from 'src/utils/translate';
import JobResultFileDownloadModal from '../JobResultFileDownloadModal';
import { GQL_JOBS_WITH_JOBRESULTS } from './gql';
import { JobsWithJobResultsQuery, JobsWithJobResultsQueryVariables } from './gql.generated';

interface Props {
  projectId: number;
  jobIds: string[];
  jobResultIds: string[];
  transactionId: string;
}

type ModalTypes = 'JOB' | 'JOB_RESULT' | 'WORKER';

interface Modal {
  visible: boolean;
  type: ModalTypes;
  jobIds?: string[];
  jobResultIds?: string[];
}

interface Formdata {
  jobs: {
    target: string;
    checked: boolean;
    jobResults?: {
      target: string;
      checked: boolean;
    }[];
  }[];
}

function JobWithJobResultList(props: Props) {
  const { projectId, jobIds, transactionId } = props;

  const { user } = useAuth();

  const { loading, error, data } = useQuery<JobsWithJobResultsQuery, JobsWithJobResultsQueryVariables>(
    GQL_JOBS_WITH_JOBRESULTS,
    {
      variables: {
        ids: jobIds,
      },
      onError: errorLogger.error,
    }
  );

  const { register, getValues, setValue, watch } = useForm<Formdata>();

  const checks = watch('jobs')?.map((it) => it);

  const jobsAllChecked = React.useMemo(() => {
    if (!checks) return false;
    return checks.every((it) => it.checked);
  }, [checks]);

  const jobResultsAllChecked = React.useMemo(() => {
    if (!checks) {
      return false;
    }

    return checks.every((it) => {
      if (!it.jobResults) {
        return true;
      }
      return it.jobResults.every((result) => result.checked);
    });
  }, [checks]);

  const jobSomeChecked = React.useMemo(() => {
    if (!checks) {
      return false;
    }

    return checks.some((it) => it.checked);
  }, [checks]);

  const jobResultSomeChecked = React.useMemo(() => {
    if (!checks) {
      return false;
    }
    return checks.some((it) => {
      if (!it.jobResults) {
        return false;
      }
      return it.jobResults.some((result) => result.checked);
    });
  }, [checks]);

  const [modal, setModal] = React.useState<Modal>();

  const handleModalVisible = React.useCallback(
    (e) => {
      const { name } = e.target;

      const jobs = getValues('jobs');

      setModal({
        visible: true,
        type: name,
        jobIds: jobs
          .filter((it) => {
            return it.checked;
          })
          .map((it) => it.target),
        jobResultIds: jobs
          .flatMap((it) => {
            return it.jobResults?.filter((result) => result && result.checked);
          })
          .filter(Boolean)
          .map((it) => it!.target),
      });
    },
    [getValues]
  );

  const handleJobResultModalVisible = React.useCallback(() => {
    const jobs = getValues('jobs');

    setModal({
      visible: true,
      type: 'JOB_RESULT',
      jobIds: jobs
        .filter((it) => {
          return it.jobResults?.some((result) => result && result.checked);
        })
        .map((it) => it.target),
      jobResultIds: [''],
    });
  }, [getValues, setModal]);

  const handleJobResultWorkerModalVisible = React.useCallback((jobResultId: string) => {
    setModal({
      visible: true,
      type: 'WORKER',
      jobIds: [],
      jobResultIds: [jobResultId],
    });
  }, []);

  const handleJobResultDownloadModalVisible = React.useCallback(
    (jobId: string) => {
      setModal({
        visible: true,
        type: 'JOB_RESULT',
        jobIds: [jobId],
        jobResultIds: [''],
      });
    },
    [setModal]
  );

  const handleJobsAllChecked = React.useCallback(() => {
    setValue(
      'jobs',
      getValues('jobs').map((it) => {
        return {
          ...it,
          checked: !jobsAllChecked,
        };
      })
    );
  }, [jobsAllChecked, setValue, getValues]);

  const handleJobResultAllChecked = React.useCallback(() => {
    setValue(
      'jobs',
      getValues('jobs').map((it) => {
        if (it.jobResults) {
          return {
            ...it,
            jobResults: it.jobResults.map((result) => {
              return {
                ...result,
                checked: !jobResultsAllChecked,
              };
            }),
          };
        }
        return it;
      })
    );
  }, [getValues, jobResultsAllChecked, setValue]);

  const handleEditorLinkCopy = React.useCallback((editorKey: number) => {
    try {
      const editorLink = JAMAKE_EDITOR + `/ko/${editorKey}`;

      if (!navigator.clipboard) {
        const container = document.createElement('textarea');
        document.body.appendChild(container);
        container.value = editorLink;
        container.select();
        document.execCommand('copy');
        document.body.removeChild(container);

        return window.alert('클립보드에 에디터 url이 복사되었습니다.');
      }

      navigator.clipboard.writeText(editorLink).then(() => {
        window.alert('클립보드에 에디터 url이 복사되었습니다.');
      });
    } catch (error) {
      errorLogger.error(error);
    }
  }, []);

  React.useEffect(() => {
    if (data && data.jobs) {
      setValue(
        'jobs',
        data.jobs.map((job) => {
          return {
            target: job.id,
            checked: false,
            jobResults: job.jobResults?.map((it) => {
              return { target: it.id, checked: false };
            }),
          };
        })
      );
    }
  }, [data, setValue]);

  if (loading) return <div>Loading...</div>;
  if (error) {
    return (
      <div>
        <h2>{error.name}</h2>
        <h3>{error.message}</h3>
      </div>
    );
  }
  return (
    <JobsWithJobResultsWrapper>
      <Row className={'buttonWrap'}>
        <Row>
          <ButtonBase name={'JOB'} onClick={handleModalVisible} disabled={!jobSomeChecked}>
            콘텐츠 첨부파일
          </ButtonBase>
        </Row>
        <Row>
          {user?.role === 'ADMIN' && (
            <ButtonBase name={'WORKER'} onClick={handleModalVisible} disabled={user?.role !== 'ADMIN'}>
              담당 작업자
            </ButtonBase>
          )}
          <ButtonBase name={'JOB_RESULT'} onClick={handleJobResultModalVisible} disabled={!jobResultSomeChecked}>
            결과물 파일확인
          </ButtonBase>
        </Row>
        {modal && modal?.visible && modal?.type === 'JOB' && (
          <JobFileDownloadModal
            jobIds={modal.jobIds!}
            onDismiss={() => setModal((prev) => prev && { ...prev, visible: false })}
          />
        )}
        {modal?.visible && modal?.type === 'WORKER' && (
          <WorkerManageModal
            jobResultIds={modal.jobResultIds ?? []}
            onDismiss={() => setModal((prev) => prev && { ...prev, visible: false })}
          />
        )}
        {modal?.visible && modal?.type === 'JOB_RESULT' && (
          <JobResultFileDownloadModal
            projectId={projectId}
            jobIds={modal.jobIds!}
            onDismiss={() => setModal((prev) => prev && { ...prev, visible: false })}
            transactionId={transactionId}
          />
        )}
      </Row>
      {data && (
        <Column>
          <Row>
            <Row className={'jobs'}>
              <span>
                <CheckBox onChange={handleJobsAllChecked} checked={jobsAllChecked} />
              </span>
              <span>콘텐츠</span>
            </Row>
            <div className={'tit'}></div>
            <Row className={'job_results'}>
              <span>
                <CheckBox onChange={handleJobResultAllChecked} checked={jobResultsAllChecked} />
              </span>
              <span>결과물 ID</span>
              <span>작업범위</span>
              <span>원본언어</span>
              <span>번역언어</span>
              <span>납품형태</span>
              <span>작업대상</span>
              <span>부가서비스</span>
              <span>최초생성일</span>
              <span>납품예정일</span>
              <span>담당 PM</span>
              {user?.role === 'ADMIN' && <span>담당 작업자</span>}
              <span>작업 결과물</span>
            </Row>
          </Row>

          {data.jobs?.map((job, index) => (
            <Row key={job.id}>
              <Row className={'jobs'}>
                <span>
                  <CheckBox {...register(`jobs.${index}.checked`)} />
                </span>
                <span>J-{job.id}</span>
                <span>{job.name}</span>
                <Column>
                  <ButtonBase
                    className={'addJobFile'}
                    onClick={() => setModal({ visible: true, type: 'JOB', jobIds: [job.id] })}
                  >
                    첨부파일
                  </ButtonBase>
                  {user?.role === 'ADMIN' &&
                    job.jobFiles?.map((jobFile) => {
                      return jobFile.editors?.map((editor, idx) => {
                        return (
                          <ButtonBase
                            key={idx}
                            className={'editorLink'}
                            onClick={() => handleEditorLinkCopy(editor.key)}
                          >
                            에디터 {editor.index}
                          </ButtonBase>
                        );
                      });
                    })}
                </Column>
              </Row>
              <div className={'tit'}></div>
              <Column>
                {job.jobResults?.map((it, idx) => (
                  <Row key={it.id} className={'job_results'}>
                    <span>
                      <CheckBox {...register(`jobs.${index}.jobResults.${idx}.checked`)} />
                    </span>
                    <span>JR-{it.id}</span>
                    <span>{it.productOrder?.productType && WorkRange[it.productOrder?.productType]}</span>
                    <span>{it.productOrder?.sourceLanguage && Source_Language[it.productOrder?.sourceLanguage]}</span>
                    <span>
                      {it.productOrder?.translateLanguage && Translate_Language[it.productOrder?.translateLanguage]}
                    </span>
                    <span>{it.productOrder?.deliveryType && ResultType[it.productOrder?.deliveryType]}</span>
                    <span>{it.productOrder?.productSource && WorkTarget[it.productOrder?.productSource]}</span>
                    <span>
                      {it.productOrder?.productOptionOrders &&
                        it.productOrder?.productOptionOrders[0]?.type &&
                        AdditionalServices[it.productOrder?.productOptionOrders[0]?.type]}
                    </span>
                    <span>{dateFormat(it.createDateTime, 'YYYY.MM.DD')}</span>
                    <span>{job.doneDateTime && dateFormat(job.doneDateTime, 'YYYY.MM.DD')}</span>
                    <span>{job.manager?.name}</span>
                    {user?.role === 'ADMIN' && (
                      <span>
                        <ButtonBase onClick={() => handleJobResultWorkerModalVisible(it.id)}>
                          <img src={ICN_PERSON} alt={'workerAdd'} />
                        </ButtonBase>
                      </span>
                    )}
                    <span className={'addFile'} onClick={() => handleJobResultDownloadModalVisible(job.id)}>
                      <ButtonBase>첨부파일</ButtonBase>
                    </span>
                  </Row>
                ))}
              </Column>
            </Row>
          ))}
        </Column>
      )}
    </JobsWithJobResultsWrapper>
  );
}

export default JobWithJobResultList;
