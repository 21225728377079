import { cn } from '@voithru/front-core';
import colors, { ColorTemplate } from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';
import AnchorBase from '../AnchorBase';

export const Anchor = styled(AnchorBase)`
  color: ${getRGBA(colors.black)};
`;

export const Title = styled.p`
  margin: 0;
  padding: 0;
  white-space: nowrap;
`;

export const Description = styled.p`
  margin: 0;
  padding: 0;
`;

export const ErrorMessage = styled.span`
  color: ${getRGBA(colors.red50)};
`;

interface BadgeProps {
  color?: ColorTemplate;
  disabled?: boolean;
}
function attrsBadge(props: BadgeProps & React.ComponentProps<'span'>) {
  const { color = 'grey', className, disabled } = props;
  return { className: cn(color, disabled && 'disabled', className) };
}
export const Badge = styled.span.attrs(attrsBadge)<BadgeProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  padding: 0 8px;
  height: 19px;
  box-sizing: border-box;

  line-height: 1;
  font-size: 10px;
  font-weight: 700;

  &.red {
    background: ${getRGBA(colors.red10)};
    color: ${getRGBA(colors.red50)};
  }

  &.yellow {
    background: ${getRGBA(colors.yellow20)};
    color: ${getRGBA(colors.yellow80)};
  }

  &.green {
    background: ${getRGBA(colors.green20)};
    color: ${getRGBA(colors.green60)};
  }

  &.grey {
    background: ${getRGBA(colors.grey20)};
    color: ${getRGBA(colors.grey60)};
  }

  &.blue {
    background: ${getRGBA(colors.blue20)};
    color: ${getRGBA(colors.blue50)};
  }

  &.disabled {
    background: ${getRGBA(colors.grey10)};
    color: ${getRGBA(colors.grey40)};
  }
`;
