import { useMount } from '@voithru/front-core';
import React from 'react';
import { Link } from 'react-router-dom';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { Row } from 'src/components/atoms/styled/layout';
import JobResultTaskList from 'src/components/molecules/JobResultTaskList';
import AppPaths from 'src/constants/AppPaths';
import { JobResultResponse } from 'src/types/api/JobResult';
import { isAxiosError } from 'src/utils/api/axios';
import { InspectionProcessingWrapper } from './styled';

interface JobResultWithSize {
  totalSize: number;
  element: JobResultResponse[];
}

function InspectionProcessing() {
  const [jobResult, setJobResult] = React.useState<JobResultWithSize>();

  const getJobResult = React.useCallback(async () => {
    try {
      const res = await api.jobResult.pages({
        page: 0,
        size: 10,
        status: ['REVIEWABLE', 'REVIEW_REJECTED', 'REVIEW_APPROVED'],
      });

      if (isAxiosError(res)) {
        throw res;
      }

      const { data } = res;

      setJobResult({
        totalSize: data.totalSize,
        element: data.element,
      });
    } catch (err) {
      errorLogger.error(err);
    }
  }, []);

  useMount(() => {
    getJobResult();
  });

  return (
    <InspectionProcessingWrapper>
      <Row>
        <span>작업결과물 ({jobResult?.totalSize})</span>
        <Link to={`${AppPaths.projects.path}?tab=result&status=REVIEWABLE,REVIEW_REJECTED,REVIEW_APPROVED`}>
          더보기 &gt;
        </Link>
      </Row>
      {jobResult && (
        <JobResultTaskList
          jobResultIds={jobResult.element.map((jr) => {
            return { id: jr.id };
          })}
        />
      )}
    </InspectionProcessingWrapper>
  );
}

export default InspectionProcessing;
