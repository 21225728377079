import { Parameter, useMount } from '@voithru/front-core';
import { nanoid } from 'nanoid';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { useLoading } from 'src/components/context/ApplicationContext';
import { ProjectResponse } from 'src/types/api/Project';
import { isAxiosError } from 'src/utils/api/axios';
import ProjectCreateDone from './components/ProjectCreateDone';
import ProjectCreateInfo from './components/ProjectCreateInfo';
import ProjectCreateOrder from './components/ProjectCreateOrder';
import ProjectCreateUpload from './components/ProjectCreateUpload';
import { PageState } from './type';

function CreateProjectPage() {
  const { pathname, search, state: pageState = {} } = useLocation<PageState>();
  const { step = 'INFO', transactionId } = pageState;

  const loading = useLoading();

  const history = useHistory<PageState>();
  const [project, setProject] = React.useState<ProjectResponse>();

  const loadProjectFromId = React.useMemo(
    () =>
      loading(async (id: number) => {
        const res = await api.project.item(id).get();
        if (isAxiosError(res)) {
          throw res;
        }

        const state = { step: step || 'INFO', transactionId: transactionId };

        history.replace({ pathname, search, state });

        setProject(res.data);
      }),
    [history, loading, pathname, search, step, transactionId]
  );

  useMount(() => {
    if (transactionId) {
      return;
    }

    const { id } = Parameter.parse(search);

    if (isNaN(Number(id))) {
      const state = { step: step || 'INFO', transactionId: nanoid() };

      return history.replace({ pathname, search, state });
    }
    loadProjectFromId(Number(id)).catch(errorLogger.error);
  });
  const prevStep = React.useRef(step);
  React.useEffect(() => {
    if (prevStep.current === step) {
      return;
    }

    const { id } = Parameter.parse(search);
    if (isNaN(Number(id))) {
      return;
    }

    prevStep.current = step;
    loadProjectFromId(Number(id)).catch(errorLogger.error);
  }, [loadProjectFromId, step, search]);

  const { id } = Parameter.parse(search);
  const projectId = Number(id);

  if (step !== 'INFO' && project == null) {
    return null;
  }

  if (!transactionId) {
    return null;
  }
  return (
    <>
      {step === 'INFO' && <ProjectCreateInfo projectId={projectId} />}
      {step === 'ORDER' && project && <ProjectCreateOrder project={project} />}
      {step === 'UPLOAD' && projectId && <ProjectCreateUpload projectId={projectId} transactionId={transactionId} />}
      {step === 'DONE' && projectId && <ProjectCreateDone projectId={projectId} transactionId={transactionId} />}
    </>
  );
}

export default CreateProjectPage;
