import { cn } from '@voithru/front-core';
import React from 'react';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';
import { CSSRow } from './layout';
import ICN_RADIO_ON from 'src/assets/icons/icn-radio-on.svg';
import ICN_RADIO_OFF from 'src/assets/icons/icn-radio-off.svg';
import ICN_CHECKBOX_ON from 'src/assets/icons/icn-checkbox-on-24px.svg';
import ICN_CHECKBOX_OFF from 'src/assets/icons/icn-checkbox-off-24px.svg';

function attrsInputBase(props: React.ComponentProps<'input'>) {
  const { type = 'text' } = props;
  return { type };
}
export const InputBase = styled.input.attrs(attrsInputBase)`
  background: transparent;
  margin: 0;
  border: none;
  padding: 0;

  appearance: none;
  outline: none;
`;

interface InputProps {
  error?: boolean;
}
function attrsInput(props: InputProps & React.ComponentProps<'input'>) {
  const { className, error } = props;
  return { className: cn(error && 'error', className) };
}
export const Input = styled(InputBase).attrs(attrsInput)<InputProps>`
  ${CSSRow}
  justify-content: center;
  align-items: center;

  background: ${getRGBA(colors.grey5)};
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  padding: 0 16px;
  height: 48px;
  box-sizing: border-box;

  color: ${getRGBA(colors.grey70)};
  font-size: 16px;
  font-weight: 700;

  &::placeholder {
    color: ${getRGBA(colors.grey50)};
  }

  &.error {
    border-color: ${getRGBA(colors.red50)};
  }
`;

function attrsRadioBox() {
  return { type: 'radio' };
}
export const RadioBox = styled(InputBase).attrs(attrsRadioBox)`
  background-image: url(${ICN_RADIO_OFF});
  background-position: center;
  width: 24px;
  height: 24px;

  cursor: pointer;

  &:checked {
    background-image: url(${ICN_RADIO_ON});
  }
`;

function attrsCheckBox() {
  return { type: 'checkbox' };
}
export const CheckBox = styled(InputBase).attrs(attrsCheckBox)`
  background-image: url(${ICN_CHECKBOX_OFF});
  background-position: center;
  width: 24px;
  height: 24px;

  cursor: pointer;

  &:checked {
    background-image: url(${ICN_CHECKBOX_ON});
  }
`;
