import { Parameter } from '@voithru/front-core';
import { AxiosInstance } from 'axios';
import { APIAccountId, APIPagination } from 'src/types/api';
import { generatePath } from 'react-router';
import {
  DocumentPageRequest,
  DocumentRequest,
  DocumentResponse,
  DocumentStatus,
  DocumentsUploadRequest,
} from 'src/types/api/Document';

const DOCUMENTS_PATH = '/api/v1/documents';

function apiDocuments(instance: AxiosInstance) {
  return {
    get: (params: APIAccountId & DocumentRequest) => {
      const query = Parameter.toURLSearchParams(params);
      return instance.get<DocumentResponse[]>(DOCUMENTS_PATH, { params: query });
    },
    pages: (params: DocumentPageRequest) => {
      const query = Parameter.toURLSearchParams(params);
      return instance.get<APIPagination<DocumentResponse>>(DOCUMENTS_PATH + '/page', { params: query });
    },
    post: (data: DocumentsUploadRequest) => {
      return instance.post(DOCUMENTS_PATH, data);
    },
    item(documentId: number) {
      const DOCUMENTS_ITEM_PATH = generatePath(DOCUMENTS_PATH + '/:documentId', { documentId });

      return {
        patch: (status: DocumentStatus) =>
          instance.patch(DOCUMENTS_ITEM_PATH + '/status', undefined, { params: { status } }),
      };
    },
  };
}

export default apiDocuments;
