import { gql } from '@apollo/client';

export const GQL_EDITOR_CREATE = gql`
  query jobFilesInEditorCreate($id: ID!) {
    job(id: $id) {
      jobFiles {
        file {
          id
          name
          s3Path
        }
      }
      project {
        productOrders {
          sourceLanguage
        }
      }
    }
  }
`;
