import { CButton } from '@coreui/react';
import React from 'react';
import { useHistory } from 'react-router';
import { Title } from 'src/components/atoms/styled/element';
import { Column, Row } from 'src/components/atoms/styled/layout';
import AppPaths from 'src/constants/AppPaths';
import AllocationWork from './components/AllocationWork';
import DeliveryStatus from './components/DeliveryStatus';
import DualTable from './components/DualTable';
import InspectionProcessing from './components/InspectionProcessing';
import TaskProgress from './components/TaskProgress';
import { DashboardWrapper } from './styled';

function DashBoardPage() {
  const history = useHistory();

  const handleCreateProject = React.useCallback(() => history.push(AppPaths.createProject.path), [history]);

  const handleCreateJob = React.useCallback(() => history.push(AppPaths.createJob.path), [history]);

  return (
    <DashboardWrapper>
      <Column>
        <Row>
          <Column style={{ justifyContent: 'center', width: '100%' }}>
            <Title>납품일 기준 할당작업현황</Title>
          </Column>
          <Column>
            <CButton
              onClick={() => {
                handleCreateProject();
              }}
            >
              프로젝트 생성하기
            </CButton>
          </Column>
          <Column>
            <CButton
              onClick={() => {
                handleCreateJob();
              }}
            >
              콘텐츠 생성하기
            </CButton>
          </Column>
        </Row>
        <AllocationWork />
      </Column>
      <DualTable />
      <Column>
        <Title>작업진행관리</Title>
        <TaskProgress />
      </Column>
      <Column>
        <Title>검수처리현황</Title>
        <InspectionProcessing />
      </Column>
      <Column>
        <Title>납품현황</Title>
        <DeliveryStatus />
        {/* <DeliveryStatusWithGraphql /> */}
      </Column>
    </DashboardWrapper>
  );
}

export default DashBoardPage;
