import { CCloseButton } from '@coreui/react';
import React from 'react';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import ICN_DOWNLOAD from 'src/assets/icons/icn-download-24px.svg';
import ICN_PREVIEW from 'src/assets/icons/icn-preview-24px.svg';
import ICN_UPLOAD from 'src/assets/icons/icn-upload-24px.svg';
import { ButtonBase } from 'src/components/atoms/styled/button';
import { Column, CSSRow, Row } from 'src/components/atoms/styled/layout';
import FormModal from 'src/components/organisms/FormModal';
import { useAuth } from 'src/components/context/AuthContext';
import { APIFileResponse } from 'src/types/api/File';
import { JobFileRequest, JobFileResponse, JobsResponse } from 'src/types/api/Job';
import { JobResultFileResponse, JobResultFileType, JobResultResponse } from 'src/types/api/JobResult';
import { ProductOrderResponse } from 'src/types/api/ProductOrder';
import { isAxiosError } from 'src/utils/api/axios';
import { dateFormat } from 'src/utils/date';
import { downloadAPIFile, NamedFile, selectFile, separateFileIntoNameAndExtension } from 'src/utils/files';
import { getPreviewUrl, previewToWindow } from 'src/utils/preview';
import { getByteString } from 'src/utils/string';
import styled from 'styled-components';
import { nanoid } from 'nanoid';
import MultipartUpload from 'src/utils/MultipartUpload';
import { useMount } from '@voithru/front-core';
import { useContentsUploaderService } from 'src/components/context/ContentsUploaderContext';
import { useUploaderContext } from 'src/components/context/UploaderContext';
import { handleEnterKeyDown } from 'src/utils/dom';
import {
  WorkRange,
  Source_Language,
  Translate_Language,
  ResultType,
  WorkTarget,
  AdditionalServices,
} from 'src/utils/translate';
import QueueTask from 'src/utils/QueueTask';

interface Props {
  title: string;
  type: 'job' | 'jobResult';

  visible: boolean;
  onVisible: (visible: boolean) => void;

  jobs?: JobsResponse[];
  jobResults?: JobResultResponse[];

  jobFileMap?: Map<number, JobFileResponse[]>;
  jobResultFileMap?: Map<number, JobResultFileResponse[]>;
  productOrderMap?: Map<number, ProductOrderResponse>;

  transactionId: string;
}

function FileDownloadModal(props: Props) {
  const {
    title,
    type,
    visible,
    onVisible,
    jobs,
    jobResults,
    productOrderMap,
    jobFileMap = new Map<number, JobFileResponse[]>(),
    jobResultFileMap = new Map<number, JobResultFileResponse[]>(),
    transactionId,
  } = props;

  const queueTask = React.useMemo(() => new QueueTask(), []);

  const { user } = useAuth();

  const [editFileId, setEditFileId] = React.useState<string>();

  const editFileRef = React.useRef<HTMLInputElement>(null);

  const [, forceUpdate] = React.useReducer((x) => !x, true);

  const registerService = useContentsUploaderService();
  const context = useUploaderContext();

  const manager = React.useMemo(
    () => registerService.createUploadService(transactionId),
    [registerService, transactionId]
  );

  const dataJobOrJobResult = React.useMemo(() => {
    if (type === 'job') {
      return jobs;
    }

    if (type === 'jobResult') {
      return jobResults;
    }

    return undefined;
  }, [type, jobs, jobResults]);

  const filteredJobs = React.useMemo(() => {
    if (type === 'job') return jobs;

    if (type === 'jobResult') {
      return jobs?.filter((it) => {
        return jobResults?.find((result) => result.jobId === it.id);
      });
    }

    return undefined;
  }, [type, jobs, jobResults]);

  const jobResultsMap = React.useMemo(() => {
    const map = new Map<number, JobResultResponse[]>();
    if (!jobResults) return;

    jobResults.forEach((it) => {
      const maps = [...(map.get(it.jobId) ?? []), it];
      map.set(it.jobId, maps);
    });

    return map;
  }, [jobResults]);

  const handleFileAllDownload = React.useCallback(() => {
    if (jobFileMap) {
      const entry = jobFileMap.entries();

      for (const item of entry) {
        const [, files] = item;

        files.forEach((it) => {
          queueTask.dispatch(downloadAPIFile, it.file);
        });
      }
    }

    if (jobResultFileMap) {
      const entry = jobResultFileMap.entries();

      for (const item of entry) {
        const [, files] = item;

        files.forEach((it) => {
          if (!it.file) {
            return;
          }

          queueTask.dispatch(downloadAPIFile, it.file);
        });
      }
    }
  }, [jobFileMap, jobResultFileMap, queueTask]);

  const jobFilesDownload = React.useCallback(
    (id: number) => {
      const files = jobFileMap?.get(id) ?? null;
      if (!files) return;

      for (const item of files) {
        downloadAPIFile(item.file);
      }
    },
    [jobFileMap]
  );

  const jobResultFilesDonwload = React.useCallback(
    (id: number) => {
      const files = jobResultFileMap?.get(id) ?? null;
      if (!files) return;

      for (const item of files) {
        item.file && downloadAPIFile(item.file);
      }
    },
    [jobResultFileMap]
  );

  const fileDownload = React.useCallback((file?: APIFileResponse) => {
    file && downloadAPIFile(file);
  }, []);

  const getJobFiles = React.useCallback(async () => {
    if (jobs) {
      for (const item of jobs) {
        const res = await api.jobs.item(item.id).files.get();

        if (isAxiosError(res)) {
          throw res;
        }

        if (res.data) {
          jobFileMap.set(item.id, res.data);
        }
      }
    }
  }, [jobFileMap, jobs]);

  const getJobResultFiles = React.useCallback(async () => {
    if (jobResults) {
      for (const item of jobResults) {
        const res = await api.jobResult.item(item.id).files();

        if (isAxiosError(res)) {
          throw res;
        }

        if (res.data) {
          jobResultFileMap.set(item.id, res.data);
        }
      }
    }
  }, [jobResultFileMap, jobResults]);

  const handleRemoveJobFile = React.useCallback(
    async (jobId, jobFileId) => {
      if (window.confirm('해당 파일을 삭제하시겠습니까?')) {
        try {
          const res = await api.jobs.item(jobId).files.item(jobFileId).delete({
            jobId,
            fileId: jobFileId,
          });
          if (isAxiosError(res)) {
            throw res;
          }

          await getJobFiles();
          forceUpdate();
        } catch (error) {
          errorLogger.error(error);
        }
      }
    },
    [getJobFiles]
  );

  const handleRemoveJobResultFile = React.useCallback(
    async (jobId: number, jobResultId: number, jobResultFileId?: string) => {
      if (!jobResultFileId) {
        return;
      }

      if (window.confirm('해당 파일을 삭제하시겠습니까?')) {
        try {
          const res = await api.jobs.item(jobId).jobResultItem(jobResultId).files.item(jobResultFileId).delete({
            jobResultId,
            fileId: jobResultFileId,
          });
          if (isAxiosError(res)) {
            throw res;
          }

          await getJobResultFiles();
          forceUpdate();
        } catch (error) {
          errorLogger.error(error);
        }
      }
    },
    [getJobResultFiles]
  );

  const handleFilePreview = React.useCallback(async (file?: APIFileResponse) => {
    if (!file) {
      return;
    }

    const data = await getPreviewUrl({
      id: file.id,
      name: file.name ?? '',
    }).catch(errorLogger.error);

    if (!data) return;

    previewToWindow(data);
  }, []);

  const handleJobFileUpload = React.useCallback(
    async (jobId: number) => {
      if (user?.role !== 'ADMIN') {
        window.alert('파일 업로드 권한이 없습니다.');
        return;
      }

      const file = await selectFile({ multiple: false });

      if (file) {
        const namedFile: NamedFile = {
          id: nanoid(),
          name: file[0].name,
          file: file[0],
        };

        const lastIndex = Math.max(...(jobFileMap?.get(jobId)?.map((a) => a.index ?? 0) ?? []));

        const uploader = new MultipartUpload(namedFile);

        await uploader.ready('JOB');

        uploader.addEventListener('done', async () => {
          const data: JobFileRequest[] = [
            {
              jobId,
              fileId: uploader.fileId ?? '',
              fileType: 'CONTENTS_FILE',
              index: lastIndex + 1,
            },
          ];

          await api.jobs
            .item(jobId)
            .files.post(data)
            .catch(errorLogger.error)
            .finally(async () => {
              const res = await api.jobs.item(jobId).files.get();
              if (isAxiosError(res)) {
                throw res;
              }
              jobFileMap.set(jobId, res.data);

              forceUpdate();
            });
        });

        manager.register(uploader);

        uploader.start();
      }
    },
    [user?.role, jobFileMap, manager]
  );

  const handleJobResultFileUpload = React.useCallback(
    async (jobId: number, jobResultId: number, multiple: boolean = false, directory: boolean = false) => {
      if (user?.role !== 'ADMIN') {
        window.alert('파일 업로드 권한이 없습니다.');
        return;
      }

      const files = await selectFile({ multiple, directory });

      const uploadFile = async (file: File, fileIndex: number) => {
        const namedFile: NamedFile = {
          id: nanoid(),
          name: file.webkitRelativePath || file.name,
          file: file,
        };

        const fileType = namedFile.name.split('.');
        let fileTypeUpperCase = fileType[fileType.length - 1].toUpperCase();
        const JobResultFileTypes = ['SRT', 'PSD', 'IMAGE', 'TEXT', 'VIDEO', 'OTHER'];

        if (!JobResultFileTypes.includes(fileTypeUpperCase)) {
          fileTypeUpperCase = 'OTHER';
        }

        const uploader = new MultipartUpload(namedFile);

        // job 사용하지않음
        await uploader.ready('JOB');

        uploader.addEventListener('done', async () => {
          if (!uploader.fileId) {
            return;
          }

          const data = [
            {
              jobResultId,
              fileId: uploader.fileId,
              fileType: fileTypeUpperCase as JobResultFileType,
              index: fileIndex,
            },
          ];

          await api.jobs
            .item(jobId)
            .jobResultItem(jobResultId)
            .files.post(data)
            .catch(errorLogger.error)
            .finally(async () => {
              try {
                const filesRes = await api.jobResult.item(jobResultId).files();

                if (isAxiosError(filesRes)) {
                  throw filesRes;
                }

                jobResultFileMap.set(jobResultId, filesRes.data);

                forceUpdate();
              } catch (err) {
                errorLogger.error(err);
              }
            });
        });

        manager.register(uploader);

        uploader.start();
      };

      if (files) {
        const rt: Array<Promise<void>> = [];

        const lastIndex = Math.max(...(jobResultFileMap.get(jobResultId)?.map((a) => a.index ?? 0) ?? []));

        Array.from(files).forEach((file, index) => {
          rt.push(queueTask.dispatch(uploadFile, file, lastIndex + index + 1));
        });

        await Promise.all(rt);
      }
    },
    [jobResultFileMap, manager, queueTask, user?.role]
  );

  const handleEditJobFileName = React.useCallback(
    async (jobFile: JobFileResponse, extension?: string | null) => {
      if (
        !editFileRef.current?.value ||
        !jobFile.file ||
        jobFile.file.name === editFileRef.current?.value ||
        !editFileId
      ) {
        return;
      }

      await api.file
        .item(editFileId)
        .put({
          ...jobFile.file,
          name: editFileRef.current?.value + extension,
        })
        .catch(errorLogger.error);

      await getJobFiles().catch(errorLogger.error);

      setEditFileId(undefined);
    },
    [editFileId, getJobFiles]
  );

  const handleEditJobResultFileName = React.useCallback(
    async (jobResultFile: JobResultFileResponse, extension?: string | null) => {
      if (
        !editFileRef.current?.value ||
        !jobResultFile.file ||
        jobResultFile.file.name === editFileRef.current?.value ||
        !editFileId
      ) {
        return;
      }

      await api.file
        .item(editFileId)
        .put({
          ...jobResultFile.file,
          name: editFileRef.current?.value + extension,
        })
        .catch(errorLogger.error);

      await getJobResultFiles().catch(errorLogger.error);

      setEditFileId(undefined);
    },
    [editFileId, getJobResultFiles]
  );

  React.useEffect(() => {
    if (editFileId === undefined) return;

    editFileRef.current?.focus();
  }, [editFileId]);

  useMount(() => {
    context.register(transactionId, manager);
  });

  return (
    <FormModal title={title} visible={visible} onDismiss={() => onVisible(false)} onSubmit={() => {}}>
      <FileDownloadModalWrap>
        <Row className={'buttonWrap'}>
          <ButtonBase onClick={handleFileAllDownload} disabled={!dataJobOrJobResult?.length}>
            전체 다운로드
          </ButtonBase>
        </Row>
        <Column>
          {filteredJobs?.map((it) => (
            <React.Fragment key={it.id}>
              <Row className={'titleWrap'}>
                <span>CON-{it.id}</span>
                <span>{it.name}</span>
                <ButtonBase onClick={() => jobFilesDownload(it.id)}>
                  <img src={ICN_DOWNLOAD} alt={'download'} />
                </ButtonBase>
              </Row>
              {type === 'jobResult' &&
                jobResultsMap &&
                jobResultsMap.get(it.id)?.map((jobResult) => {
                  const productOrder = productOrderMap?.get(jobResult.productOrderId) ?? null;
                  return (
                    <React.Fragment key={jobResult.id}>
                      <Column>
                        <Row>
                          <span>JR-{jobResult.id} </span>
                          {productOrder && (
                            <span>
                              {WorkRange[productOrder.productType]}/{Source_Language[productOrder.sourceLanguage]}/
                              {productOrder.translateLanguage
                                ? Translate_Language[productOrder.translateLanguage]
                                : '-'}
                              /{productOrder.deliveryType && ResultType[productOrder.deliveryType]}/
                              {productOrder.productSource ? WorkTarget[productOrder.productSource] : '-'}
                              {productOrder.options?.map((option) => AdditionalServices[option.type]).join(',')}
                            </span>
                          )}
                          <ButtonBase onClick={() => jobResultFilesDonwload(jobResult.id)}>
                            <img src={ICN_DOWNLOAD} alt={'download'} />
                          </ButtonBase>
                        </Row>
                        {jobResultFileMap?.get(jobResult.id)?.map((jobResultFile) => {
                          if (jobResultFile.file) {
                            const splitJobResultFileName = separateFileIntoNameAndExtension(jobResultFile.file.name);

                            return (
                              <Row key={jobResultFile.id} className={'files'}>
                                {editFileId === jobResultFile.fileId && (
                                  <span>
                                    <input
                                      defaultValue={splitJobResultFileName[0] || ''}
                                      ref={editFileRef}
                                      onBlur={() =>
                                        handleEditJobResultFileName(jobResultFile, splitJobResultFileName[1])
                                      }
                                      onKeyDown={(e) => handleEnterKeyDown(e, editFileRef)}
                                    />
                                    {splitJobResultFileName[1]}
                                  </span>
                                )}
                                {editFileId !== jobResultFile.fileId && (
                                  <span onDoubleClick={() => setEditFileId(jobResultFile.fileId)}>
                                    {jobResultFile.file.name}
                                  </span>
                                )}
                                <span>{dateFormat(jobResultFile.file.createDateTime, 'YYYY.MM.DD')}</span>
                                <span>{jobResultFile.file.volume}</span>
                                <ButtonBase onClick={() => handleFilePreview(jobResultFile?.file)}>
                                  <img src={ICN_PREVIEW} alt={'preview'} />
                                </ButtonBase>
                                <ButtonBase onClick={() => fileDownload(jobResultFile.file)}>
                                  <img src={ICN_DOWNLOAD} alt={'download'} />
                                </ButtonBase>
                                <ButtonBase
                                  onClick={() =>
                                    handleRemoveJobResultFile(it.id, jobResult.id, jobResultFile?.file?.id)
                                  }
                                >
                                  <CCloseButton onClick={(e) => e.preventDefault()} />
                                </ButtonBase>
                              </Row>
                            );
                          }

                          return null;
                        })}
                      </Column>
                      <ButtonBase onClick={() => handleJobResultFileUpload(it.id, jobResult.id, true, false)}>
                        <img src={ICN_UPLOAD} alt={'upload'} />
                        파일 첨부하기
                      </ButtonBase>
                      <ButtonBase onClick={() => handleJobResultFileUpload(it.id, jobResult.id, false, true)}>
                        <img src={ICN_UPLOAD} alt={'upload'} />
                        폴더 첨부하기
                      </ButtonBase>
                    </React.Fragment>
                  );
                })}
              {type === 'job' &&
                jobFileMap
                  ?.get(it.id)
                  ?.sort((lhs, rhs) => {
                    return lhs.index - rhs.index;
                  })
                  .map((jobFile) => {
                    const splitJobFileName = separateFileIntoNameAndExtension(jobFile.file.name);

                    return (
                      <Row key={jobFile.id} className={'files'}>
                        {editFileId === jobFile.fileId && (
                          <span>
                            <input
                              defaultValue={splitJobFileName[0] || ''}
                              ref={editFileRef}
                              onBlur={() => handleEditJobFileName(jobFile, splitJobFileName[1])}
                              onKeyDown={(e) => handleEnterKeyDown(e, editFileRef)}
                            />
                            {splitJobFileName[1]}
                          </span>
                        )}
                        {editFileId !== jobFile.fileId && (
                          <span onDoubleClick={() => setEditFileId(jobFile.fileId)}>{jobFile.file.name}</span>
                        )}
                        <span>{dateFormat(jobFile.createDateTime, 'YYYY.MM.DD')}</span>
                        <span>{getByteString(jobFile.file.volume)}</span>
                        <ButtonBase onClick={() => handleFilePreview(jobFile.file)}>
                          <img src={ICN_PREVIEW} alt={'preview'} />
                        </ButtonBase>

                        <ButtonBase onClick={() => fileDownload(jobFile.file)}>
                          <img src={ICN_DOWNLOAD} alt={'download'} />
                        </ButtonBase>
                        <ButtonBase onClick={() => handleRemoveJobFile(it.id, jobFile.file.id)}>
                          <CCloseButton onClick={(e) => e.preventDefault()} />
                        </ButtonBase>
                      </Row>
                    );
                  })}
              {type === 'job' && (
                <ButtonBase onClick={() => handleJobFileUpload(it.id)}>
                  <img src={ICN_UPLOAD} alt={'upload'} />
                  파일 첨부하기
                </ButtonBase>
              )}
            </React.Fragment>
          ))}
        </Column>
      </FileDownloadModalWrap>
    </FormModal>
  );
}

export default FileDownloadModal;

const FileDownloadModalWrap = styled(Column)`
  & > ${Row}.buttonWrap {
    justify-content: flex-end;
  }

  & > ${Column} {
    padding: 12px;

    font-size: 15px;
    & > ${Row}.titleWrap {
      & > span {
        margin-right: 20px;
      }
    }

    & > ${Column} {
      padding: 12px;
    }
    & > ${ButtonBase} {
      ${CSSRow};
      align-items: center;
      justify-content: center;

      & > img {
        margin-right: 4px;
      }
    }

    ${Row}.files {
      margin-left: 85px;

      & > span:first-child {
        flex: 1;
      }

      & > span {
        margin-right: 25px;
      }
    }
  }
`;
