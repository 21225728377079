import { Column, Row } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const PaymentSettlementTitleWrapper = styled(Row)`
  margin: 12px;
  padding: 12px;
  align-items: center;

  background: #006be517;

  & > ${Column} {
    margin-left: 18px;
  }
`;

export const PaymentSettlementBodyWrapper = styled(Column)`
  margin-top: 20px;

  border-bottom: 1px solid ${getRGBA(colors.grey80)};
  & > ${Row} {
    padding: 4px 0;

    & > span {
      padding-left: 4px;
    }

    font-size: 15px;
    text-align: left;
    span:nth-child(1) {
      width: 30px;
    }
    span:nth-child(2) {
      width: 100px;
    }
    span:nth-child(3) {
      width: 140px;
    }
    span:nth-child(4) {
      width: 140px;
    }
    span:nth-child(5) {
      width: 80px;
    }
    span:nth-child(6) {
      width: 100px;
    }
    span:nth-child(7) {
      width: 80px;
    }
    span:nth-child(8) {
      width: 80px;
    }
    span:nth-child(9) {
      width: 100px;
    }
    span:nth-child(10) {
      width: 120px;
    }
    span:nth-child(11) {
      width: 100px;
    }

    & > ${Column}.additionalServices {
      width: 100px;

      & > span {
        width: 100px;
      }
    }
  }

  & > ${Row}:first-child {
    border-bottom: 1px solid ${getRGBA(colors.grey80)};
    box-sizing: border-box;
  }

  & > ${Row} + ${Row} {
    border-bottom: 1px solid ${getRGBA(colors.grey50)};
    box-sizing: border-box;
  }

  & > ${Row}:last-child {
    border-bottom: 0px;
  }
`;
