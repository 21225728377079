import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react';
import React from 'react';

interface Props {
  productCount: number;
  onClick: () => void;
}

function AddProductsAlertModal(props: Props) {
  const { productCount, onClick } = props;

  const [visible, setVisible] = React.useState(false);

  const onConfirm = React.useCallback(() => {
    onClick();
    setVisible(false);
  }, [onClick]);

  return (
    <div className="align-items-center d-flex">
      <CButton style={{ width: 'max-content' }} onClick={() => setVisible(!visible)}>
        추가하기
      </CButton>
      <CModal size={'sm'} visible={visible} onDismiss={() => setVisible(false)}>
        <CModalHeader onDismiss={() => setVisible(false)}>
          <CModalTitle>신규상품 등록</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div>
            <div>총 {productCount}개의 신규 상품을 등록하시겠습니까?</div>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            취소
          </CButton>
          <CButton onClick={() => onConfirm()}>확인</CButton>
        </CModalFooter>
      </CModal>
    </div>
  );
}
export default AddProductsAlertModal;
