import { useApolloClient } from '@apollo/client';
import { CCard, CCardBody, CTable, CTableBody, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import { useForceUpdate, useMount } from '@voithru/front-core';
import React from 'react';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import minglo, { MingloAccount } from 'src/api/minglo';
import { ProductOptionType } from 'src/types/graphql';
import { isAxiosError } from 'src/utils/api/axios';
import { handlePreventEvent } from 'src/utils/dom';
import JobResultSelectTable from './components/JobResultSelectTable';
import MingloAccountSearch from './components/MingloAccountSearch';
import MingloWorkerTableRow from './components/MingloWorkerTableRow';
import { GQL_WORKER_MANAGE } from './gql';
import { WorkerManageModalQuery, WorkerManageModalQueryVariables } from './gql.generated';
import { WorkerManageModalWrapper } from './styled';

type GrantStatus = 'ALL' | 'PARTIAL' | 'NONE';

interface MingloAccountWithJobResults {
  account: MingloAccount;
  jobResultIds: string[];
}

interface Props {
  jobResultIds: string[];

  onDismiss(): void;
}

function WorkerManageModal(props: Props) {
  const { jobResultIds, onDismiss } = props;

  const forceUpdate = useForceUpdate();
  const client = useApolloClient();

  const mingloAccounts = React.useMemo(() => new Map<number, MingloAccount>(), []);
  const loadMinglo = React.useCallback(
    async (id: number) => {
      if (mingloAccounts.has(id)) {
        return mingloAccounts.get(id)!;
      }

      const res = await minglo.account.item(id).get();
      if (isAxiosError(res)) {
        throw res;
      }

      mingloAccounts.set(id, res.data);
      forceUpdate();
      return res.data;
    },
    [forceUpdate, mingloAccounts]
  );

  const jobResults = React.useMemo(() => new Map<string, NonNullable<WorkerManageModalQuery['jobResult']>>(), []);
  const load = React.useCallback(
    async (id: string) => {
      const res = await client.query<WorkerManageModalQuery, WorkerManageModalQueryVariables>({
        query: GQL_WORKER_MANAGE,
        variables: { id },
      });
      if (res.error) {
        throw res;
      }

      for (const grants of res.data.jobResult?.workerJobResultGrants ?? []) {
        loadMinglo(grants.hermesAccountId).catch(errorLogger.error);
      }

      return res.data;
    },
    [client, loadMinglo]
  );

  useMount(() => {
    for (const id of jobResultIds) {
      load(id)
        .then((it) => it.jobResult && jobResults.set(id, it.jobResult))
        .then(() => forceUpdate())
        .catch(errorLogger.error);
    }
  });

  // AccountId, GrantStatus
  const [grant, setGrant] = React.useState(() => new Map<number, GrantStatus>());

  const [selected, setSelected] = React.useState<string[]>([]);
  const [accounts, setAccounts] = React.useState<MingloAccountWithJobResults[]>([]);
  const [addedAccounts, setAddedAccounts] = React.useState<MingloAccount[]>([]);

  React.useEffect(() => {
    setGrant(new Map());
  }, [selected]);

  React.useEffect(() => {
    if (selected.length === 0) {
      setAccounts([]);
      return;
    }

    const accounts = Array.from(mingloAccounts.values())
      .map(
        (it): MingloAccountWithJobResults => ({
          account: it,
          jobResultIds: selected.filter((s) => {
            const target = jobResults.get(s);
            if (!target) {
              return false;
            }

            return target.workerJobResultGrants?.some((g) => g.hermesAccountId === it.id);
          }),
        })
      )
      .filter((it) => it.jobResultIds.length !== 0);

    setAccounts(accounts);
  }, [addedAccounts, jobResults, mingloAccounts, selected]);

  const handleSearchClick = React.useCallback((account: MingloAccount) => {
    setAddedAccounts((prev) => [...prev, account]);
  }, []);

  const handleDeleteAddedAccount = React.useCallback((account: MingloAccount) => {
    setAddedAccounts((prev) => prev.filter((it) => it.id !== account.id));
  }, []);

  const handleChangeCheck = React.useCallback(
    (account: MingloAccount) => (status: GrantStatus) => {
      grant.set(account.id, status);
    },
    [grant]
  );

  const handleSubmit = React.useCallback(
    async (e: React.FormEvent) => {
      handlePreventEvent(e);

      if (
        selected.length === 0 ||
        selected.some((it) => !jobResults.has(it)) ||
        Array.from(grant.keys()).length === 0
      ) {
        return;
      }

      for (const jobResultId of selected) {
        try {
          const target = jobResults.get(jobResultId);
          if (!target) {
            continue;
          }

          const granted = Array.from(grant.entries()).filter(([_, value]) => value === 'ALL');
          const declined = Array.from(grant.entries()).filter(([_, value]) => value === 'NONE');

          const requestPost = granted.map(([hermesAccountId]) => ({
            projectId: target.projectId,
            jobResultId: Number(jobResultId),
            hermesAccountId,
          }));
          const resPost = await api.workerJobResultGrants.post(requestPost);
          if (isAxiosError(resPost)) {
            throw resPost;
          }

          const requestDelete = declined.map(([hermesAccountId]) => ({
            projectId: target.projectId,
            jobResultId: Number(jobResultId),
            hermesAccountId,
          }));
          const resDelete = await api.workerJobResultGrants.delete(requestDelete);
          if (isAxiosError(resDelete)) {
            throw resDelete;
          }
        } catch (error) {
          errorLogger.error(error);
        }
      }

      onDismiss?.();
    },
    [grant, jobResults, onDismiss, selected]
  );

  return (
    <WorkerManageModalWrapper visible title={'작업담당자 관리'} onDismiss={() => onDismiss?.()} onSubmit={handleSubmit}>
      <CCard>
        <CCardBody>
          <JobResultSelectTable
            data={Array.from(jobResults.values()).map((it) => ({
              id: it.id,
              name: it.job.name || '-',
              productType: it.productOrder?.productType,
              productSource: it.productOrder?.productSource,

              sourceLanguage: it.productOrder?.sourceLanguage,
              translateLanguage: it.productOrder?.translateLanguage,
              deliveryType: it.productOrder?.deliveryType,

              productOptionOrders: it.productOrder?.productOptionOrders
                ?.filter((it) => Boolean(it.type))
                ?.map((it) => it.type) as ProductOptionType[],
            }))}
            onChange={setSelected}
          />
        </CCardBody>
      </CCard>
      <CCard style={{ marginTop: 16 }}>
        <CCardBody>
          <MingloAccountSearch
            exclude={[...accounts.map((it) => it.account.id), ...addedAccounts.map((it) => it.id)]}
            onClick={handleSearchClick}
          />
          <CTable>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell>ID</CTableHeaderCell>
                <CTableHeaderCell>이름</CTableHeaderCell>
                <CTableHeaderCell>이메일</CTableHeaderCell>
                <CTableHeaderCell>전화번호</CTableHeaderCell>
                <CTableHeaderCell>할당정보</CTableHeaderCell>
                <CTableHeaderCell>할당정보 변경</CTableHeaderCell>
                <CTableHeaderCell>삭제</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {accounts.map((it) => (
                <MingloWorkerTableRow
                  key={it.account.id}
                  data={it.account}
                  status={it.jobResultIds.length === selected.length ? 'ALL' : 'PARTIAL'}
                  onChangeStatus={handleChangeCheck(it.account)}
                />
              ))}
              {addedAccounts
                .filter((account) => !accounts.find((it) => it.account.id === account.id))
                .map((it) => (
                  <MingloWorkerTableRow
                    key={it.id}
                    data={it}
                    status={'NONE'}
                    onChangeStatus={handleChangeCheck(it)}
                    onClickDelete={handleDeleteAddedAccount}
                  />
                ))}
            </CTableBody>
          </CTable>
        </CCardBody>
      </CCard>
    </WorkerManageModalWrapper>
  );
}

export default WorkerManageModal;
