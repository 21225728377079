import axios from 'axios';
import { LOGGER_SERVER, LOGGER_SERVER_TIMEOUT } from 'src/constants/API';
import { APP_ENVIRONMENT } from 'src/constants/configs';
import LocalStorageManager from '../utils/LocalStorageManager';

const axiosLogger = axios.create({
  baseURL: LOGGER_SERVER,
  timeout: LOGGER_SERVER_TIMEOUT,
});

async function rrwebLog(event: unknown) {
  try {
    // logger use snake case data format
    const log = {
      app_name: 'backlot-admin-front',
      env: APP_ENVIRONMENT === 'production' ? 'prod' : 'dev',
      event: event,
      user_agent: window.navigator.userAgent,
      path: window.location.href,
      user_data: LocalStorageManager.getItem('user') || 'none',
    };

    await axiosLogger.post('/api/logs/screens', log, { withCredentials: true });
  } catch (err) {
    console.error(err);
  }
}

const logger = { rrwebLog: rrwebLog };
export default logger;
