import React from 'react';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { APIPaginationSort } from 'src/types/api';
import { EntityHistoryNotificationAPIResponse, EntityHistoryNotificationRequest } from 'src/types/api/EntityHistory';
import { isAxiosError } from 'src/utils/api/axios';
import { dateCmp, TimeUnit } from 'src/utils/date';
import { useAuth } from '../AuthContext';

const PAGE_SIZE = 6;
const RECENT_DATE_SPAN = 7 * TimeUnit.DAY;
const NOTIFICATION_POLLING_INTERVAL = 5 * TimeUnit.MIN;

function useNotificationManager() {
  const { user } = useAuth();

  const [totalSize, setTotalSize] = React.useState<number>(Infinity);
  const cachedNotifications = React.useMemo(() => new Map<number, EntityHistoryNotificationAPIResponse>(), []);
  const cachedPages = React.useMemo(() => new Map<number, number[]>(), []);
  const totalPage = React.useMemo(() => Math.ceil(totalSize / PAGE_SIZE), [totalSize]);
  const sort = React.useMemo(() => {
    const sortInfo: APIPaginationSort = {
      sortKey: 'createDateTime',
      direction: 'desc',
    };
    return `${sortInfo.sortKey},${sortInfo.direction}`;
  }, []);

  const [lastNotificationId, setLastNotificationId] = React.useState<number>(NaN);
  const [anyRecentUnreadNotificationId, setAnyRecentUnreadNotificationId] = React.useState<number>(NaN);
  const hasRecentUnreadNotification = React.useCallback(
    () => !isNaN(anyRecentUnreadNotificationId),
    [anyRecentUnreadNotificationId]
  );

  const fetchNotificationFromCache = React.useCallback(
    (notificationId: number) => {
      if (isNaN(notificationId)) {
        return null;
      }
      return cachedNotifications.get(notificationId) || null;
    },
    [cachedNotifications]
  );

  const isRecentNotification = React.useCallback(
    (notification: number | EntityHistoryNotificationAPIResponse) => {
      if (typeof notification === 'number') {
        if (isNaN(notification)) {
          return false;
        }
        const fetchedNotification = fetchNotificationFromCache(notification);
        if (!fetchedNotification) {
          return false;
        }
        notification = fetchedNotification;
      }

      const recentDateThreshold = new Date();
      recentDateThreshold.setTime(recentDateThreshold.getTime() - RECENT_DATE_SPAN);

      return dateCmp(recentDateThreshold, notification.createDateTime) <= 0;
    },
    [fetchNotificationFromCache]
  );
  const hasAnyRecentNotification = React.useCallback(
    () => isRecentNotification(lastNotificationId),
    [isRecentNotification, lastNotificationId]
  );
  const isRecentUnreadNotification = React.useCallback(
    (notification: number | EntityHistoryNotificationAPIResponse) => {
      if (typeof notification === 'number') {
        if (isNaN(notification)) {
          return false;
        }
        const fetchedNotification = fetchNotificationFromCache(notification);
        if (!fetchedNotification) {
          return false;
        }
        notification = fetchedNotification;
      }

      return Boolean(notification.status === 'NOT_READ' && isRecentNotification(notification));
    },
    [fetchNotificationFromCache, isRecentNotification]
  );

  const updateNotificationItemsUsingCands = React.useCallback(
    (cands: EntityHistoryNotificationAPIResponse[]) => {
      setLastNotificationId((prevId) => {
        const lastAmongCands = cands[0];
        const lastNotification = fetchNotificationFromCache(prevId);
        if (
          lastAmongCands &&
          (!lastNotification || dateCmp(lastNotification.createDateTime, lastAmongCands.createDateTime) < 0)
        ) {
          return lastAmongCands.id;
        } else {
          return prevId;
        }
      });
      setAnyRecentUnreadNotificationId((prevId) => {
        if (isRecentUnreadNotification(prevId)) {
          return prevId;
        }
        const anyUnreadAmongCands = cands.find(isRecentUnreadNotification);
        if (anyUnreadAmongCands) {
          return anyUnreadAmongCands.id;
        } else {
          return NaN;
        }
      });
    },
    [fetchNotificationFromCache, isRecentUnreadNotification]
  );

  const clearCache = React.useCallback(() => {
    cachedNotifications.clear();

    setTotalSize(1);

    cachedPages.clear();

    setLastNotificationId(NaN);

    setAnyRecentUnreadNotificationId(NaN);
  }, [cachedNotifications, cachedPages]);

  const getPage = React.useCallback(
    async (partialRequest: EntityHistoryNotificationRequest = {}) => {
      if (user === undefined) {
        return { element: [], pageSize: 1, currentPage: 0, totalPage: 0, totalSize: 0 };
      }

      const request: EntityHistoryNotificationRequest = {
        accountId: user?.id,
        sort,
        size: 1,
        page: 0,
        ...partialRequest,
      };

      const res = await api.entityHistory.notification.site.get(request);
      if (isAxiosError(res)) {
        throw res;
      }

      const requestedPage = res.data.element;
      requestedPage.forEach((notification) => {
        const prevNotification = cachedNotifications.get(notification.id);
        if (prevNotification?.status === notification.status) {
          return;
        }
        cachedNotifications.set(notification.id, notification);
      });
      updateNotificationItemsUsingCands(requestedPage);
      if (!partialRequest.status || !partialRequest.afterCreateDateTime) {
        setTotalSize(res.data.totalSize);
      }
      return res.data;
    },
    [user, sort, updateNotificationItemsUsingCands, cachedNotifications]
  );

  const getLastNotificationId = React.useCallback(async () => {
    const { element } = await getPage();
    const nextId = element[0] ? element[0].id : NaN;

    return nextId;
  }, [getPage]);

  const retrieveAnyUnreadNotificationFromCache = React.useCallback(() => {
    return Array.from(cachedNotifications.values()).find(isRecentUnreadNotification);
  }, [cachedNotifications, isRecentUnreadNotification]);

  const getAnyUnreadNotificationId = React.useCallback(
    (hint: number = NaN) => {
      setAnyRecentUnreadNotificationId((prevId) => {
        if (isRecentUnreadNotification(prevId)) {
          return prevId;
        }

        if (isRecentUnreadNotification(hint)) {
          return hint;
        }

        const nextUnread = retrieveAnyUnreadNotificationFromCache();
        if (nextUnread) {
          return nextUnread.id;
        }

        (async () => {
          if (isRecentUnreadNotification(prevId)) {
            return;
          }

          const recentDateThreshold = new Date();
          recentDateThreshold.setTime(recentDateThreshold.getTime() - RECENT_DATE_SPAN);

          const { element } = await getPage({
            status: 'NOT_READ',
            afterCreateDateTime: recentDateThreshold.toISOString(),
          });
          const found = element[0] ? element[0].id : NaN;
          setAnyRecentUnreadNotificationId((prevId2) => (isRecentUnreadNotification(prevId2) ? prevId2 : found));
        })();

        return NaN;
      });
    },
    [isRecentUnreadNotification, getPage, retrieveAnyUnreadNotificationFromCache]
  );

  const markAsRead = React.useCallback(
    async (notificationId: number) => {
      const res = await api.entityHistory.notification.site.item(notificationId).read({ status: 'READ' });
      if (isAxiosError(res)) {
        errorLogger.error(res);
      }
      cachedNotifications.set(notificationId, res.data);

      if (notificationId !== anyRecentUnreadNotificationId) {
        return;
      }
      getAnyUnreadNotificationId();
    },
    [anyRecentUnreadNotificationId, cachedNotifications, getAnyUnreadNotificationId]
  );

  const tryRefresh = React.useCallback(async () => {
    const hint = await getLastNotificationId();
    await getAnyUnreadNotificationId(hint);
  }, [getLastNotificationId, getAnyUnreadNotificationId]);

  React.useEffect(() => {
    const intervalHandle = setInterval(tryRefresh, NOTIFICATION_POLLING_INTERVAL);
    return () => {
      clearInterval(intervalHandle);
    };
  }, [tryRefresh]);

  React.useEffect(() => {
    cachedPages.clear();
  }, [lastNotificationId, cachedPages]);

  React.useEffect(() => {
    if (user === undefined) {
      clearCache();
    } else {
      tryRefresh();
    }
  }, [clearCache, tryRefresh, user]);

  const fetchPage = React.useCallback(
    async (page: number) => {
      page--;

      if (page < 0) {
        return [];
      }

      const cachedPage = cachedPages.get(page);
      if (cachedPage) {
        return cachedPage.map((notificationId) => cachedNotifications.get(notificationId)!);
      }
      if (page > totalPage) {
        return [];
      }
      const { element } = await getPage({ page, size: PAGE_SIZE });
      cachedPages.set(
        page,
        element.map((notification) => notification.id)
      );

      return element;
    },
    [cachedPages, totalPage, cachedNotifications, getPage]
  );

  const fetchRecentPage = React.useCallback(
    async (page: number) => {
      const recentDateThreshold = new Date();
      recentDateThreshold.setTime(recentDateThreshold.getTime() - RECENT_DATE_SPAN);

      const notifications = await fetchPage(page);

      const firstNotification = notifications[0] || null;
      const lastNotification = notifications[notifications.length - 1] || null;
      const containsOldNotifications =
        lastNotification && dateCmp(recentDateThreshold, lastNotification.createDateTime) > 0;
      const containsRecentNotifications =
        firstNotification && dateCmp(recentDateThreshold, firstNotification.createDateTime) <= 0;
      if (!containsRecentNotifications) {
        return [];
      } else if (!containsOldNotifications) {
        return notifications;
      }

      return notifications.filter((notification) => dateCmp(recentDateThreshold, notification.createDateTime) <= 0);
    },
    [fetchPage]
  );

  return {
    fetchPage,
    fetchRecentPage,
    fetchNotificationFromCache,
    hasAnyRecentNotification,
    markAsRead,
    tryRefresh,
    totalPage,
    hasRecentUnreadNotification,
  };
}

export default useNotificationManager;
