import React from 'react';
import useLoadingManager from './useLoadingManager';

interface ApplicationContextValue {
  loading: ReturnType<typeof useLoadingManager>['loading'];
}

const ApplicationContext = React.createContext<ApplicationContextValue | undefined>(undefined);
export const ApplicationContextConsumer = ApplicationContext.Consumer;

interface ProviderProps {
  children?: React.ReactNode;
}

export function ApplicationContextProvider(props: ProviderProps): React.ReactElement {
  const { children } = props;

  const { loading } = useLoadingManager();
  return <ApplicationContext.Provider value={{ loading }}>{children}</ApplicationContext.Provider>;
}

export function useApplicationContext() {
  const context = React.useContext(ApplicationContext);
  if (!context) {
    throw new Error('Use Provider before it');
  }

  return context;
}

export function useLoading() {
  const { loading } = useApplicationContext();
  return loading;
}
