import { AxiosInstance } from 'axios';
import { GoogleRequest, LoginRequest } from 'src/types/api/Auth';

const PATH = '/backlot/v1/api/auth';

const OAUTH_PATH = '/oauth/v1/login/GOOGLE';

function apiAuth(instance: AxiosInstance) {
  return {
    oAuthGoogleLogin: (data: GoogleRequest) => instance.post(OAUTH_PATH, { ...data }),
    login: (data: LoginRequest) => instance.post(PATH, data),
    google: (idToken: string) => instance.post('/api/oauth/google/', { idToken }),
    hermes: () => instance.get<string>('/hermes-api/token'),
  };
}

export default apiAuth;
