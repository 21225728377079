import { useQuery } from '@apollo/client';
import React from 'react';
import errorLogger from 'src/api/errorLogger';
import FileAnchor from 'src/components/atoms/FileAnchor';
import { Description, Title } from 'src/components/atoms/styled/element';
import { Column, Row } from 'src/components/atoms/styled/layout';
import { ContentType } from 'src/utils/translate';
import { GQL_PROJECT_CURT_INFO } from './gql';
import { ProjectInfoQuery, ProjectInfoQueryVariables } from './gql.generated';
import { ProjectInWorkerRelayWrapper } from './styled';

interface Props {
  projectId: number;
}

function ProjectCurtInfo(props: Props) {
  const { projectId } = props;

  const { loading, data, error } = useQuery<ProjectInfoQuery, ProjectInfoQueryVariables>(GQL_PROJECT_CURT_INFO, {
    variables: { projectId: projectId.toString() },
    onError: errorLogger.error,
  });

  if (loading) return <div>Loading...</div>;
  if (error) {
    return (
      <div>
        <h2>{error.name}</h2>
        <h3>{error.message}</h3>
      </div>
    );
  }

  const projectInWorkerRelay = data?.project;

  if (!projectInWorkerRelay || projectInWorkerRelay === null) {
    return <div>No Data to show</div>;
  }

  const { id, category, name, manager, projectFiles, projectRelateLinks, description } = projectInWorkerRelay;

  return (
    <ProjectInWorkerRelayWrapper>
      <Title className={'title'}>
        [P-{id}] [{category && ContentType[category]}] {name}
      </Title>
      <Column className={'detail'}>
        <Row>
          <Title>프로젝트 담당 PM</Title>
          <Description>
            {manager?.name}({manager?.email})
          </Description>
        </Row>
        <Row>
          <Title>프로젝트 관련 파일</Title>
          <Column>
            {projectFiles?.map((it, idx) => {
              return (
                <span key={idx}>
                  <FileAnchor file={{ name: it.file.name || '', id: it.file.id }}>{it.file.name}</FileAnchor>
                </span>
              );
            })}
          </Column>
        </Row>
        <Row>
          <Title>프로젝트 관련 링크</Title>
          <Column>
            {projectRelateLinks?.map((it, idx) => {
              return (
                <span key={idx}>
                  <a href={it.url} target={'_blank'} rel={'noopener noreferrer'}>
                    {it.url}
                  </a>
                </span>
              );
            })}
          </Column>
        </Row>
        <Row>
          <Title>특이사항</Title>
          <Description>{description}</Description>
        </Row>
      </Column>
    </ProjectInWorkerRelayWrapper>
  );
}

export default ProjectCurtInfo;
