import { Column, Row } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const EntityHistoryTableWrapper = styled(Column)`
  padding: 16px;
  width: 3318px;

  border: 1px solid ${getRGBA(colors.grey80)};

  & > ${Row} {
    width: 1250px;

    align-items: center;
    padding: 4px 0;

    font-size: 15px;

    & > span {
      padding-left: 4px;
      min-height: 22px;
      & > input {
        width: inherit;
      }
      & > label {
        & > input {
          display: none;
        }
      }
    }
    & > span:nth-child(1) {
      width: 100px;
    }

    & > span:nth-child(2) {
      width: 220px;
    }
    & > span:nth-child(3) {
      width: 140px;
    }
    & > span:nth-child(4) {
      width: 120px;
    }
    & > span:nth-child(5) {
      width: 100px;
    }
    & > span:nth-child(6) {
      width: 120px;
    }

    & > span:nth-child(7) {
      width: 350px;
    }

    & > span:nth-child(8) {
      width: 100px;
    }

    &:first-child {
      border-bottom: 1px solid black;
    }
  }
`;
