import { Button } from 'src/components/atoms/styled/button';
import { Title } from 'src/components/atoms/styled/element';
import { Column, Row } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const FileDropBoxWrapper = styled(Column)`
  justify-content: center;
  align-items: center;

  background: ${getRGBA(colors.grey5)};
  border: 1px solid ${getRGBA(colors.grey20)};
  border-radius: 8px;
  height: 161px;

  & > ${Title} {
    color: ${getRGBA(colors.grey60)};
    font-size: 16px;
  }

  & > ${Row} {
    margin: 16px 0 0;

    & > ${Button} {
      justify-content: center;
      align-items: center;

      padding: 0 8px;
      height: 30px;
      box-sizing: border-box;

      & > svg path {
        fill: white;
      }

      & > span {
        margin-left: 4px;
      }

      &:not(:first-child) {
        margin-left: 7px;
      }
    }
  }

  &.hover {
    background: ${getRGBA(colors.blue20)};
    border: 1px solid ${getRGBA(colors.blue50)};
  }
`;
