import {
  CButton,
  CCol,
  CContainer,
  CFormInput,
  CFormLabel,
  CModalBody,
  CModalFooter,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import React from 'react';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import StatusLabel from 'src/components/atoms/StatusLabel';
import { CheckBox } from 'src/components/atoms/styled/form';
import { modalStep } from '../../type';
import { JobsResponse } from 'src/types/api/Job';
import { JobResultResponse } from 'src/types/api/JobResult';
import { ProductOrderOption, ProductOrderResponse } from 'src/types/api/ProductOrder';
import { UserResponse } from 'src/types/api/User';
import { dateFormat } from 'src/utils/date';
import {
  WorkRange,
  Source_Language,
  Translate_Language,
  ResultType,
  WorkTarget,
  AdditionalServices,
} from 'src/utils/translate';

interface Props {
  jobResults: JobResultResponse[] | undefined;
  jobsMap: Map<number, JobsResponse>;
  managerMap: Map<number, UserResponse>;
  productOrderMap: Map<number, ProductOrderResponse>;
  setTargetJobs: (data: JobsResponse[]) => void;
  onClose: () => void;
  handleStep: (nextStep: modalStep) => void;
  setTargetJobResults: (data: JobResultResponse[]) => void;
}

function CreateJobResult(props: Props) {
  const { jobResults, jobsMap, managerMap, productOrderMap, onClose, setTargetJobs, handleStep, setTargetJobResults } =
    props;

  const [productOrderChecks] = React.useState<Map<number, boolean>>(() => new Map());
  const [jobsCheck] = React.useState<Map<number, boolean>>(() => new Map());
  const createJobResult = React.useCallback(async () => {
    const targetJobs: JobsResponse[] = [];
    const projectOrderIds = Array.from(productOrderChecks)
      ?.filter((order) => order[1] === true)
      .map((item) => item[0]);
    const jobIds = Array.from(jobsCheck)
      ?.filter((job) => job[1] === true)
      .map((item) => {
        if (item[0]) {
          const job = jobsMap.get(item[0]);
          if (job) {
            targetJobs.push(job);
          }
        }
        return item[0];
      });
    await api.jobResult
      .post({ jobIds: jobIds, productOrderIds: projectOrderIds })
      .then((res) => {
        setTargetJobResults(res.data);
        setTargetJobs(targetJobs);
      })
      .catch((error) => errorLogger.error(error))
      .finally(() => handleStep('APPROVEDJOBRESULT'));
  }, [productOrderChecks, jobsCheck, jobsMap, setTargetJobResults, setTargetJobs, handleStep]);

  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const [disabledProductOrderCheckIds] = React.useState<Map<number, boolean>>(() => new Map());
  const handleJobsChecked = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
      jobsCheck.set(id, e.target.checked);
      jobResults
        ?.filter((it) => it.jobId === id)
        .map((item) => disabledProductOrderCheckIds.set(item.productOrderId, e.target.checked));
      forceUpdate();
    },
    [disabledProductOrderCheckIds, jobResults, jobsCheck]
  );

  const handleOrdersChecked = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
      productOrderChecks.set(id, e.target.checked);
      forceUpdate();
    },
    [productOrderChecks]
  );

  const amount = React.useMemo(() => {
    const map = new Map<number, number>();
    if (jobResults && productOrderMap) {
      jobResults.forEach((it) => {
        map.set(it.productOrderId, (map.get(it.productOrderId) ?? 0) + 1);
      });
    }
    return map;
  }, [productOrderMap, jobResults]);

  const [filterJobs, setFilterJobs] = React.useState<JobsResponse[]>([]);
  const filterJobList = React.useCallback(
    (data) => {
      const filterJobList: JobsResponse[] = [];
      Array.from(jobsMap).map((job) => {
        if (job[1].name.indexOf(data) !== -1) {
          filterJobList.push(job[1]);
        }
        return true;
      });
      setFilterJobs(filterJobList);
    },
    [jobsMap]
  );

  React.useEffect(() => {
    const filterJobList: JobsResponse[] = [];
    Array.from(jobsMap).map((job) => filterJobList.push(job[1]));
    setFilterJobs(filterJobList);
  }, [jobsMap]);

  const calProductOrderAdditionalServiceTotalPrice = React.useCallback((options: ProductOrderOption[]) => {
    return options.reduce((prev, acc) => {
      return prev + (acc.price ?? 0);
    }, 0);
  }, []);

  const [searchContent, setSearchContent] = React.useState<string>();

  return (
    <>
      <CModalBody>
        <CContainer style={{ width: '1000px' }}>
          <CRow className="align-items-center">
            <CCol>
              <CFormLabel>작업 컨텐츠 선택하기</CFormLabel>
            </CCol>
            <CCol sm={8} style={{ position: 'relative' }}>
              <CFormInput onChange={(e) => setSearchContent(e.target.value)} />
              <CButton
                onClick={() => filterJobList(searchContent)}
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                }}
              >
                🔍
              </CButton>
            </CCol>
          </CRow>

          <CCol style={{ height: '320px', overflow: 'auto' }}>
            <CTable bordered>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell></CTableHeaderCell>
                  <CTableHeaderCell>콘텐츠 ID</CTableHeaderCell>
                  <CTableHeaderCell>콘텐츠 명</CTableHeaderCell>
                  <CTableHeaderCell>진행상태</CTableHeaderCell>
                  <CTableHeaderCell>납품예정일</CTableHeaderCell>
                  <CTableHeaderCell>담당 PM</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {filterJobs.map((it, idx) => {
                  return (
                    <CTableRow key={idx}>
                      <CTableDataCell>
                        {' '}
                        <CheckBox onChange={(e) => handleJobsChecked(e, it?.id)} checked={jobsCheck.get(it?.id)} />
                      </CTableDataCell>
                      <CTableDataCell>CON - {it?.id}</CTableDataCell>
                      <CTableDataCell>{it?.name}</CTableDataCell>
                      <CTableDataCell>
                        {' '}
                        <StatusLabel status={it?.status} />
                      </CTableDataCell>
                      <CTableDataCell>
                        {' '}
                        {it.scheduledDeadlineDateTime &&
                          dateFormat(new Date(it.scheduledDeadlineDateTime), 'YYYY.MM.DD')}
                      </CTableDataCell>
                      <CTableDataCell> {it.managerId && managerMap.get(it.managerId)}</CTableDataCell>
                    </CTableRow>
                  );
                })}
              </CTableBody>
            </CTable>
          </CCol>
          <div>주문 선택하기</div>
          <CCol style={{ height: '320px', overflow: 'auto', position: 'relative' }}>
            <CTable bordered style={{ width: 'max-content' }}>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell></CTableHeaderCell>
                  <CTableHeaderCell>작업범위</CTableHeaderCell>
                  <CTableHeaderCell>원본언어</CTableHeaderCell>
                  <CTableHeaderCell>번역언어</CTableHeaderCell>
                  <CTableHeaderCell>납품형태</CTableHeaderCell>
                  <CTableHeaderCell>작업대상</CTableHeaderCell>
                  <CTableHeaderCell>상품단가</CTableHeaderCell>
                  <CTableHeaderCell>부가서비스</CTableHeaderCell>
                  <CTableHeaderCell>부가서비스 단가</CTableHeaderCell>
                  <CTableHeaderCell>작업량(/예상)</CTableHeaderCell>
                  <CTableHeaderCell>금액(/예상)</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {Array.from(productOrderMap)
                  .filter((it) => {
                    const isProductOrderInJobResult = jobResults?.map((jobResult) => {
                      if (jobsCheck.get(jobResult.jobId)) {
                        if (jobResult.productOrderId === it[1].id) {
                          return false;
                        }
                      }

                      return true;
                    });

                    return !isProductOrderInJobResult?.includes(false);
                  })
                  .map((it, idx) => {
                    const amountValue = amount.get(it[1].id) ?? 0;
                    const price = it[1].price ?? 0;
                    const optionPrice =
                      it[1].options?.reduce((prev, acc) => {
                        return prev + (acc.price ?? 0);
                      }, 0) || 0;
                    return (
                      <CTableRow key={idx}>
                        <CTableDataCell>
                          <CheckBox
                            onChange={(e) => handleOrdersChecked(e, it[1].id)}
                            checked={productOrderChecks.get(it[1].id)}
                            disabled={!Array.from(jobsCheck.values()).includes(true)}
                          />
                        </CTableDataCell>
                        <CTableDataCell>{WorkRange[it[1].productType]}</CTableDataCell>
                        <CTableDataCell>{Source_Language[it[1].sourceLanguage]}</CTableDataCell>
                        <CTableDataCell>
                          {it[1].translateLanguage && Translate_Language[it[1].translateLanguage]}
                        </CTableDataCell>
                        <CTableDataCell>{it[1]?.deliveryType && ResultType[it[1].deliveryType]}</CTableDataCell>
                        <CTableDataCell>{it[1].productSource && WorkTarget[it[1].productSource]}</CTableDataCell>
                        <CTableDataCell>{it[1].price ? it[1].price : '_'}</CTableDataCell>
                        <CTableDataCell style={{ display: 'block' }}>
                          {it[1].options?.map((option, idx) => (
                            <div key={idx}>{AdditionalServices[option.type]}</div>
                          ))}
                        </CTableDataCell>
                        <CTableDataCell>
                          {it[1].options && calProductOrderAdditionalServiceTotalPrice(it[1].options)}
                        </CTableDataCell>
                        <CTableDataCell>
                          {amountValue}/{it[1].quantity}
                        </CTableDataCell>
                        <CTableDataCell>
                          {price * amountValue + optionPrice || '-'}/{price * it[1].quantity || '-'}
                        </CTableDataCell>
                      </CTableRow>
                    );
                  })}
              </CTableBody>
            </CTable>
          </CCol>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton type={'button'} color={'secondary'} onClick={() => onClose()}>
          취소하기
        </CButton>
        <CButton className={'align-items-center'} type={'submit'} color={'primary'} onClick={() => createJobResult()}>
          다음
        </CButton>
      </CModalFooter>
    </>
  );
}

export default CreateJobResult;
