import { Title } from 'src/components/atoms/styled/element';
import { Column } from 'src/components/atoms/styled/layout';
import styled from 'styled-components';

export const HistoriesWrapper = styled(Column)`
  & > ${Title} {
    margin-bottom: 24px;

    font-weight: bold;
    font-size: 18px;
  }

  & > ${Column} {
    width: 1300px;
  }
`;
