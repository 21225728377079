import { cilPlus, cilSearch } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CButton,
  CContainer,
  CFormInput,
  CHeaderBrand,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import React from 'react';
import { generatePath, useHistory } from 'react-router';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import AppPagination from 'src/components/molecules/AppPagination';
import AppPaths from 'src/constants/AppPaths';
import { toSortString } from 'src/types/api';
import { User } from 'src/types/api/User';
import { dateFormat } from 'src/utils/date';

function AdminAccountsPage() {
  const [targetAccount, setTargetAccount] = React.useState<string>('');

  const history = useHistory();

  const [adminAccountList, setAdminAccountList] = React.useState<User[]>();
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [maxPage, setMaxPage] = React.useState<number>(0);
  const getAdminAccountList = React.useCallback(async () => {
    await api.account
      .pages({
        size: 10,
        page: currentPage,
        roles: 'ADMIN',
        name: targetAccount,
        sort: toSortString({ direction: 'desc', sortKey: 'id' }),
      })
      .then((res) => {
        const { data } = res;
        const list: User[] = data.element;
        setAdminAccountList(list);
        setMaxPage(data.totalPage);
      })
      .catch((error) => errorLogger.error(error));
  }, [currentPage, targetAccount]);

  React.useEffect(() => {
    getAdminAccountList();
  }, [currentPage, getAdminAccountList, maxPage]);

  const resetList = React.useCallback(() => {
    setTargetAccount('');
    getAdminAccountList();
  }, [getAdminAccountList, setTargetAccount]);

  const alramSetting = React.useCallback(
    (accountId?: number) => {
      if (!accountId) {
        return;
      }

      const path = generatePath(AppPaths.accountSetting.path, { id: accountId });

      history.push(path);
    },
    [history]
  );

  return (
    <div className="bg-light d-flex flex-column justify-content-center">
      <CHeaderBrand>계정관리</CHeaderBrand>
      <CContainer>
        <div className="d-flex flex-row" style={{ marginBottom: '5px' }}>
          <div className="d-flex">
            <CFormInput
              style={{ width: '200px' }}
              type="text"
              placeholder={'사용자 검색'}
              onChange={(e) => setTargetAccount(e.target.value)}
            />
            <CButton onClick={getAdminAccountList}>
              <CIcon icon={cilSearch} />
            </CButton>
          </div>
        </div>
        <CRow>
          <CButton onClick={resetList} style={{ margin: '20px 15px', width: '135px' }}>
            초기화
          </CButton>
        </CRow>
        <CTable bordered style={{ width: 'max-content', minWidth: '100%' }}>
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell>NO</CTableHeaderCell>
              <CTableHeaderCell>이름</CTableHeaderCell>
              <CTableHeaderCell>로그인 계정</CTableHeaderCell>

              <CTableHeaderCell className={'justify-content-center d-flex'}>설정</CTableHeaderCell>
              <CTableHeaderCell>생성일자</CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {adminAccountList?.map((adminAccount, idx) => {
              const { id, name, email, createDateTime } = adminAccount;
              return (
                <CTableRow key={idx}>
                  <CTableDataCell>
                    <CTableDataCell>{id}</CTableDataCell>
                  </CTableDataCell>
                  <CTableDataCell>{name}</CTableDataCell>
                  <CTableDataCell>{email}</CTableDataCell>
                  <CTableDataCell className={'justify-content-center d-flex'}>
                    <CButton key={id} onClick={() => alramSetting(id)}>
                      <CIcon icon={cilPlus} />
                    </CButton>
                  </CTableDataCell>
                  <CTableDataCell>{createDateTime && dateFormat(createDateTime, 'YYYY.MM.DD')}</CTableDataCell>
                </CTableRow>
              );
            })}
          </CTableBody>
        </CTable>
        <AppPagination currentPage={currentPage} maxPage={maxPage} setCurrentPage={(page) => setCurrentPage(page)} />
      </CContainer>
    </div>
  );
}

export default AdminAccountsPage;
