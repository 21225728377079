import { Description, Title } from 'src/components/atoms/styled/element';
import { Column, Row } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const EditorCreateBodyWrapper = styled(Column)`
  & > ${Description} {
    margin-bottom: 24px;

    white-space: pre-wrap;

    & > span {
      color: ${getRGBA(colors.blue50)};
    }
  }

  & > ${Row} {
    align-items: center;

    height: 50px;

    border-bottom: 1px solid ${getRGBA(colors.grey80)};

    & > ${Title} {
      width: 120px;
    }
  }

  & > ${Row}.video {
    & > input {
      width: 450px;
    }
    & > span {
      width: 90px;

      text-align: center;
    }
  }

  & > ${Row}.script {
    border-bottom: 0;

    & > :last-child {
      width: 450px;
    }
  }
`;
