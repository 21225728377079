import { useQuery } from '@apollo/client';
import { CDropdownItem, CSpinner } from '@coreui/react';
import { cn } from '@voithru/front-core';
import React from 'react';
import { generatePath, useHistory } from 'react-router';
import errorLogger from 'src/api/errorLogger';
import { useNotification } from 'src/components/context/NotificationContext';
import AppPaths from 'src/constants/AppPaths';
import { EntityHistoryNotificationAPIResponse } from 'src/types/api/EntityHistory';

import { dateFormat } from 'src/utils/date';
import {
  EntityStatusMSGTitleEnum,
  EntityStatuMSGEnum,
  EntityStateEnum,
  getTargetSet,
  EntityHistoryEnum,
} from 'src/utils/entityHistory';
import { GQL_ALARM_ITEM } from './gql';
import { NotificationQuery, NotificationQueryVariables } from './gql.generated';

interface Props {
  item: EntityHistoryNotificationAPIResponse;
}

function AlramItem(props: Props) {
  const { variable, toValue, createDateTime, id, entityType, status, state, entity } = props.item;

  const { markAsRead } = useNotification();

  const { targetId, targetType } = getTargetSet(entityType, entity);

  const { data, loading } = useQuery<NotificationQuery, NotificationQueryVariables>(GQL_ALARM_ITEM, {
    variables: {
      ...Object.fromEntries([[(targetType ?? '').toLocaleLowerCase(), true]]),
      entityId: targetId?.toString() || '',
    },
    onError: errorLogger.error,
  });

  const { job, jobResult } = React.useMemo(() => {
    return {
      job: data?.job,
      jobResult: data?.jobResult,
    };
  }, [data]);

  const history = useHistory();

  const [linkId, workName] = React.useMemo(() => {
    switch (entityType) {
      case 'JOB':
        return [job?.projectId, job?.name];
      case 'JOB_FILE':
        return [targetId, job?.name];
      case 'JOB_RESULT':
        return [jobResult?.projectId, jobResult?.job.name];
      default:
        return ['-'];
    }
  }, [entityType, targetId, job, jobResult]);

  const pathName = React.useMemo(() => {
    if (!linkId) {
      return '/';
    }

    if (entityType === 'JOB_RESULT' || entityType === 'JOB') {
      const path = generatePath(AppPaths.projectDetail.path, { id: linkId });

      return path;
    }
    if (entityType === 'JOB_FILE') {
      const path = generatePath(AppPaths.detailContent.path, { id: linkId });

      return path;
    }

    return '/';
  }, [entityType, linkId]);

  const linkToEntityInfo = React.useCallback(async () => {
    try {
      await markAsRead(id);

      history.push(pathName);
    } catch (error) {
      errorLogger.error(error);
    }
  }, [history, id, markAsRead, pathName]);

  const [alramMSGTitle, alramMSGContent] = React.useMemo(() => {
    if (variable === 'status' && toValue) {
      return [EntityStatusMSGTitleEnum[toValue], EntityStatuMSGEnum[toValue]];
    }
    if (entityType !== 'JOB_FILE') {
      return [
        EntityStatusMSGTitleEnum['REQUESTED'],
        `${EntityHistoryEnum[entityType]} ${EntityStatuMSGEnum['REQUESTED']}`,
      ];
    }

    return ['파일변경', `콘텐츠 파일이 ${EntityStateEnum[state]}되었습니다.`];
  }, [variable, toValue, entityType, state]);

  if (loading) return <CSpinner color="primary" />;

  return (
    <CDropdownItem className={cn(status === 'READ' && 'read')} onClick={linkToEntityInfo}>
      <span>
        [{alramMSGTitle}] {workName}
      </span>
      <br /> {dateFormat(createDateTime, '{YYYY.MM.DD} ') + alramMSGContent}
    </CDropdownItem>
  );
}

export default AlramItem;
