import { useQuery } from '@apollo/client';
import { CFormSelect } from '@coreui/react';

import React from 'react';
import { useForm } from 'react-hook-form';
import errorLogger from 'src/api/errorLogger';
import { ButtonBase } from 'src/components/atoms/styled/button';
import { Description, Title } from 'src/components/atoms/styled/element';
import { Row } from 'src/components/atoms/styled/layout';
import FormModal from 'src/components/organisms/FormModal';
import { VideoProcessRequest } from 'src/types/api/VideoProcess';
import { VideoProcessRequestStatus } from 'src/types/globalTypesFile/graphql';
import { LanguageCounytryCode } from 'src/utils/translate';
import { dateFormat } from 'src/utils/date';
import { getByteString } from 'src/utils/string';
import { EditorCreateBodyWrapper } from './styled';
import { GQL_EDITOR_CREATE } from './gql';
import { JobFilesInEditorCreateQuery, JobFilesInEditorCreateQueryVariables } from './gql.generated';

interface FormData {
  script: string;
  scriptPath: string;
  devisionTime: string;
}

interface Props {
  title: React.ReactNode;

  onSubmit(data: VideoProcessRequest): void;

  contentData: {
    contentId: string;
    contentName: string;

    fileId: string;
    fileName: string;
    fileVolume: string | number;
    fileUploadStartDateTime: string;
  };

  editorStatus: VideoProcessRequestStatus | undefined;
}

function EditorCreateModal(props: Props) {
  const { title, onSubmit, contentData, editorStatus } = props;

  const { loading, error, data } = useQuery<JobFilesInEditorCreateQuery, JobFilesInEditorCreateQueryVariables>(
    GQL_EDITOR_CREATE,
    {
      variables: {
        id: contentData.contentId,
      },
      onError: errorLogger.error,
    }
  );

  const jobFiles = data?.job?.jobFiles;

  const jobScriptFiles = React.useMemo(() => {
    return jobFiles?.filter((it) => {
      const fileName = it.file?.name;

      const scriptExtension = {
        srt: true,
        vtt: true,
      };

      return fileName && scriptExtension[fileName.substr(fileName?.length - 3, 3)];
    });
  }, [jobFiles]);

  const { register, handleSubmit } = useForm<FormData>({ shouldUnregister: true });

  const productOrder = data?.job?.project?.productOrders && data?.job?.project?.productOrders[0];

  const submit = React.useCallback(
    (form: FormData) => {
      const { script, devisionTime } = form;

      if (!contentData.fileId || !productOrder || !productOrder.sourceLanguage) {
        throw new Error('EditorCreate: Does not have submitting data');
      }

      const scriptData: VideoProcessRequest = {
        jobFileId: Number(contentData.fileId),
        language: LanguageCounytryCode[productOrder.sourceLanguage] ?? '',
      };

      if (script) {
        scriptData.scriptFileS3Path = script;
      }

      if (devisionTime && !isNaN(Number(devisionTime))) {
        scriptData.videoLengthInSeconds = Number(devisionTime);
      }

      setEditorCreateOpen(false);
      return onSubmit(scriptData);
    },
    [contentData.fileId, productOrder, onSubmit]
  );

  const [isEditorCreateOpen, setEditorCreateOpen] = React.useState(false);

  if (loading) return <div>loading...</div>;
  if (error) {
    return (
      <div>
        <h2>{error.name}</h2>
        <h3>{error.message}</h3>
      </div>
    );
  }

  return (
    <>
      <ButtonBase
        type={'button'}
        onClick={() => setEditorCreateOpen(true)}
        className={'editor'}
        disabled={editorStatus === 'REGISTERED'}
      >
        에디터 생성
      </ButtonBase>
      <FormModal
        title={title}
        visible={isEditorCreateOpen}
        onDismiss={() => setEditorCreateOpen(false)}
        onSubmit={handleSubmit(submit)}
      >
        <EditorCreateBodyWrapper>
          <Description>
            Minglo V2가 지원되기 전 영상 에디터를 지원하기 위한 기능으로 <span>JAMAKE</span> 에디터가 생성됩니다. <br />
            원본 파일이 MOV, MP4일 경우에 생성가능하며 동일 콘텐츠 내 첨부된 자막파일을 함께 통합하여 생성가능합니다.
          </Description>
          <Row>
            <Title>J-{contentData.contentId}</Title>
            <span>{contentData.contentName}</span>
          </Row>
          <Row className={'video'}>
            <Title>영상파일</Title>
            <input defaultValue={contentData.fileName} disabled readOnly />
            <span>{contentData.fileVolume && getByteString(Number(contentData.fileVolume))}</span>
            <span>
              {contentData.fileUploadStartDateTime && dateFormat(contentData.fileUploadStartDateTime, 'YYYY.MM.DD')}
            </span>
          </Row>
          <Row className={'script'}>
            <Title>자막파일</Title>
            <CFormSelect {...register('script')}>
              {jobScriptFiles?.map((item, idx) => (
                <option key={idx} value={item.file?.s3Path ?? ''}>
                  {item.file?.name}
                </option>
              ))}
            </CFormSelect>
          </Row>
          <Row>
            <Title>분할기준</Title>
            <span>
              <input {...register('devisionTime')} /> 초
            </span>
          </Row>
        </EditorCreateBodyWrapper>
      </FormModal>
    </>
  );
}

export default EditorCreateModal;
