import React from 'react';
import { Route, RouteProps, useHistory } from 'react-router';
import { useAuth, useBeforeLocation } from 'src/components/context/AuthContext';

type Props = React.PropsWithChildren<RouteProps>;
function NoneAuthRoute(props: Props) {
  const history = useHistory();
  const { initialize, user } = useAuth();

  const [state, action] = useBeforeLocation();

  React.useEffect(() => {
    if (!initialize || !user) {
      return;
    }

    if (state) {
      history.replace(state);
      action(undefined);
      return;
    }

    history.replace('/');
  }, [action, history, initialize, state, user]);

  if (!initialize || user) {
    return null;
  }

  return <Route {...props} />;
}

export default NoneAuthRoute;
