import { EntityHistoryType } from 'src/types/api/EntityHistory';
import { JobsResponse, JobFileResponse, JobRelateLinkResponse } from 'src/types/api/Job';
import { JobResultResponse, JobResultFileResponse } from 'src/types/api/JobResult';
import { ProductOrderResponse } from 'src/types/api/ProductOrder';
import { ProjectResponse, ProjectFile, ProjectRelateLinkResponse } from 'src/types/api/Project';

export enum EntityStatusMSGTitleEnum {
  DONE = '작업완료',
  DROP = '작업취소',
  REVIEWABLE = '검수요청',
  REVIEW_APPROVED = '검수승인',
  REVIEW_REJECTED = '검수거절',
  PROCESSING = '작업시작',
  REGISTERED = '작업확정',
  REQUESTED = '의뢰',
  REQUESTING = '의뢰임시저장',
}

export enum EntityStatuMSGEnum {
  DONE = '작업이 완료 되었습니다.',
  DROP = '작업이 취소 되었습니다.',
  REVIEWABLE = '검수가 요청되었습니다.',
  REVIEW_APPROVED = '승인이 완료 되었습니다.',
  REVIEW_REJECTED = '승인이 거절 되었습니다.',
  PROCESSING = '작업이 시작 되었습니다.',
  REGISTERED = '진행이 확정되었습니다.',
  REQUESTED = '신규 의뢰 건이 있습니다.',
  REQUESTING = '신규 의뢰 임시저장 건이 있습니다.',
}

export enum EntityStateEnum {
  CREATE = '추가',
  UPDATE = '변경',
  DELETE = '삭제',
}

export enum EntityHistoryEnum {
  ACCOUNT = '계정',
  PROJECT = '프로젝트',
  PROJECT_RELATE_LINK = '프로젝트 관련 링크',
  PROJECT_FILE = '프로젝트 첨부파일',

  JOB = '콘텐츠',
  JOB_RELATE_LINK = '콘텐츠 관련 링크',
  JOB_FILE = '콘텐츠 첨부파일',

  JOB_RESULT = '작업 결과물',
  JOB_RESULT_FILE = '작업 결과물 첨부파일',

  PRODUCT_ORDER = '작업정보',
  PRODUCT_OPTION_ORDER = '작업정보-부가서비스',
}

interface targetType {
  targetId?: string | number;
  targetType?: 'project' | 'job' | 'job_result';
}

export function getTargetSet(entityType: EntityHistoryType, entity?: string): targetType {
  if (!entity) {
    return {};
  }

  const entityObject = JSON.parse(entity);

  switch (entityType) {
    case 'PROJECT': {
      const project = entityObject as ProjectResponse;
      return { targetId: project.id, targetType: 'project' };
    }
    case 'PROJECT_FILE': {
      const projectFile = entityObject as ProjectFile;
      return { targetId: projectFile.projectId, targetType: 'project' };
    }
    case 'PROJECT_RELATE_LINK': {
      const projectRelateLink = entityObject as ProjectRelateLinkResponse;
      return { targetId: projectRelateLink.projectId, targetType: 'project' };
    }
    case 'JOB': {
      const job = entityObject as JobsResponse;
      return { targetId: job.id, targetType: 'job' };
    }
    case 'JOB_FILE': {
      const jobFile = entityObject as JobFileResponse;
      return { targetId: jobFile.jobId, targetType: 'job' };
    }
    case 'JOB_RELATE_LINK': {
      const jobRelateLink = entityObject as JobRelateLinkResponse;
      return { targetId: jobRelateLink.jobId, targetType: 'job' };
    }
    case 'JOB_RESULT': {
      const jobResult = entityObject as JobResultResponse;
      return { targetId: jobResult.id, targetType: 'job_result' };
    }
    case 'JOB_RESULT_FILE': {
      const jobResultFile = entityObject as JobResultFileResponse;
      return { targetId: jobResultFile.jobResultId, targetType: 'job_result' };
    }
    case 'PRODUCT_ORDER': {
      const productOrder = entityObject as ProductOrderResponse;
      return { targetId: productOrder.projectId, targetType: 'project' };
    }
    // ProductOptionOrder의 경우 해결해야함.

    default:
      return {};
  }
}
