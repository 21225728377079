import { ButtonWithBorder } from 'src/components/atoms/styled/button';
import { Column, Row } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const ProjectTaskWrapper = styled(Column)`
  width: 2270px;
  & > ${Row} {
    justify-content: space-between;
    align-items: flex-end;
    & > ${Row} {
      & > ${ButtonWithBorder} + ${ButtonWithBorder} {
        margin-left: 13px;
      }

      & > a {
        color: ${getRGBA(colors.grey90)};
        text-decoration: none;
      }
    }
  }
`;
