import { CButton } from '@coreui/react';
import React from 'react';
import { CompanyFile } from 'src/types/api/Company';
import useFileUpload from 'src/utils/hooks/useFileUpload';
import MultipartUpload from 'src/utils/MultipartUpload';

interface IUploadFileProps {
  setFile: (uploaders: MultipartUpload[]) => void;
  origFile?: CompanyFile[];
  setOrigFile?: (file: CompanyFile[]) => void;
}

function UploadGuide(props: IUploadFileProps) {
  const { setFile, origFile, setOrigFile } = props;
  const { uploaders, handleSelectFile } = useFileUpload('COMPANY');

  const handleDeleteGuide = React.useCallback(
    (fileId: string) => {
      if (!setOrigFile || !origFile) {
        return;
      }

      setOrigFile(origFile.filter((of) => of.fileId !== fileId));
    },
    [setOrigFile, origFile]
  );

  React.useEffect(() => {
    setFile(uploaders);
  }, [setFile, uploaders]);

  return (
    <div
      style={{
        marginTop: '-10px',
        left: '11px',
      }}
    >
      <CButton onClick={handleSelectFile} style={{ fontSize: '10px' }}>
        업로드
      </CButton>
      <div>
        {uploaders?.map((uploader, idx) => {
          return (
            <div key={idx} style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              <div>{uploader.namedFile.name}</div>
              <div onClick={() => uploader.cancel()}>X</div>
            </div>
          );
        })}
      </div>
      <div>
        {origFile?.map((file, idx) => {
          return (
            <div key={idx} style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              <div>{file.file?.name}</div>
              <div onClick={() => handleDeleteGuide(file.fileId)}>X</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default UploadGuide;
