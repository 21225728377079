import React from 'react';
import errorLogger from 'src/api/errorLogger';

export default class ErrorBoundary extends React.Component<{}, {}> {
  public componentDidMount(): void {
    if ('onunhandledrejection' in window) {
      window.addEventListener('unhandledrejection', (event) => {
        this.loggingPromiseRejectionErrorEvent(event);
      });
    }

    window.addEventListener('error', (e) => {
      this.loggingErrorEvent(e);
    });
  }

  public componentDidCatch(error: Error, info: object) {
    this.loggingError(error);
  }

  public render() {
    return this.props.children;
  }

  private async loggingError(error: Error) {
    errorLogger.error(error.message);
    if (error.stack) {
      errorLogger.error(error.stack);
    }
  }

  private async loggingErrorEvent(error: ErrorEvent) {
    errorLogger.error(error.message);
  }

  private async loggingPromiseRejectionErrorEvent(error: PromiseRejectionEvent) {
    errorLogger.error(error.reason);
    if (error.reason != null && error.reason.stack != null) {
      errorLogger.error(error.reason.stack);
    }
  }
}
