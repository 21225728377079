import { useQuery } from '@apollo/client';
import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import StatusLabel from 'src/components/atoms/StatusLabel';
import { CheckBox } from 'src/components/atoms/styled/form';
import { Row } from 'src/components/atoms/styled/layout';
import { dateFormat } from 'src/utils/date';
import { ContentType } from 'src/utils/translate';
import styled from 'styled-components';
import { JobStatus } from 'src/types/globalTypesFile/graphql';
import { ProjectTaskProgressWrapper } from './styled';
import AppPaths from 'src/constants/AppPaths';
import { GET_PROJECT_QUERY } from './gql';
import { GetProjectsQuery, GetProjectsQueryVariables } from './gql.generated';

interface Props {
  projectIds: { id: number }[];
  onChange?(id?: number): void;
  className?: string;
}

function ProjectTaskList(props: Props) {
  const { className, onChange, projectIds } = props;

  const ids = projectIds.map((it) => it.id.toString());
  const { data, loading, error } = useQuery<GetProjectsQuery, GetProjectsQueryVariables>(GET_PROJECT_QUERY, {
    variables: {
      ids,
    },
  });

  if (loading) return <div>loading</div>;
  if (error) return <div>error</div>;

  if (data?.projects == null) return <div />;

  const projects = Array.from(data.projects).sort(
    (a, b) => ids.findIndex((id) => id === a.id) - ids.findIndex((id) => id === b.id)
  );

  return (
    <ProjectTaskProgressWrapper className={className}>
      <Row>
        <span></span>
        <span>프로젝트 ID</span>
        <span>콘텐츠 타입</span>
        <span>프로젝트 명</span>
        <span>진행상태</span>
        <span>콘텐츠 상태</span>
        <span>고객 회사명</span>
        <span>고객 담당자</span>
        <span>최종수정일</span>
        <span>생성일</span>
        <span>납품예정일</span>
        <span>담당PM</span>
      </Row>
      {projects.map((project) => {
        const detailProjectPath = generatePath(AppPaths.projectDetail.path, { id: project.id });

        return (
          <Row key={project.id}>
            <span>{onChange && <CheckBox onChange={() => onChange(Number(project.id))} />}</span>
            <span>
              <Link to={detailProjectPath}>P-{project.id}</Link>
            </span>
            <span>{project.category && ContentType[project.category]}</span>
            <span>{project.name}</span>
            <span>
              <StatusLabel status={project.status} />
            </span>
            <span>
              <span>승인대기 {project.jobs?.filter((j) => j.status === JobStatus.REQUESTED)?.length ?? 0}</span>
              <span>작업대기 {project.jobs?.filter((j) => j.status === JobStatus.REGISTERED)?.length ?? 0}</span>
              <span>작업중 {project.jobs?.filter((j) => j.status === JobStatus.PROCESSING)?.length ?? 0}</span>
              <span>검수대기 {project.jobs?.filter((j) => j.status === JobStatus.REVIEWABLE)?.length ?? 0}</span>
              <span>작업완료 {project.jobs?.filter((j) => j.status === JobStatus.DONE)?.length ?? 0}</span>
            </span>
            <span>{project.account?.company?.name}</span>
            <span>{project.account?.company?.manager?.name}</span>
            <span>{project.updateDateTime && dateFormat(project.updateDateTime, 'YYYY.MM.DD')}</span>
            <span>{dateFormat(project.createDateTime!!, 'YYYY.MM.DD')}</span>
            <span>
              {project.scheduledDeadlineDateTime && dateFormat(project.scheduledDeadlineDateTime, 'YYYY.MM.DD')}
            </span>
            <span>{project.manager?.name}</span>
          </Row>
        );
      })}
    </ProjectTaskProgressWrapper>
  );
}

export default styled(ProjectTaskList)``;
