import { gql } from '@apollo/client';

export const GQL_CONTENT_INFO = gql`
  query ContentInfo($id: ID!) {
    job(id: $id) {
      # 작업 분량
      workSize
      workSizeType

      description

      jobFiles {
        id
        jobId
        index
        fileType

        file {
          id
          name
        }
      }

      jobRelateLinks {
        id
        url
      }
    }
  }
`;
