import { CButton, CCol, CFormCheck, CFormInput, CFormLabel, CRow } from '@coreui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import AdminSearchModal from 'src/components/organisms/AdminSearchModal';
import ClientSearchModal from 'src/components/organisms/ClientSearchModal';
import CompanySearchModal from 'src/components/organisms/CompanySearchModal';
import { Company } from 'src/types/api/Company';
import { JobsStatus } from 'src/types/api/Job';
import { JobResultStatus } from 'src/types/api/JobResult';
import { ProjectStatus } from 'src/types/api/Project';
import { UserResponse } from 'src/types/api/User';
import { dateFormat } from 'src/utils/date';
import { Status } from 'src/utils/translate';
import { SearchFormFilter } from '../../type';

interface FormData {
  keyword?: string;
  companyName?: string;
  client?: string;
  createDateTime?: {
    from?: string;
    to?: string;
  };
  updateDateTime?: {
    from?: string;
    to?: string;
  };
  status?: (ProjectStatus | JobsStatus | JobResultStatus)[];
  manager?: string;
}

const INIT: FormData = {
  keyword: undefined,
  companyName: undefined,
  client: undefined,
  createDateTime: undefined,
  updateDateTime: undefined,
  status: undefined,
  manager: undefined,
};

interface ISearchProps {
  setFilter: (data: SearchFormFilter) => void;
}

function SearchFormOfProjectAndJobList(props: ISearchProps) {
  const { setFilter } = props;
  const [visible, setVisible] = React.useState<boolean>(false);
  const [targetCompany, setTargetCompany] = React.useState<Company>();
  const [targetClient, setTargetClient] = React.useState<{ account: UserResponse; company?: Company }>();
  const [targetAdmin, setTargetAdmin] = React.useState<UserResponse>();

  const { register, getValues, reset, setValue } = useForm<FormData>({ defaultValues: {} });
  const [num, increase] = React.useReducer((x) => x + 1, 0);

  React.useEffect(() => {
    if (targetCompany) {
      setValue('companyName', targetCompany.name);
    }
    if (targetClient) {
      setValue('client', targetClient.account.name);
    }
    if (targetAdmin) {
      setValue('manager', targetAdmin.name);
    }
  }, [targetCompany, targetAdmin, targetClient, setValue]);

  const createDateTimeSetting = React.useCallback(
    (data: string, type: 'createDate' | 'updateDate') => {
      const date = new Date();

      const dateBeforeMonth = new Date().setMonth(date.getMonth() - Number(data[0]));

      const dateFrom = dateFormat(dateBeforeMonth, 'YYYY-MM-DD');
      const dateTo = dateFormat(date, 'YYYY-MM-DD');

      if (type === 'createDate') {
        setValue('createDateTime.from', dateFrom);
        setValue('createDateTime.to', dateTo);
      }

      if (type === 'updateDate') {
        setValue('updateDateTime.from', dateFrom);
        setValue('updateDateTime.to', dateTo);
      }
    },
    [setValue]
  );

  const clear = React.useCallback(() => {
    setTargetAdmin(undefined);
    setTargetClient(undefined);
    setTargetCompany(undefined);
    setFilter({});

    reset(INIT);
    increase();
  }, [setTargetAdmin, setTargetClient, setTargetCompany, setFilter, reset, increase]);

  const search = React.useCallback(
    (data: FormData = getValues()) => {
      const { keyword, createDateTime, updateDateTime, status } = data;

      const filter: SearchFormFilter = {
        name: keyword || undefined,
        companyId: targetCompany?.id,
        accountId: targetClient?.account.id,
        managerId: targetAdmin?.id,
        createDateTimeFrom: createDateTime?.from || undefined,
        createDateTimeTo: createDateTime?.to || undefined,
        updateDateTimeFrom: updateDateTime?.from || undefined,
        updateDateTimeTo: updateDateTime?.to || undefined,
        status: status,
      };

      setFilter({ ...filter });
    },
    [getValues, targetClient, targetAdmin, targetCompany, setFilter]
  );
  return (
    <React.Fragment key={num}>
      <CButton onClick={() => setVisible(!visible)}>{visible ? '-' : '+'}</CButton>
      {visible && (
        <div style={{ width: '900px', height: '470px', backgroundColor: 'white', padding: '20px' }}>
          <div className="align-items-center" style={{ marginBottom: '15px' }}>
            <CRow className="align-items-center">
              <CCol>
                <CFormLabel>키워드</CFormLabel>
              </CCol>
              <CCol sm={8} style={{ position: 'relative' }}>
                <CFormInput type={'text'} {...register('keyword')} />
              </CCol>
            </CRow>
          </div>
          <div className="align-items-center" style={{ marginBottom: '15px' }}>
            <CRow className="align-items-center">
              <CCol>
                <CFormLabel>고객 회사</CFormLabel>
              </CCol>
              <CCol sm={8} style={{ position: 'relative' }}>
                <CFormInput type={'text'} {...register('companyName')} disabled={true} />
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                  }}
                >
                  <CompanySearchModal setTargetItem={(item: Company) => setTargetCompany(item)} />
                </div>
              </CCol>
            </CRow>
          </div>
          <div className="align-items-center" style={{ marginBottom: '15px' }}>
            <CRow className="align-items-center">
              <CCol>
                <CFormLabel>고객 담당자</CFormLabel>
              </CCol>
              <CCol sm={8} style={{ position: 'relative' }}>
                <CFormInput type={'text'} {...register('client')} disabled={true} />
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                  }}
                >
                  <ClientSearchModal
                    setTargetItem={(item: { account: UserResponse; company?: Company }) => setTargetClient(item)}
                  />
                </div>
              </CCol>
            </CRow>
          </div>
          <div className="align-items-center" style={{ marginBottom: '15px' }}>
            <CRow className="align-items-center">
              <CCol>
                <CFormLabel>생성일</CFormLabel>
              </CCol>
              <CCol sm={8} style={{ position: 'relative' }}>
                <div style={{ display: 'flex' }}>
                  <CFormInput type="date" {...register('createDateTime.from')} />
                  <CFormInput type="date" {...register('createDateTime.to')} />
                </div>
              </CCol>
              <CCol sm={3}>
                <CButton
                  style={{ marginRight: '7px', width: '55px', fontSize: '12px' }}
                  onClick={() => createDateTimeSetting('3', 'createDate')}
                >
                  3개월
                </CButton>
                <CButton
                  style={{ marginRight: '7px', width: '55px', fontSize: '12px' }}
                  onClick={() => createDateTimeSetting('6', 'createDate')}
                >
                  6개월
                </CButton>
                <CButton
                  style={{ marginRight: '7px', width: '55px', fontSize: '12px' }}
                  onClick={() => createDateTimeSetting('9', 'createDate')}
                >
                  9개월
                </CButton>
              </CCol>
            </CRow>
          </div>
          <div className="align-items-center" style={{ marginBottom: '15px' }}>
            <CRow className="align-items-center">
              <CCol>
                <CFormLabel>최종 수정일</CFormLabel>
              </CCol>
              <CCol sm={8} style={{ position: 'relative' }}>
                <div style={{ display: 'flex' }}>
                  <CFormInput type="date" {...register('updateDateTime.from')} />
                  <CFormInput type="date" {...register('updateDateTime.to')} />
                </div>
              </CCol>
              <CCol sm={3}>
                <CButton
                  style={{ marginRight: '7px', width: '55px', fontSize: '12px' }}
                  onClick={() => createDateTimeSetting('3', 'updateDate')}
                >
                  3개월
                </CButton>
                <CButton
                  style={{ marginRight: '7px', width: '55px', fontSize: '12px' }}
                  onClick={() => createDateTimeSetting('6', 'updateDate')}
                >
                  6개월
                </CButton>
                <CButton
                  style={{ marginRight: '7px', width: '55px', fontSize: '12px' }}
                  onClick={() => createDateTimeSetting('9', 'updateDate')}
                >
                  9개월
                </CButton>
              </CCol>
            </CRow>
          </div>

          <div className="align-items-center" style={{ marginBottom: '15px' }}>
            <CRow>
              <CCol>
                <CFormLabel>진행상태</CFormLabel>
              </CCol>

              <CCol sm={8}>
                <CRow>
                  {Object.keys(Status).map((it) => (
                    <CCol key={it} style={{ minWidth: 'max-content' }}>
                      <CFormCheck value={it} label={Status[it]} {...register('status')} />
                    </CCol>
                  ))}
                </CRow>
              </CCol>
            </CRow>
          </div>

          <div className="align-items-center" style={{ marginBottom: '15px' }}>
            <CRow className="align-items-center">
              <CCol>
                <CFormLabel>담당 번역 PM</CFormLabel>
              </CCol>
              <CCol sm={8} style={{ position: 'relative' }}>
                <CFormInput type={'text'} {...register('manager')} disabled={true} />
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                  }}
                >
                  <AdminSearchModal setTargetItem={(item: UserResponse) => setTargetAdmin(item)} />
                </div>
              </CCol>
            </CRow>
          </div>
          <CButton color="secondary" onClick={() => search()}>
            검색
          </CButton>
          <CButton color="light" onClick={clear}>
            초기화
          </CButton>
        </div>
      )}
    </React.Fragment>
  );
}

export default SearchFormOfProjectAndJobList;
