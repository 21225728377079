import { cilEnvelopeOpen, cilList, cilMenu } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavItem,
  CNavLink,
} from '@coreui/react';
import React from 'react';
import { logo } from 'src/assets/brand/logo';
import AlramDropDown from 'src/components/organisms/AlramDropDown';
import AppBreadcrumb from '../AppBreadcrumb';
import AppHeaderDropdown from '../AppHeaderDropdown';

interface Props {
  onSidebarShow: React.Dispatch<React.SetStateAction<boolean>>;
}

function AppHeader(props: Props) {
  const { onSidebarShow } = props;

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler className="ps-1" onClick={() => onSidebarShow((prev) => !prev)}>
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none">
          <CIcon icon={logo} height={48} />
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">
          {/*<CNavItem>*/}
          {/*  <CNavLink to='/dashboard' component={NavLink}>*/}
          {/*    Dashboard*/}
          {/*  </CNavLink>*/}
          {/*</CNavItem>*/}
          <CNavItem>
            <CNavLink href="#">Users</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">Settings</CNavLink>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav>
          <AlramDropDown />
        </CHeaderNav>
        <CHeaderNav>
          <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilList} size="lg" />
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilEnvelopeOpen} size="lg" />
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav className="ms-3">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer>
    </CHeader>
  );
}

export default AppHeader;
