import { cn, useMount } from '@voithru/front-core';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { ButtonBase } from 'src/components/atoms/styled/button';
import { Title } from 'src/components/atoms/styled/element';
import { CheckBox } from 'src/components/atoms/styled/form';
import { Column, Row } from 'src/components/atoms/styled/layout';
import ProductOrderOptionTypesModal from 'src/components/organisms/ProductOrderOptionTypesModal';
import SelectOptionsPriceModal from 'src/components/organisms/SelectOptionsPriceModal';
import SelectPriceModal from 'src/components/organisms/SelectPriceModal';
import { LANGUAGES, LANGUAGES_SUPPORT } from 'src/constants/APILanguages';
import { PRODUCT_DELIVERY_TYPES, PRODUCT_ORDER_TYPES, PRODUCT_SOURCE_TYPES } from 'src/constants/Product';
import { Product } from 'src/types/api/Product';
import { ProductOrderOption, ProductOrderPrice, ProductOrderResponse } from 'src/types/api/ProductOrder';
import { ProjectResponse } from 'src/types/api/Project';
import { isAxiosError } from 'src/utils/api/axios';
import { WorkRange, Source_Language, Translate_Language, ResultType, WorkTarget } from 'src/utils/translate';
import { useProjectDetailInfoContext } from '../../context';
import { ProjectOrderInfoWrapper } from './styled';

interface Props {
  projectId: number;
  project: ProjectResponse;

  onChecked: (id: number) => void;
  forceUpdate: () => void;
}

interface TotalPrice {
  amount?: number;
  quantity?: number;
}

interface FormData {
  product: ProductOrderResponse[];
}

function ProjectOrderInfo(props: Props) {
  const { projectId, project, onChecked, forceUpdate } = props;

  const { jobResults, jobsMap, productOrders, productOrderChecks, setProductOrders } = useProjectDetailInfoContext();

  const { handleSubmit, register, control, setValue, watch } = useForm<FormData>();

  const { fields, append, update, remove } = useFieldArray({ control, name: 'product', keyName: 'fieldKey' });

  const watchProduct = watch('product');

  const [isEdit, setIsEdit] = React.useState(false);

  const [allChecked, setAllChecked] = React.useState(false);

  const amount = React.useMemo(() => {
    const map = new Map<number, number>();
    if (jobResults && productOrders && jobsMap) {
      jobResults.forEach((it) => {
        const jobWorkSize = jobsMap.get(it.jobId)?.workSize ?? 0;

        map.set(it.productOrderId, (map.get(it.productOrderId) ?? 0) + jobWorkSize);
      });
    }
    return map;
  }, [productOrders, jobResults, jobsMap]);

  const totalPrice = React.useMemo<TotalPrice>(() => {
    if (productOrders) {
      return productOrders.reduce((obj: TotalPrice, acc) => {
        const optionPrice =
          acc.options?.reduce((prev, acc) => {
            return prev + (acc.price ?? 0);
          }, 0) || 0;
        const price = acc.price ?? 0;
        const getAmount = amount.get(acc.id) ?? 0;

        return {
          amount: (obj['amount'] ?? 0) + getAmount * price + optionPrice,
          quantity: (obj['quantity'] ?? 0) + price * acc.quantity,
        };
      }, {});
    }
    return {};
  }, [productOrders, amount]);

  const handleAllChecked = React.useCallback(() => {
    const entryProductOrderChecks = productOrderChecks.entries();

    for (const item of entryProductOrderChecks) {
      const [id] = item;

      productOrderChecks.set(id, !allChecked);
    }
    forceUpdate();
    setAllChecked(!allChecked);
  }, [allChecked, forceUpdate, productOrderChecks]);

  const handleChecked = React.useCallback(
    (id: number) => {
      if (allChecked) {
        setAllChecked(false);
      }

      onChecked(id);

      if (!allChecked) {
        const entryProductOrderChecks = productOrderChecks.entries();
        let flag = true;
        for (const item of entryProductOrderChecks) {
          const [, bool] = item;
          flag = bool;
          if (!flag) break;
        }
        setAllChecked(flag);
      }
    },
    [allChecked, onChecked, productOrderChecks]
  );

  const putProductOrderOptions = React.useCallback(async (data: ProductOrderOption[]) => {
    for (const item of data) {
      if (item.id) {
        const res = await api.productOrder.item(item.id).productOrderOptions.patch(item);

        if (isAxiosError(res)) {
          throw res;
        }
      }
    }
  }, []);

  const putProductOrder = React.useCallback(async (id: number, data: Product) => {
    const param: ProductOrderPrice = {
      id: id,
      productId: data.id!,
      price: data.price,
    };

    const res = await api.productOrder.item(id).patch(param);

    if (isAxiosError(res)) {
      throw res;
    }
  }, []);

  const checkDeletedRelatJobResult = React.useCallback(
    (products: ProductOrderResponse[]) => {
      let deletedRelateJobResult = false;

      jobResults?.forEach((jobResult) => {
        const findRelated = products.find((product) => jobResult.productOrderId === product.id);

        if (!findRelated) {
          deletedRelateJobResult = true;
        }
      });

      return deletedRelateJobResult;
    },
    [jobResults]
  );
  const get = React.useCallback(async () => {
    const productOrderRes = await api.project.item(projectId).productOrders();

    if (isAxiosError(productOrderRes)) {
      throw productOrderRes;
    }

    for (const item of productOrderRes.data) {
      productOrderChecks.set(item.id, false);
    }

    setProductOrders(productOrderRes.data);
  }, [productOrderChecks, projectId, setProductOrders]);

  const onSubmit = React.useCallback(
    async (data: FormData) => {
      try {
        if (checkDeletedRelatJobResult(data.product)) {
          setIsEdit(false);
          get();
          return window.alert('작업결과물이 연결된 주문정보는 삭제가 불가능합니다.');
        }

        const res = await api.project.item(projectId).related({ productOrders: data.product });
        if (isAxiosError(res)) {
          throw res;
        }

        get();
      } catch (err) {
        errorLogger.error(err);
      }

      setIsEdit(false);
    },
    [checkDeletedRelatJobResult, get, projectId]
  );

  useMount(() => {
    get().catch(errorLogger.error);
  });

  React.useEffect(() => {
    if (!productOrders) return;

    setValue('product', [...productOrders]);
  }, [productOrders, isEdit, setValue]);

  return (
    <ProjectOrderInfoWrapper as={'form'} onSubmit={handleSubmit(onSubmit)}>
      <Row className={'titleWrap'}>
        <Title>프로젝트 주문정보</Title>
        {!isEdit && <ButtonBase onClick={() => setIsEdit(!isEdit)}>수정하기</ButtonBase>}
        {isEdit && (
          <>
            <ButtonBase onClick={() => append({ projectId })}>작업추가</ButtonBase>
            <ButtonBase type={'submit'}>완료하기</ButtonBase>
          </>
        )}
      </Row>
      <Row className={'totalWrap'}>
        <span>Total</span>
        <Row>
          <span>{totalPrice['amount']?.toLocaleString()}</span>
          <span>/{totalPrice['quantity']?.toLocaleString()}</span>
        </Row>
      </Row>
      <Column>
        <Row>
          <span>{!isEdit && <CheckBox onChange={handleAllChecked} checked={allChecked} />}</span>
          <span>작업범위</span>
          <span>원본언어</span>
          <span>번역언어</span>
          <span>납품형태</span>
          <span>작업대상</span>
          <span>상품단가</span>
          <span>부가서비스</span>
          <span>부가서비스단가</span>
          <span>작업량(/예상)</span>
          <span>금액(/예상)</span>
          <span />
        </Row>

        {!isEdit &&
          productOrders?.map((it) => {
            const amountValue = amount.get(it.id) ?? 0;
            const price = it.price ?? 0;
            const optionPrice =
              it.options?.reduce((prev, acc) => {
                return prev + (acc.price ?? 0);
              }, 0) || 0;
            return (
              <Row key={it.id} className={cn(productOrderChecks.get(it.id) && 'isHighLight')}>
                <span>
                  <CheckBox onChange={() => handleChecked(it.id)} checked={productOrderChecks.get(it.id)} />
                </span>
                <span>{WorkRange[it.productType]}</span>
                <span>{Source_Language[it.sourceLanguage]}</span>
                <span>{it.translateLanguage && Translate_Language[it.translateLanguage]}</span>
                <span>{it.deliveryType && ResultType[it.deliveryType]}</span>
                <span>{it.productSource && WorkTarget[it.productSource]}</span>
                <span>
                  <SelectPriceModal
                    projectCategory={project.category}
                    productOrder={it}
                    onSubmitHandler={async (data) => {
                      await putProductOrder(it.id, data).catch(errorLogger.error);
                      await get().catch(errorLogger.error);
                    }}
                    isEdit={true}
                  />
                </span>
                <span>
                  <ProductOrderOptionTypesModal productOrder={it} onSubmitHandler={(data) => console.log(data)} />
                </span>
                <span>
                  <SelectOptionsPriceModal
                    productOrderOptions={it.options ?? []}
                    category={project.category}
                    onSubmitHandler={async (data) => {
                      await putProductOrderOptions(data).catch(errorLogger.error);
                      await get().catch(errorLogger.error);
                    }}
                    isEdit={true}
                  />
                </span>
                <span>
                  {amountValue}/{it.quantity}
                </span>
                <span>
                  {(price * amountValue + optionPrice).toLocaleString() || '-'}/
                  {(price * it.quantity).toLocaleString() || '-'}
                </span>
              </Row>
            );
          })}
        {isEdit &&
          fields.map((it, index) => {
            if (!project.category) {
              throw new Error('INVALID category');
            }

            const amountValue = amount.get(it.id) ?? 0;
            const price = it.price ?? 0;
            const optionPrice =
              it.options?.reduce((prev, acc) => {
                return prev + (acc.price ?? 0);
              }, 0) || 0;

            return (
              <Row key={it.id} className={cn(productOrderChecks.get(it.id) && 'isHighLight')}>
                <span></span>
                <span>
                  <select
                    {...register(`product.${index}.productType`, {
                      onChange: () =>
                        update(index, {
                          ...it,
                          translateLanguage:
                            watchProduct[index].productType !== 'CAPTION' ? it.translateLanguage : undefined,
                          deliveryType:
                            watchProduct[index].productType !== 'SPOTTING_ONLY' &&
                            watchProduct[index].productType !== 'TIME_CODE'
                              ? it.deliveryType
                              : undefined,
                        }),
                    })}
                    defaultValue={it.productType}
                  >
                    {PRODUCT_ORDER_TYPES[project.category]?.map((item) => (
                      <option key={item} value={item}>
                        {WorkRange[item]}
                      </option>
                    ))}
                  </select>
                </span>
                <span>
                  <select
                    {...register(`product.${index}.sourceLanguage`, { required: true })}
                    defaultValue={it.sourceLanguage}
                  >
                    {LANGUAGES_SUPPORT.map((item) => (
                      <option key={item} value={item}>
                        {Source_Language[item]}
                      </option>
                    ))}
                  </select>
                </span>
                <span>
                  {(watchProduct[index].productType === 'TRANSLATION' ||
                    watchProduct[index].productType === 'TYPESETTER_ONLY') && (
                    <select
                      {...register(`product.${index}.translateLanguage`)}
                      defaultValue={it.translateLanguage ?? ''}
                    >
                      {LANGUAGES.filter((lan) => lan !== watchProduct[index].sourceLanguage).map((item) => (
                        <option key={item} value={item}>
                          {Translate_Language[item]}
                        </option>
                      ))}
                    </select>
                  )}
                </span>
                <span>
                  {watchProduct[index].productType !== 'SPOTTING_ONLY' &&
                    watchProduct[index].productType !== 'TIME_CODE' && (
                      <select
                        {...register(`product.${index}.deliveryType`)}
                        defaultValue={it.deliveryType}
                        placeholder={'납품형태'}
                      >
                        {PRODUCT_DELIVERY_TYPES[project.category]
                          ?.filter((it) => {
                            if (project.category === 'VIDEO') {
                              return watchProduct[index].productType !== 'BURN_IN' || it !== 'NO_BURN_IN';
                            }
                            if (project.category === 'WEBTOON') {
                              return watchProduct[index].productType !== 'TYPESETTER_ONLY' || it !== 'NO_TYPESETTER';
                            }

                            return it;
                          })
                          ?.map((item) => (
                            <option key={item} value={item}>
                              {ResultType[item]}
                            </option>
                          ))}
                      </select>
                    )}
                </span>
                <span>
                  {project.category === 'VIDEO' && (
                    <select {...register(`product.${index}.productSource`)} defaultValue={it.productSource ?? 'null'}>
                      {PRODUCT_SOURCE_TYPES[project.category]?.map((item) => (
                        <option key={item} value={item}>
                          {WorkTarget[item]}
                        </option>
                      ))}
                    </select>
                  )}
                </span>
                <span>
                  <SelectPriceModal projectCategory={project.category} productOrder={it} isEdit={false} />
                </span>
                <span>
                  <ProductOrderOptionTypesModal
                    productOrder={it}
                    onSubmitHandler={(data) => {
                      const options = data.map((item) => {
                        return it.options?.find((option) => option.type === item) ?? { type: item };
                      });

                      update(index, {
                        ...it,
                        options,
                      });
                    }}
                    isEdit={true}
                    category={project.category}
                  />
                </span>
                <span>
                  <SelectOptionsPriceModal productOrderOptions={it.options ?? []} />
                </span>
                <span>
                  <span>{amountValue}</span>
                  <span>/</span>
                  {isEdit && (
                    <input
                      style={{ width: '100%' }}
                      {...register(`product.${index}.quantity`)}
                      defaultValue={it.quantity}
                      onChange={(e) => {
                        let workSize = Number(e.target.value);
                        if (isNaN(workSize)) {
                          workSize = 1;
                        } else if (workSize <= 0) {
                          workSize = 1;
                        }
                        e.target.value = workSize.toString();
                      }}
                    />
                  )}
                  {!isEdit && <span>{it.quantity}</span>}
                </span>
                <span>
                  {(price * amountValue + optionPrice).toLocaleString() || '-'}/
                  {(price * it.quantity).toLocaleString() || '-'}
                </span>
                <ButtonBase onClick={() => remove(index)}>X</ButtonBase>
              </Row>
            );
          })}
      </Column>
    </ProjectOrderInfoWrapper>
  );
}

export default ProjectOrderInfo;
