import { Parameter } from '@voithru/front-core';
import { AxiosInstance } from 'axios';
import { generatePath } from 'react-router';
import { APIAccountId, APIResponse } from 'src/types/api';
import { APICredential, APICredentialRequest } from 'src/types/api/Credential';

const PATH_CREDENTIAL = '/api/v1/credential';
function apiCredential(instance: AxiosInstance) {
  return {
    get: (params: APIAccountId) => {
      const search = Parameter.toURLSearchParams(params);
      return instance.get<APIResponse<APICredential[]>>(PATH_CREDENTIAL, { params: search });
    },
    post: (params: APICredentialRequest) => {
      const search = Parameter.toURLSearchParams(params);
      return instance.post<APIResponse<APICredential>>(PATH_CREDENTIAL + '/google', {}, { params: search });
    },
    item(userCredentialId: number) {
      const CREDENTIAL_ITEM_PATH = generatePath(PATH_CREDENTIAL + '/:userCredentialId', { userCredentialId });

      return {
        name: (params: Pick<APICredential, 'name'>) => {
          const search = Parameter.toURLSearchParams(params);
          return instance.patch<APIResponse<APICredential>>(CREDENTIAL_ITEM_PATH + '/name', {}, { params: search });
        },
        delete: () => instance.delete(CREDENTIAL_ITEM_PATH),
      };
    },
    token: (params: Pick<APICredential, 'id'>) => {
      const search = Parameter.toURLSearchParams({ userCredentialId: params.id });
      return instance.get<APIResponse<string>>(PATH_CREDENTIAL + '/google/token', { params: search });
    },
  };
}

export default apiCredential;
