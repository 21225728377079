import { KeyboardCode } from '@voithru/front-core';
import React from 'react';

export function handlePreventEvent(e: React.SyntheticEvent | Event) {
  e.preventDefault();
}

export function handlePreventEnterEvent(e: Event) {
  const keycode = new KeyboardCode(e).toString();
  if (keycode === 'enter') {
    e.preventDefault();
  }
}

export function handlePreventWindowClose(e: Event) {
  e.preventDefault();
  e.returnValue = false;
  return '';
}

export const handleEnterKeyDown = (
  e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  targetRef: React.RefObject<HTMLInputElement | HTMLTextAreaElement>
) => {
  if (!targetRef.current?.value) {
    return;
  }

  if (new KeyboardCode(e).toString() === 'enter') {
    targetRef.current.blur();
  }
};
