import {
  CCol,
  CContainer,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import React from 'react';
import { ButtonBase } from 'src/components/atoms/styled/button';
import { JobsResponse } from 'src/types/api/Job';
import { UploadJobResultsModalWrapper } from './styled';
import ICN_UPLOAD from 'src/assets/icons/icn-upload-24px.svg';
import { nanoid } from 'nanoid';
import { useForm } from 'react-hook-form';
import { selectFile, SelectFileOptions, useMount } from '@voithru/front-core';
import errorLogger from 'src/api/errorLogger';
import { CheckBox } from 'src/components/atoms/styled/form';
import { useContentsUploaderService } from 'src/components/context/ContentsUploaderContext';
import { useUploaderContext } from 'src/components/context/UploaderContext';
import { JobResultResponse } from 'src/types/api/JobResult';
import { NamedFile } from 'src/utils/files';
import { FileGroup } from 'src/utils/JobRegisterService';
import { getTransactionJobResultsStorage } from 'src/utils/storages';

interface Props {
  filteredJobResults: JobResultResponse[];
  jobResultsChecks: Map<number, boolean>;
  jobsMap: Map<number, JobsResponse>;

  transactionId: string;
}

function UploadJobResultModal(props: Props) {
  const { filteredJobResults, jobResultsChecks, jobsMap, transactionId } = props;

  const registerService = useContentsUploaderService();
  const context = useUploaderContext();

  const manager = React.useMemo(
    () => registerService.createUploadService(transactionId),
    [registerService, transactionId]
  );

  const [visible, setVisible] = React.useState(false);

  const [jobResultUploadChecks] = React.useState<Map<number, boolean>>(() => new Map());
  const [connectedFileWithJobResultMap] = React.useState<
    Map<number, { jobResult: JobResultResponse; fileGroup: FileGroup }[]>
  >(() => new Map());
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);
  const { handleSubmit } = useForm();

  const [orderedFileGroups, setOrderedFileGroups] = React.useState<FileGroup[]>([]);
  const onSelectFiles = React.useCallback(
    (fgs: FileGroup[]) => {
      const files = fgs.flatMap((fg) => fg.files);
      for (const namedFile of files) {
        manager.register(namedFile);
      }
      fgs.forEach((value, index) => (value.index = orderedFileGroups.length + index));
      setOrderedFileGroups((prev) => prev.concat(fgs));
    },
    [manager, orderedFileGroups.length]
  );

  const handleFileSelect = React.useCallback(
    (options: SelectFileOptions = {}) =>
      async () => {
        const files = await selectFile({ multiple: true, ...options });
        const named = Array.from(files ?? []).map(
          (it): NamedFile => ({ id: nanoid(), name: it.webkitRelativePath || it.name, file: it })
        );
        const fileGroups: FileGroup[] = [];

        if (!files || files.length === 0) {
          onSelectFiles([]);
          return;
        }

        if (options.directory) {
          const directory = files[0]?.webkitRelativePath?.substr(0, files[0]?.webkitRelativePath.indexOf('/'));
          if (!directory) throw Error('cant find directory name');
          const fileGroup: FileGroup = {
            id: nanoid(),
            index: NaN,
            name: directory,
            files: named,
          };
          fileGroups.push(fileGroup);
        } else {
          named.forEach((file) => {
            const fileGroup: FileGroup = {
              id: nanoid(),
              index: NaN,
              name: file.name,
              files: [file],
            };
            fileGroups.push(fileGroup);
          });
        }
        onSelectFiles(fileGroups);
      },
    [onSelectFiles]
  );

  const clear = React.useCallback(() => {
    setOrderedFileGroups([]);
    jobResultUploadChecks.clear();
    connectedFileWithJobResultMap.clear();
  }, [connectedFileWithJobResultMap, jobResultUploadChecks]);

  const connectFileWithJobResult = React.useCallback(
    (it: FileGroup) => {
      const targetJobResult = filteredJobResults.filter((it) => {
        return jobResultUploadChecks.get(it.id);
      });
      if (targetJobResult.length > 0) {
        const targetConnectedFileWithJobResult = connectedFileWithJobResultMap.get(targetJobResult[0].id);
        if (targetConnectedFileWithJobResult) {
          connectedFileWithJobResultMap.set(targetJobResult[0].id, [
            ...targetConnectedFileWithJobResult,
            {
              jobResult: targetJobResult[0],
              fileGroup: it,
            },
          ]);
        } else {
          connectedFileWithJobResultMap.set(targetJobResult[0].id, [{ jobResult: targetJobResult[0], fileGroup: it }]);
        }
      }

      setOrderedFileGroups(orderedFileGroups?.filter((item) => item.files[0].id !== it.files[0].id));
      forceUpdate();
    },
    [
      filteredJobResults,
      jobResultUploadChecks,
      connectedFileWithJobResultMap,
      orderedFileGroups,
      setOrderedFileGroups,
      forceUpdate,
    ]
  );

  const submit = React.useCallback(async () => {
    const storage = getTransactionJobResultsStorage(transactionId);
    const uploadFiles: FileGroup[] = [];
    const fileWithJobResultStorage: { jobId: number; jobResultId: number; fileId: string }[] = [];
    Array.from(connectedFileWithJobResultMap).map((connectedFileWithJobResult) => {
      if (connectedFileWithJobResult[1]) {
        connectedFileWithJobResult[1].map((it) => {
          if (it.jobResult.jobId && it.jobResult.id && it.fileGroup.files[0]) {
            fileWithJobResultStorage.push({
              jobId: Number(it.jobResult.jobId),
              jobResultId: Number(it.jobResult.id),
              fileId: String(it.fileGroup.files[0].id),
            });
            uploadFiles.push(it.fileGroup);
          }
          return true;
        });
      }
      return true;
    });
    storage.item = fileWithJobResultStorage;

    if (!manager || manager.uploaders.length === 0) {
      return;
    }

    const fileGroups = uploadFiles.map((it, index) => ({
      ...it,
      name: it.name,
      index,
    }));

    try {
      registerService.start(transactionId, fileGroups, 'POST_JOB_RESULT_FILE');
      clear();
      setVisible(false);
    } catch (error) {
      errorLogger.error(error);
    }
  }, [transactionId, connectedFileWithJobResultMap, manager, registerService, clear, setVisible]);

  const disconnectJobResultWithFile = React.useCallback(
    (key: number, it: { jobResult: JobResultResponse; fileGroup: FileGroup }) => {
      if (connectedFileWithJobResultMap.get(key)) {
        const filterItem = connectedFileWithJobResultMap
          .get(key)
          ?.filter((item) => item.fileGroup.files[0].id !== it.fileGroup.files[0].id);
        connectedFileWithJobResultMap.set(key, filterItem ? filterItem : []);
      } else {
        connectedFileWithJobResultMap.delete(key);
      }
      setOrderedFileGroups([...orderedFileGroups, it.fileGroup]);
      forceUpdate();
    },
    [connectedFileWithJobResultMap, orderedFileGroups]
  );

  const handleChecked = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
      jobResultUploadChecks.clear();
      jobResultUploadChecks.set(id, e.target.checked);
      forceUpdate();
    },
    [jobResultUploadChecks]
  );

  useMount(() => {
    context.register(transactionId, manager);
  });

  return (
    <>
      <ButtonBase
        onClick={() => setVisible(!visible)}
        disabled={
          !filteredJobResults.filter((it) => {
            return jobResultsChecks.get(it.id);
          }).length
        }
      >
        업로드
      </ButtonBase>
      <UploadJobResultsModalWrapper
        title={'작업 결과물 업로드'}
        visible={visible}
        onDismiss={() => setVisible(false)}
        onSubmit={handleSubmit(submit)}
      >
        <CContainer style={{ display: 'flex', width: '1000px' }}>
          <CCol style={{ height: '320px', overflow: 'auto' }}>
            <CTable bordered>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell></CTableHeaderCell>
                  <CTableHeaderCell>결과물 ID</CTableHeaderCell>
                  <CTableHeaderCell>콘텐츠 명</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {filteredJobResults
                  .filter((it) => {
                    return jobResultsChecks.get(it.id);
                  })
                  .map((it, idx) => {
                    const job = jobsMap.get(it.jobId) ?? null;

                    return (
                      <CTableRow key={idx}>
                        <CTableDataCell>
                          {' '}
                          <CheckBox
                            onChange={(e) => handleChecked(e, it.id)}
                            checked={jobResultUploadChecks.get(it.id)}
                          />
                        </CTableDataCell>
                        <CTableDataCell>JR - {it.id}</CTableDataCell>
                        <CTableDataCell>{job?.name}</CTableDataCell>
                      </CTableRow>
                    );
                  })}
              </CTableBody>
            </CTable>
          </CCol>
          <CCol style={{ height: '320px', overflow: 'auto', position: 'relative' }}>
            <CTable bordered>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell>NO.</CTableHeaderCell>
                  <CTableHeaderCell style={{ display: 'flex', justifyContent: 'space-between' }}>
                    결과물 파일
                    {orderedFileGroups.length > 0 && (
                      <div onClick={handleFileSelect()}>
                        <ButtonBase>
                          <img src={ICN_UPLOAD} alt={'upload'} />
                        </ButtonBase>
                      </div>
                    )}
                  </CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {orderedFileGroups.length > 0 ? (
                  orderedFileGroups.map((it, idx) => {
                    return (
                      <CTableRow key={idx} onClick={() => connectFileWithJobResult(it)}>
                        <CTableDataCell> {idx + 1}</CTableDataCell>
                        <CTableDataCell>{it.files[0].name}</CTableDataCell>
                      </CTableRow>
                    );
                  })
                ) : (
                  <div style={{ position: 'absolute', left: '50%' }} onClick={handleFileSelect()}>
                    <ButtonBase>
                      <img src={ICN_UPLOAD} alt={'upload'} />
                    </ButtonBase>
                  </div>
                )}
              </CTableBody>
            </CTable>
          </CCol>
        </CContainer>
        {connectedFileWithJobResultMap.size > 0 && (
          <CContainer>
            <CTable bordered>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell>결과물 ID</CTableHeaderCell>
                  <CTableHeaderCell>콘텐츠 명</CTableHeaderCell>
                  <CTableHeaderCell>결과물 파일</CTableHeaderCell>
                  <CTableHeaderCell></CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {Array.from(connectedFileWithJobResultMap).map((item, idx) => {
                  const fileWithJobResult = connectedFileWithJobResultMap.get(item[0]);
                  if (!fileWithJobResult || !fileWithJobResult[0]) {
                    return null;
                  }
                  const job = jobsMap.get(fileWithJobResult[0].jobResult.jobId) ?? null;
                  return fileWithJobResult?.map((it) => {
                    return (
                      <CTableRow key={idx}>
                        <CTableDataCell>JR - {it.jobResult.id}</CTableDataCell>
                        <CTableDataCell>{job?.name}</CTableDataCell>
                        <CTableDataCell>{it.fileGroup.files[0].name}</CTableDataCell>
                        <CTableDataCell onClick={() => disconnectJobResultWithFile(item[0], it)}>X</CTableDataCell>
                      </CTableRow>
                    );
                  });
                })}
              </CTableBody>
            </CTable>
          </CContainer>
        )}
      </UploadJobResultsModalWrapper>
    </>
  );
}

export default UploadJobResultModal;
