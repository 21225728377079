import { Title } from 'src/components/atoms/styled/element';
import { Column, Row } from 'src/components/atoms/styled/layout';
import styled from 'styled-components';

export const CredentialInfoWrapper = styled(Column)`
  & > ${Row} {
    margin: 40px 0 25px 0;

    justify-content: space-between;

    width: 100%;

    border-bottom: 1px solid #d8dbe0;

    & > ${Title} {
      font-size: 20px;
    }
  }
`;
