import { gql } from '@apollo/client';

export const GQL_BASIC_INFO_PROJECT = gql`
  query ProjectCreateInfo($id: ID!) {
    project(id: $id) {
      account {
        id
        name
        company {
          name
        }
        department
        phoneNumber
        email
        masterAccountId
      }

      manager {
        id
        name
      }

      projectFiles {
        id
        fileType
        file {
          id
          name
        }
      }
    }
  }
`;
