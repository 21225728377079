import { Description, Title } from 'src/components/atoms/styled/element';
import { Column, Row } from 'src/components/atoms/styled/layout';
import styled from 'styled-components';

export const ProjectInWorkerRelayWrapper = styled(Column)`
  width: 1686px;

  & > ${Title}.title {
    margin-bottom: 12px;

    font-size: 20px;
  }

  & > ${Column}.detail {
    justify-content: center;
    width: 95%;

    background: #c4c4c42b;

    & > ${Row} {
      margin: 24px 32px;

      & > ${Title} {
        width: 180px;
      }
      & > ${Description} {
        padding: 12px;

        border-radius: 6px;

        min-width: 845px;

        background: #c4c4c45e;
      }

      & > ${Column} {
        min-width: 845px;

        & > span {
          padding: 12px;

          border-radius: 6px;

          background: #c4c4c45e;
        }

        & > span + span {
          margin-top: 12px;
        }
      }
    }
  }
`;
