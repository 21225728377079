import { AxiosInstance } from 'axios';
import {
  Company,
  CompanyFileRequest,
  CompanyFileResponse,
  CompanyPostWithAccountResponse,
  CompanyResponse,
} from 'src/types/api/Company';
import { generatePath } from 'react-router';
import { User } from 'src/types/api/User';

const PATH_COMPANY = '/api/v1/companies';

function apiCompany(instance: AxiosInstance) {
  return {
    get: () => instance.get<CompanyResponse[]>(PATH_COMPANY),
    post: (data: Company) => instance.post(PATH_COMPANY, data),
    postWithAccount: (data: { company: Company; account: User }) =>
      instance.post<CompanyPostWithAccountResponse>(PATH_COMPANY + '/withAccount', data),
    item(companyId: number) {
      const COMPANY_ITEM_PATH = generatePath(PATH_COMPANY + '/:companyId', { companyId });

      return {
        files: {
          post: (data: CompanyFileRequest[]) => instance.post(COMPANY_ITEM_PATH + '/files', data),
          get: () => instance.get<CompanyFileResponse[]>(COMPANY_ITEM_PATH + '/files'),
        },
        get: () => instance.get<CompanyResponse>(COMPANY_ITEM_PATH),
        put: (data: Company) => instance.put(COMPANY_ITEM_PATH, data),
      };
    },
  };
}

export default apiCompany;
