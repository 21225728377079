import axios, { AxiosRequestConfig } from 'axios';
import { AWS_LOCATION } from './config';
import { S3PartUploadParams } from 'src/types/api/File';

interface S3PutPartOptions {
  bucket: string;
  key: string;
  params: S3PartUploadParams;
  headers: {
    Authorization: string;
    'x-amz-date': string;
    'x-amz-content-sha256': string;
    'content-type': 'multipart/form';
  };
}

const s3 = {
  putPart: (file: File | Blob | ArrayBuffer, options: S3PutPartOptions, axiosOptions?: AxiosRequestConfig) => {
    const { bucket, key, params, headers } = options;
    const S3_PATH = `https://${bucket}.s3.${AWS_LOCATION}.amazonaws.com/` + key;
    return axios.put(S3_PATH, file, { ...axiosOptions, params, headers });
  },
};

export function apiUtils() {
  return {
    s3,
  };
}
