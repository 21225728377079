import { CContainer } from '@coreui/react';
import React from 'react';
import AppHeader from 'src/components/templates/DefaultLayout/components/AppHeader';
import AppSidebar from 'src/components/templates/DefaultLayout/components/AppSidebar';

interface Props {
  children?: React.ReactNode;
}

function DefaultLayout(props: Props) {
  const { children } = props;

  const [isSidebarShow, setSidebarShow] = React.useState(true);

  return (
    <div style={{ height: '100%' }}>
      <AppSidebar isSidebarShow={isSidebarShow} onSidebarShow={setSidebarShow} />
      <div className="wrapper d-flex flex-column bg-light " style={{ minHeight: '100%' }}>
        <AppHeader onSidebarShow={setSidebarShow} />
        <div className="body flex-grow-1 px-3" style={{ padding: '1px 0', overflow: 'auto' }}>
          <CContainer
            lg
            style={{
              margin: 0,
            }}
          >
            {children}
          </CContainer>
        </div>
      </div>
    </div>
  );
}

export default DefaultLayout;
