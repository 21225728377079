import { cn } from '@voithru/front-core';
import React from 'react';

interface Position {
  x: number;
  y: number;
}

function getPosition(e: MouseEvent) {
  const { clientX, clientY } = e;
  return { x: clientX, y: clientY };
}

interface Options {
  onChange(from: number, to: number): void;
}

function useDragAndDrop(options: Options) {
  const { onChange } = options;
  const position = React.useRef<Position>();
  const indexFrom = React.useRef<number>(NaN);

  const [targetIndex, setIndex] = React.useState(NaN);

  const handleMouseDown = React.useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    if (position.current || isNaN(e.clientX) || isNaN(e.clientY)) {
      return;
    }

    const parent = e.currentTarget.parentElement?.parentElement;
    const parentChildren = Array.from(parent?.children ?? []);
    const index = parentChildren.findIndex((it) => it.contains(e.target as HTMLElement));
    if (index <= 0) {
      return;
    }

    position.current = getPosition(e.nativeEvent);
    indexFrom.current = index;

    const next = cn(document.body.className, 'hold');
    document.body.className = next;
  }, []);

  const handleMouseMove = React.useCallback((e: React.MouseEvent) => {
    if (!position.current || isNaN(indexFrom.current)) {
      return;
    }

    const arr = Array.from(e.currentTarget.children ?? []);
    const index = arr.findIndex((it) => it.contains(e.target as HTMLElement));
    if (index <= 0) {
      return;
    }

    setIndex(index);
  }, []);

  const handleDocumentMouseUp = React.useCallback(
    (e: MouseEvent) => {
      const next = document.body.className.split(' ').filter((it) => it !== 'hold');
      document.body.className = cn(...next);

      if (!position.current) {
        return;
      }

      if (isNaN(targetIndex)) {
        position.current = undefined;
        indexFrom.current = NaN;
        return;
      }

      e.preventDefault();
      onChange(indexFrom.current, targetIndex);
      position.current = undefined;
      indexFrom.current = NaN;
      setIndex(NaN);
    },
    [onChange, targetIndex]
  );

  React.useEffect(() => {
    document.addEventListener('mouseup', handleDocumentMouseUp);

    return () => {
      document.removeEventListener('mouseup', handleDocumentMouseUp);
    };
  }, [handleDocumentMouseUp]);

  return { from: indexFrom.current, to: targetIndex, handleMouseDown, handleMouseMove };
}

export default useDragAndDrop;
