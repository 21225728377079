export function setCookie(name: string, value: string, days: number) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

export const getValueFromCookie: (keyStartWith: string) => string | undefined = (keyStartWith: string) => {
  const [, value] =
    document.cookie
      .split(';')
      .filter((kv) => kv.trim().startsWith(keyStartWith))[0]
      ?.split('=') || [];
  return value?.trim();
};

export function deleteCookie(name: string) {
  const domain = process.env.REACT_APP_ENVIRONMENT === 'local' ? 'localhost' : '.totus.pro';

  if (name === 'access_token') {
    document.cookie = name + `=; expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=${domain};`;
  }

  if (name === 'refresh_token') {
    document.cookie = name + `=; expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=${domain};`;
  }

  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;; path=/';
}
