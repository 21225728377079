import { useMount } from '@voithru/front-core';
import React from 'react';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { Column } from 'src/components/atoms/styled/layout';
import { ProjectResponse } from 'src/types/api/Project';
import { isAxiosError } from 'src/utils/api/axios';
import { getDocumentByJobResultId } from 'src/utils/api/getter';
import { useProjectDetailInfoContext } from '../../context';
import JobList from '../JobList';
import JobResultList from '../JobResultList';
import ProjectOrderInfo from '../ProjectOrderInfo';
import { ProjectDetailInfoWrapper } from './styled';

interface Props {
  project: ProjectResponse;
  projectId: number;

  onProjectChange(): void;

  transactionId: string;
}

function ProjectDetailInfo(props: Props) {
  const { project, projectId, onProjectChange, transactionId } = props;

  const { jobsChecks, productOrderChecks, setJobResults, jobResultChecks, documentMap, jobResultFileMap } =
    useProjectDetailInfoContext();

  const [force, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const setProductOrderChecksWithForceUpdate = React.useCallback(
    (id: number) => {
      productOrderChecks.set(id, !productOrderChecks.get(id));

      forceUpdate();
    },
    [productOrderChecks]
  );

  const setJobsChecksWithForceUpdate = React.useCallback(
    (id: number, bool?: boolean) => {
      if (bool !== undefined) {
        jobsChecks.set(id, bool);
      } else {
        jobsChecks.set(id, !jobsChecks.get(id));
      }

      forceUpdate();
    },
    [jobsChecks]
  );

  const getJobResultWithOthers = React.useCallback(async () => {
    const jobResultRes = await api.project.item(projectId).jobResult.get({
      status: [
        'REQUESTED',
        'REGISTERED',
        'PROCESSING',
        'REVIEWABLE',
        'REVIEW_REJECTED',
        'REVIEW_APPROVED',
        'DONE',
        'DROP',
      ],
    });

    if (isAxiosError(jobResultRes)) {
      throw jobResultRes;
    }

    for (const item of jobResultRes.data) {
      jobResultChecks.set(item.id, false);

      const documentRes = await getDocumentByJobResultId(item.id);
      if (documentRes.data?.id) {
        documentMap.set(item.id, documentRes.data);
      }

      const jobResultFileRes = await api.jobResult.item(item.id).files();

      if (isAxiosError(jobResultFileRes)) {
        throw jobResultFileRes;
      }

      jobResultFileMap.set(item.id, jobResultFileRes.data);
    }

    setJobResults(jobResultRes.data);
  }, [documentMap, jobResultChecks, jobResultFileMap, projectId, setJobResults]);

  useMount(() => {
    getJobResultWithOthers().catch(errorLogger.error);
  });

  return (
    <ProjectDetailInfoWrapper>
      <Column>
        <ProjectOrderInfo
          projectId={projectId}
          project={project}
          onChecked={setProductOrderChecksWithForceUpdate}
          forceUpdate={forceUpdate}
        />
        <JobList
          project={project}
          projectId={projectId}
          onProjectChange={onProjectChange}
          onChecked={setJobsChecksWithForceUpdate}
          forceUpdate={forceUpdate}
          transactionId={transactionId}
        />
      </Column>
      <div className={'tit'}></div>
      <JobResultList
        key={force}
        projectId={projectId}
        getJobResultWithOthers={getJobResultWithOthers}
        transactionId={transactionId}
      />
    </ProjectDetailInfoWrapper>
  );
}

export default ProjectDetailInfo;
