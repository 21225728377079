import { CButton } from '@coreui/react';
import { cn, useMount } from '@voithru/front-core';
import { nanoid } from 'nanoid';
import React from 'react';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import ICN_DOWNLOAD from 'src/assets/icons/icn-download-24px.svg';
import ICN_FOLDER_UPLOAD from 'src/assets/icons/icn-folder@2x.svg';
import ICN_FILE_UPLOAD from 'src/assets/icons/icn-upload-24px.svg';
import IssuedStatusBadge from 'src/components/atoms/IssuedStatusBadge';
import StatusLabel from 'src/components/atoms/StatusLabel';
import { ButtonBase } from 'src/components/atoms/styled/button';
import { Title } from 'src/components/atoms/styled/element';
import { CheckBox } from 'src/components/atoms/styled/form';
import { Column, Row } from 'src/components/atoms/styled/layout';
import { useContentsUploaderService } from 'src/components/context/ContentsUploaderContext';
import { useUploaderContext } from 'src/components/context/UploaderContext';
import FileDownloadModal from 'src/components/organisms/FileDownloadModal';
import JobResultStatusUpdateModal from 'src/components/organisms/JobResultStatusUpdateModal';
import AddJobResultModal from 'src/components/pages/ProjectDetailPage/components/AddJobResultModal';
import UploadJobResultModal from 'src/components/pages/ProjectDetailPage/components/UploadJobResultsModal';
import { JobResultFileType, JobResultResponse, JobResultStatus, JobResultStatusUpdate } from 'src/types/api/JobResult';
import { isAxiosError } from 'src/utils/api/axios';
import { dateFormat, formatDueDay } from 'src/utils/date';
import { handleEnterKeyDown } from 'src/utils/dom';
import { downloadAPIFile, NamedFile, selectFile } from 'src/utils/files';
import MultipartUpload from 'src/utils/MultipartUpload';
import QueueTask from 'src/utils/QueueTask';
import {
  AdditionalServices,
  ResultType,
  Source_Language,
  Translate_Language,
  WorkRange,
  WorkTarget,
} from 'src/utils/translate';
import styled from 'styled-components';
import { useProjectDetailInfoContext } from '../../context';
import { JobResultListWrapper } from './styled';

interface Props {
  projectId: number;

  getJobResultWithOthers(): Promise<void>;
  transactionId: string;

  className?: string;
}

type Status = JobResultStatus | 'ALL';

type JobResultStatusType = Partial<Record<Status, number>>;

function JobResultList(props: Props) {
  const { className, getJobResultWithOthers, transactionId } = props;

  const queueTask = React.useMemo(() => new QueueTask(), []);

  const {
    jobs,
    jobResults,
    productOrderChecks,
    jobsChecks,
    jobResultChecks,
    documentMap,
    jobsMap,
    productOrderMap,
    jobFileMap,
    jobResultFileMap,
    jobManagerMap,
    jobResultFilterStatus,
    setJobResultFilterStatus,
  } = useProjectDetailInfoContext();

  const registerService = useContentsUploaderService();
  const context = useUploaderContext();

  const manager = React.useMemo(
    () => registerService.createUploadService(transactionId),
    [registerService, transactionId]
  );

  const [allChecked, setAllChecked] = React.useState(() => false);
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);
  const jobResultStatus = React.useMemo<JobResultStatusType>(() => {
    if (!jobResults) {
      return {};
    }
    let status = jobResults.reduce((obj: JobResultStatusType, it) => {
      obj[it.status] = (obj[it.status] ?? 0) + 1;
      return obj;
    }, {});

    return {
      ...status,
      ALL: jobResults.length,
    };
  }, [jobResults]);

  const [editReviewDeadLineId, setEditReviewDeadLineId] = React.useState<number>();
  const [editScheduleDeadLineId, setEditScheduleDeadLineId] = React.useState<number>();

  const editReviewDeadLineRef = React.useRef<HTMLInputElement>(null);
  const editScheduleDeadLineRef = React.useRef<HTMLInputElement>(null);
  const filteredJobResults = React.useMemo(() => {
    if (!jobResults) {
      return [];
    }

    const entryProductOrderChecks = productOrderChecks.entries();
    const entryJobsChecks = jobsChecks.entries();
    let orderCheckFlag = false;
    let jobsChecksFlag = false;

    for (const item of entryProductOrderChecks) {
      const [, bool] = item;

      orderCheckFlag = bool;

      if (orderCheckFlag) {
        break;
      }
    }

    for (const item of entryJobsChecks) {
      const [, bool] = item;

      jobsChecksFlag = bool;
      if (jobsChecksFlag) {
        break;
      }
    }
    let filterResult: JobResultResponse[];

    if (jobResultFilterStatus === 'ALL') {
      filterResult = jobResults.slice();
    } else {
      filterResult = jobResults.filter((it) => it.status === jobResultFilterStatus);
    }

    if (orderCheckFlag && jobsChecksFlag) {
      return filterResult.filter((it) => {
        return productOrderChecks.get(it.productOrderId) && jobsChecks.get(it.jobId);
      });
    }

    if (orderCheckFlag) {
      return filterResult.filter((it) => {
        return productOrderChecks.get(it.productOrderId);
      });
    }

    if (jobsChecksFlag) {
      return filterResult.filter((it) => {
        return jobsChecks.get(it.jobId);
      });
    }

    return filterResult.sort((a, b) => a.id - b.id);
  }, [jobResults, productOrderChecks, jobsChecks, jobResultFilterStatus]);

  const handleAllChecked = React.useCallback(() => {
    const entryJobResultChekcs = jobResultChecks.entries();

    for (const item of entryJobResultChekcs) {
      const [id] = item;
      jobResultChecks.set(id, !allChecked);
    }

    setAllChecked(!allChecked);
  }, [allChecked, jobResultChecks]);

  const handleChecked = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
      if (allChecked) {
        setAllChecked(false);
      }
      jobResultChecks.set(id, e.target.checked);

      if (!allChecked) {
        const entryJobResultChecks = jobResultChecks.entries();
        let flag = true;
        for (const item of entryJobResultChecks) {
          const [, bool] = item;
          flag = bool;
          if (!flag) break;
        }
        setAllChecked(flag);
      }

      forceUpdate();
    },
    [jobResultChecks, allChecked]
  );

  const fileDownloads = React.useCallback(
    (id: number) => {
      const files = jobResultFileMap.get(id) ?? null;

      if (files === null) return;

      for (const item of files) {
        if (item.file) {
          downloadAPIFile(item.file);
        }
      }
    },
    [jobResultFileMap]
  );

  const updateStatus = React.useCallback(
    async (id: number, status: JobResultStatus) => {
      if (window.confirm('업데이트 하시겠습니까?')) {
        try {
          const res = await api.jobResult.item(id).status(status);
          if (isAxiosError(res)) {
            throw res;
          }

          getJobResultWithOthers();
        } catch (err) {
          errorLogger.error(err);
        }
      }
    },
    [getJobResultWithOthers]
  );

  const put = React.useCallback(async (data: Partial<JobResultResponse>) => {
    if (data.id === undefined) return;
    const res = await api.jobResult.item(data.id!).put(data);
    if (isAxiosError(res)) {
      throw res;
    }
  }, []);

  const handleFileSelect = React.useCallback(
    async (jobId: number, jobResultId: number, multiple: boolean = false, directory: boolean = false) => {
      const files = await selectFile({ multiple, directory });

      const uploadFile = async (file: File, fileIndex: number) => {
        const namedFile: NamedFile = {
          id: nanoid(),
          name: file.webkitRelativePath || file.name,
          file: file,
        };

        const fileType = namedFile.name.split('.');
        let fileTypeUpperCase = fileType[fileType.length - 1].toUpperCase();
        const JobResultFileTypes = ['SRT', 'PSD', 'IMAGE', 'TEXT', 'VIDEO', 'OTHER'];

        if (!JobResultFileTypes.includes(fileTypeUpperCase)) {
          fileTypeUpperCase = 'OTHER';
        }

        const uploader = new MultipartUpload(namedFile);
        // job 사용하지않음
        await uploader.ready('JOB');

        uploader.addEventListener('done', async () => {
          if (!uploader.fileId) {
            return;
          }

          const data = [
            {
              jobResultId: jobResultId,
              fileId: uploader.fileId,
              fileType: fileTypeUpperCase as JobResultFileType,
              index: fileIndex,
            },
          ];
          await api.jobs
            .item(jobId)
            .jobResultItem(jobResultId)
            .files.post(data)
            .catch(errorLogger.error)
            .finally(async () => {
              try {
                const filesRes = await api.jobResult.item(jobResultId).files();

                if (isAxiosError(filesRes)) {
                  throw filesRes;
                }

                jobResultFileMap.set(jobResultId, filesRes.data);
              } catch (err) {
                errorLogger.error(err);
              }
            });
        });

        manager.register(uploader);

        uploader.start();
      };

      if (files) {
        const rt: Array<Promise<void>> = [];

        const lastIndex = Math.max(...(jobResultFileMap.get(jobResultId)?.map((a) => a.index ?? 0) ?? []));

        Array.from(files).forEach((file, index) => {
          rt.push(queueTask.dispatch(uploadFile, file, lastIndex + index + 1));
        });

        await Promise.all(rt);
      }
    },
    [jobResultFileMap, manager, queueTask]
  );

  const onSubmitJobResultsStatusUpdate = React.useCallback(
    async (statusUpdatingJobResults: JobResultStatusUpdate[]) => {
      try {
        const res = await api.jobResult.patch(statusUpdatingJobResults);
        if (isAxiosError(res)) {
          throw res;
        }

        await getJobResultWithOthers();

        setAllChecked(false);
      } catch (error) {
        errorLogger.error(error);
      }
    },
    [getJobResultWithOthers]
  );

  const [filesModalVisible, setFilesModalVisible] = React.useState(false);

  React.useEffect(() => {
    if (editReviewDeadLineId === undefined) return;
    editReviewDeadLineRef.current?.focus();
  }, [editReviewDeadLineId]);

  const handleEditReviewDeadLine = React.useCallback(
    async (jobResult: JobResultResponse) => {
      if (!editReviewDeadLineRef.current?.value) {
        return setEditReviewDeadLineId(undefined);
      }
      if (
        jobResult.requestedReviewDateTime &&
        editReviewDeadLineRef.current?.value !== dateFormat(jobResult.requestedReviewDateTime, 'YYYY.MM.DD')
      ) {
        return setEditReviewDeadLineId(undefined);
      }

      await put({
        ...jobResult,
        requestedReviewDeadlineDateTime: new Date(
          editReviewDeadLineRef.current?.value.replaceAll('.', '-')
        ).toISOString(),
      }).catch(errorLogger.error);
      await getJobResultWithOthers().catch(errorLogger.error);

      setEditReviewDeadLineId(undefined);
    },
    [put, getJobResultWithOthers]
  );

  const handleEditScheduleDeadLine = React.useCallback(
    async (jobResult: JobResultResponse) => {
      if (!editScheduleDeadLineRef.current?.value) {
        return setEditScheduleDeadLineId(undefined);
      }
      if (
        jobResult.scheduledDeadlineDateTime &&
        editScheduleDeadLineRef.current?.value === dateFormat(jobResult.scheduledDeadlineDateTime, 'YYYY.MM.DD')
      ) {
        return setEditScheduleDeadLineId(undefined);
      }
      const scheduledDeadlineDateTime = new Date(editScheduleDeadLineRef.current?.value.replaceAll('.', '-'));

      scheduledDeadlineDateTime.setHours(23);
      scheduledDeadlineDateTime.setMinutes(59);

      await put({
        ...jobResult,
        scheduledDeadlineDateTime: scheduledDeadlineDateTime.toISOString(),
      }).catch(errorLogger.error);
      await getJobResultWithOthers().catch(errorLogger.error);

      setEditScheduleDeadLineId(undefined);
    },
    [getJobResultWithOthers, put]
  );

  useMount(() => {
    context.register(transactionId, manager);
  });

  return (
    <JobResultListWrapper className={className}>
      <Row>
        <Title>작업결과물</Title>
        <AddJobResultModal
          jobsMap={jobsMap}
          managerMap={jobManagerMap}
          productOrderMap={productOrderMap}
          jobResults={jobResults}
          refreshJobResultList={getJobResultWithOthers}
        />
      </Row>
      <Row className={'buttonWrap'}>
        <Row className={'modalButtonWrap'}>
          <JobResultStatusUpdateModal
            jobResultIds={filteredJobResults
              .filter((it) => jobResultChecks.get(it.id))
              .map((it) => {
                return it.id;
              })}
            onSubmitHandler={onSubmitJobResultsStatusUpdate}
          />
          <ButtonBase
            onClick={() => setFilesModalVisible(true)}
            disabled={!filteredJobResults.filter((it) => jobResultChecks.get(it.id))}
          >
            첨부파일 확인
          </ButtonBase>
          <UploadJobResultModal
            filteredJobResults={filteredJobResults}
            jobResultsChecks={jobResultChecks}
            jobsMap={jobsMap}
            transactionId={transactionId}
          />
          <FileDownloadModal
            title={'작업 결과물 첨부파일'}
            type={'jobResult'}
            visible={filesModalVisible}
            onVisible={setFilesModalVisible}
            jobs={jobs}
            jobResults={filteredJobResults.filter((it) => jobResultChecks.get(it.id))}
            jobFileMap={jobFileMap}
            jobResultFileMap={jobResultFileMap}
            productOrderMap={productOrderMap}
            transactionId={transactionId}
          />
        </Row>
        <Row className={'statusWrap'}>
          <ButtonBase
            className={cn(jobResultFilterStatus === 'ALL' && 'active')}
            onClick={() => setJobResultFilterStatus('ALL')}
          >
            전체 {jobResultStatus.ALL || 0}
          </ButtonBase>
          <ButtonBase
            className={cn(jobResultFilterStatus === 'REQUESTED' && 'active')}
            onClick={() => setJobResultFilterStatus('REQUESTED')}
          >
            승인대기 {jobResultStatus.REQUESTED || 0}
          </ButtonBase>
          <ButtonBase
            className={cn(jobResultFilterStatus === 'REGISTERED' && 'active')}
            onClick={() => setJobResultFilterStatus('REGISTERED')}
          >
            작업대기 {jobResultStatus.REGISTERED || 0}
          </ButtonBase>
          <ButtonBase
            className={cn(jobResultFilterStatus === 'PROCESSING' && 'active')}
            onClick={() => setJobResultFilterStatus('PROCESSING')}
          >
            작업 중 {jobResultStatus.PROCESSING || 0}
          </ButtonBase>
          <ButtonBase
            className={cn(jobResultFilterStatus === 'REVIEWABLE' && 'active')}
            onClick={() => setJobResultFilterStatus('REVIEWABLE')}
          >
            검수대기 {jobResultStatus.REVIEWABLE || 0}
          </ButtonBase>
          <ButtonBase
            className={cn(jobResultFilterStatus === 'REVIEW_REJECTED' && 'active')}
            onClick={() => setJobResultFilterStatus('REVIEW_REJECTED')}
          >
            검수승인 {jobResultStatus.REVIEW_REJECTED || 0}
          </ButtonBase>
          <ButtonBase
            className={cn(jobResultFilterStatus === 'REVIEW_APPROVED' && 'active')}
            onClick={() => setJobResultFilterStatus('REVIEW_APPROVED')}
          >
            검수거절 {jobResultStatus.REVIEW_APPROVED || 0}
          </ButtonBase>

          <ButtonBase
            className={cn(jobResultFilterStatus === 'DONE' && 'active')}
            onClick={() => setJobResultFilterStatus('DONE')}
          >
            작업완료 {jobResultStatus.DONE || 0}
          </ButtonBase>
        </Row>
      </Row>

      <Column>
        <Row>
          <span>
            <CheckBox onChange={handleAllChecked} checked={allChecked} />
          </span>
          <span>결과물ID</span>
          <span>콘텐츠명</span>
          <span>작업범위</span>
          <span>원본언어</span>
          <span>번역언어</span>
          <span>납품형태</span>
          <span>작업대상</span>
          <span>부가서비스</span>
          <span>진행상태</span>
          <span>상태업데이트</span>
          <span>다운로드</span>
          <span>업로드</span>
          <span>최초생성일</span>
          <span>검수완료요청일</span>
          <span>납품예정일</span>
          <span>납품완료일</span>
          <span>납품내역서 발급상태</span>
          <span>담당 PM</span>
        </Row>
        {filteredJobResults.map((it) => {
          const productOrder = productOrderMap.get(it.productOrderId) ?? null;
          const job = jobsMap.get(it.jobId) ?? null;
          return (
            <Row key={it.id}>
              <span>
                <CheckBox onChange={(e) => handleChecked(e, it.id)} checked={jobResultChecks.get(it.id)} />
              </span>
              <span>JR-{it.id}</span>
              <span>{job?.name}</span>
              <span>{productOrder && WorkRange[productOrder.productType]}</span>
              <span>{productOrder && Source_Language[productOrder.sourceLanguage]}</span>
              <span>{productOrder?.translateLanguage && Translate_Language[productOrder.translateLanguage]}</span>
              <span>{productOrder?.deliveryType && ResultType[productOrder.deliveryType]}</span>
              <span>{productOrder?.productSource && WorkTarget[productOrder.productSource]}</span>
              <span>
                {productOrder &&
                  Array.isArray(productOrder.options) &&
                  productOrder.options.length !== 0 &&
                  AdditionalServices[productOrder.options[0]?.type]}
              </span>
              <span>
                <StatusLabel status={it.status} />
              </span>
              <span className={'buttonWrap'}>
                {it.status === 'REQUESTED' && (
                  <>
                    <ButtonBase onClick={() => updateStatus(it.id, 'REGISTERED')}>승인</ButtonBase>
                    <ButtonBase disabled></ButtonBase>
                    <ButtonBase onClick={() => updateStatus(it.id, 'DROP')}>작업취소</ButtonBase>
                  </>
                )}
                {it.status === 'REGISTERED' && (
                  <>
                    <ButtonBase disabled></ButtonBase>
                    <ButtonBase onClick={() => updateStatus(it.id, 'PROCESSING')}>작업시작</ButtonBase>
                    <ButtonBase onClick={() => updateStatus(it.id, 'DROP')}>작업취소</ButtonBase>
                  </>
                )}
                {it.status === 'PROCESSING' && (
                  <>
                    <ButtonBase onClick={() => updateStatus(it.id, 'REVIEWABLE')}>검수요청</ButtonBase>
                    <ButtonBase disabled></ButtonBase>
                    <ButtonBase onClick={() => updateStatus(it.id, 'DROP')}>작업취소</ButtonBase>
                  </>
                )}
                {it.status === 'REVIEWABLE' && (
                  <>
                    <ButtonBase onClick={() => updateStatus(it.id, 'REVIEW_APPROVED')}>검수승인</ButtonBase>
                    <ButtonBase onClick={() => updateStatus(it.id, 'PROCESSING')}>작업시작</ButtonBase>
                    <ButtonBase onClick={() => updateStatus(it.id, 'REVIEW_REJECTED')}>검수거절</ButtonBase>
                  </>
                )}
                {it.status === 'REVIEW_REJECTED' && (
                  <>
                    <ButtonBase onClick={() => updateStatus(it.id, 'REVIEWABLE')}>검수요청</ButtonBase>
                    <ButtonBase onClick={() => updateStatus(it.id, 'PROCESSING')}>작업시작</ButtonBase>
                    <ButtonBase onClick={() => updateStatus(it.id, 'DROP')}>작업취소</ButtonBase>
                  </>
                )}
                {it.status === 'REVIEW_APPROVED' && (
                  <>
                    <ButtonBase onClick={() => updateStatus(it.id, 'DONE')}>작업완료</ButtonBase>
                    <ButtonBase onClick={() => updateStatus(it.id, 'PROCESSING')}>작업시작</ButtonBase>
                    <ButtonBase onClick={() => updateStatus(it.id, 'DROP')}>작업취소</ButtonBase>
                  </>
                )}
                {it.status === 'DONE' && (
                  <>
                    <ButtonBase disabled></ButtonBase>
                    <ButtonBase onClick={() => updateStatus(it.id, 'PROCESSING')}>작업시작</ButtonBase>
                    <ButtonBase onClick={() => updateStatus(it.id, 'REVIEWABLE')}>검수요청</ButtonBase>
                  </>
                )}
                {it.status === 'DROP' && (
                  <>
                    <ButtonBase disabled></ButtonBase>
                    <ButtonBase onClick={() => updateStatus(it.id, 'PROCESSING')}>작업시작</ButtonBase>
                    <ButtonBase disabled></ButtonBase>
                  </>
                )}
              </span>
              <span>
                <ButtonBase disabled={!jobResultFileMap.get(it.id)} onClick={() => fileDownloads(it.id)}>
                  <img src={ICN_DOWNLOAD} alt={'download'} />
                </ButtonBase>
              </span>
              <span className={'buttonWrap'}>
                <CButton onClick={() => handleFileSelect(it.jobId, it.id, true)} color={'none'}>
                  <img src={ICN_FILE_UPLOAD} alt={'upload'} />
                </CButton>
                <CButton onClick={() => handleFileSelect(it.jobId, it.id, false, true)} color={'none'}>
                  <img src={ICN_FOLDER_UPLOAD} alt={'upload'} />
                </CButton>
              </span>
              <span>{dateFormat(it.createDateTime, 'YYYY.MM.DD')}</span>
              {editReviewDeadLineId === it.id && (
                <span>
                  <input
                    type={'text'}
                    ref={editReviewDeadLineRef}
                    defaultValue={
                      it.requestedReviewDeadlineDateTime && dateFormat(it.requestedReviewDeadlineDateTime, 'YYYY.MM.DD')
                    }
                    placeholder={'yyyy.mm.dd'}
                    onBlur={() => handleEditReviewDeadLine(it)}
                    onKeyDown={(e) => handleEnterKeyDown(e, editReviewDeadLineRef)}
                  />
                </span>
              )}

              {editReviewDeadLineId !== it.id && (
                <span onDoubleClick={() => setEditReviewDeadLineId(it.id)}>
                  {it.requestedReviewDeadlineDateTime && dateFormat(it.requestedReviewDeadlineDateTime, 'YYYY.MM.DD')}
                </span>
              )}
              {editScheduleDeadLineId === it.id && (
                <span>
                  <input
                    type={'text'}
                    ref={editScheduleDeadLineRef}
                    defaultValue={
                      it.scheduledDeadlineDateTime && dateFormat(it.scheduledDeadlineDateTime, 'YYYY.MM.DD')
                    }
                    placeholder={'yyyy.mm.dd'}
                    onBlur={() => handleEditScheduleDeadLine(it)}
                    onKeyDown={(e) => handleEnterKeyDown(e, editScheduleDeadLineRef)}
                  />
                </span>
              )}
              {editScheduleDeadLineId !== it.id && (
                <span onDoubleClick={() => setEditScheduleDeadLineId(it.id)}>
                  {it.scheduledDeadlineDateTime && dateFormat(it.scheduledDeadlineDateTime, 'YYYY.MM.DD')}
                </span>
              )}

              <span>{job?.doneDateTime && formatDueDay(job?.doneDateTime)}</span>
              <span>
                <IssuedStatusBadge status={documentMap.get(it.id)?.status || ''} />
              </span>
            </Row>
          );
        })}
      </Column>
    </JobResultListWrapper>
  );
}

export default styled(JobResultList)``;
