import { useMount } from '@voithru/front-core';
import React from 'react';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import StatusLabel from 'src/components/atoms/StatusLabel';
import { Column, Row } from 'src/components/atoms/styled/layout';
import { CompanyResponse } from 'src/types/api/Company';
import { JobsResponse } from 'src/types/api/Job';
import { ProjectResponse } from 'src/types/api/Project';
import { isAxiosError } from 'src/utils/api/axios';
import { getAccountByAccountId } from 'src/utils/api/getter';
import { dateFormat } from 'src/utils/date';
import { ContentType } from 'src/utils/translate';
import styled from 'styled-components';
import { DashboardStatusRowWrapper } from './styled';

interface Props {
  jobs: JobsResponse;

  className?: string;
}

function ContentRow(props: Props) {
  const { jobs, className } = props;

  const [project, setProject] = React.useState<ProjectResponse>();
  const [company, setCompany] = React.useState<CompanyResponse>();
  const getProject = React.useCallback(async () => {
    try {
      const res = await api.project.item(jobs.projectId).get();

      if (isAxiosError(res)) {
        throw res;
      }

      if (res.data.accountId) {
        const accountRes = await getAccountByAccountId(res.data.accountId);

        if (accountRes.data.companyId) {
          const companyRes = await api.companies.item(accountRes.data.companyId).get();

          if (isAxiosError(companyRes)) {
            throw companyRes;
          }

          setCompany(companyRes.data);
        }
      }

      setProject(res.data);
    } catch (err) {
      errorLogger.error(err);
    }
  }, [jobs.projectId]);

  useMount(() => {
    getProject();
  });

  return (
    <DashboardStatusRowWrapper className={className}>
      <span>
        <StatusLabel status={jobs.status} />
      </span>
      <Column>
        <Row>
          <Row>[콘텐츠]</Row>
          <Row>[J-{jobs.id}]</Row>
          <Row>[{project?.category && ContentType[project?.category]}]</Row>
          <Row>{jobs?.name}</Row>
        </Row>
        <Row>
          {company?.name}-{project?.name}
        </Row>
      </Column>
      <Row>{jobs && dateFormat(jobs?.createDateTime, 'YYYY.MM.DD')}</Row>
    </DashboardStatusRowWrapper>
  );
}

export default styled(ContentRow)``;
