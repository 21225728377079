import { CModal, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import { cn } from '@voithru/front-core';
import { nanoid } from 'nanoid';
import React from 'react';
import useDrivePicker from 'react-google-drive-picker';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import logger from 'src/api/logger';
import { ReactComponent as ICN_CONTENTS } from 'src/assets/icons/icn-contents@1x.svg';
import { ReactComponent as ICN_FOLDER } from 'src/assets/icons/icn-folder@2x.svg';
import { Button } from 'src/components/atoms/styled/button';
import { Title } from 'src/components/atoms/styled/element';
import { Column, Row } from 'src/components/atoms/styled/layout';
import { useAuth } from 'src/components/context/AuthContext';
import { GOOGLE_CLIENT_ID } from 'src/constants/configs';
import { APICredential } from 'src/types/api/Credential';
import { APIFileResponseWithIndex, GoogleFile } from 'src/types/api/File';
import { isAxiosError } from 'src/utils/api/axios';
import { getFileEntries, NamedFile, selectFile, SelectFileOptions } from 'src/utils/files';
import useFileDrop from 'src/utils/hooks/useFileDrop';
import { FileGroup } from 'src/utils/JobRegisterService';
import styled from 'styled-components';
import { FileDropBoxWrapper } from './styled';

interface Props {
  onSelectFiles(fileGroups: FileGroup[]): void;
  onGoogleFileGroups(file: GoogleFile, index: number): void;
  projectAccountId?: number;
  className?: string;
}

type GoogleCustomViewType = 'DOCS' | 'FOLDERS';

function FileDropBox(props: Props) {
  const { onSelectFiles, onGoogleFileGroups, projectAccountId, className } = props;
  const { user } = useAuth();

  const handleTransfer = React.useCallback(
    async (data: DataTransfer) => {
      const arr: FileGroup[] = [];
      const entries: (FileSystemEntry | FileSystemDirectoryEntry)[] = [];
      for (const item of Array.from(data.items)) {
        const entry: FileSystemEntry | FileSystemDirectoryEntry | null | undefined = item.webkitGetAsEntry?.();

        if (!entry) {
          continue;
        }
        entries.push(entry);
      }
      for (const entry of entries) {
        const files = await getFileEntries(entry);
        arr.push({
          id: nanoid(),
          index: NaN,
          name: entry.name,
          files: files,
        });
      }

      if (arr.length === 0) {
        return;
      }

      onSelectFiles(arr);
    },
    [onSelectFiles]
  );

  const { target, dropState, handleDrop, onDragOver, onDragLeave } = useFileDrop<HTMLDivElement>({
    onDrop: handleTransfer,
  });

  const handleFileSelect = React.useCallback(
    (options: SelectFileOptions = {}) =>
      async () => {
        const files = await selectFile({ multiple: true, ...options });

        const named = Array.from(files ?? []).map(
          (it): NamedFile => ({ id: nanoid(), name: it.webkitRelativePath || it.name, file: it })
        );

        const fileGroups: FileGroup[] = [];

        if (!files || files.length === 0) {
          onSelectFiles([]);
          return;
        }

        if (options.directory) {
          const directory = files[0]?.webkitRelativePath?.substr(0, files[0]?.webkitRelativePath.indexOf('/'));
          if (!directory) throw Error('cant find directory name');
          const fileGroup: FileGroup = {
            id: nanoid(),
            index: NaN,
            name: directory,
            files: named,
          };
          fileGroups.push(fileGroup);
        } else {
          named.forEach((file) => {
            const fileGroup: FileGroup = {
              id: nanoid(),
              index: NaN,
              name: file.name,
              files: [file],
            };
            fileGroups.push(fileGroup);
          });
        }
        onSelectFiles(fileGroups);
      },
    [onSelectFiles]
  );
  const [credentialList, setCredentialList] = React.useState<APICredential[]>([]);
  const loadCredentialList = React.useCallback(async () => {
    try {
      const clientCredentials = await api.credential.get({ accountId: projectAccountId });
      const accountCredentials = await api.credential.get({ accountId: user?.id });
      if (isAxiosError(clientCredentials) || isAxiosError(accountCredentials)) {
        throw clientCredentials;
      }
      const credentialList = [];
      credentialList.push(...clientCredentials.data);
      credentialList.push(...accountCredentials.data);
      setCredentialList(credentialList);
      return;
    } catch (error) {
      errorLogger.error(error);
    }
  }, [projectAccountId, user?.id]);

  const [modal, setModal] = React.useState<boolean>();
  const [googleCustomView, setGoogleCustomView] = React.useState<GoogleCustomViewType>('DOCS');
  const handleGoogleDriveUplad = React.useCallback(
    async (opend: boolean, customView?: GoogleCustomViewType) => {
      if (typeof projectAccountId !== 'number') {
        window.alert('프로젝트를 선택해주세요.');
        return;
      }

      if (opend) {
        await loadCredentialList();
      }

      if (customView) {
        setGoogleCustomView(customView);
      }
      setModal(opend);
    },
    [loadCredentialList, projectAccountId]
  );

  const [openPicker, data] = useDrivePicker();
  const [selectCredentialId, setSelectCredentialId] = React.useState<number>();

  //구글 드라이브들이 있는 모달을 열어 하나를 선택 시  access_token을 받아와 밑의 openPicker-token에 집어 넣으면 해당 드라이브 picker UI가 나옴
  const handleOpenPicker = React.useCallback(
    async (credentialId?: number) => {
      setModal(false);
      setSelectCredentialId(credentialId);
      if (typeof credentialId !== 'number') {
        return;
      }
      const res = await api.credential.token({ id: credentialId });
      if (isAxiosError(res)) {
        throw res;
      }

      openPicker({
        clientId: GOOGLE_CLIENT_ID,
        developerKey: '',
        viewId: googleCustomView,
        token: res.data,
        showUploadView: true,
        showUploadFolders: true,
        setIncludeFolders: true,
        supportDrives: true,
        multiselect: true,
        setSelectFolderEnabled: false,
      });
    },
    [googleCustomView, openPicker]
  );

  const [pickedFileIds, setPickedFileIds] = React.useState<string[]>([]);
  const [pickedFolderIds, setPickedFolderIds] = React.useState<string[]>([]);

  const googleDriveUpload = React.useCallback(
    async (fileIds: string[]) => {
      if (!selectCredentialId) {
        return;
      }
      try {
        for (const [index, fileId] of fileIds.entries()) {
          const res = await api.file.google.post({
            accountId: projectAccountId,
            userCredentialId: selectCredentialId,
            cloudFileId: fileId,
          });

          if (isAxiosError(res)) {
            throw res;
          }

          onGoogleFileGroups(
            {
              ...res.data,
              index,
              jobFiles: [
                {
                  ...res.data,
                  index,
                },
              ],
            },
            index
          );
        }
      } catch (e) {
        logger.rrwebLog(e);
      }

      setPickedFileIds([]);
    },
    [selectCredentialId, projectAccountId, onGoogleFileGroups]
  );

  const getCloudFileIds = React.useCallback(
    async (folderIds: string[]) => {
      if (!selectCredentialId) {
        return;
      }
      try {
        for (const [index, folderId] of folderIds.entries()) {
          const res = await api.file.google.folder({
            userCredentialId: selectCredentialId,
            cloudFolderId: folderId,
          });

          if (isAxiosError(res)) {
            throw res;
          }
          const { data } = res;

          const jobFiles: APIFileResponseWithIndex[] = [];
          for (const [idx, file] of data.files.entries()) {
            const response = await api.file.google.post({
              accountId: projectAccountId,
              userCredentialId: selectCredentialId,
              cloudFileId: file.cloudFileId,
              path: file.path,
            });

            if (isAxiosError(response)) {
              throw response;
            }

            jobFiles.push({
              ...response.data,
              index: idx,
            });
          }
          const volume = jobFiles.reduce((prev, cur) => {
            return prev + cur.volume;
          }, 0);

          onGoogleFileGroups(
            {
              id: 'folder',
              name: res.data.name,
              index: 0,
              volume,
              jobFiles: jobFiles,
            },
            index
          );
        }
        setPickedFolderIds([]);
      } catch (e) {
        logger.rrwebLog(e);
      }
    },
    [onGoogleFileGroups, projectAccountId, selectCredentialId]
  );

  React.useEffect(() => {
    if (!data) {
      return;
    }

    if (googleCustomView === 'DOCS') {
      setPickedFileIds(data.docs.map((it) => it.id));
      return;
    }
    setPickedFolderIds(data.docs.map((it) => it.id));
  }, [data, googleCustomView]);

  React.useEffect(() => {
    if (pickedFileIds.length === 0) {
      return;
    }
    googleDriveUpload(pickedFileIds);
  }, [googleDriveUpload, pickedFileIds]);

  React.useEffect(() => {
    if (pickedFolderIds.length === 0) {
      return;
    }

    getCloudFileIds(pickedFolderIds);
  }, [getCloudFileIds, pickedFolderIds]);

  return (
    <FileDropBoxWrapper
      className={cn('drop', dropState.current?.hover && 'hover', className)}
      ref={target}
      onDrop={handleDrop}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
    >
      <Title>{'파일 업로드 (Drag & Drop 가능)'}</Title>
      <Row>
        <Button color={'blue'} onClick={handleFileSelect({ directory: true })}>
          <ICN_FOLDER width={15} height={15} />
          <span>폴더 단위로 업로드</span>
        </Button>
        <Button color={'blue'} onClick={handleFileSelect()}>
          <ICN_CONTENTS width={15} height={15} />
          <span>파일 단위로 업로드</span>
        </Button>
        <Button color={'blue'} onClick={() => handleGoogleDriveUplad(true, 'DOCS')}>
          <ICN_CONTENTS width={15} height={15} />
          <span>구글 드라이브 업로드</span>
        </Button>
        <Button color={'blue'} onClick={() => handleGoogleDriveUplad(true, 'FOLDERS')}>
          <ICN_CONTENTS width={15} height={15} />
          <span>구글 드라이브 폴더 업로드</span>
        </Button>
      </Row>

      <CModal onDismiss={() => handleGoogleDriveUplad(false)} visible={modal}>
        <Column>
          <CTable bordered style={{ width: 'max-content', minWidth: '100%' }}>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell>드라이브 이름</CTableHeaderCell>
                <CTableHeaderCell>구글 계정</CTableHeaderCell>
                <CTableHeaderCell>연동자</CTableHeaderCell>
                <CTableHeaderCell>접근권한관리</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {credentialList.map((credential) => (
                <CTableRow key={credential.credentialId} onClick={() => handleOpenPicker(credential.id)}>
                  <CTableDataCell>
                    Google Drive
                    <span> - {credential.name}</span>
                  </CTableDataCell>
                  <CTableDataCell>{credential.cloudEmail}</CTableDataCell>
                  <CTableDataCell>{credential.accountId}</CTableDataCell>
                </CTableRow>
              ))}
            </CTableBody>
          </CTable>
        </Column>
      </CModal>
    </FileDropBoxWrapper>
  );
}

export default styled(FileDropBox)``;
