import React from 'react';
import {
  CCol,
  CContainer,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import { useForm } from 'react-hook-form';
import { ApprovedJobResultModalWrapper } from './styled';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { ButtonBase } from 'src/components/atoms/styled/button';
import { JobsResponse } from 'src/types/api/Job';
import { JobResultStatus, JobResultResponse } from 'src/types/api/JobResult';
import { ProductOrderResponse } from 'src/types/api/ProductOrder';
import { isAxiosError } from 'src/utils/api/axios';
import {
  WorkRange,
  Source_Language,
  Translate_Language,
  ResultType,
  WorkTarget,
  AdditionalServices,
} from 'src/utils/translate';

interface Props {
  targetJob: JobsResponse;
  productOrderMap: Map<number, ProductOrderResponse>;
  callback?: () => void;
  buttonName: string;
  setParentVisible?: (visible: boolean) => void;
}

const JOB_STATUES: JobResultStatus[] = [
  'REQUESTED',
  'REGISTERED',
  'PROCESSING',
  'REVIEWABLE',
  'REVIEW_REJECTED',
  'REVIEW_APPROVED',
  'DONE',
  'DROP',
];
function ApprovedJobResultModal(props: Props) {
  const { targetJob, callback, productOrderMap, buttonName, setParentVisible } = props;
  const [visible, setVisible] = React.useState(false);
  const [targetJobResults, setTargetJobResults] = React.useState<JobResultResponse[] | undefined>([]);
  const { handleSubmit } = useForm();

  const updateStatus = React.useCallback(async (id: number, status: JobResultStatus) => {
    try {
      const res = await api.jobResult.item(id).status(status);
      if (isAxiosError(res)) {
        throw res;
      }
    } catch (err) {
      errorLogger.error(err);
    }
  }, []);

  const updateJobResultStatus = React.useCallback(async () => {
    targetJobResults &&
      (await Promise.all(
        targetJobResults?.map(async (it) => {
          await updateStatus(it.id, 'REGISTERED');
        })
      ));
    callback?.();
    setVisible(false);
    setParentVisible?.(false);
  }, [callback, setParentVisible, targetJobResults, updateStatus]);

  const getJobResult = React.useCallback(async (jobId: number) => {
    const res = await api.jobs.item(jobId).jobResult({ status: JOB_STATUES });
    if (isAxiosError(res)) {
      throw res;
    }

    setTargetJobResults(res.data);
  }, []);

  React.useEffect(() => {
    getJobResult(targetJob.id).catch(errorLogger.error);
  }, [getJobResult, targetJob]);

  return (
    <>
      <ButtonBase onClick={() => setVisible(!visible)}>{buttonName}</ButtonBase>
      <ApprovedJobResultModalWrapper
        title={'작업결과물 확인하기'}
        visible={visible}
        onDismiss={() => setVisible(false)}
        onSubmit={handleSubmit(() => {
          updateJobResultStatus();
        })}
      >
        <CContainer style={{ width: '1000px' }}>
          <CCol style={{ height: '700px', overflow: 'auto' }}>
            <CTable bordered>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell>작업 콘텐츠 ID</CTableHeaderCell>
                  <CTableHeaderCell>콘텐츠 명</CTableHeaderCell>
                  <CTableHeaderCell>작업 결과물 ID</CTableHeaderCell>
                  <CTableHeaderCell>작업범위</CTableHeaderCell>
                  <CTableHeaderCell>원본언어</CTableHeaderCell>
                  <CTableHeaderCell>번역언어</CTableHeaderCell>
                  <CTableHeaderCell>납품형태</CTableHeaderCell>
                  <CTableHeaderCell>작업대상</CTableHeaderCell>
                  <CTableHeaderCell>부가서비스</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {targetJobResults?.map((it) => {
                  const targetOrder = productOrderMap.get(it.productOrderId);
                  return (
                    <CTableRow key={it.id}>
                      <CTableDataCell>CON - {it.jobId}</CTableDataCell>
                      <CTableDataCell>{targetJob.name}</CTableDataCell>
                      <CTableDataCell>JR - {it.id}</CTableDataCell>
                      <CTableDataCell>{targetOrder && WorkRange[targetOrder.productType]}</CTableDataCell>
                      <CTableDataCell>{targetOrder && Source_Language[targetOrder.sourceLanguage]}</CTableDataCell>
                      <CTableDataCell>
                        {targetOrder?.translateLanguage && Translate_Language[targetOrder.translateLanguage]}
                      </CTableDataCell>
                      <CTableDataCell>
                        {targetOrder?.deliveryType && ResultType[targetOrder.deliveryType]}
                      </CTableDataCell>
                      <CTableDataCell>
                        {targetOrder?.productSource && WorkTarget[targetOrder.productSource]}
                      </CTableDataCell>
                      <CTableDataCell style={{ display: 'block' }}>
                        {targetOrder?.options?.map((option, idx) => (
                          <div key={idx}>{AdditionalServices[option.type]}</div>
                        ))}
                      </CTableDataCell>
                    </CTableRow>
                  );
                })}
              </CTableBody>
            </CTable>
          </CCol>
        </CContainer>
      </ApprovedJobResultModalWrapper>
    </>
  );
}

export default ApprovedJobResultModal;
