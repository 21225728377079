import { DeliveryType, ProductOrderOptionType, ProductOrderType, ProductSourceType } from 'src/types/api/ProductOrder';
import { ProjectCategory } from 'src/types/api/Project';

type ProductByCategory<T> = Partial<Record<ProjectCategory, readonly T[]>>;

/**
 * Product Order Types
 */
export const PRODUCT_ORDER_TYPES = Object.freeze<ProductByCategory<ProductOrderType>>({
  VIDEO: ['TRANSLATION', 'CAPTION', 'SPOTTING_ONLY', 'TIME_CODE', 'BURN_IN'],
  WEBTOON: ['TRANSLATION', 'TYPESETTER_ONLY'],
  WEBNOVEL: ['TRANSLATION'],
  DOCUMENT: ['TRANSLATION'],
});

/**
 * Product Source Types
 */
export const PRODUCT_SOURCE_TYPES = Object.freeze<ProductByCategory<ProductSourceType>>({
  VIDEO: ['BASIC', 'VOICE_ONLY', 'SCREEN_ONLY', 'VOICE_AND_SCREEN'],
});

/**
 * Product Delivery Types
 */
export const PRODUCT_DELIVERY_TYPES = Object.freeze<ProductByCategory<DeliveryType>>({
  VIDEO: ['BURN_IN_BASIC', 'BURN_IN_ADVANCED', 'NO_BURN_IN'],
  WEBTOON: [
    'TYPESETTING_PAGE_TO_PAGE',
    'TYPESETTING_PAGE_TO_SCROLL',
    'TYPESETTING_SCROLL_TO_PAGE',
    'TYPESETTING_SCROLL_TO_SCROLL',
    'NO_TYPESETTER',
  ],
});

/**
 * Product Order Options
 */
export const PRODUCT_ORDER_OPTION_TYPES = Object.freeze<ProductByCategory<ProductOrderOptionType>>({
  VIDEO: ['URGENT_TASK', 'DISCOUNT_ON_DEFAULT_CAPTION', 'DISCOUNT_ON_BYPRODUCT'],
  WEBTOON: ['URGENT_TASK', 'DISCOUNT_ON_DETACHED_LAYER', 'DISCOUNT_ON_BYPRODUCT'],
  WEBNOVEL: ['URGENT_TASK', 'DISCOUNT_ON_BYPRODUCT'],
  DOCUMENT: ['URGENT_TASK', 'DISCOUNT_ON_BYPRODUCT'],
});
