import { Parameter } from '@voithru/front-core';
import { AxiosInstance } from 'axios';
import { generatePath } from 'react-router';
import {
  AlarmChannelConfigurationPutRequest,
  AlarmChannelConfigurationResponse,
  EntityHistoryNotificationAPIResponse,
  EntityHistoryNotificationPageResponse,
  EntityHistoryNotificationRequest,
  EntityHistoryNotificationStatus,
  EntityHistoryPageResponse,
  EntityHistoryRequest,
  ManagerAlarmConfigurationPutRequest,
  ManagerAlarmConfigurationResponse,
} from 'src/types/api/EntityHistory';
import { SlackTokenAndTeamIdResponse } from 'src/types/api/Slack';

const ENTITY_HISTORY_PATH = '/api/v1/entityHistories';

function apiEntityHistory(instance: AxiosInstance) {
  const ENTITY_HISTORY_NOTIFICATION_PATH = ENTITY_HISTORY_PATH + '/notifications';
  const ENTITY_HISTORY_NOTIFICATION_SITE_PATH = ENTITY_HISTORY_NOTIFICATION_PATH + '/site';
  const NOTIFICATION_CHANNEL_CONFIGURATION_PATH = ENTITY_HISTORY_NOTIFICATION_PATH + '/alarmChannelConfiguration';

  return {
    get: (params: EntityHistoryRequest) => instance.get<EntityHistoryPageResponse>(ENTITY_HISTORY_PATH, { params }),
    notification: {
      site: {
        get: (params: EntityHistoryNotificationRequest) =>
          instance.get<EntityHistoryNotificationPageResponse>(ENTITY_HISTORY_NOTIFICATION_SITE_PATH, { params }),
        item(id: number) {
          const ENTITY_HISTORY_NOTIFICATION_SITE_READ_PATH = generatePath(
            ENTITY_HISTORY_NOTIFICATION_SITE_PATH + '/:id/read',
            { id }
          );
          return {
            read: (search: { status: EntityHistoryNotificationStatus }) => {
              const params = Parameter.toURLSearchParams(search);

              return instance.patch<EntityHistoryNotificationAPIResponse>(
                ENTITY_HISTORY_NOTIFICATION_SITE_READ_PATH,
                undefined,
                { params }
              );
            },
          };
        },
      },
      configuration(accountId: number) {
        const NOTIFICATION_CONFIGURATION_PATH = generatePath(NOTIFICATION_CHANNEL_CONFIGURATION_PATH + '/:accountId', {
          accountId,
        });
        const NOTIFICATION_MANAGER_CONFIGURATION_PATH = generatePath(
          ENTITY_HISTORY_NOTIFICATION_PATH + '/managerAlarmConfiguration/:accountId',
          { accountId }
        );
        return {
          get: () => instance.get<AlarmChannelConfigurationResponse>(NOTIFICATION_CONFIGURATION_PATH),
          put: (data: AlarmChannelConfigurationPutRequest) =>
            instance.put<AlarmChannelConfigurationResponse>(NOTIFICATION_CONFIGURATION_PATH, data),
          manager: {
            get: () => instance.get<ManagerAlarmConfigurationResponse>(NOTIFICATION_MANAGER_CONFIGURATION_PATH),
            put: (data: ManagerAlarmConfigurationPutRequest) =>
              instance.put<ManagerAlarmConfigurationResponse>(NOTIFICATION_MANAGER_CONFIGURATION_PATH, data),
          },
        };
      },
      slack: {
        getTokenAndTeamId: () =>
          instance.get<SlackTokenAndTeamIdResponse>(NOTIFICATION_CHANNEL_CONFIGURATION_PATH + '/slackToken'),
      },
    },
  };
}

export default apiEntityHistory;
