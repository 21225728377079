import React from 'react';
import { Route, RouteProps, useHistory, useLocation } from 'react-router';
import api from 'src/api';
import { useAuth, useBeforeLocation } from 'src/components/context/AuthContext';
import AppPaths from 'src/constants/AppPaths';

type Props = React.PropsWithChildren<RouteProps>;
function AuthRoute(props: Props) {
  const history = useHistory();
  const location = useLocation();

  const { initialize, user } = useAuth();
  const [, action] = useBeforeLocation();

  React.useEffect(() => {
    if (!initialize || user) {
      return;
    }

    api.manager.auth.token = null;

    action(location.pathname);

    history.replace(AppPaths.login.path);
  }, [action, history, initialize, location.pathname, user]);

  if (!initialize || !user) {
    return null;
  }

  return <Route {...props} />;
}

export default AuthRoute;
