import { CTable } from '@coreui/react';
import styled from 'styled-components';

export const JobResultSelectTableWrapper = styled(CTable)`
  & > tbody > tr {
    cursor: pointer;

    &:hover {
      background: var(--cui-light);
    }
  }
`;
