import { gql } from '@apollo/client';

export const GET_PROJECT_QUERY = gql`
  query GetProjects($ids: [ID!]) {
    projects(ids: $ids) {
      id
      category
      name
      status
      jobs {
        id
        status
      }
      account {
        company {
          id
          name
          manager {
            id
            name
          }
        }
      }
      updateDateTime
      createDateTime
      scheduledDeadlineDateTime
      manager {
        name
      }
    }
  }
`;
