import { AxiosError, AxiosInstance } from 'axios';
import errorLogger from 'src/api/errorLogger';

export function isAxiosError<T>(error: unknown): error is AxiosError<T> {
  return Boolean(typeof error === 'object' && error && 'isAxiosError' in error);
}

const IGNORED_URL_PATTERNS = [/\/api\/v1\/jobResults\/\d+\/document\/latest$/];
export function setupErrorLoggingInterceptor(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.response.use(undefined, (error) => {
    const errorEntries: [string, unknown][] = [];
    let isIgnored = false;
    if (error.config) {
      const { url, method } = error.config;
      errorEntries.push(['url', url]);
      errorEntries.push(['method', method]);
      if (IGNORED_URL_PATTERNS.some((pattern) => pattern.test(url))) {
        isIgnored = true;
      }
    }
    if (error.code) {
      errorEntries.push(['code', error.code]);
    }
    if (error.request?.responseText) {
      errorEntries.push(['responseText', error.request?.responseText]);
    }
    if (error.request?.status) {
      errorEntries.push(['status', error.request?.status]);
    }
    if (!isIgnored && errorEntries.length > 0) {
      errorLogger.error(JSON.stringify(Object.fromEntries(errorEntries)));
    }
    return Promise.reject(error);
  });
}
