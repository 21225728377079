import axios from 'axios';
import { generatePath } from 'react-router';
import { MINGLO_SERVER, MINGLO_SERVER_TIMEOUT } from 'src/constants/API';
import { LoginRequest } from 'src/types/api/Auth';
import AuthorizationManager from './AuthorizationManager';

export interface MingloAccount {
  id: number;
  name: string;
  email: string;
  role: 'WORKER' | 'ADMIN' | 'CLIENT';
  password: string;
  googleOauthId: string;
  appleOauthId: string;
  appleIdToken: string;
  phoneNumber: string;
  verified: boolean;
  registeredDateTime: string;
  modifiedDateTime: string;
  accountGroupId: number;
  accountGroupAssignedDateTime: string;
  phoneCountryCode: string;
  countryISOCode: 'KR' | 'JP' | 'GLOBAL';
}

interface AccountSearch {
  query: string;
}

const axiosMinglo = axios.create({
  baseURL: MINGLO_SERVER,
  timeout: MINGLO_SERVER_TIMEOUT,
});

const minglo = {
  instance: axiosMinglo,
  manager: {
    auth: new AuthorizationManager('minglo', axiosMinglo),
  },

  auth: {
    login: (data: LoginRequest) => axiosMinglo.post('/api/auth', data),
    google: (idToken: string) => axiosMinglo.post('/api/oauth/google/', { idToken }),
  },

  account: {
    get: () => axiosMinglo.get<MingloAccount>('/api/v1/accounts'),
    search: (params: AccountSearch) => axiosMinglo.get<MingloAccount[]>('/api/v1/admin/accounts/search', { params }),

    item(id: number) {
      const PATH = generatePath('/api/v1/admin/accounts/:id', { id });
      return {
        get: () => axiosMinglo.get<MingloAccount>(PATH),
      };
    },
  },
};

export default minglo;
