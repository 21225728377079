import { Title } from 'src/components/atoms/styled/element';
import { Column } from 'src/components/atoms/styled/layout';
import styled from 'styled-components';

export const ProjectInfoWrapper = styled(Column)`
  width: 600px;

  & > ${Title} {
    font-size: 18px;
  }

  & > ${Column} {
    padding: 10px;

    & > div.row {
      margin-bottom: 15px;
    }
  }
`;
