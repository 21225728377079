import { CButton, CCol, CFormCheck, CFormInput, CFormLabel, CFormSelect, CRow } from '@coreui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { Title } from 'src/components/atoms/styled/element';
import UploadBusinessLicense from 'src/components/molecules/UploadBusinessLicense';
import UploadGuide from 'src/components/molecules/UploadGuide';
import AdminSearchModal from 'src/components/organisms/AdminSearchModal';
import { Company, CompanyCountry, CompanyFile, CompanyFileRequest, CompanyType } from 'src/types/api/Company';
import { User } from 'src/types/api/User';
import { isAxiosError } from 'src/utils/api/axios';
import MultipartUpload from 'src/utils/MultipartUpload';
import { BusinessType } from 'src/utils/translate';
import { AccountCreateModalWrapper } from './styled';

interface FormData {
  companyName: string;
  businessType: CompanyType;
  country: CompanyCountry;
  businessCode: string;
  businessLicense: string;
  representativePhoneNumber: string;
  representativeEmail: string;
  sales: string;
  name: string;
  department: string;
  phoneNumber: string;
  email: string;
  taxInvoiceEmail?: string;
}

interface Props {
  callback?: () => void;
}

function AccountCreateModal(props: Props) {
  const { callback } = props;

  const { register, setValue, reset, handleSubmit } = useForm<FormData>();
  const [businessLicense, setBusinessLicense] = React.useState<MultipartUpload[]>();
  const [origBusinessLicense, setOrigBusinessLicense] = React.useState<CompanyFile[]>();
  const [origGuide, setOrigGuide] = React.useState<CompanyFile[]>();
  const [guides, setGuides] = React.useState<MultipartUpload[]>();
  const [targetItem, setTargetItem] = React.useState<User>();

  const [visible, setVisible] = React.useState(false);

  const salesTargetHandler = React.useCallback(
    (data: User) => {
      setValue('sales', data.name);
      setTargetItem(data);
    },
    [setValue]
  );

  const clear = React.useCallback(() => {
    setBusinessLicense(undefined);
    setOrigBusinessLicense(undefined);
    setOrigGuide(undefined);
    setGuides(undefined);
    setTargetItem(undefined);
    setVisible(false);

    return reset({});
  }, [reset]);

  const handleConfirm = React.useCallback(() => {
    window.alert('계정 생성이 완료되었습니다.');

    return clear();
  }, [clear]);

  const submit = React.useCallback(
    async (data: FormData) => {
      const {
        businessType,
        companyName,
        country,
        name,
        phoneNumber,
        email,
        businessCode,
        representativeEmail,
        representativePhoneNumber,
        department,
        taxInvoiceEmail,
      } = data;

      if (!businessType || !companyName || !country || !name || !phoneNumber || !email || !targetItem?.id) {
        return window.alert('필수 입력 항목을 모두 입력해주세요.');
      }

      const companyData: Company = {
        businessRegistrationNumber: businessCode,
        companyType: businessType,
        country: country,
        email: representativeEmail,
        managerAccountId: targetItem.id,
        name: companyName,
        phoneNumber: representativePhoneNumber,
        taxInvoiceEmail: taxInvoiceEmail,
      };
      const account: User = {
        email: email,
        name: name,
        phoneNumber: phoneNumber,
        role: 'CLIENT_MASTER',
        department: department,
      };

      try {
        const emailRes = await api.signUp.isExistEmail(email);
        if (isAxiosError(emailRes)) {
          throw emailRes;
        }

        if (emailRes.data) {
          return window.alert('중복된 이메일입니다.');
        }

        const accountRes = await api.company.postWithAccount({ company: companyData, account });

        if (isAxiosError(accountRes)) {
          throw accountRes;
        }

        const businessLicenseFiles = (businessLicense
          ?.map((license) => {
            if (!license.fileId) {
              return undefined;
            }

            const businessLicenseFile = {
              companyId: accountRes.data.company.id!,
              fileId: license.fileId,
              fileType: 'BUSINESS_LICENSE',
            };

            return businessLicenseFile;
          })
          .filter(Boolean) ?? []) as CompanyFileRequest[];

        const businessGuideFiles = (guides
          ?.map((guide) => {
            if (!guide.fileId) {
              return undefined;
            }

            const businessGuideFile = {
              companyId: accountRes.data.company.id!,
              fileId: guide.fileId,
              fileType: 'GUIDE',
            };

            return businessGuideFile;
          })
          .filter(Boolean) ?? []) as CompanyFileRequest[];

        const files = [...businessGuideFiles, ...businessLicenseFiles];

        if (files.length !== 0) {
          const fileRes = await api.company.item(accountRes.data.company.id!).files.post(files);
          if (isAxiosError(fileRes)) {
            throw fileRes;
          }
        }

        return handleConfirm();
      } catch (error) {
        errorLogger.error(error);

        return window.alert('계정 생성이 실패하였습니다.');
      } finally {
        callback?.();
      }
    },
    [targetItem?.id, businessLicense, guides, handleConfirm, callback]
  );

  const handleDismiss = React.useCallback(() => {
    clear();
    callback?.();
    setVisible(false);
  }, [callback, clear]);

  return (
    <div className="align-items-center d-flex">
      <CButton style={{ width: 'max-content' }} onClick={() => setVisible(!visible)}>
        계정생성하기
      </CButton>
      <AccountCreateModalWrapper
        title={'계정 생성하기'}
        visible={visible}
        onDismiss={() => handleDismiss()}
        onSubmit={handleSubmit(submit)}
      >
        <Title>회사정보</Title>
        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>회사명</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormInput type={'text'} {...register('companyName', { required: true })} />
          </CCol>
        </CRow>

        <CRow style={{ marginBottom: '15px' }}>
          <CCol>
            <CFormLabel>구분</CFormLabel>
          </CCol>

          <CCol sm={8}>
            <CRow>
              {Object.keys(BusinessType).map((it) => (
                <CCol key={it}>
                  <CFormCheck
                    type="radio"
                    {...register('businessType', { required: true })}
                    value={it}
                    label={BusinessType[it]}
                  />
                </CCol>
              ))}
            </CRow>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CFormLabel>국가</CFormLabel>
          </CCol>

          <CCol sm={8}>
            <CFormSelect {...register('country', { required: true })}>
              <option value={'KOR'}>KOR</option>
              <option value={'JPN'}>JPN</option>
              <option value={'ENG'}>ENG</option>
            </CFormSelect>
          </CCol>
        </CRow>

        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>사업자 등록번호</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormInput type={'text'} {...register('businessCode', { required: true })} />
          </CCol>
        </CRow>

        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>사업자 등록증</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <UploadBusinessLicense
              setFile={(file) => setBusinessLicense(file)}
              origFile={origBusinessLicense}
              setOrigFile={(file) => setOrigBusinessLicense(file)}
            />
          </CCol>
        </CRow>
        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>대표 전화번호</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormInput type={'text'} {...register('representativePhoneNumber')} />
          </CCol>
        </CRow>

        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>대표 이메일</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormInput type={'text'} {...register('representativeEmail', { required: true })} />
          </CCol>
        </CRow>
        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>세금계산서 담당 이메일</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormInput type={'text'} {...register('taxInvoiceEmail')} />
          </CCol>
        </CRow>
        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>대표 작업 가이드</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <UploadGuide
              setFile={(file) => setGuides(file)}
              origFile={origGuide}
              setOrigFile={(file) => setOrigGuide(file)}
            />
          </CCol>
        </CRow>
        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>담당 sales</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormInput type={'text'} {...register('sales', { required: true })} disabled={true} />
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
              }}
            >
              <AdminSearchModal setTargetItem={(item: User) => salesTargetHandler(item)} />
            </div>
          </CCol>
        </CRow>

        <Title>대표 계정 설정</Title>
        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>이름</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormInput type={'text'} {...register('name', { required: true })} />
          </CCol>
        </CRow>
        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>소속부서</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormInput type={'text'} {...register('department', { required: true })} />
          </CCol>
        </CRow>
        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>연락처</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormInput type={'text'} {...register('phoneNumber', { required: true })} />
          </CCol>
        </CRow>
        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>로그인 ID</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormInput type={'text'} {...register('email', { required: true })} />
          </CCol>
        </CRow>
      </AccountCreateModalWrapper>
    </div>
  );
}

export default AccountCreateModal;
