import { useQuery } from '@apollo/client';
import {
  CButton,
  CCardBody,
  CCloseButton,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import React from 'react';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import ICN_DOWNLOAD from 'src/assets/icons/icn-download-24px.svg';
import ICN_PREVIEW from 'src/assets/icons/icn-preview-24px.svg';
import FileAnchor from 'src/components/atoms/FileAnchor';
import { ButtonBase } from 'src/components/atoms/styled/button';
import { Title } from 'src/components/atoms/styled/element';
import { Row } from 'src/components/atoms/styled/layout';
import { isAxiosError } from 'src/utils/api/axios';
import { downloadAPIFile, NamedFile, selectFile, separateFileIntoNameAndExtension } from 'src/utils/files';
import useAwaiter from 'src/utils/hooks/useAwaiter';
import { getPreviewUrl, previewToWindow } from 'src/utils/preview';
import { getByteString } from 'src/utils/string';
import styled from 'styled-components';
import { ContentResultFilesWrapper } from './styled';
import ICN_UPLOAD from 'src/assets/icons/icn-upload-24px.svg';
import { useAuth } from 'src/components/context/AuthContext';
import { nanoid } from 'nanoid';
import MultipartUpload from 'src/utils/MultipartUpload';
import { JobFileRequest } from 'src/types/api/Job';
import { useMount } from '@voithru/front-core';
import { useContentsUploaderService } from 'src/components/context/ContentsUploaderContext';
import { useUploaderContext } from 'src/components/context/UploaderContext';
import { handleEnterKeyDown } from 'src/utils/dom';
import { GQL_CONTENT_RESULT_FILES } from './gql';
import { ContentResultFilesQuery, ContentResultFilesQueryVariables } from './gql.generated';
import { File as JobFile } from 'src/types/graphql';
import QueueTask from 'src/utils/QueueTask';

interface Props {
  jobId: number;

  transactionId: string;

  className?: string;
}

function ContentResultFiles(props: Props) {
  const { jobId, transactionId, className } = props;

  const queueTask = React.useMemo(() => new QueueTask(), []);

  const { user } = useAuth();

  const registerService = useContentsUploaderService();
  const context = useUploaderContext();

  const manager = React.useMemo(
    () => registerService.createUploadService(transactionId),
    [registerService, transactionId]
  );

  const { data, loading, error, refetch } = useQuery<ContentResultFilesQuery, ContentResultFilesQueryVariables>(
    GQL_CONTENT_RESULT_FILES,
    {
      variables: {
        id: jobId.toString(),
      },
      onError: errorLogger.error,
    }
  );

  const job = data?.job;

  const contentsFileList = React.useMemo(() => {
    return job?.jobFiles?.filter((it) => it.fileType === 'CONTENTS_FILE');
  }, [job]);

  const downloadLoading = useAwaiter();

  const handleFileAllDownload = React.useCallback(() => {
    if (!contentsFileList || contentsFileList.length === 0) {
      return;
    }

    for (const jobFile of contentsFileList) {
      queueTask.dispatch(downloadAPIFile, {
        id: jobFile.file?.id ?? '',
        name: jobFile.file?.name ?? '',
      });
    }
  }, [contentsFileList, queueTask]);

  const handleFilePreview = React.useCallback(async (file: JobFile) => {
    const data = await getPreviewUrl({
      id: file.id,
      name: file.name ?? '',
    }).catch(errorLogger.error);

    if (!data) return;

    previewToWindow(data);
  }, []);

  const [editFileId, setEditFileId] = React.useState<number>();
  const editFileRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (jobId === undefined) return;

    editFileRef.current?.focus();
  }, [jobId]);

  const handleRenameFile = React.useCallback(
    async (fileId?: string, editName?: string, fileExtension?: string) => {
      if (!fileId || !editName || !fileExtension) {
        return;
      }

      try {
        const res = await api.file.item(fileId).namePatch(editName + fileExtension);

        if (isAxiosError(res)) {
          throw res;
        }

        setEditFileId(undefined);

        refetch({
          id: jobId.toString(),
        });
      } catch (error) {
        errorLogger.error(error);
      }
    },
    [jobId, refetch]
  );

  const handleRemoveFile = React.useCallback(
    async (id: string) => {
      if (window.confirm('해당 파일을 삭제하시겠습니까?')) {
        try {
          const res = await api.jobs.item(jobId).files.item(id).delete({
            jobId,
            fileId: id,
          });
          if (isAxiosError(res)) {
            throw res;
          }

          refetch({
            id: jobId.toString(),
          });
        } catch (error) {
          errorLogger.error(error);
        }
      }
    },
    [jobId, refetch]
  );

  const handleFileUpload = React.useCallback(async () => {
    if (user?.role !== 'ADMIN') {
      window.alert('파일 업로드 권한이 없습니다.');
      return;
    }

    const files = await selectFile({ multiple: true });

    const uploadFile = async (file: File, fileIndex: number) => {
      const namedFile: NamedFile = {
        id: nanoid(),
        name: file.webkitRelativePath || file.name,
        file: file,
      };

      const uploader = new MultipartUpload(namedFile);

      // job 사용하지않음
      await uploader.ready('JOB');

      uploader.addEventListener('done', async () => {
        const res = await api.jobs.item(jobId).files.get();
        if (isAxiosError(res)) {
          throw res;
        }

        const data: JobFileRequest[] = [
          {
            jobId,
            fileId: uploader.fileId ?? '',
            fileType: 'CONTENTS_FILE',
            index: fileIndex,
          },
        ];

        await api.jobs
          .item(jobId)
          .files.post(data)
          .catch(errorLogger.error)
          .finally(async () => {
            await refetch({
              id: jobId.toString(),
            });
          });
      });

      manager.register(uploader);

      uploader.start();
    };

    if (files) {
      const rt: Array<Promise<void>> = [];

      const lastIndex = Math.max(...(contentsFileList?.map((a) => a.index ?? 0) ?? []));

      Array.from(files).forEach((file, index) => {
        rt.push(queueTask.dispatch(uploadFile, file, lastIndex + index + 1));
      });

      await Promise.all(rt);
    }
  }, [user?.role, manager, jobId, refetch, contentsFileList, queueTask]);

  useMount(() => {
    context.register(transactionId, manager);
  });

  if (loading) return <div>loading...</div>;

  if (error) {
    return (
      <div>
        <h2>{error.name}</h2>
        <h3>{error.message}</h3>
      </div>
    );
  }

  if (job) {
    return (
      <ContentResultFilesWrapper className={className}>
        <CCardBody>
          <Row>
            <Title>작업 콘텐츠 파일</Title>
            <CButton onClick={handleFileAllDownload}>
              {downloadLoading.isLoading.length !== 0 && <CSpinner color={'light'} />}
              <span>전체 다운로드</span>
            </CButton>
          </Row>

          <CTable>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell>NO</CTableHeaderCell>
                <CTableHeaderCell>파일</CTableHeaderCell>
                <CTableHeaderCell>용량</CTableHeaderCell>
                <CTableHeaderCell>프리뷰</CTableHeaderCell>
                <CTableHeaderCell>다운로드</CTableHeaderCell>
                <CTableHeaderCell>삭제</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {contentsFileList
                ?.sort((a, b) => (a.index ?? 0) - (b.index ?? 0))
                .map((it, idx) => {
                  const splitFileName = separateFileIntoNameAndExtension(it.file?.name);
                  return (
                    <CTableRow key={it.id}>
                      <CTableHeaderCell>{idx + 1}</CTableHeaderCell>
                      {editFileId === idx ? (
                        <span key={idx}>
                          <input
                            defaultValue={splitFileName[0] ?? ''}
                            ref={editFileRef}
                            onBlur={() =>
                              handleRenameFile(it.file?.id, editFileRef.current?.value, splitFileName[1] ?? '')
                            }
                            onKeyDown={(e) => handleEnterKeyDown(e, editFileRef)}
                            style={{
                              width: '80%',
                            }}
                          />
                          {splitFileName[1]}
                        </span>
                      ) : (
                        <CTableDataCell onDoubleClick={() => setEditFileId(idx)}>{it.file?.name}</CTableDataCell>
                      )}

                      <CTableDataCell>{it.file?.volume && getByteString(it.file?.volume)}</CTableDataCell>
                      <CTableDataCell>
                        <ButtonBase>
                          <img
                            src={ICN_PREVIEW}
                            onClick={() => it.file && handleFilePreview(it.file)}
                            alt={'preview'}
                          />
                        </ButtonBase>
                      </CTableDataCell>
                      <CTableDataCell>
                        <FileAnchor
                          file={{
                            id: it.file?.id ?? '',
                            name: it.file?.name ?? '',
                          }}
                        >
                          <img src={ICN_DOWNLOAD} alt={'다운로드'} width={24} height={24} />
                        </FileAnchor>
                      </CTableDataCell>
                      <CTableDataCell>
                        <CCloseButton
                          onClick={() => {
                            it.file && handleRemoveFile(it.file.id);
                          }}
                        />
                      </CTableDataCell>
                    </CTableRow>
                  );
                })}
              <CTableRow className={'uploadBtn'}>
                <ButtonBase onClick={handleFileUpload}>
                  <span>파일 첨부하기</span>
                  <img src={ICN_UPLOAD} alt={'upload'} />
                </ButtonBase>
              </CTableRow>
            </CTableBody>
          </CTable>
        </CCardBody>
      </ContentResultFilesWrapper>
    );
  }
  return null;
}

export default styled(ContentResultFiles)``;
