import { ButtonBase } from 'src/components/atoms/styled/button';
import { Description, Title } from 'src/components/atoms/styled/element';
import { Column, Row } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const PreviewFirstModalWrapper = styled(Column)`
  & > ${ButtonBase} {
    padding: 7px 11px;

    background: black;
    border-radius: 4px;

    color: ${getRGBA(colors.white)};
  }
`;

export const PreviewFirstModalTitleWrapper = styled(Column)`
  margin: 12px;
  padding: 12px;

  & > ${Row}.titleContent {
    & > ${Column} {
      flex: 1;

      & > ${Row}.description > ${Description} {
        margin-left: 14px;
        margin-top: 14px;
      }
    }

    & > ${Column}.supplierInformation {
      & > ${Title} {
        margin-top: 20px;
        margin-bottom: 20px;

        font-size: 18px;
      }

      & > ${Row} {
        border: 1px solid ${getRGBA(colors.grey80)};
        box-sizing: border-box;

        font-size: 15px;

        & > ${Title} {
          flex: 1;
        }

        & > ${Description} {
          flex: 3;
        }

        & > ${Title}, ${Description} {
          border-right: 1px solid ${getRGBA(colors.grey80)};

          padding-left: 10px;

          height: 100%;
        }

        ${Description}:last-child() {
          border-right: 0;
        }
      }
    }
  }

  & > ${Row}.title {
    padding: 12px;

    border-top: 1px solid ${getRGBA(colors.grey80)};
    border-bottom: 1px solid ${getRGBA(colors.grey80)};

    & > ${Title} {
      width: 100%;
      text-align: center;
      font-weight: bold;

      font-size: 18px;
    }
  }
`;

export const PreviewFirstModalBodyWrapper = styled(Column)`
  justify-content: space-between;

  min-height: 1024px;

  & > ${Column} {
    margin-top: 20px;

    align-items: center;

    & > ${Row} {
      width: 95%;
    }

    & > ${Row}.listRow {
      padding: 4px 12px;

      & > span {
        padding-left: 4px;
      }

      font-size: 15px;
      text-align: left;
      span:nth-child(1) {
        width: 100px;
      }
      span:nth-child(2) {
        width: 100px;
      }
      span:nth-child(3) {
        width: 100px;
      }
      span:nth-child(4) {
        width: 150px;
      }
      span:nth-child(5) {
        width: 120px;
      }
      span:nth-child(6) {
        width: 150px;
      }
      span:nth-child(7) {
        width: 80px;
      }
      span:nth-child(8) {
        width: 80px;

        word-break: break-all;
      }
      span:nth-child(9) {
        width: 120px;

        word-break: break-all;
        text-align: right;
      }

      & > ${Column}.additionalServices {
        width: 150px;

        & > span {
          width: 150px;
        }
      }
    }

    & > ${Row}.price {
      padding: 4px 0;

      justify-content: space-between;

      background: #e9e9e9;

      ${Description}:nth-child(1) {
        margin-left: 24px;
      }

      ${Description}:nth-child(2) {
        margin-right: 36px;

        text-align: right;
      }
    }

    & > ${Row}.notice {
      padding: 4px 0;

      justify-content: space-between;

      ${Description}:nth-child(1) {
        flex: 1;

        border-right: 1px solid ${getRGBA(colors.grey80)};

        margin-left: 24px;
      }

      ${Description}:nth-child(2) {
        flex: 3;
        margin-left: 48px;

        text-align: left;
      }
    }

    & > ${Row}:first-child {
      padding-top: 12px;
      padding-bottom: 12px;

      border-top: 1px solid ${getRGBA(colors.grey80)};
      border-bottom: 1px solid ${getRGBA(colors.grey80)};
      box-sizing: border-box;

      background: #e9e9e9;
    }

    & > ${Row} + ${Row} {
      border-bottom: 1px solid ${getRGBA(colors.grey50)};
      box-sizing: border-box;
    }
  }
`;

export const PreviewFirstModalBodyBottomWrapper = styled(Column)``;
