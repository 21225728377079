import { useQuery } from '@apollo/client';
import {
  CContainer,
  CFormCheck,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import errorLogger from 'src/api/errorLogger';
import StatusLabel from 'src/components/atoms/StatusLabel';
import { ButtonBase } from 'src/components/atoms/styled/button';
import { Row } from 'src/components/atoms/styled/layout';
import FormModal from 'src/components/organisms/FormModal';
import { JobsStatus, JobsStatusUpdate } from 'src/types/api/Job';
import { User } from 'src/types/api/User';
import { dateFormat } from 'src/utils/date';
import styled from 'styled-components';
import AdminSearchModal from '../AdminSearchModal';
import { GQL_JOB_STATUS } from './gql';
import { JobStatusQuery, JobStatusQueryVariables } from './gql.generated';

interface FormData {
  isStatus: boolean;
  status?: JobsStatus;
  isScheduledDeadlineDateTime: boolean;
  scheduledDeadlineDateTime?: string;
  isManagerId: boolean;
  managerId?: string;
}

interface Props {
  jobIds: number[];
  onSubmitHandler: (data: JobsStatusUpdate[]) => void;
}

const JOB_STATUSES = [
  { label: '작성 중', value: 'REQUESTING' },
  { label: '승인대기', value: 'REQUESTED' },
  { label: '작업대기', value: 'REGISTERED' },
  { label: '작업 중', value: 'PROCESSING' },
  { label: '검수 대기', value: 'REVIEWABLE' },
  { label: '작업취소', value: 'DROP' },
  { label: '작업완료', value: 'DONE' },
];

function JobStatusUpdateModal(props: Props) {
  const { jobIds, onSubmitHandler } = props;

  const ids = React.useMemo(() => jobIds.map((it) => it.toString()), [jobIds]);

  const { loading, error, data } = useQuery<JobStatusQuery, JobStatusQueryVariables>(GQL_JOB_STATUS, {
    variables: { ids },
  });

  const jobs = data?.jobs;

  const { register, handleSubmit, getValues, watch, setValue, reset } = useForm<FormData>();

  const [managerItem, setManagerItem] = React.useState<User>();

  const managerTargetHandler = React.useCallback(
    (data: User) => {
      if (watch('isManagerId')) {
        setValue('managerId', data.id?.toString());
        setManagerItem(data);
      }
    },
    [setValue, watch]
  );

  const [visible, setVisible] = React.useState(false);

  const onSubmit = React.useCallback(
    async (form: FormData = getValues()) => {
      const { isStatus, isScheduledDeadlineDateTime, isManagerId, status, scheduledDeadlineDateTime, managerId } = form;

      if (!isStatus && !isScheduledDeadlineDateTime && !isManagerId) {
        window.alert('변경할 데이터가 없습니다.');

        return setVisible(false);
      }

      if (!status && !scheduledDeadlineDateTime && !managerId) {
        errorLogger.error("Don't have submitting data");
      }

      const data = jobIds.map((jobId) => {
        if (!jobId) {
          throw new Error("Don't have job id");
        }

        const jobUpdate: JobsStatusUpdate = {
          id: jobId,
        };

        if (status) {
          jobUpdate.status = status;
        }

        if (scheduledDeadlineDateTime) {
          jobUpdate.scheduledDeadlineDateTime = new Date(scheduledDeadlineDateTime.replaceAll('.', '-')).toISOString();
        }

        if (managerId) {
          jobUpdate.managerId = Number(managerId);
        }

        return jobUpdate;
      });

      onSubmitHandler(data);

      setVisible(false);
      reset({});
      setManagerItem(undefined);
    },
    [getValues, jobIds, onSubmitHandler, reset]
  );

  if (loading) return <div>loading...</div>;
  if (error) {
    errorLogger.error(error);

    return (
      <div>
        <h2>{error.name}</h2>
        <h3>{error.message}</h3>
      </div>
    );
  }

  return (
    <>
      <ButtonBase onClick={() => setVisible(!visible)} disabled={!jobIds.length}>
        상태 업데이트
      </ButtonBase>
      <FormModal
        title={'작업 콘텐츠 상태 업데이트'}
        visible={visible}
        onDismiss={() => setVisible(false)}
        onSubmit={handleSubmit((data: FormData) => onSubmit(data))}
      >
        <span>
          총 {jobIds.length}
          개의 작업 콘텐츠 상태를 변경합니다.
        </span>
        <CContainer style={{ height: '320px', overflow: 'auto' }}>
          <CTable bordered>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell>콘텐츠 ID</CTableHeaderCell>
                <CTableHeaderCell>콘텐츠 명</CTableHeaderCell>
                <CTableHeaderCell>진행상태</CTableHeaderCell>
                <CTableHeaderCell>납품예정일</CTableHeaderCell>
                <CTableHeaderCell>담당 PM</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {jobs?.map((it, idx) => (
                <CTableRow key={idx}>
                  <CTableDataCell>CON - {it.id}</CTableDataCell>
                  <CTableDataCell>{it.name}</CTableDataCell>
                  <CTableDataCell>
                    <StatusLabel status={it.status} />
                  </CTableDataCell>
                  <CTableDataCell>
                    {it.scheduledDeadlineDateTime && dateFormat(it.scheduledDeadlineDateTime, 'YYYY.MM.DD')}
                  </CTableDataCell>
                  <CTableDataCell>{it.manager?.name}</CTableDataCell>
                </CTableRow>
              ))}
            </CTableBody>
          </CTable>
        </CContainer>
        <Row>
          <CFormCheck {...register('isStatus')} />
          진행상태
          <Row>
            {JOB_STATUSES.map((status, idx) => {
              return (
                <CFormCheck
                  key={idx}
                  type="radio"
                  value={status.value}
                  label={status.label}
                  {...register('status')}
                  disabled={!watch('isStatus')}
                />
              );
            })}
          </Row>
        </Row>
        <Row>
          <CFormCheck {...register('isScheduledDeadlineDateTime')} />
          납품예정일
          <input
            type="date"
            {...register('scheduledDeadlineDateTime')}
            disabled={!watch('isScheduledDeadlineDateTime')}
          />
        </Row>
        <Row>
          <CFormCheck {...register('isManagerId')} />
          담당 PM
          <input value={managerItem && managerItem.name} readOnly disabled={!watch('isManagerId')} />
          <AdminSearchModal setTargetItem={(item: User) => managerTargetHandler(item)} />
        </Row>
      </FormModal>
    </>
  );
}

export default styled(JobStatusUpdateModal)``;
