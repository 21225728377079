import { gql } from '@apollo/client';

export const GQL_DETAIL_CONTENT = gql`
  query DetailContent($id: ID!) {
    job(id: $id) {
      name
      status
      createDateTime
      updateDateTime
      # 예상 작업 시작기간
      scheduledStartDateTime
      # 예상 작업 완료기간
      scheduledDeadlineDateTime
      # 납품완료일
      doneDateTime

      # 담당 PM
      manager {
        name
      }

      account {
        # Master Id
        masterAccount {
          name
        }

        company {
          name

          # 고객 담당자
          manager {
            name
          }
        }
      }

      # 작업 분량
      workSize
      # 작업 분량 표시방법
      workSizeType

      jobFiles {
        id
        index
        fileType

        file {
          id
          name
          volume
        }
      }

      project {
        id
        name
        category
      }
    }
  }
`;
