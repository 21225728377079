import { Description } from 'src/components/atoms/styled/element';
import { InputBase } from 'src/components/atoms/styled/form';
import { Column, Row } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const ContentFileListWrapper = styled.div`
  & > ${Description} {
    margin: 21px 0 0;

    color: ${getRGBA(colors.grey60)};
    font-size: 16px;
  }

  & > ${Column}.table {
    margin: 16px 0 0;
    & > ${Row} {
      justify-content: center;
      align-items: center;

      border-top: 1px solid ${getRGBA(colors.grey20)};
      height: 40px;
      box-sizing: border-box;

      color: ${getRGBA(colors.grey70)};
      font-size: 16px;

      &:last-child {
        border-bottom: 1px solid ${getRGBA(colors.grey20)};
      }

      &.focus,
      &:hover {
        background: ${getRGBA(colors.grey10)};
      }

      &.header {
        background: ${getRGBA(colors.grey10)};

        font-size: 14px;
      }

      & > span,
      & > ${Row} {
        padding: 0 12px;
        box-sizing: border-box;

        &:nth-child(1) {
          justify-content: center;
          align-items: center;

          width: 33px;
          cursor: grab;
        }

        &:nth-child(2) {
          width: 60px;
        }

        &:nth-child(3) {
          flex: 1;

          & > ${InputBase} {
            width: 100%;
            color: ${getRGBA(colors.grey70)};
            font-size: 16px;
          }
        }

        &:nth-child(4) {
          width: 137px;
        }

        &:nth-child(5) {
          justify-content: center;
          align-items: center;

          width: 33px;
        }
      }
    }
  }
`;
