import api from 'src/api';
import { isAxiosError } from 'src/utils/api/axios';

const MS_URL = 'https://view.officeapps.live.com/op/embed.aspx?src=';

function getFileType(name: string) {
  const splittedNmae = name.split('.');

  const type = splittedNmae[splittedNmae.length - 1].toLocaleLowerCase();

  switch (type) {
    case 'mp4':
    case 'mov':
      return 'video';

    case 'jpg':
    case 'png':
    case 'jpeg':
      return 'image';

    case 'xlsx':
    case 'docx':
    case 'docs':
    case 'pptx':
      return 'ms';

    case 'pdf':
      return 'docs';

    case 'psb':
    case 'psd':
      return 'psd';

    default:
      return null;
  }
}

async function getUrl(id: string, type: string) {
  if (type === 'psd') {
    const res = await api.file.item(id).previewDownloadUrl();
    if (isAxiosError(res)) {
      throw res;
    }

    return res.data;
  } else {
    const res = await api.file.item(id).downloadUrl();
    if (isAxiosError(res)) {
      throw res;
    }

    return res.data;
  }
}

interface PrieviewToWindowProps {
  url: string;
  type: string;
  msUrl?: string;
  name: string;
}

export async function previewToWindow({ url, type, msUrl, name }: PrieviewToWindowProps) {
  if (msUrl) {
    window.open(msUrl, '_blank');
  } else {
    const win = window.open('', '_blank');

    if (type === 'image' || type === 'psd') {
      win?.document.write(`<img src=${url} />`);
    }

    if (type === 'video') {
      const video = document.createElement('video');
      video.src = url;
      video.controls = true;
      win?.document.body.appendChild(video);
    }

    if (type === 'docs') {
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          const pdfBlob = new Blob([blob], { type: 'application/pdf' });
          win?.document.write(`<embed width="100%" height="100%" src="${URL.createObjectURL(pdfBlob)}" />`);
        });
    }
  }
}

interface Props {
  id: string;
  name: string;
}

export async function getPreviewUrl(file: Props) {
  const { name, id } = file;

  const type = getFileType(name);

  if (!type) return null;

  const res = await getUrl(id, type);

  if (type === 'ms') {
    const urls = new URL(res);

    const url = MS_URL + urls.origin + urls.pathname;

    return { url: res, type, msUrl: url, name };
  }

  return { url: res, type, name };
}
