import { CTable } from '@coreui/react';
import styled from 'styled-components';

export const AccountSelectTableWrapper = styled(CTable)`
  position: relative;
  max-height: 100px;
  overflow: auto;

  & > tbody {
    & > tr {
      cursor: pointer;

      &:hover {
        background: var(--cui-light);
      }
    }
  }
`;
