export type EnvironmentType = 'development' | 'production';

const env: AppEnv = process.env;
interface AppEnv {
  REACT_APP_NAME?: string;
  REACT_APP_ENVIRONMENT?: EnvironmentType;
  REACT_APP_GOOGLE_CLIENT_ID?: string;
  REACT_APP_TOTUS_GOOGLE_CLIENT_ID?: string;
  [key: string]: string | undefined;
}

if (!env.REACT_APP_NAME) {
  const error = new Error('APP_NAME is not set!');
  console.warn(error);
  throw error;
}

if (!env.REACT_APP_ENVIRONMENT) {
  const error = new Error('APP_ENVIRONMENT is not set!');
  console.warn(error);
  throw error;
}

if (!env.REACT_APP_GOOGLE_CLIENT_ID) {
  const error = new Error('GOOGLE_CLIENT_ID is not set!');
  console.warn(error);
  throw error;
}

if (!env.REACT_APP_TOTUS_GOOGLE_CLIENT_ID) {
  const error = new Error('TOTUS_GOOGLE_CLIENT_ID is not set!');
  console.warn(error);
  throw error;
}

export const {
  REACT_APP_NAME: APP_NAME,
  REACT_APP_ENVIRONMENT: APP_ENVIRONMENT,
  REACT_APP_GOOGLE_CLIENT_ID: GOOGLE_CLIENT_ID = '',
  REACT_APP_TOTUS_GOOGLE_CLIENT_ID: TOTUS_GOOGLE_CLIENT_ID = '',
} = env;
