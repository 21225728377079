import axios from 'axios';
import { GRAYLOG_SERVER, GRAYLOG_SERVER_TIMEOUT } from 'src/constants/API';
import { APP_ENVIRONMENT, APP_NAME } from 'src/constants/configs';
import { STORAGE_USER } from 'src/utils/storages';

const axiosGraylog = axios.create({
  baseURL: GRAYLOG_SERVER,
  timeout: GRAYLOG_SERVER_TIMEOUT,
});

async function error(error: unknown) {
  if (APP_ENVIRONMENT === 'development') {
    console.warn('[DEBUG] errorLogger.error:\n', error);
  }

  try {
    const data = {
      appName: APP_NAME,
      env: APP_ENVIRONMENT === 'production' ? 'prod' : 'dev',
      message: '',
      userAgent: window.navigator.userAgent,
      path: window.location.href,
      userdata: STORAGE_USER.item || 'none',
    };

    if (error instanceof Error) {
      data.message = `${error.message}\n${error.stack}`;
    } else if (typeof error === 'string' || typeof error === 'number') {
      data.message = `${error}`;
    } else {
      data.message = JSON.stringify(error);
    }

    await axiosGraylog.post('/log/error', data);
  } catch (err) {
    console.error(err);
  }
}

const errorLogger = { error };
export default errorLogger;
