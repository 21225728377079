interface APIResponseBase {
  id: number;
  createDateTime: string;
  updateDateTime?: string;
}

export type APIResponse<T> = APIResponseBase & T;

export interface APIError {
  message: string;
}

export interface APIPaginationRequest {
  page?: number;
  size?: number;
  sort?: string;
}

export interface APIPaginationSort {
  sortKey: string;
  direction: APIPaginationSortDirection;
}

export type APIPaginationSortDirection = 'asc' | 'desc';

export function toSortString(sort: APIPaginationSort) {
  return `${sort.sortKey},${sort.direction}`;
}

export interface APIPagination<T> {
  element: T[];
  currentPage: number;
  totalSize: number;
  pageSize: number;
  totalPage: number;
}

export interface APIAccountId {
  accountId?: number;
}

export interface APIManagerId {
  managerId?: number;
}

export type APIImageType = 'PAGE' | 'SCROLL';

export type APILanguages =
  | 'KOREAN'
  | 'ENGLISH'
  | 'JAPANESE'
  | 'SIMPLIFIED_CHINESE'
  | 'TAIWAN_CHINESE'
  | 'SPANISH'
  | 'EUROPE_SPANISH'
  | 'VIETNAMESE'
  | 'INDONESIAN'
  | 'THAI'
  | 'RUSSIAN'
  | 'FRENCH'
  | 'PORTUGUESE'
  | 'AMERICA_PORTUGUESE'
  | 'GERMAN'
  | 'ETC';
