import { CButton, CFormInput, CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react';
import { useMount } from '@voithru/front-core';
import React from 'react';
import { useForm } from 'react-hook-form';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { ProjectResponse } from 'src/types/api/Project';

interface FormData {
  search: string;
}

interface IProps {
  setTargetItem: (item: ProjectResponse) => void;
}

function ProjectSearchModal(props: IProps) {
  const { setTargetItem } = props;

  const [searchList, setSearchList] = React.useState<ProjectResponse[]>([]);
  const [filteredList, setFilteredList] = React.useState<ProjectResponse[]>([]);

  const getProject = React.useCallback(async () => {
    await api.project.get({}).then((res) => {
      setSearchList(res.data);
      setFilteredList(res.data);
    });
  }, []);

  useMount(() => {
    getProject().catch(errorLogger.error);
  });

  const [visible, setVisible] = React.useState(false);

  const { handleSubmit, register } = useForm<FormData>();

  const submit = React.useCallback(
    (form: FormData) => {
      setFilteredList(searchList.filter((item) => item.name?.includes(form.search)));
    },
    [searchList]
  );

  const handleSelectItem = React.useCallback(
    (item: ProjectResponse) => {
      setTargetItem(item);
      setVisible(false);
    },
    [setTargetItem, setVisible]
  );

  return (
    <div>
      <div className="align-items-center d-flex">
        <CButton style={{ width: 'max-content' }} onClick={() => setVisible(!visible)}>
          🔎
        </CButton>
        <CModal size={'lg'} visible={visible} onDismiss={() => setVisible(false)}>
          <CModalHeader onDismiss={() => setVisible(false)}>
            <CModalTitle>프로젝트 검색하기</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
              <form onChange={handleSubmit(submit)}>
                <div className="mb-3">
                  <CFormInput type={'text'} {...register('search')} />
                </div>

                {filteredList?.map((item, idx) => (
                  <div
                    key={idx}
                    style={{ borderBottom: '1px solid black', padding: '2px', cursor: 'pointer' }}
                    onClick={() => handleSelectItem(item)}
                  >
                    {item.name}
                  </div>
                ))}
              </form>
            </div>
          </CModalBody>
        </CModal>
      </div>
    </div>
  );
}

export default ProjectSearchModal;
