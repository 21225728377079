import { CCol, CFormInput, CFormLabel, CRow } from '@coreui/react';
import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { Title } from 'src/components/atoms/styled/element';
import { Column } from 'src/components/atoms/styled/layout';
import ProjectSearchModal from 'src/components/pages/CreateJobPage/components/ProjectSearchModal';
import { ProjectResponse } from 'src/types/api/Project';
import { FormDataOfCreateJob } from '../../type';
import { ProjectInfoWrapper } from './styled';

interface Props {
  setProject(item: ProjectResponse): void;
  register: UseFormRegister<FormDataOfCreateJob>;
}

function ProjectInfo(props: Props) {
  const { setProject, register } = props;

  return (
    <ProjectInfoWrapper>
      <Title>프로젝트 정보</Title>
      <Column>
        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>프로젝트 명</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormInput type={'text'} {...register('projectName', { required: true })} disabled={true} />
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
              }}
            >
              <ProjectSearchModal setTargetItem={(item: ProjectResponse) => setProject(item)} />
            </div>
          </CCol>
        </CRow>

        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>컨텐츠 유형</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormInput type={'text'} {...register('contentsType', { required: true })} disabled={true} />
          </CCol>
        </CRow>

        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>희망 작업 기간</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormInput type={'text'} {...register('dateTime')} disabled={true} />
          </CCol>
        </CRow>

        <CRow className="align-items-center">
          <CCol>
            <CFormLabel>번역 담당 PM</CFormLabel>
          </CCol>
          <CCol sm={8} style={{ position: 'relative' }}>
            <CFormInput type={'text'} {...register('projectManager')} disabled={true} />
          </CCol>
        </CRow>
      </Column>
    </ProjectInfoWrapper>
  );
}

export default ProjectInfo;
