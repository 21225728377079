import { useMount } from '@voithru/front-core';
import React from 'react';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import StatusLabel from 'src/components/atoms/StatusLabel';
import { Column, Row } from 'src/components/atoms/styled/layout';
import { CompanyResponse } from 'src/types/api/Company';
import { JobsResponse } from 'src/types/api/Job';
import { JobResultResponse } from 'src/types/api/JobResult';
import { ProjectResponse } from 'src/types/api/Project';
import { isAxiosError } from 'src/utils/api/axios';
import { getAccountByAccountId, getJobById, getProjectById } from 'src/utils/api/getter';
import { dateFormat } from 'src/utils/date';
import { ContentType } from 'src/utils/translate';
import styled from 'styled-components';
import { DashboardStatusRowWrapper } from './styled';

interface Props {
  result: JobResultResponse;

  className?: string;
}

function ContentResultRow(props: Props) {
  const { result, className } = props;

  const [content, setContent] = React.useState<JobsResponse>();
  const [project, setProject] = React.useState<ProjectResponse>();
  const [company, setCompany] = React.useState<CompanyResponse>();
  const getProject = React.useCallback(async () => {
    const res = await getProjectById(result.projectId);

    if (res.data.accountId) {
      const accountRes = await getAccountByAccountId(res.data.accountId);

      if (accountRes.data.companyId) {
        const companyRes = await api.companies.item(accountRes.data.companyId).get();

        if (isAxiosError(companyRes)) {
          throw companyRes;
        }

        setCompany(companyRes.data);
      }
    }

    setProject(res.data);
  }, [result.projectId]);

  const getContent = React.useCallback(async () => {
    const job = await getJobById(result.jobId);
    setContent(job.data);
  }, [result.jobId]);

  const getApi = React.useCallback(async () => {
    getProject();
    getContent();
  }, [getProject, getContent]);

  useMount(() => {
    getApi().catch(errorLogger.error);
  });

  return (
    <DashboardStatusRowWrapper className={className}>
      <span>
        <StatusLabel status={result.status} />
      </span>
      <Column>
        <Row>
          <Row>[작업결과물]</Row>
          <Row>[JR-{result.id}]</Row>
          <Row>[{project?.category && ContentType[project?.category]}]</Row>
          <Row>{content?.name}</Row>
        </Row>
        <Row>
          {company?.name}-{project?.name}
        </Row>
      </Column>
      <Row>{result && dateFormat(result?.createDateTime, 'YYYY.MM.DD')}</Row>
    </DashboardStatusRowWrapper>
  );
}

export default styled(ContentResultRow)``;
