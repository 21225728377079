import { ButtonBase } from 'src/components/atoms/styled/button';
import { Title } from 'src/components/atoms/styled/element';
import { Column, Row } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const ProjectOrderInfoWrapper = styled(Column)`
  width: 100%;

  & > ${Row}.titleWrap {
    justify-content: space-between;

    & > ${Title} {
      flex: 1;

      font-size: 18px;
      font-weight: 700;
    }

    & > ${ButtonBase} {
      padding: 3px 11px;

      background: ${getRGBA(colors.green50)};
      border-radius: 4px;

      color: ${getRGBA(colors.white)};
    }

    & > ${ButtonBase} + ${ButtonBase} {
      margin-left: 16px;
    }
  }

  & > ${Row}.totalWrap {
    justify-content: flex-end;

    margin-top: 17px;

    & > span:first-child {
      margin-right: 32px;

      color: ${getRGBA(colors.grey90)};
    }

    & > ${Row} {
      & > span {
        color: ${getRGBA(colors.grey50)};
      }

      & > span:first-child {
        color: ${getRGBA(colors.grey90)};
      }
    }
  }

  & > ${Column} {
    margin-top: 20px;

    border-bottom: 1px solid ${getRGBA(colors.grey80)};
    & > ${Row} {
      &.isHighLight {
        background: ${getRGBA(colors.blue50, 0.4)};
      }

      padding: 4px 0;

      & > span {
        padding-left: 4px;
      }

      font-size: 15px;
      text-align: left;
      span:nth-child(1) {
        width: 30px;
      }
      span:nth-child(2) {
        width: 100px;
      }
      span:nth-child(3) {
        width: 100px;
      }
      span:nth-child(4) {
        width: 120px;
      }
      span:nth-child(5) {
        width: 160px;
      }
      span:nth-child(6) {
        width: 100px;
      }
      span:nth-child(7) {
        width: 120px;
      }
      span:nth-child(8) {
        width: 120px;
      }
      span:nth-child(9) {
        width: 120px;
      }
      span:nth-child(10) {
        width: 100px;
      }
      span:nth-child(11) {
        width: 100px;

        white-space: normal;
        word-break: break-word;
      }
      span:nth-child(12) {
        flex: 1;
      }
    }

    & > ${Row}:first-child {
      border-bottom: 1px solid ${getRGBA(colors.grey80)};
      box-sizing: border-box;
    }

    & > ${Row} + ${Row} {
      border-bottom: 1px solid ${getRGBA(colors.grey50)};
      box-sizing: border-box;
    }

    & > ${Row}:last-child {
      border-bottom: 0px;
    }
  }
`;
