import { Parameter } from '@voithru/front-core';
import { AxiosInstance } from 'axios';
import { APIAccountId, APIPagination } from 'src/types/api';
import {
  JobResultFileResponse,
  JobResultRequest,
  JobResultResponse,
  JobResultStatusUpdate,
  JobResultStatus,
  JobResultPageRequest,
} from 'src/types/api/JobResult';
import { generatePath } from 'react-router';
import { DocumentResponse } from 'src/types/api/Document';

const JOB_RESULT_PATH = '/api/v1/jobResults';

function apiJobResult(instance: AxiosInstance) {
  return {
    get: (params: APIAccountId & JobResultRequest) => {
      const query = Parameter.toURLSearchParams(params);
      return instance.get<JobResultResponse[]>(JOB_RESULT_PATH, { params: query });
    },
    pages: (params: JobResultPageRequest) => {
      const query = Parameter.toURLSearchParams(params);
      return instance.get<APIPagination<JobResultResponse>>(JOB_RESULT_PATH + '/page', { params: query });
    },
    post: (data: { jobIds: number[]; productOrderIds: number[] }) => {
      return instance.post(JOB_RESULT_PATH, data);
    },
    patch: (data: JobResultStatusUpdate[]) => {
      return instance.patch(JOB_RESULT_PATH, data);
    },
    item(jobResultId: number) {
      const JOB_RESULT_ITEM_PATH = generatePath(JOB_RESULT_PATH + '/:jobResultId', { jobResultId });
      const DOCUMENT_PATH = JOB_RESULT_ITEM_PATH + '/document';

      return {
        put: (data: Partial<JobResultResponse>) => instance.put<JobResultResponse>(JOB_RESULT_ITEM_PATH, data),
        status: (status: JobResultStatus) =>
          instance.patch<JobResultResponse>(JOB_RESULT_ITEM_PATH + '/status', undefined, {
            params: { jobResultStatus: status },
          }),
        files: () => instance.get<JobResultFileResponse[]>(JOB_RESULT_ITEM_PATH + '/files'),
        document: () => instance.get<DocumentResponse>(DOCUMENT_PATH),
        latestDocument: () => instance.get<DocumentResponse>(DOCUMENT_PATH + '/latest'),
      };
    },
  };
}

export default apiJobResult;
