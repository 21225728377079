import { once } from '@voithru/front-core';
import { nanoid } from 'nanoid';
import React from 'react';
import { NamedFile, selectFile } from 'src/utils/files';
import MultipartUpload from 'src/utils/MultipartUpload';

function useFileUpload(type: 'PROJECT' | 'JOB' | 'COMPANY', id?: number) {
  const uploaders = React.useRef<MultipartUpload[]>([]);
  const [, forceUpdate] = React.useReducer(() => ({}), {});

  const startUpload = React.useCallback(async () => {
    const target = uploaders.current.find((it) => it.status === 'READY');
    const uploading = uploaders.current.find((it) => it.status === 'START');
    if (!target || uploading) {
      return;
    }

    try {
      target.start().then(() => startUpload());
    } catch (error) {
      console.error(error);
    }
  }, [uploaders]);

  React.useEffect(() => {
    const target = uploaders.current.find((it) => it.status !== 'DONE');
    if (!target) {
      return;
    }

    startUpload();
  }, [startUpload, uploaders]);

  const uploadFile = React.useCallback(
    async (file: File | File[] | FileList) => {
      const arr = file instanceof File ? [file] : Array.from(file);

      return Promise.all(arr.map(async (it) => {
        try {
          const namedFile: NamedFile = {
            id: nanoid(),
            name: it.name,
            file: it,
          };
          const uploader = new MultipartUpload(namedFile);

          uploader.addEventListener('progress', () => forceUpdate());
          uploader.addEventListener('start', () => forceUpdate());
          uploader.addEventListener('cancel', () => {
            uploaders.current = uploaders.current.filter((it) => it !== uploader);
            forceUpdate();
          });
          uploader.addEventListener('done', () => forceUpdate());

          uploaders.current = uploaders.current.concat(uploader);
          forceUpdate();

          await uploader.ready(type, id);
          startUpload();

          return uploader;
        } catch (error) {
          console.error('ProjectCreateInfo.uploadFile', error);
          return null;
        }
      }));
    },
    [id, startUpload, type]
  );

  const handleSelectFile = React.useMemo(
    () =>
      once(async () => {
        const files = await selectFile({ multiple: true });
        if (!files) {
          return;
        }

        const arr = Array.from(files);
        if (arr.length === 0) {
          return;
        }

        return await uploadFile(arr);
      }),
    [uploadFile]
  );

  return { uploaders: uploaders.current, uploadFile, handleSelectFile };
}

export default useFileUpload;
