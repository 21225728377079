import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CSpinner } from '@coreui/react';
import React from 'react';
import errorLogger from 'src/api/errorLogger';
import useAwaiter from 'src/utils/hooks/useAwaiter';
import styled from 'styled-components';

interface Props {
  title: React.ReactNode;
  children?: React.ReactNode;

  visible: boolean;
  onDismiss(): void;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  className?: string;
  size?: 'sm' | 'lg' | 'xl';
}
function FormModal(props: Props) {
  const { title, children, visible, onDismiss, onSubmit, className, size = 'lg' } = props;
  const submitLoading = useAwaiter();

  const handleSubmit = React.useMemo(
    () =>
      submitLoading.loading(async (e: React.FormEvent<HTMLFormElement>) => {
        try {
          await onSubmit(e);
        } catch (error) {
          errorLogger.error(error);
        }
      }),
    [submitLoading, onSubmit]
  );

  return (
    <CModal className={className} size={size} visible={visible} onDismiss={onDismiss}>
      <CModalHeader onDismiss={onDismiss}>
        <CModalTitle>{title}</CModalTitle>
      </CModalHeader>
      <form onSubmit={handleSubmit}>
        <CModalBody>{children}</CModalBody>
        <CModalFooter>
          <CButton type={'button'} color={'secondary'} onClick={onDismiss}>
            취소
          </CButton>
          <CButton className={'align-items-center'} type={'submit'} color={'primary'}>
            {submitLoading.isLoading.length !== 0 && (
              <CSpinner style={{ width: 16, height: 16, marginRight: '8px', borderWidth: 3 }} color={'light'} />
            )}
            <span>확인</span>
          </CButton>
        </CModalFooter>
      </form>
    </CModal>
  );
}

export default styled(FormModal)`
  & .modal-body {
    max-height: calc(100vh - 200px);
    overflow: auto;
  }
`;
