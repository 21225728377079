import {
  CButton,
  CContainer,
  CFormCheck,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { Button } from 'src/components/atoms/styled/button';
import { ProductOption, ProductOptionSearch } from 'src/types/api/Product';
import { ProductOrderOption, ProductOrderOptionType } from 'src/types/api/ProductOrder';
import { ProjectCategory } from 'src/types/api/Project';
import { isAxiosError } from 'src/utils/api/axios';
import { AdditionalServices } from 'src/utils/translate';

interface Props {
  productOrderOptions: ProductOrderOption[];
  isEdit?: boolean;
  category?: ProjectCategory | undefined;
  onSubmitHandler?: (selectProductOrderOptions: ProductOrderOption[]) => void;
}

type FormData = Record<ProductOrderOptionType, string>;

function SelectOptionsPriceModal(props: Props) {
  const { productOrderOptions, category, isEdit = false, onSubmitHandler } = props;

  const { register, handleSubmit, getValues } = useForm<FormData>({ defaultValues: {} });

  const [productOptionGroupedMap] = React.useState<Map<ProductOrderOptionType, ProductOption[]>>(() => new Map());

  const productOptionTypes = React.useMemo(() => productOrderOptions.map((it) => it.type), [productOrderOptions]);

  const loadProductOptionGroupedMap = React.useCallback(async () => {
    if (!productOptionTypes) return;

    productOptionGroupedMap.clear();

    const searchProductOption: ProductOptionSearch = { productOptionTypes, requestCategory: category };

    const productOptionRes = await api.product.option.search(searchProductOption);

    if (isAxiosError(productOptionRes)) {
      throw productOptionRes;
    }

    for (const item of productOptionRes.data) {
      productOptionGroupedMap.set(item.type, [...(productOptionGroupedMap.get(item.type) ?? []), item]);
    }
  }, [category, productOptionGroupedMap, productOptionTypes]);

  const handleAddProductOptionLink = React.useCallback(() => {
    return window.alert('현재 제공하지 않는 기능입니다.');
  }, []);

  const [visible, setVisible] = React.useState(false);

  const onSubmit = React.useCallback(
    async (data: FormData = getValues()) => {
      if (!productOptionTypes) {
        throw new Error('Not have OptionTypes');
      }

      if (!productOptionGroupedMap) {
        throw new Error('Not have ProductOptionGroupedMap');
      }

      if (
        window.confirm(
          '부가서비스 단가를 적용합니다 \n단가를 변경 하는 경우 연관된 작업결과물 부가서비스 단가에 일괄 적용됩니다.'
        )
      ) {
        const selectProductOrderOptions = productOrderOptions.map((it) => {
          // data contains all data as string, so convert to right type, number
          const productOptionId = Number(data[it.type]);
          const selectOption = productOptionGroupedMap.get(it.type)?.find((po) => po.id === productOptionId);

          if (!selectOption) {
            throw new Error('Not have selectOption in ProductOption');
          }

          it.productOptionId = productOptionId;
          it.price = selectOption.price;

          return it;
        });

        onSubmitHandler && onSubmitHandler(selectProductOrderOptions);
      }
      setVisible(false);
    },
    [getValues, productOptionTypes, productOptionGroupedMap, productOrderOptions, onSubmitHandler]
  );

  React.useEffect(() => {
    try {
      loadProductOptionGroupedMap();
    } catch (error) {
      errorLogger.error(error);
    }
  }, [loadProductOptionGroupedMap, productOrderOptions]);

  return (
    <div className="align-items-center d-flex">
      <Button
        style={{ width: 'max-content', color: '#006BE5', textDecoration: 'underline' }}
        onClick={() => setVisible(!visible)}
        disabled={!productOrderOptions.length || !isEdit}
      >
        {productOrderOptions ? (
          <span>
            {productOrderOptions.reduce((acc, item) => {
              return acc + (item.price ?? 0);
            }, 0)}
          </span>
        ) : (
          <span style={{ width: 'max-content' }}>단가 선택하기</span>
        )}
      </Button>
      <CModal size={'lg'} visible={visible} onDismiss={() => setVisible(false)}>
        <form
          onSubmit={(e) => {
            e.stopPropagation();

            handleSubmit((data: FormData) => onSubmit(data))(e);
          }}
        >
          <CModalHeader onDismiss={() => setVisible(false)}>
            <CModalTitle>부가 서비스 단가 선택하기</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <div className="d-flex flex-row  justify-content-end" style={{ marginBottom: '5px' }}>
              <button onClick={() => handleAddProductOptionLink()}>+ 단가 추가</button>
            </div>
            <CContainer style={{ height: '320px', overflow: 'auto' }}>
              <CTable bordered>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell />
                    <CTableHeaderCell>서비스 명</CTableHeaderCell>
                    <CTableHeaderCell>단가</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {productOptionTypes?.map((type) => {
                    const groupedOptions = productOptionGroupedMap.get(type)?.sort((a, b) => a.price - b.price);

                    return groupedOptions?.map((it) => {
                      return (
                        <CTableRow key={it.id}>
                          <CTableDataCell>
                            <CFormCheck type={'radio'} value={it.id} {...register(it.type, { required: true })} />
                          </CTableDataCell>
                          <CTableDataCell>{AdditionalServices[it.type]}</CTableDataCell>
                          <CTableDataCell>{it.price}</CTableDataCell>
                        </CTableRow>
                      );
                    });
                  })}
                </CTableBody>
              </CTable>
            </CContainer>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => {
                setVisible(false);
              }}
            >
              취소
            </CButton>
            <CButton type={'submit'} color={'primary'}>
              확인
            </CButton>
          </CModalFooter>
        </form>
      </CModal>
    </div>
  );
}

export default SelectOptionsPriceModal;
