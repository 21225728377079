import { CModal } from '@coreui/react';
import { Row } from 'src/components/atoms/styled/layout';
import styled from 'styled-components';

export const UserInfoModal = styled(CModal)`
  & .modal-body {
    & > ${Row} {
      & > span:nth-child(1) {
        width: 180px;
      }

      &:not(:first-child) {
        margin-top: 8px;
      }
    }
  }
`;

export const ProjectDescriptionWrapper = styled(Row)`
  justify-content: space-between;

  & > .card {
    flex: 1;

    padding: 14px 16px;

    & > ${Row} {
      &:not(:first-child) {
        margin-top: 4px;
      }
    }

    &.client > ${Row} {
      &:not(:first-child) {
        margin-top: 8px;
      }

      & > span:nth-child(1) {
        min-width: 120px;
      }

      & > ${Row} {
        flex-wrap: wrap;

        & > .btn {
          margin: 0 8px 8px 0;
          font-size: 14px;
        }
      }
    }

    &.info {
      margin-left: 8px;

      & > ${Row} {
        align-items: center;

        & > span:nth-child(1) {
          width: 180px;
        }

        & > span:nth-child(2) {
          flex: 1;

          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          &.editable {
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
`;
