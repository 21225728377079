import { Title } from 'src/components/atoms/styled/element';
import { Column } from 'src/components/atoms/styled/layout';
import { StatusCountBadge } from 'src/components/atoms/StatusCountBadge';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const StatusCountBadgeWrapper = styled(Column)`
  justify-content: center;
  align-items: center;

  width: fit-content;

  & > ${Title} {
    font-size: 15px;
    color: ${getRGBA(colors.grey90)};
  }

  & > ${StatusCountBadge} {
    margin-top: 10px;

    & > a {
      color: ${getRGBA(colors.white)};
      text-decoration: none;
    }
  }
`;

export const AllocationWorkWrapper = styled.div`
  display: flex;

  margin-top: 40px;

  & > div {
    display: flex;
    align-items: flex-end;

    & > ${StatusCountBadgeWrapper} + ${StatusCountBadgeWrapper} {
      margin-left: 40px;
    }
  }

  & > div + div {
    &::before {
      display: block;

      margin: 0 72px;
      width: 2px;
      height: 70px;

      border: 1px solid #b9b9b9;

      content: '';
    }
  }
`;
