import { gql } from '@apollo/client';

export const GQL_PROJECT_DESCRIPTION_COLLAPSE = gql`
  query ProjectDescriptionCollapse($id: ID!) {
    project(id: $id) {
      description
      projectFiles {
        id
        fileType
        file {
          id
          name
          volume
          fileUploadStartDateTime
        }
      }
      projectRelateLinks {
        id
        url
      }
    }
  }
`;
