import { gql } from '@apollo/client';

export const GET_JOBS_QUERY = gql`
  query GetJobs($ids: [ID!]) {
    jobs(ids: $ids) {
      id
      category
      project {
        name
      }
      name
      status
      account {
        company {
          id
          name
          manager {
            name
          }
        }
      }
      createDateTime
      updateDateTime
      scheduledDeadlineDateTime
      manager {
        name
      }
      jobFiles {
        fileType
        file {
          id
          name
        }
      }
    }
  }
`;
