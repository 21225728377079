import { useQuery } from '@apollo/client';
import React from 'react';
import { useForm } from 'react-hook-form';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import ICN_DELETE from 'src/assets/icons/icn-delete-24px.svg';
import ICN_DOWNLOAD from 'src/assets/icons/icn-download-24px.svg';
import ICN_PREVIEW from 'src/assets/icons/icn-preview-24px.svg';
import { ButtonBase } from 'src/components/atoms/styled/button';
import { CheckBox } from 'src/components/atoms/styled/form';
import { Column, Row } from 'src/components/atoms/styled/layout';
import FormModal from 'src/components/organisms/FormModal';
import { JAMAKE_EDITOR } from 'src/constants/API';
import { useAuth } from 'src/components/context/AuthContext';
import { isAxiosError } from 'src/utils/api/axios';
import { dateFormat } from 'src/utils/date';
import { handleEnterKeyDown } from 'src/utils/dom';
import { downloadAPIFile, separateFileIntoNameAndExtension } from 'src/utils/files';
import { getPreviewUrl, previewToWindow } from 'src/utils/preview';
import { getByteString } from 'src/utils/string';
import { FileDownloadModalWrap } from './styled';
import EditorCreateModal from 'src/components/organisms/EditorCreateModal';
import { VideoProcessRequest } from 'src/types/api/VideoProcess';
import { GQL_JOB_FILES } from './gql';
import { JobFilesQuery, JobFilesQueryVariables } from './gql.generated';
import { File } from 'src/types/graphql';
import QueueTask from 'src/utils/QueueTask';

interface Props {
  jobIds: string[];

  onDismiss: () => void;
}

interface FormData {
  jobs: {
    target: string;
    checked: boolean;

    files?: {
      target: string;
      checked: boolean;
    }[];
  }[];
}

function JobFileDownloadModal(props: Props) {
  const { jobIds, onDismiss } = props;

  const { user } = useAuth();

  const { loading, data, refetch } = useQuery<JobFilesQuery, JobFilesQueryVariables>(GQL_JOB_FILES, {
    variables: {
      ids: jobIds,
    },

    onError: errorLogger.error,
  });

  const jobs = data?.jobs;

  const queueTask = React.useMemo(() => new QueueTask(), []);

  const { register, getValues, setValue, watch } = useForm<FormData>();

  const checks = watch('jobs')?.map((it) => it);

  const [editFileId, setEditFileId] = React.useState<string>();

  const editFileRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (editFileId === undefined) return;

    editFileRef.current?.focus();
  }, [editFileId]);

  const someChecked = React.useMemo(() => {
    if (!checks) return false;
    return checks.some((it) => it.checked || it.files?.some((file) => file.checked));
  }, [checks]);

  const jobFilesAllChecked = React.useCallback(
    (index: number) => {
      if (!checks || !checks[index].files) return false;

      return checks[index].files?.every((it) => it.checked);
    },
    [checks]
  );

  const jobsAllChecked = React.useMemo(() => {
    if (!checks) return false;

    return checks.every((it) => it.checked);
  }, [checks]);

  const handleRefetch = React.useCallback(() => {
    refetch({
      ids: jobIds,
    });
  }, [jobIds, refetch]);

  const handleJobFilesAllChecked = React.useCallback(
    (index: number) => {
      setValue(
        `jobs.${index}.files`,
        getValues(`jobs.${index}.files`)?.map((it) => {
          return {
            ...it,
            checked: !jobFilesAllChecked(index),
          };
        })
      );
    },
    [jobFilesAllChecked, setValue, getValues]
  );

  const handleJobsAllChecked = React.useCallback(() => {
    setValue(
      'jobs',
      getValues('jobs').map((it) => {
        return {
          ...it,
          checked: !jobsAllChecked,
          files:
            it.files?.map((file) => {
              return { ...file, checked: !jobsAllChecked };
            }) ?? undefined,
        };
      })
    );
  }, [jobsAllChecked, setValue, getValues]);

  const handleCheckedFileDonwload = React.useCallback(async () => {
    if (!jobs) return;

    jobs.forEach((job, idx) => {
      const files = job.jobFiles ?? [];

      if (!checks[idx].checked) {
        files.forEach((file, index) => {
          const checkFile = checks[idx].files ?? [];

          if (!checkFile[index].checked || !file.file?.id || !file.file.name) {
            return;
          }

          queueTask.dispatch(downloadAPIFile, {
            id: file.file?.id,
            name: file.file.name,
          });
        });

        return;
      }

      for (const file of files) {
        if (!file.file) {
          return;
        }

        queueTask.dispatch(downloadAPIFile, {
          id: file.file.id,
          name: file.file.name!,
        });
      }
    });
  }, [checks, jobs, queueTask]);

  const handleJobFileDownload = React.useCallback(async (file: File) => {
    if (file.name) {
      await downloadAPIFile({
        id: file.id,
        name: file.name,
      });
    }
  }, []);

  const handleJobFilePreview = React.useCallback(async (file: File) => {
    const data = await getPreviewUrl({
      id: file.id,
      name: file.name ?? '',
    }).catch(errorLogger.error);

    if (!data) return;

    previewToWindow(data);
  }, []);

  const handleJobFileDelete = React.useCallback(
    async (jobId: number, fileId: string) => {
      const data = {
        jobId,
        fileId,
      };

      if (window.confirm('해당 파일을 삭제하시겠습니까?')) {
        const res = await api.jobs.item(jobId).files.item(fileId).delete(data);

        if (isAxiosError(res)) {
          throw res;
        }

        handleRefetch();
      }
    },
    [handleRefetch]
  );

  React.useEffect(() => {
    if (!jobs) return;

    setValue(
      'jobs',
      jobs.map((job) => {
        return {
          target: job.id,
          checked: false,
          files: job.jobFiles?.map((it) => {
            return {
              target: it.fileId ?? '',
              checked: false,
            };
          }),
        };
      })
    );
  }, [jobs, setValue]);

  const handleEditorLinkCopy = React.useCallback((editorKey: number) => {
    try {
      const editorLink = JAMAKE_EDITOR + `/ko/${editorKey}`;

      if (!navigator.clipboard) {
        const container = document.createElement('textarea');
        document.body.appendChild(container);
        container.value = editorLink;
        container.select();
        document.execCommand('copy');
        document.body.removeChild(container);

        return window.alert('클립보드에 에디터 url이 복사되었습니다.');
      }

      navigator.clipboard.writeText(editorLink).then(() => {
        window.alert('클립보드에 에디터 url이 복사되었습니다.');
      });
    } catch (error) {
      errorLogger.error(error);
    }
  }, []);

  const handleEditorCreateSubmit = React.useCallback(
    async (data: VideoProcessRequest) => {
      if (window.confirm('에디터를 생성합니다.')) {
        try {
          const res = await api.videoProcessRequest.post(data);
          if (isAxiosError(res)) {
            throw res;
          }

          handleRefetch();
        } catch (error) {
          errorLogger.error(error);
        }
      }
    },
    [handleRefetch]
  );

  const handleJobFileName = React.useCallback(
    async (file?: File | null, extensionName?: string | null) => {
      if (!editFileRef.current?.value || !editFileId || !file || file?.name !== editFileRef.current?.value) {
        return;
      }

      await api.file
        .item(editFileId)
        .put({
          id: file?.id,
          isDirectory: file?.isDirectory!,
          parentDirectoryId: file?.parentDirectoryId ?? undefined,
          s3Path: file?.s3Path!,
          volume: file?.volume!,
          name: editFileRef.current?.value + extensionName,
        })
        .catch(errorLogger.error);

      handleRefetch();

      setEditFileId(undefined);
    },
    [editFileId, handleRefetch]
  );

  if (loading) return <div>loading...</div>;

  return (
    <FormModal title={'콘텐츠 첨부파일'} visible={true} size={'xl'} onDismiss={onDismiss} onSubmit={() => {}}>
      <FileDownloadModalWrap>
        <Row className={'buttonWrap'}>
          <ButtonBase type={'button'} onClick={handleCheckedFileDonwload} disabled={!someChecked}>
            콘텐츠 다운로드
          </ButtonBase>
        </Row>

        <Column>
          <Row className={'titleWrap'}>
            <span>
              <CheckBox onChange={handleJobsAllChecked} checked={jobsAllChecked} />
            </span>
            <span>콘텐츠 ID</span>
            <span>콘텐츠 명</span>
            <span>콘텐츠 파일</span>
            {user?.role === 'ADMIN' && <span>에디터</span>}
            <span>용량</span>
            <span>업로드일</span>
            <span>담당PM</span>
            {user?.role === 'ADMIN' && <span>파일삭제</span>}
          </Row>
          {jobs?.map((it, index) => (
            <Row key={it.id} className={'itemWrap'}>
              <span>
                <CheckBox
                  {...register(`jobs.${index}.checked`)}
                  onChange={() => handleJobFilesAllChecked(index)}
                  checked={jobFilesAllChecked(index)}
                />
              </span>
              <span>J-{it.id}</span>
              <span>{it.name}</span>
              <Column>
                {it.jobFiles
                  ?.sort((a, b) => (a.index ?? 0) - (b.index ?? 0))
                  .map((jobFile, idx) => {
                    const splitJobFileName = separateFileIntoNameAndExtension(jobFile.file?.name);

                    return (
                      <Row key={jobFile.fileId}>
                        <span>
                          <CheckBox {...register(`jobs.${index}.files.${idx}.checked`)} />
                        </span>
                        <span>
                          {editFileId === jobFile.fileId && (
                            <span>
                              <input
                                defaultValue={splitJobFileName[0] ?? ''}
                                ref={editFileRef}
                                onBlur={() => handleJobFileName(jobFile.file, splitJobFileName[1])}
                                onKeyDown={(e) => handleEnterKeyDown(e, editFileRef)}
                              />
                              {splitJobFileName[1]}
                            </span>
                          )}
                          {editFileId !== jobFile.fileId && (
                            <span
                              onDoubleClick={() =>
                                user?.role === 'ADMIN' && jobFile.fileId && setEditFileId(jobFile.fileId)
                              }
                            >
                              {jobFile.file?.name}
                            </span>
                          )}

                          <span>
                            <ButtonBase onClick={() => jobFile.file && handleJobFilePreview(jobFile.file)}>
                              <img src={ICN_PREVIEW} alt={'preview'} />
                            </ButtonBase>
                          </span>
                          <span>
                            <ButtonBase
                              type={'button'}
                              onClick={() => jobFile.file && handleJobFileDownload(jobFile.file)}
                            >
                              <img src={ICN_DOWNLOAD} alt={'download'} />
                            </ButtonBase>
                          </span>
                        </span>
                        {user?.role === 'ADMIN' && (
                          <span>
                            {jobFile.editors ? (
                              jobFile.editors.map((editor, idx) => {
                                return (
                                  <ButtonBase
                                    key={idx}
                                    color={'secondary'}
                                    className={'editorLink'}
                                    onClick={() => handleEditorLinkCopy(editor.key)}
                                  >
                                    에디터 {editor.index}
                                  </ButtonBase>
                                );
                              })
                            ) : (
                              <EditorCreateModal
                                title={'에디터 생성'}
                                onSubmit={handleEditorCreateSubmit}
                                contentData={{
                                  contentId: it.id,
                                  contentName: it.name ?? '',
                                  fileId: jobFile.id ?? '',
                                  fileName: jobFile.file?.name ?? '',
                                  fileVolume: jobFile.file?.volume ?? '',
                                  fileUploadStartDateTime: jobFile.file?.fileUploadStartDateTime ?? '',
                                }}
                                editorStatus={jobFile.videoProcessRequest?.status}
                              />
                            )}
                          </span>
                        )}
                        <span>{jobFile.file?.volume && getByteString(jobFile.file?.volume)}</span>
                        <span>
                          {jobFile.file?.fileUploadStartDateTime &&
                            dateFormat(jobFile.file?.fileUploadStartDateTime, 'YYYY.MM.DD')}
                        </span>
                        <span>{it.manager?.name}</span>
                        {user?.role === 'ADMIN' && (
                          <span>
                            <ButtonBase
                              onClick={() =>
                                jobFile.file?.id &&
                                handleJobFileDelete(parseInt(it.id), jobFile.file?.id).catch(errorLogger.error)
                              }
                            >
                              <img src={ICN_DELETE} alt={'delete'} />
                            </ButtonBase>
                          </span>
                        )}
                      </Row>
                    );
                  })}
              </Column>
            </Row>
          ))}
        </Column>
      </FileDownloadModalWrap>
    </FormModal>
  );
}

export default JobFileDownloadModal;
