import { gql } from '@apollo/client';

export const GQL_NOTIFICATION_ITEM = gql`
  query NotificationItem($job: Boolean = false, $job_result: Boolean = false, $entityId: ID!, $publisherId: ID!) {
    job(id: $entityId) @include(if: $job) {
      ...jobFrag

      status

      projectId
    }

    jobResult(id: $entityId) @include(if: $job_result) {
      job {
        ...jobFrag
      }
      id

      status

      projectId
    }

    publisherAccount: account(id: $publisherId) {
      name
    }
  }

  fragment jobFrag on Job {
    name

    account {
      company {
        name
      }
    }
  }
`;
