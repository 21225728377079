import { Title } from 'src/components/atoms/styled/element';
import FormModal from 'src/components/organisms/FormModal';
import styled from 'styled-components';

export const AccountCreateModalWrapper = styled(FormModal)`
  & .modal-body {
    & > ${Title} {
      margin-bottom: 25px;

      border-bottom: 1px solid #d8dbe0;

      width: 100%;

      font-size: 20px;
    }

    & > ${Title}:not(:first-child) {
      margin-top: 40px;
    }

    & > div.row {
      margin-bottom: 16px;
    }
  }
`;
