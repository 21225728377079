import { Row } from 'src/components/atoms/styled/layout';
import FormModal from 'src/components/organisms/FormModal';
import styled from 'styled-components';

export const CreateClientAccountModalWrapper = styled(FormModal)`
  & .modal-body {
    & > ${Row} {
      padding: 8px 16px;

      & > .form-label {
        width: 120px;
      }

      & > .form-control {
        flex: 2;
      }
    }

    & > span.error {
      display: block;
      color: red;
      text-align: right;
    }
  }
`;
