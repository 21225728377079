import { gql } from '@apollo/client';

export const GQL_AUTH_CHECK = gql`
  query AuthCheck($hermesAccountId: Int!, $projectId: Int!, $id: ID!) {
    workerJobResultGrants(hermesAccountId: $hermesAccountId, projectId: $projectId) {
      jobResult {
        id
        jobId
      }
    }

    project(id: $id) {
      jobs {
        id
        jobResults {
          id
        }
      }
    }
  }
`;
