import { CModal, CModalHeader } from '@coreui/react';
import React from 'react';
import { ButtonBase } from 'src/components/atoms/styled/button';
import { Company } from 'src/types/api/Company';
import { JobsResponse } from 'src/types/api/Job';
import { JobResultResponse } from 'src/types/api/JobResult';
import { ProductOrder } from 'src/types/api/ProductOrder';
import { ProjectResponse } from 'src/types/api/Project';
import PaymentSettlement from './components/PaymentSettlement';
import SettlementPreview from './components/SettlementPreview';
import SettlementPublish from './components/SettlementPublish';
import { PaymentSettlementModalWrapper } from './styled';
import { modalStep } from './type';

interface Props {
  selectedJobResults: JobResultResponse[];
  productOrderMap: Map<number, ProductOrder>;
  jobsMap: Map<number, JobsResponse>;
  projectMap: Map<number, ProjectResponse>;
  company?: Company;
}

function PaymentSettlementModal(props: Props) {
  const { selectedJobResults, productOrderMap, jobsMap, projectMap, company } = props;

  const [modalType, setCurrentModalType] = React.useState<modalStep>('PAYMENTSETTLEMENT');
  const [visible, setVisible] = React.useState(false);

  const handleCloseModal = React.useCallback(() => {
    setCurrentModalType('PAYMENTSETTLEMENT');
    setVisible(false);
  }, []);

  return (
    <PaymentSettlementModalWrapper>
      <ButtonBase onClick={() => setVisible(!visible)} disabled={!selectedJobResults.length}>
        납품 내역서 발행
      </ButtonBase>
      <CModal size={'xl'} visible={visible} onDismiss={() => handleCloseModal()} className={'modalCover'}>
        <CModalHeader onDismiss={() => handleCloseModal()} />
        {modalType === 'PAYMENTSETTLEMENT' && (
          <PaymentSettlement
            selectedJobResults={selectedJobResults}
            productOrderMap={productOrderMap}
            jobsMap={jobsMap}
            projectMap={projectMap}
            company={company}
            handleStep={(nextStep) => setCurrentModalType(nextStep)}
            onClose={() => handleCloseModal()}
          />
        )}
        {modalType === 'SETTLEMENTPREVIEW' && (
          <SettlementPreview
            selectedJobResults={selectedJobResults}
            productOrderMap={productOrderMap}
            jobsMap={jobsMap}
            company={company}
            handleStep={(nextStep) => setCurrentModalType(nextStep)}
          />
        )}
        {modalType === 'SETTLEMENTPUBLISH' && (
          <SettlementPublish
            selectedJobResults={selectedJobResults}
            productOrderMap={productOrderMap}
            jobsMap={jobsMap}
            projectMap={projectMap}
            company={company}
            handleStep={(nextStep) => setCurrentModalType(nextStep)}
          />
        )}
      </CModal>
    </PaymentSettlementModalWrapper>
  );
}

export default PaymentSettlementModal;
