import { AxiosInstance } from 'axios';
import { VideoProcessRequest } from 'src/types/api/VideoProcess';

const PATH = '/api/v1/videoProcessRequest';

function apiVideoProcessRequest(instance: AxiosInstance) {
  return {
    post: (data: VideoProcessRequest) => instance.post(PATH, data),
  };
}

export default apiVideoProcessRequest;
