import React from 'react';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import StatusLabel from 'src/components/atoms/StatusLabel';
import { Badge, Title } from 'src/components/atoms/styled/element';
import { Column, Row } from 'src/components/atoms/styled/layout';
import { JobsResponse, JobsStatus } from 'src/types/api/Job';
import { ProjectResponse, ProjectStatus } from 'src/types/api/Project';
import { isAxiosError } from 'src/utils/api/axios';
import { TimeUnit } from 'src/utils/date';
import styled from 'styled-components';
import { JobSummaryCountWrapper, JobSummaryWrapper } from './styled';

type JobGroup = Record<JobsStatus, JobsResponse[] | undefined>;

interface CountProps {
  title: string;
  count: number;
  status?: ProjectStatus | JobsStatus;

  className?: string;
  children?: React.ReactNode;
}
function CountColumn(props: CountProps) {
  const { title, count, status, className, children } = props;

  return (
    <JobSummaryCountWrapper className={className}>
      <Title>{title}</Title>
      {status === 'REQUESTING' && <Badge color={'grey'}>{'작성중'}</Badge>}
      {status && status !== 'REQUESTING' && <StatusLabel status={status} />}
      <span className={'count'}>{Intl.NumberFormat().format(count || 0)}</span>
      {children}
    </JobSummaryCountWrapper>
  );
}

interface Props {
  project: ProjectResponse;

  className?: string;
}

function JobSummary(props: Props) {
  const { project, className } = props;

  const [jobs, setJobs] = React.useState<JobsResponse[]>();
  const loadJobs = React.useCallback(async (projectId: number) => {
    const res = await api.project.item(projectId).jobs();
    if (isAxiosError(res)) {
      throw res;
    }

    setJobs(res.data);
    return res.data;
  }, []);

  React.useEffect(() => {
    loadJobs(project.id).catch(errorLogger.error);
  }, [loadJobs, project.id]);

  const counts = React.useMemo(
    () =>
      jobs?.reduce((group: JobGroup, it) => {
        group[it.status] = [...(group[it.status] ?? []), it];
        return group;
      }, {} as JobGroup),
    [jobs]
  );

  return (
    <JobSummaryWrapper className={className}>
      <Row>
        <CountColumn className={'total'} title={'총 작업'} count={jobs?.length ?? NaN} />
        <Column className={'group'}>
          <CountColumn
            title={'신규작업의뢰'}
            count={
              (counts?.['REQUESTING']?.length ?? 0) +
              (counts?.['REQUESTED']?.length ?? 0) +
              (counts?.['REGISTERED']?.length ?? 0)
            }
          />
          <Row>
            <CountColumn title={'승인 전'} status={'REQUESTING'} count={counts?.['REQUESTING']?.length ?? NaN} />
            <CountColumn title={''} status={'REQUESTED'} count={counts?.['REQUESTED']?.length ?? NaN} />
            <CountColumn
              title={'승인 후 착수 전'}
              status={'REGISTERED'}
              count={counts?.['REGISTERED']?.length ?? NaN}
            />
          </Row>
        </Column>
        <Column className={'group'}>
          <CountColumn
            title={'처리중 작업'}
            count={(counts?.['PROCESSING']?.length ?? 0) + (counts?.['REVIEWABLE']?.length ?? 0)}
          />
          <Row>
            <Column className={'description'}>
              <span>전체</span>
              <span className={'error'}>3일 이내</span>
              <span className={'error'}>일정 지연</span>
            </Column>
            <CountColumn title={'진행중 작업'} status={'PROCESSING'} count={counts?.['PROCESSING']?.length ?? NaN}>
              <span className={'error'}>
                {
                  counts?.['PROCESSING']?.filter((it) => {
                    const scheduled = it.scheduledDeadlineDateTime
                      ? new Date(it.scheduledDeadlineDateTime).getTime()
                      : Number.POSITIVE_INFINITY;
                    return scheduled > Date.now() - TimeUnit.DAY * 3 && scheduled < Date.now();
                  }).length
                }
              </span>
              <span className={'error'}>
                {
                  counts?.['PROCESSING']?.filter((it) => {
                    const scheduled = it.scheduledDeadlineDateTime
                      ? new Date(it.scheduledDeadlineDateTime).getTime()
                      : Number.POSITIVE_INFINITY;
                    return scheduled >= Date.now();
                  }).length
                }
              </span>
            </CountColumn>
            <CountColumn title={'고객검수중 작업'} status={'REVIEWABLE'} count={counts?.['REVIEWABLE']?.length ?? NaN}>
              <span className={'error'}>
                {
                  counts?.['REVIEWABLE']?.filter((it) => {
                    const scheduled = it.scheduledDeadlineDateTime
                      ? new Date(it.scheduledDeadlineDateTime).getTime()
                      : Number.POSITIVE_INFINITY;
                    return scheduled > Date.now() - TimeUnit.DAY * 3 && scheduled < Date.now();
                  }).length
                }
              </span>
              <span className={'error'}>
                {
                  counts?.['REVIEWABLE']?.filter((it) => {
                    const scheduled = it.scheduledDeadlineDateTime
                      ? new Date(it.scheduledDeadlineDateTime).getTime()
                      : Number.POSITIVE_INFINITY;
                    return scheduled >= Date.now();
                  }).length
                }
              </span>
            </CountColumn>
          </Row>
        </Column>
        <Column className={'group'}>
          <CountColumn
            title={'종료된 작업'}
            count={(counts?.['DONE']?.length ?? 0) + (counts?.['DROP']?.length ?? 0)}
          />
          <Row>
            <CountColumn title={'작업 종료'} status={'DONE'} count={counts?.['DONE']?.length ?? NaN} />
            <CountColumn title={''} status={'DROP'} count={counts?.['DROP']?.length ?? NaN} />
          </Row>
        </Column>
      </Row>
    </JobSummaryWrapper>
  );
}

export default styled(JobSummary)``;
