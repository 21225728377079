import { Column, Row } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const JobResultForSettlementWrapper = styled(Column)`
  width: 2504px;

  & > ${Row} {
    justify-content: space-between;
    align-items: flex-end;
    & > span {
      padding: 16px 12px;
      width: fit-content;
      border-bottom: 2px solid ${getRGBA(colors.grey90)};
      box-sizing: border-box;

      font-size: 20px;
      color: ${getRGBA(colors.grey90)};
    }
    & > a {
      color: ${getRGBA(colors.grey90)};
      text-decoration: none;
    }
  }

  & > ${Column} {
    margin-top: 24px;
    width: 100%;
    & > ${Row}:first-child {
      border: 2px solid ${getRGBA(colors.grey90)};
    }

    & > ${Row} {
      padding: 17px 0;

      text-align: left;
      font-size: 15px;
      & > span {
        padding-left: 4px;
      }

      & > span:nth-child(1) {
        width: 40px;
      }
      & > span:nth-child(2) {
        width: 100px;
      }
      & > span:nth-child(3) {
        width: 95px;
      }
      & > span:nth-child(4) {
        width: 240px;
      }
      & > span:nth-child(5) {
        width: 240px;
      }
      & > span:nth-child(6) {
        width: 100px;
      }
      & > span:nth-child(7) {
        width: 140px;
      }
      & > span:nth-child(8) {
        width: 120px;
      }
      & > span:nth-child(9) {
        width: 120px;
      }
      & > span:nth-child(10) {
        width: 120px;
      }
      & > span:nth-child(11) {
        width: 175px;
      }
      & > span:nth-child(12) {
        width: 80px;
      }
      & > span:nth-child(13) {
        width: 100px;
      }
      & > span:nth-child(14) {
        width: 120px;
      }
      & > span:nth-child(15) {
        width: 120px;
      }
      & > span:nth-child(16) {
        width: 100px;
      }
      & > span:nth-child(17) {
        width: 90px;
      }
      & > span:nth-child(18) {
        width: 90px;
      }
      & > span:nth-child(19) {
        width: 100px;
      }
      & > span:nth-child(20) {
        width: 100px;
      }
      & > span:nth-child(21) {
        width: 100px;
      }
      & > span:nth-child(22) {
        width: 80px;
      }
      & > span:nth-child(23) {
        width: 80px;
      }
      & > span:nth-child(24) {
        flex: 1;
      }
    }
  }
`;
