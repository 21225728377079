import { Eventer, once, StorageBase } from '@voithru/front-core';
import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { APIError } from 'src/types/api';
import { isAxiosError } from 'src/utils/api/axios';

function hasToken(header: unknown): boolean {
  if (typeof header !== 'object' || !header) {
    return false;
  }

  return Boolean(Object.values(header).find((it) => typeof it === 'string' && /^Bearer/gi.test(it)));
}

type AuthorizationEventHandler = () => void;

interface EventListener {
  (events: 'success', func: (token: string) => void): void;
  (events: 'failed', func: AuthorizationEventHandler): void;
}

class Events {
  public success = new Eventer<(token: string) => void>();
  public failed = new Eventer();

  public addEventListener: EventListener = (events, func) => {
    this[events].addEventListener(func);
  };
}

interface AxiosRequestCustomConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

interface AxiosCustomError<T> extends AxiosError<T> {
  config: AxiosRequestCustomConfig;
}

class AuthorizationManager {
  private storage = new StorageBase<string>(`${this.serviceName}:auth`);

  public get token(): string | null {
    return this.storage.item;
  }

  public set token(token: string | null) {
    this.storage.item = token;
  }

  private events = new Events();
  public addEventListener: Events['addEventListener'] = this.events.addEventListener.bind(this.events);

  constructor(private serviceName: string, private apiInstance: AxiosInstance) {
    this.events.addEventListener('success', (token) => (this.token = token));
    this.events.addEventListener('failed', () => (this.token = null));

    apiInstance.interceptors.response.use(undefined, this.axiosIntercepterResponseError);
  }

  private axiosIntercepterResponseError = async (err: AxiosCustomError<APIError>) => {
    const { config: original, response } = err;
    const { status = NaN, data } = response || {};
    if (original._retry || !response || status !== 401 || /^AccountId/.test(data?.message ?? '')) {
      return err;
    }

    const isRefreshUrl = original.url && /refreshToken/.test(original.url);
    if (isRefreshUrl || !hasToken(original.headers)) {
      this.events.failed.run();
      return err;
    }

    const token = await this.refreshToken();
    const requestHeaders = { ...original.headers, Authorization: token };
    const request = { ...original, headers: requestHeaders, _retry: true };
    return await this.apiInstance(request);
  };

  private apiRefreshToken = async (retry = 0): Promise<string | undefined> => {
    if (!this.token) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append('token', this.token.replace(/^Bearer /, ''));

      const response = await this.apiInstance.post('/api/v1/accounts/refreshToken', formData);
      if (isAxiosError(response)) {
        throw response;
      }

      const { status, headers } = response;
      if (status !== 200) {
        throw response;
      }

      const token = headers.authorization;
      if (token) {
        this.events.success.run(token);
      }

      return token;
    } catch (err: unknown) {
      if (retry > 2 || (isAxiosError(err) && err.response?.status === 401)) {
        this.events.failed.run();
        throw err;
      }

      return await this.apiRefreshToken(retry + 1);
    }
  };

  public refreshToken = once(this.apiRefreshToken);
}

export default AuthorizationManager;
