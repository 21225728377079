import { ButtonBase } from 'src/components/atoms/styled/button';
import { Column, CSSRow, Row } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const FileDownloadModalWrap = styled(Column)`
  & > ${Row}.buttonWrap {
    & > ${ButtonBase} {
      padding: 4px 8px;

      background: ${getRGBA(colors.green50)};
      border-radius: 4px;

      color: ${getRGBA(colors.white)};

      &:disabled {
        background: ${getRGBA(colors.grey50)};
      }
    }
  }

  & > ${Column} {
    padding: 12px;
    width: max-content;

    font-size: 15px;
    & > ${Row}.titleWrap {
      border-bottom: 1px solid ${getRGBA(colors.grey80)};

      & > span {
        padding-left: 4px;
      }
      & > span:nth-child(1) {
        min-width: 30px;
        width: 30px;
      }
      & > span:nth-child(2) {
        min-width: 80px;
        width: 80px;
      }
      & > span:nth-child(3) {
        min-width: 180px;
        width: 180px;
      }
      & > span:nth-child(4) {
        min-width: 300px;
        width: 300px;
      }
      & > span:nth-child(5) {
        width: 130px;
      }
      & > span:nth-child(6) {
        width: 80px;
      }
      & > span:nth-child(7) {
        width: 100px;
      }
      & > span:nth-child(8) {
        width: 100px;
      }
      & > span:nth-child(9) {
        width: 80px;
      }
    }

    & > ${Row}.itemWrap {
      span {
        white-space: pre-wrap;
        word-break: break-all;

        padding-left: 4px;
      }
      & > span:nth-child(1) {
        min-width: 30px;
        width: 30px;
      }
      & > span:nth-child(2) {
        min-width: 80px;
        width: 80px;
      }
      & > span:nth-child(3) {
        min-width: 180px;
        width: 180px;
      }
      & > ${Column} {
        & > ${Row} {
          margin: 6px 0;
          & > span:nth-child(1) {
            min-width: 30px;
            width: 30px;
          }
          & > span:nth-child(2) {
            ${CSSRow};

            width: 260px;

            & > span:nth-child(1) {
              width: 180px;
            }
            & > span:nth-child(2) {
              width: 40px;
            }
            & > span:nth-child(3) {
              width: 40px;
            }
          }
          & > span:nth-child(3) {
            width: 130px;

            & > ${ButtonBase}.editorLink {
              margin-right: 8px;

              color: #0b5fff;
              text-decoration: underline;
            }

            & > ${ButtonBase}.editor {
              padding: 0 5px;

              border-radius: 12px;
              background-color: #0b5fff;

              color: white;

              :disabled {
                background-color: ${getRGBA(colors.grey80)};
              }
            }
          }
          & > span:nth-child(4) {
            width: 80px;
          }
          & > span:nth-child(5) {
            width: 100px;
          }
          & > span:nth-child(6) {
            width: 100px;
          }
          & > span:nth-child(7) {
            width: 80px;

            text-align: center;
          }
        }
      }
    }

    & > ${Column} {
      padding: 12px;
    }

    ${Row}.files {
      margin-left: 85px;

      & > span:first-child {
        flex: 1;
      }

      & > span {
        margin-right: 25px;
      }
    }
  }
`;
