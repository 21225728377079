import { gql } from '@apollo/client';

export const GQL_JOB_STATUS = gql`
  query JobStatus($ids: [ID!]) {
    jobs(ids: $ids) {
      id
      name
      status
      scheduledDeadlineDateTime

      manager {
        name
      }
    }
  }
`;
