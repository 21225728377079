import { useQuery } from '@apollo/client';
import React from 'react';
import errorLogger from 'src/api/errorLogger';
import StatusLabel from 'src/components/atoms/StatusLabel';
import { Row } from 'src/components/atoms/styled/layout';
import { EntityHistoryAPIResponse, EntityHistoryState } from 'src/types/api/EntityHistory';
import { dateFormat } from 'src/utils/date';
import { EntityHistoryEnum, getTargetSet } from 'src/utils/entityHistory';
import { Status, TaskCode } from 'src/utils/translate';
import { GQL_ENTITY_ITEM } from './gql';
import { HistoryQuery, HistoryQueryVariables } from './gql.generated';

interface Props {
  item: EntityHistoryAPIResponse;
}

function EntityHistoryItem(props: Props) {
  const { createDateTime, entityType, variable, accountId, fromValue, toValue, state, id, publishAccountId, entity } =
    props.item;

  const { targetId, targetType } = getTargetSet(entityType, entity);

  const { data, loading } = useQuery<HistoryQuery, HistoryQueryVariables>(GQL_ENTITY_ITEM, {
    variables: {
      ...Object.fromEntries([[(targetType ?? '').toLocaleLowerCase(), true]]),
      entityId: targetId?.toString() || '',
      isAccount: Boolean(accountId),
      accountId: accountId?.toString() || '',
      publisherId: publishAccountId?.toString() || '',
    },
    onError: errorLogger.error,
  });

  const { project, job, jobResult, company, publisherAccount } = React.useMemo(() => {
    return {
      project: data?.project,
      job: data?.job,
      jobResult: data?.jobResult,
      company: data?.company,
      publisherAccount: data?.publisherAccount,
    };
  }, [data]);

  const workStatus = React.useMemo(() => {
    switch (targetType) {
      case 'project':
        return project?.status;
      case 'job':
        return job?.status;
      case 'job_result':
        return jobResult?.status;
      default:
        return '';
    }
  }, [targetType, project?.status, job?.status, jobResult?.status]);

  const getDetailMSGContent = React.useCallback(
    (state: EntityHistoryState, fromValue?: string, toValue?: string) => {
      if (state === 'CREATE') {
        return EntityHistoryEnum[entityType] + ' 추가됨.';
      }

      if (state === 'UPDATE') {
        return fromValue + ' → ' + toValue + ' 변경됨.';
      }

      if (state === 'DELETE') {
        return EntityHistoryEnum[entityType] + ' 삭제됨.';
      }

      return '-';
    },
    [entityType]
  );

  const [detailMSGtitle, detailMSGContent] = React.useMemo(() => {
    const detailMSGContent = getDetailMSGContent(state, fromValue, toValue);

    if (variable === 'status' && fromValue && toValue) {
      return ['진행상태', getDetailMSGContent(state, Status[fromValue], Status[toValue])];
    }
    if (variable === 'name') {
      return [EntityHistoryEnum[entityType] + '이름', detailMSGContent];
    }
    if (variable === 'managerId') {
      return ['담당PM', detailMSGContent];
    }
    if (variable === 'requestedStartDateTime') {
      const dateFromValue = fromValue && fromValue !== 'null' ? dateFormat(fromValue, 'YYYY.MM.DD') : fromValue;
      const dateToValue = toValue && toValue !== 'null' ? dateFormat(toValue, 'YYYY.MM.DD') : toValue;
      return ['희망작업시작일', getDetailMSGContent(state, dateFromValue, dateToValue)];
    }
    if (variable === 'requestedDeadlineDateTime') {
      const dateFromValue = fromValue && fromValue !== 'null' ? dateFormat(fromValue, 'YYYY.MM.DD') : fromValue;
      const dateToValue = toValue && toValue !== 'null' ? dateFormat(toValue, 'YYYY.MM.DD') : toValue;

      return ['희망작업종료일', getDetailMSGContent(state, dateFromValue, dateToValue)];
    }
    if (variable === 'description') {
      return ['특이사항', detailMSGContent];
    }

    if (variable === 'workSize') {
      return ['콘텐츠 작업분량', detailMSGContent];
    }

    if (entityType === 'PROJECT_FILE' || entityType === 'JOB_FILE' || entityType === 'JOB_RESULT_FILE') {
      return ['첨부파일', detailMSGContent];
    }
    if (entityType === 'PROJECT_RELATE_LINK' || entityType === 'JOB_RELATE_LINK') {
      return ['관련링크', detailMSGContent];
    }

    if (entityType === 'ACCOUNT') {
      return ['계정정보', detailMSGContent];
    }

    if (entityType === 'PRODUCT_ORDER') {
      return ['작업정보', detailMSGContent];
    }

    if (entityType === 'PRODUCT_OPTION_ORDER') {
      return ['작업 부가정보', detailMSGContent];
    }

    return [EntityHistoryEnum[entityType], detailMSGContent];
  }, [getDetailMSGContent, state, fromValue, toValue, variable, entityType]);

  const workType = React.useMemo(() => {
    switch (entityType) {
      case 'PROJECT':
      case 'PROJECT_FILE':
      case 'PROJECT_RELATE_LINK':
      case 'PRODUCT_ORDER':
      case 'PRODUCT_OPTION_ORDER':
        return '프로젝트';
      case 'JOB':
      case 'JOB_FILE':
      case 'JOB_RELATE_LINK':
        return '콘텐츠';
      case 'JOB_RESULT':
      case 'JOB_RESULT_FILE':
        return '작업 결과물';

      default:
        return '-';
    }
  }, [entityType]);

  if (loading) return <div>loading</div>;

  return (
    <Row>
      <span>{id}</span>
      <span>{dateFormat(createDateTime, 'YYYY.MM.DD hh:mm:ss', { isLocale: true })}</span>
      <span>{workType}</span>
      <span>
        {targetType?.toUpperCase() && TaskCode[targetType?.toUpperCase()]}-{targetId}
      </span>
      <span>
        <StatusLabel status={workStatus ?? ''} />
      </span>
      <span>{company?.company?.name}</span>
      <span>
        [{detailMSGtitle}] : {detailMSGContent}
      </span>
      <span>{publisherAccount?.name || 'SYSTEM'}</span>
    </Row>
  );
}

export default EntityHistoryItem;
