import { useQuery } from '@apollo/client';
import React from 'react';
import errorLogger from 'src/api/errorLogger';
import ICN_DOWNLOAD from 'src/assets/icons/icn-download-24px.svg';
import IssuedStatusBadge from 'src/components/atoms/IssuedStatusBadge';
import StatusLabel from 'src/components/atoms/StatusLabel';
import { ButtonBase } from 'src/components/atoms/styled/button';
import { CheckBox } from 'src/components/atoms/styled/form';
import { Row } from 'src/components/atoms/styled/layout';
import { dateFormat } from 'src/utils/date';
import { downloadAPIFile } from 'src/utils/files';
import {
  ContentType,
  WorkRange,
  Source_Language,
  Translate_Language,
  ResultType,
  AdditionalServices,
} from 'src/utils/translate';
import styled from 'styled-components';
import { GET_JOB_RESULTS } from './gql';
import { GetJobResultsQuery, GetJobResultsQueryVariables } from './gql.generated';
import { JobResultTaskWrapper } from './styled';

interface Props {
  jobResultIds: { id: number }[];
  onChange?(id: number): void;
  className?: string;
}

function JobResultTaskList(props: Props) {
  const { jobResultIds, onChange, className } = props;

  const ids = React.useMemo(() => jobResultIds.map((it) => it.id.toString()), [jobResultIds]);
  const { data, loading, error } = useQuery<GetJobResultsQuery, GetJobResultsQueryVariables>(GET_JOB_RESULTS, {
    variables: {
      ids,
    },
    onError: errorLogger.error,
  });

  const fileDownloads = React.useCallback((files: Array<{ id: string; name: string }>) => {
    if (files.length === 0) return;
    for (const item of files) {
      downloadAPIFile(item);
    }
  }, []);

  const jobResults = React.useMemo(
    () =>
      Array.from(data?.jobResults ?? []).sort(
        (a, b) => ids.findIndex((id) => id === a!!.id) - ids.findIndex((id) => id === b!!.id)
      ),
    [data, ids]
  );

  if (loading) return <div>loading</div>;
  if (error) return <div>error</div>;

  if (data == null) return <div>error</div>;
  if (data.jobResults == null) return <div>error</div>;

  return (
    <JobResultTaskWrapper className={className}>
      <Row>
        <span></span>
        <span>결과물 ID</span>
        <span>콘텐츠 타입</span>
        <span>프로젝트 명</span>
        <span>콘텐츠 명</span>
        <span>작업범위</span>
        <span>원본언어</span>
        <span>번역언어</span>
        <span>납품형태</span>
        <span>작업대상</span>
        <span>부가서비스</span>
        <span>진행상태</span>
        <span>고객회사명</span>
        <span>고객담당자</span>
        <span>최초생성일</span>
        <span>납품예정일</span>
        <span>납품완료일</span>
        <span>납품내역서 발급상태</span>
        <span>담당PM</span>
        <span>다운로드</span>
      </Row>
      {jobResults.map((it) => {
        const files =
          it.jobResultFiles
            ?.filter((jf) => jf != null && jf.file != null)
            ?.map((jf) => {
              const jobFile = jf!!;
              return { id: jobFile.file!!.id, name: jobFile.file!!.name || '' };
            }) || [];

        return (
          <Row key={it.id}>
            <span>{onChange && <CheckBox onChange={() => onChange(Number(it.id))} />}</span>
            <span>JR-{it.id}</span>
            <span>{it.job.category && ContentType[it.job.category]}</span>
            <span>{it.project?.name}</span>
            <span>{it.job.name}</span>
            <span>{it.productOrder?.productType && WorkRange[it.productOrder.productType]}</span>
            <span>{it.productOrder?.sourceLanguage && Source_Language[it.productOrder.sourceLanguage]}</span>
            <span>{it.productOrder?.translateLanguage && Translate_Language[it.productOrder?.translateLanguage]}</span>
            <span>{it.productOrder?.deliveryType && ResultType[it.productOrder.deliveryType]}</span>
            <span>-</span>
            <span>
              {it.productOrder?.productOptionOrders &&
                it.productOrder.productOptionOrders[0].type &&
                AdditionalServices[it.productOrder?.productOptionOrders[0].type]}
            </span>
            <span>
              <StatusLabel status={it.status} />
            </span>
            <span>{it.project?.account?.company?.name}</span>
            <span>{it.project?.manager?.name}</span>
            <span>{dateFormat(it.createDateTime, 'YYYY.MM.DD')}</span>
            <span>
              {it.job.scheduledDeadlineDateTime && dateFormat(it.job.scheduledDeadlineDateTime, 'YYYY.MM.DD')}
            </span>
            <span>{it.job.doneDateTime && dateFormat(it.job.doneDateTime, 'YYYY.MM.DD')}</span>
            <span>
              <IssuedStatusBadge status={it.documents?.[it.documents?.length - 1]?.status || ''} />
            </span>
            <span>{it.project?.manager?.name}</span>
            <span>
              <ButtonBase disabled={files.length === 0 ?? true} onClick={() => fileDownloads(files)}>
                <img src={ICN_DOWNLOAD} alt={'download'} />
              </ButtonBase>
            </span>
          </Row>
        );
      })}
    </JobResultTaskWrapper>
  );
}

export default styled(JobResultTaskList)``;
