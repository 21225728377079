import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import React from 'react';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import { CheckBox } from 'src/components/atoms/styled/form';
import AppPagination from 'src/components/molecules/AppPagination';
import { ProjectResponse } from 'src/types/api/Project';

interface IProps {
  accountId: number;
  callback: () => void;
}

function ProjectModal(props: IProps) {
  const { accountId, callback } = props;

  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const [projects, setProject] = React.useState<ProjectResponse[]>();

  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalPage, setTotalPage] = React.useState(0);

  const getProjectByPage = React.useCallback(async () => {
    await api.project
      .pages({ size: 20, page: currentPage })
      .then((res) => {
        const { data } = res;
        setProject(data.element);
        setTotalPage(data.totalPage);
      })
      .catch((error) => errorLogger.error(error));
  }, [currentPage]);

  React.useEffect(() => {
    getProjectByPage();
  }, [currentPage, getProjectByPage, totalPage]);

  const [visible, setVisible] = React.useState(false);

  const [projectChecks] = React.useState<Map<number, boolean>>(() => new Map());

  const addProjectPermission = React.useCallback(async () => {
    const projectIds: number[] = [];
    for (const item of projectChecks.entries()) {
      const [id, bool] = item;
      if (bool) {
        projectIds.push(id);
      }
    }
    await api.account
      .item(accountId)
      .postPermission({ projectIds })
      .then(() => {
        callback();
        setVisible(false);
      })
      .catch((error) => errorLogger.error(error));
  }, [accountId, projectChecks, callback]);

  const handleProjectChecked = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
      projectChecks.set(id, e.target.checked);
      forceUpdate();
    },
    [projectChecks]
  );

  return (
    <div className="align-items-center d-flex">
      <CButton style={{ width: 'max-content' }} onClick={() => setVisible(!visible)}>
        프로젝트 권한 추가하기
      </CButton>
      <CModal size={'lg'} visible={visible} onDismiss={() => setVisible(false)}>
        <CModalHeader onDismiss={() => setVisible(false)}>
          <CModalTitle>프로젝트 권한 추가하기</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CTable bordered style={{ width: 'max-content', minWidth: '100%' }}>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell>-</CTableHeaderCell>
                <CTableHeaderCell>Project Id</CTableHeaderCell>
                <CTableHeaderCell>이름</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {projects?.map((project, idx) => (
                <CTableRow key={idx}>
                  <CTableDataCell>
                    <CheckBox
                      key={project.id}
                      onChange={(e) => handleProjectChecked(e, project.id)}
                      checked={projectChecks.get(project.id)}
                    />
                  </CTableDataCell>
                  <CTableDataCell>{`P-${project.id}`}</CTableDataCell>
                  <CTableDataCell>{project.name}</CTableDataCell>
                </CTableRow>
              ))}
            </CTableBody>
          </CTable>
          <AppPagination currentPage={currentPage} maxPage={totalPage} setCurrentPage={setCurrentPage} />
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            취소
          </CButton>
          <CButton onClick={() => addProjectPermission()}>확인</CButton>
        </CModalFooter>
      </CModal>
    </div>
  );
}
export default ProjectModal;
