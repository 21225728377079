import React from 'react';
import errorLogger from 'src/api/errorLogger';
import { GoogleFile } from 'src/types/api/File';
import JobRegisterService from 'src/utils/JobRegisterService';

interface ContextValues {
  service: JobRegisterService;
  googleFiles: Map<string, GoogleFile[]>;
}
const ContentsUploaderContext = React.createContext<ContextValues | undefined>(undefined);

interface Props {
  children?: React.ReactNode;
}
export function ContentsUploaderContextProvider(props: Props) {
  const { children } = props;

  const service = React.useMemo<JobRegisterService>(() => new JobRegisterService(), []);

  const [googleFiles] = React.useState<Map<string, GoogleFile[]>>(() => new Map());

  React.useEffect(() => {
    const remove = [service.addEventListener('error', (_, error) => errorLogger.error(error))];

    return () => {
      remove.forEach((it) => it());
    };
  }, [service]);

  return (
    <ContentsUploaderContext.Provider
      value={{
        service,
        googleFiles,
      }}
    >
      {children}
    </ContentsUploaderContext.Provider>
  );
}

export function useContentsUploaderService() {
  const context = React.useContext(ContentsUploaderContext);
  if (!context) {
    throw new Error('useAuthContext: Use it after Provider');
  }

  return context.service;
}

export function useGoogleFiles() {
  const context = React.useContext(ContentsUploaderContext);
  if (!context) {
    throw new Error('useAuthContext: Use it after Provider');
  }

  return context.googleFiles;
}
