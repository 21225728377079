import React from 'react';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import IssuedStatusBadge from 'src/components/atoms/IssuedStatusBadge';
import { ButtonBase } from 'src/components/atoms/styled/button';
import { Title } from 'src/components/atoms/styled/element';
import { Column, Row } from 'src/components/atoms/styled/layout';
import AppPagination from 'src/components/molecules/AppPagination';
import { CompanyResponse } from 'src/types/api/Company';
import { DocumentResponse, DocumentPageRequest } from 'src/types/api/Document';
import { UserResponse } from 'src/types/api/User';
import { isAxiosError } from 'src/utils/api/axios';
import { dateFormat } from 'src/utils/date';
import { getPreviewUrl, previewToWindow } from 'src/utils/preview';

import SearchFormOfSettlementList from './components/SearchFormOfSettlementList';
import { SettlementListWrapper } from './styled';
import { DocumentStatusType, SearchFormFilter } from './type';

function SettlementListPage() {
  const [filter, setFilter] = React.useState<SearchFormFilter>();
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [totalPage, setTotalPage] = React.useState<number>(0);

  const [issuerMap] = React.useState<Map<number, UserResponse>>(() => new Map());
  const [companyMap] = React.useState<Map<number, CompanyResponse>>(() => new Map());
  const [settlementList, setSettlementList] = React.useState<DocumentResponse[]>();

  const getDocumentList = React.useCallback(async () => {
    try {
      const companyId = filter?.company?.id;
      const companyManagerIds = filter?.companyManagerIds;
      const issuerIds = filter?.issuerIds;
      const createDateTimeFrom = filter?.createDateTimeFrom && new Date(filter.createDateTimeFrom).toISOString();
      const createDateTimeTo = filter?.createDateTimeTo && new Date(filter.createDateTimeTo).toISOString();
      const status = filter?.status && filter?.status?.length > 0 ? filter?.status : undefined;
      const params: DocumentPageRequest = {
        companyId,
        companyManagerIds,
        issuerIds,
        status,
        createDateTimeFrom,
        createDateTimeTo,
        page: currentPage,
        size: 20,
      };
      const documentRes = await api.document.pages(params);

      for (const item of documentRes.data.element) {
        if (item.issuerId) {
          const accountRes = await api.account.item(item.issuerId).get();

          if (isAxiosError(accountRes)) {
            throw accountRes;
          }

          issuerMap.set(item.id, accountRes.data);
        }
        if (item.companyId) {
          const companyRes = await api.companies.item(Number(item.companyId)).get();

          if (isAxiosError(companyRes)) {
            throw companyRes;
          }

          companyMap.set(item.id, companyRes.data);
        }
      }
      setTotalPage(documentRes.data.totalPage);
      setSettlementList(documentRes.data.element);
    } catch (err) {
      errorLogger.error(err);
    }
  }, [companyMap, currentPage, filter, issuerMap]);

  const [force, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const handleDocumentStatus = React.useCallback(
    async (id: number, status: DocumentStatusType) => {
      if (window.confirm('업데이트 하시겠습니까?')) {
        try {
          const res = await api.document.item(id).patch(status);
          if (isAxiosError(res)) {
            throw res;
          }
          getDocumentList();

          forceUpdate();
        } catch (error) {
          errorLogger.error(error);
        }
      }
    },
    [getDocumentList]
  );

  const handlePreviewDocument = React.useCallback(async (name: string, fileId: string) => {
    try {
      const res = await getPreviewUrl({ name: name, id: fileId });

      if (isAxiosError(res) || !res) {
        throw res;
      }

      await previewToWindow(res);
    } catch (error) {
      errorLogger.error(error);
    }
  }, []);

  React.useEffect(() => {
    getDocumentList();
  }, [currentPage, filter, getDocumentList]);

  return (
    <div>
      <SearchFormOfSettlementList setFilter={(data) => setFilter(data)} />
      <div style={{ overflow: 'auto' }}>
        <SettlementListWrapper key={force}>
          <Row className={'title'}>
            <Title>납품내역서 발급 목록</Title>
          </Row>
          <Column>
            <Row>
              <span></span>
              <span>발행 ID</span>
              <span>회사명</span>
              <span>항목명</span>
              <span>납품내역서 발행 상태</span>
              <span />
              <span>발행일</span>
              <span>발행자</span>
              <span>최초수정일</span>
            </Row>
            {settlementList
              ?.sort((prev, acc) => {
                return acc.id - prev.id;
              })
              .map((it) => (
                <Row key={it.id}>
                  <span></span>
                  <span>
                    <ButtonBase onClick={() => handlePreviewDocument('.pdf', it.fileId)}>FIN-{it.id}</ButtonBase>
                  </span>
                  <span>{companyMap.get(it.id)?.name}</span>
                  <span>{it.name}</span>
                  <span>
                    <IssuedStatusBadge status={it.status || ''} />
                  </span>
                  <span className={'buttonWrap'}>
                    <>
                      <ButtonBase
                        onClick={() => handleDocumentStatus(it.id, 'PROCESSED')}
                        disabled={it.status !== 'REGISTERED'}
                      >
                        정산완료
                      </ButtonBase>
                      <ButtonBase
                        onClick={() => handleDocumentStatus(it.id, 'CANCELLED')}
                        disabled={it.status !== 'REGISTERED'}
                      >
                        발행취소
                      </ButtonBase>
                    </>
                  </span>
                  <span>{it.createDateTime && dateFormat(it.createDateTime, 'YYYY.MM.DD')}</span>
                  <span>{issuerMap.get(it.id)?.name}</span>
                  <span>{it.updateDateTime && dateFormat(it.updateDateTime, 'YYYY.MM.DD')}</span>
                </Row>
              ))}
          </Column>
        </SettlementListWrapper>
      </div>
      <AppPagination currentPage={currentPage} maxPage={totalPage} setCurrentPage={setCurrentPage} />
    </div>
  );
}

export default SettlementListPage;
