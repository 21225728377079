import { Column, Row } from 'src/components/atoms/styled/layout';
import colors from 'src/theme/colors';
import { getRGBA } from 'src/utils/styles';
import styled from 'styled-components';

export const DashboardStatusRowWrapper = styled(Row)`
  align-items: center;
  padding: 10px 0;

  font-size: 14px;

  & > span {
    margin-right: 13px;
    width: 76px;

    text-align: center;
  }

  & > ${Column} {
    flex: 1;
    & > :nth-child(1) {
      font-size: 18px;
      color: ${getRGBA(colors.grey90)};
    }

    & > :nth-child(2) {
      color: ${getRGBA(colors.grey50)};
    }
  }
`;
