import React from 'react';

interface Props {
  contexts: React.ComponentType[];
  children?: React.ReactNode;
}

function ContextProviders(props: Props): React.ReactElement | null {
  const { contexts, children } = props;

  const reduce = React.useMemo(
    () => contexts.reduce((child, Context) => <Context>{child}</Context>, children),
    [children, contexts]
  );
  return <>{reduce}</>;
}

export default ContextProviders;
