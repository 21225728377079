import { useQuery } from '@apollo/client';
import {
  CButton,
  CContainer,
  CFormCheck,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import errorLogger from 'src/api/errorLogger';
import StatusLabel from 'src/components/atoms/StatusLabel';
import { ButtonBase } from 'src/components/atoms/styled/button';
import { Row } from 'src/components/atoms/styled/layout';
import { JobResultStatus, JobResultStatusUpdate } from 'src/types/api/JobResult';
import { dateFormat } from 'src/utils/date';
import {
  WorkRange,
  Source_Language,
  Translate_Language,
  ResultType,
  WorkTarget,
  AdditionalServices,
} from 'src/utils/translate';
import { GQL_JOBRESULT_STATUS } from './gql';
import { JobResultStatusQuery, JobResultStatusQueryVariables } from './gql.generated';

interface FormData {
  isStatus: boolean;
  status?: JobResultStatus;
  isRequestedReviewDeadlineDateTime: boolean;
  requestedReviewDeadlineDateTime?: string;
}

interface Props {
  jobResultIds: number[];
  onSubmitHandler: (data: JobResultStatusUpdate[]) => void;
}

const JOBRESULT_STATUSES: { label: string; value: JobResultStatus }[] = [
  { label: '승인대기', value: 'REQUESTED' },
  { label: '작업대기', value: 'REGISTERED' },
  { label: '작업 중', value: 'PROCESSING' },
  { label: '검수 대기', value: 'REVIEWABLE' },
  { label: '검수 승인', value: 'REVIEW_APPROVED' },
  { label: '검수 거절', value: 'REVIEW_REJECTED' },
  { label: '작업취소', value: 'DROP' },
  { label: '작업완료', value: 'DONE' },
];

function JobResultStatusUpdateModal(props: Props) {
  const { jobResultIds, onSubmitHandler } = props;

  const ids = React.useMemo(() => jobResultIds.map((it) => it.toString()), [jobResultIds]);

  const { loading, error, data } = useQuery<JobResultStatusQuery, JobResultStatusQueryVariables>(GQL_JOBRESULT_STATUS, {
    variables: { ids },
  });

  const jobResults = data?.jobResults;

  const { register, handleSubmit, getValues, watch, reset } = useForm<FormData>();

  const [visible, setVisible] = React.useState(false);

  const onSubmit = React.useCallback(
    async (form: FormData = getValues()) => {
      const { isStatus, isRequestedReviewDeadlineDateTime, status, requestedReviewDeadlineDateTime } = form;

      if (!isStatus && !isRequestedReviewDeadlineDateTime) {
        window.alert('변경할 데이터가 없습니다.');

        return setVisible(false);
      }

      if (!status && !requestedReviewDeadlineDateTime) {
        throw new TypeError("Don't have submitting data");
      }

      const data = jobResultIds.map((jobResultId) => {
        if (!jobResultId) {
          throw new TypeError("Don't have jobResult id");
        }

        const jobResultUpdate: JobResultStatusUpdate = {
          id: jobResultId,
        };

        if (status) {
          jobResultUpdate.status = status;
        }

        if (requestedReviewDeadlineDateTime) {
          jobResultUpdate.requestedReviewDeadlineDateTime =
            requestedReviewDeadlineDateTime &&
            new Date(requestedReviewDeadlineDateTime.replaceAll('.', '-')).toISOString();
        }

        return jobResultUpdate;
      });

      onSubmitHandler(data);
      reset({});
    },
    [getValues, jobResultIds, onSubmitHandler, reset]
  );

  if (loading) return <div>loading...</div>;
  if (error) {
    errorLogger.error(error);

    return (
      <div>
        <h2>{error.name}</h2>
        <h3>{error.message}</h3>
      </div>
    );
  }

  return (
    <>
      <ButtonBase onClick={() => setVisible(!visible)} disabled={!jobResultIds.length}>
        상태 업데이트
      </ButtonBase>
      <CModal size={'xl'} visible={visible} onDismiss={() => setVisible(false)}>
        <form onSubmit={handleSubmit((data: FormData) => onSubmit(data))}>
          <CModalHeader onDismiss={() => setVisible(false)}>
            <CModalTitle>작업결과물 상태 업데이트</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <span>
              총 {jobResultIds.length}
              개의 작업 결과물 상태를 변경합니다.
            </span>
            <CContainer style={{ height: '320px', overflow: 'auto' }}>
              <CTable bordered>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell>결과물 ID</CTableHeaderCell>
                    <CTableHeaderCell>콘텐츠 명</CTableHeaderCell>
                    <CTableHeaderCell>작업범위</CTableHeaderCell>
                    <CTableHeaderCell>원본언어</CTableHeaderCell>
                    <CTableHeaderCell>번역언어</CTableHeaderCell>
                    <CTableHeaderCell>납품형태</CTableHeaderCell>
                    <CTableHeaderCell>작업대상</CTableHeaderCell>
                    <CTableHeaderCell>부가서비스</CTableHeaderCell>
                    <CTableHeaderCell>진행상태</CTableHeaderCell>
                    <CTableHeaderCell>검수완료요청일</CTableHeaderCell>
                    <CTableHeaderCell>납품예정일</CTableHeaderCell>
                    <CTableHeaderCell>담당 PM</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {jobResults?.map((it, idx) => {
                    const { job, productOrder } = it;

                    return (
                      <CTableRow key={idx}>
                        <CTableDataCell>JR-{it.id}</CTableDataCell>
                        <CTableDataCell>{job.name}</CTableDataCell>
                        {productOrder && (
                          <>
                            <CTableDataCell>
                              {productOrder.productType && WorkRange[productOrder.productType]}
                            </CTableDataCell>
                            <CTableDataCell>
                              {productOrder.sourceLanguage && Source_Language[productOrder.sourceLanguage]}
                            </CTableDataCell>
                            <CTableDataCell>
                              {productOrder.translateLanguage && Translate_Language[productOrder.translateLanguage]}
                            </CTableDataCell>
                            <CTableDataCell>
                              {productOrder.deliveryType && ResultType[productOrder.deliveryType]}
                            </CTableDataCell>
                            <CTableDataCell>
                              {productOrder?.productSource && WorkTarget[productOrder.productSource]}
                            </CTableDataCell>
                            <CTableDataCell>
                              {productOrder.productOptionOrders &&
                                productOrder.productOptionOrders[0]?.type &&
                                AdditionalServices[productOrder.productOptionOrders[0].type]}
                            </CTableDataCell>
                          </>
                        )}

                        <CTableDataCell>
                          <StatusLabel status={it.status} />
                        </CTableDataCell>
                        <CTableDataCell>
                          {it.requestedReviewDeadlineDateTime &&
                            dateFormat(it.requestedReviewDeadlineDateTime, 'YYYY.MM.DD')}
                        </CTableDataCell>
                        <CTableDataCell>
                          {job.scheduledDeadlineDateTime && dateFormat(job.scheduledDeadlineDateTime, 'YYYY.MM.DD')}
                        </CTableDataCell>
                        <CTableDataCell>{job.manager?.name}</CTableDataCell>
                      </CTableRow>
                    );
                  })}
                </CTableBody>
              </CTable>
            </CContainer>
            <Row>
              <CFormCheck {...register('isStatus')} />
              진행상태
              <Row>
                {JOBRESULT_STATUSES.map((status, idx) => {
                  return (
                    <CFormCheck
                      key={idx}
                      type="radio"
                      value={status.value}
                      label={status.label}
                      {...register('status')}
                      disabled={!watch('isStatus')}
                    />
                  );
                })}
              </Row>
            </Row>
            <Row>
              <CFormCheck {...register('isRequestedReviewDeadlineDateTime')} />
              검수완료요청일
              <input
                type="date"
                {...register('requestedReviewDeadlineDateTime')}
                disabled={!watch('isRequestedReviewDeadlineDateTime')}
              />
            </Row>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => {
                setVisible(false);
              }}
            >
              취소
            </CButton>
            <CButton
              type={'submit'}
              color={'primary'}
              onClick={() => {
                setVisible(false);
              }}
            >
              확인
            </CButton>
          </CModalFooter>
        </form>
      </CModal>
    </>
  );
}

export default JobResultStatusUpdateModal;
