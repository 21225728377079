import { cilChartPie, cilDrop, cilList, cilSpeedometer } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CNavGroup, CNavItem, CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { logoNegative } from 'src/assets/brand/logo-negative';
import { sygnet } from 'src/assets/brand/sygnet';
import AppPaths from 'src/constants/AppPaths';

interface Props {
  isSidebarShow: boolean;
  onSidebarShow: React.Dispatch<React.SetStateAction<boolean>>;
}

function AppSidebar(props: Props) {
  const { isSidebarShow, onSidebarShow } = props;

  const [isFold, setFold] = React.useState(false);

  return (
    <CSidebar
      position="fixed"
      selfHiding="md"
      unfoldable={isFold}
      visible={isSidebarShow}
      onHide={() => {
        onSidebarShow(false);
      }}
    >
      <CSidebarBrand className="d-none d-md-flex">
        <CIcon className="sidebar-brand-full" icon={logoNegative} height={35} />
        <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} />
      </CSidebarBrand>
      <CSidebarNav>
        <CNavItem to={AppPaths.dashboard.path} {...{ component: NavLink, activeClassName: 'active' }}>
          <CIcon icon={cilSpeedometer} customClassName="nav-icon" />
          대시보드
        </CNavItem>
        <CNavItem to={AppPaths.projects.path} {...{ component: NavLink, activeClassName: 'active' }}>
          <CIcon icon={cilDrop} customClassName="nav-icon" />
          작업관리
        </CNavItem>
        <CNavGroup
          idx={'0'}
          toggler={
            <>
              <CIcon icon={cilList} customClassName="nav-icon" />
              계정관리
            </>
          }
        >
          <CNavItem to={AppPaths.clientAccounts.path} {...{ component: NavLink, activeClassName: 'active' }}>
            클라이언트 계정
          </CNavItem>
          <CNavItem to={AppPaths.adminAccounts.path} {...{ component: NavLink, activeClassName: 'active' }}>
            어드민 계정
          </CNavItem>
          <CNavItem to={AppPaths.accountSettingMe.path} {...{ component: NavLink, activeClassName: 'active' }}>
            설정
          </CNavItem>
        </CNavGroup>
        <CNavGroup
          idx={'1'}
          toggler={
            <>
              <CIcon icon={cilList} customClassName="nav-icon" />
              정산관리
            </>
          }
        >
          <CNavItem to={AppPaths.settlementManagement.path} {...{ component: NavLink, activeClassName: 'active' }}>
            <CIcon icon={cilChartPie} customClassName="nav-icon" />
            납품내역서 발행
          </CNavItem>
          <CNavItem to={AppPaths.settlementList.path} {...{ component: NavLink, activeClassName: 'active' }}>
            납품내역서 발급목록
          </CNavItem>
        </CNavGroup>
        <CNavGroup
          idx={'2'}
          toggler={
            <>
              <CIcon icon={cilList} customClassName="nav-icon" />
              상품관리
            </>
          }
        >
          <CNavItem to={AppPaths.products.path} {...{ component: NavLink, activeClassName: 'active' }}>
            기본상품 관리
          </CNavItem>
          <CNavItem to={'/productOptions'} {...{ component: NavLink, activeClassName: 'active' }}>
            부가상품 관리
          </CNavItem>
        </CNavGroup>
        <CNavGroup
          idx={'3'}
          toggler={
            <>
              <CIcon icon={cilList} customClassName="nav-icon" />
              이력 및 알림 조회
            </>
          }
        >
          <CNavItem to={AppPaths.histories.path} {...{ component: NavLink, activeClassName: 'active' }}>
            이력 조회
          </CNavItem>
          <CNavItem to={AppPaths.notifications.path} {...{ component: NavLink, activeClassName: 'active' }}>
            알림 조회
          </CNavItem>
        </CNavGroup>
      </CSidebarNav>
      <CSidebarToggler className="d-none d-lg-flex" onClick={() => setFold((prev) => !prev)} />
    </CSidebar>
  );
}

export default React.memo(AppSidebar);
