import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CFormInput,
  CContainer,
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import colors from 'src/theme/colors';
import { Product } from 'src/types/api/Product';
import { isAxiosError } from 'src/utils/api/axios';
import { getRGBA } from 'src/utils/styles';
import {
  ContentType,
  WorkRange,
  Source_Language,
  Translate_Language,
  ResultType,
  WorkTarget,
  DifficultyType,
  ExpectedQualityType,
  VolumeType,
} from 'src/utils/translate';
import ChangeProductAlertModal from '../ChangeProductAlertModal';

interface ChangePriceInfo {
  price: number;
}

interface IProps {
  productData: Product;
}

function ChangeProductModal(props: IProps) {
  const { productData } = props;

  const { register, handleSubmit, getValues } = useForm<ChangePriceInfo>();
  const [visible, setVisible] = React.useState(false);

  const onSubmit = React.useCallback(
    async (data: ChangePriceInfo = getValues()) => {
      const { price } = data;
      try {
        if (!productData?.id || isNaN(Number(productData?.id))) {
          throw new Error("change product price: id cant't use");
        }
        const res = await api.product.item(productData?.id).put({ ...productData, price: price });
        if (isAxiosError(res)) {
          throw res;
        }

        setVisible(false);
      } catch (error) {
        errorLogger.error(error);
      }
    },
    [getValues, productData]
  );

  return (
    <form onSubmit={handleSubmit((data: ChangePriceInfo) => onSubmit(data))}>
      <div className="align-items-center d-flex">
        <CButton style={{ width: 'max-content' }} onClick={() => setVisible(!visible)}>
          수정
        </CButton>
        <CModal size={'xl'} visible={visible} onDismiss={() => setVisible(false)}>
          <CModalHeader onDismiss={() => setVisible(false)}>
            <CModalTitle>단가 수정</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CContainer>
              <CTable bordered>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell>상품 ID</CTableHeaderCell>
                    <CTableHeaderCell>콘텐츠 타입</CTableHeaderCell>
                    <CTableHeaderCell>작업범위</CTableHeaderCell>
                    <CTableHeaderCell>원본언어</CTableHeaderCell>
                    <CTableHeaderCell>번역언어</CTableHeaderCell>
                    <CTableHeaderCell>납품형태</CTableHeaderCell>
                    <CTableHeaderCell>작업대상</CTableHeaderCell>
                    <CTableHeaderCell>난이도</CTableHeaderCell>
                    <CTableHeaderCell>납품퀄리티</CTableHeaderCell>
                    <CTableHeaderCell>볼륨</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  <CTableRow>
                    <CTableDataCell>{productData.id}</CTableDataCell>
                    <CTableDataCell>{ContentType[productData.requestCategory]}</CTableDataCell>
                    <CTableDataCell>{WorkRange[productData.productType]}</CTableDataCell>
                    <CTableDataCell>
                      {productData.sourceLanguage && Source_Language[productData.sourceLanguage]}
                    </CTableDataCell>
                    <CTableDataCell>
                      {productData.translateLanguage && Translate_Language[productData.translateLanguage]}
                    </CTableDataCell>
                    <CTableDataCell>{productData.deliveryType && ResultType[productData.deliveryType]}</CTableDataCell>
                    <CTableDataCell>
                      {productData.productSource && WorkTarget[productData.productSource]}
                    </CTableDataCell>
                    <CTableDataCell>{DifficultyType[productData.difficulty]}</CTableDataCell>
                    <CTableDataCell>{ExpectedQualityType[productData.expectedQuality]}</CTableDataCell>
                    <CTableDataCell>{VolumeType[productData.volume]}</CTableDataCell>
                  </CTableRow>
                </CTableBody>
              </CTable>
            </CContainer>
            <CTable bordered>
              <CTableRow>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell style={{ color: getRGBA(colors.red50) }}>기존단가</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  <CTableRow>
                    <CTableDataCell style={{ color: getRGBA(colors.red50) }}>{productData.price}</CTableDataCell>
                  </CTableRow>
                </CTableBody>
                <div>→</div>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell style={{ color: getRGBA(colors.blue50) }}>수정단가</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  <CTableRow>
                    <CTableDataCell>
                      <CFormInput style={{ color: getRGBA(colors.blue50) }} {...register('price')} />
                    </CTableDataCell>
                  </CTableRow>
                </CTableBody>
              </CTableRow>
            </CTable>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="secondary"
              onClick={() => {
                setVisible(false);
              }}
            >
              취소
            </CButton>
            <ChangeProductAlertModal onClick={() => onSubmit()} />
          </CModalFooter>
        </CModal>
      </div>
    </form>
  );
}

export default ChangeProductModal;
