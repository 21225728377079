import { CButton } from '@coreui/react';
import { cn, Parameter } from '@voithru/front-core';
import { nanoid } from 'nanoid';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import StatusLabel from 'src/components/atoms/StatusLabel';
import { ButtonBase } from 'src/components/atoms/styled/button';
import { Title } from 'src/components/atoms/styled/element';
import { Column, Row } from 'src/components/atoms/styled/layout';
import EntityHistoryTable from 'src/components/organisms/EntityHistoryTable';
import { ProjectResponse, ProjectStatus } from 'src/types/api/Project';
import { isAxiosError } from 'src/utils/api/axios';
import JobResultSummary from './components/JobResultSummary';
import JobSummary from './components/JobSummary';
import ProjectDescription from './components/ProjectDescription';
import ProjectDescriptionCollapse from './components/ProjectDescriptionCollapse';
import ProjectDetailInfo from './components/ProjectDetailInfo';
import { ProjectDetailWrapper } from './styled';
import StatusChangeModal from 'src/components/organisms/StatusChangeModal';
import { ContentType } from 'src/utils/translate';
import { ProjectDetailInfoContextProvider } from './context';
import AppPaths from 'src/constants/AppPaths';

interface Params {
  id: string;
}

type Tab = 'DETAIL' | 'RECORD';

const TRANSACTION_ID = nanoid();
function ProjectDetailPage() {
  const { id } = useParams<Params>();
  const projectId = parseInt(id);
  const history = useHistory();
  const [tab, setTab] = React.useState<Tab>('DETAIL');

  const [project, setProject] = React.useState<ProjectResponse>();
  const loadProject = React.useCallback(async (id: number) => {
    const res = await api.project.item(id).get();
    if (isAxiosError(res)) {
      throw res;
    }

    setProject(res.data);
    return res.data;
  }, []);

  React.useEffect(() => {
    if (isNaN(projectId)) {
      return;
    }

    loadProject(projectId).catch(errorLogger.error);
  }, [loadProject, projectId]);

  const [isStatusModalVisible, setStatusModalVisible] = React.useState(false);
  const handleStatusChangeSubmit = React.useCallback(
    async (status: ProjectStatus) => {
      const res = await api.project.item(projectId).status(status);
      if (isAxiosError(res)) {
        throw res;
      }

      loadProject(projectId).catch(errorLogger.error);
      setStatusModalVisible(false);
    },
    [loadProject, projectId]
  );

  const handleClickWorkerRelay = React.useCallback(() => {
    if (!project) {
      return;
    }
    const search = Parameter.toURLSearchParams({ project: project.id }).toString();

    history.push({
      pathname: AppPaths.workerRelay.path,
      search,
    });
  }, [history, project]);

  if (isNaN(projectId) || !project) {
    return null;
  }

  return (
    <ProjectDetailWrapper>
      <ProjectDetailInfoContextProvider>
        <Row title={project?.name}>
          <ButtonBase onClick={() => setStatusModalVisible(true)}>
            <StatusLabel status={project.status} />
          </ButtonBase>
          <Title>
            <span>[P-{project.id}]</span>
            <span>[{project.category && ContentType[project.category]}]</span>
            <span>{project.name}</span>
          </Title>
          <CButton color={'secondary'} onClick={handleClickWorkerRelay}>
            작업자 전달하기
          </CButton>
        </Row>
        <Row>
          <Column>
            <ProjectDescription
              project={project}
              onProjectChange={() => loadProject(projectId).catch(errorLogger.error)}
            />
            <ProjectDescriptionCollapse projectId={projectId} transactionId={TRANSACTION_ID} />
          </Column>
          <JobSummary project={project} />
          <JobResultSummary project={project} />
        </Row>
        <div>
          <button className={cn(tab === 'DETAIL' && 'active')} onClick={() => setTab('DETAIL')}>
            프로젝트 상세정보
          </button>
          <button className={cn(tab === 'RECORD' && 'active')} onClick={() => setTab('RECORD')}>
            프로젝트 이력정보
          </button>
        </div>
        {tab === 'DETAIL' && (
          <ProjectDetailInfo
            project={project}
            projectId={projectId}
            onProjectChange={() => loadProject(projectId).catch(errorLogger.error)}
            transactionId={TRANSACTION_ID}
          />
        )}
        {tab === 'RECORD' && <EntityHistoryTable params={{ topLevelEntityId: projectId }} />}
      </ProjectDetailInfoContextProvider>

      <StatusChangeModal
        visible={isStatusModalVisible}
        onDismiss={() => setStatusModalVisible(false)}
        onSubmit={handleStatusChangeSubmit}
        status={project.status}
      />
    </ProjectDetailWrapper>
  );
}

export default ProjectDetailPage;
