import { Eventer, EventerRemoveHandler, once } from '@voithru/front-core';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import api from 'src/api';
import {
  APIFileCompleteMultipartUploadRequest,
  APIFileMultipartUploadParams,
  APIFileMultiPartUploadResponse,
  APIFilePartHeaderValueResponse,
  APIFileRequest,
} from 'src/types/api/File';
import { isAxiosError } from 'src/utils/api/axios';
import { NamedFile } from './files';
import ManualPromise from './ManualPromise';
import { ByteSize, sha256Checksum } from './string';

type VoidHandler = () => void;
type ProgressEventHandler = (e: ProgressEvent) => void;

interface MultipartUploadAddEventListener {
  (type: 'progress', fn: ProgressEventHandler): EventerRemoveHandler;

  (type: 'start', fn: VoidHandler): EventerRemoveHandler;

  (type: 'cancel', fn: VoidHandler): EventerRemoveHandler;

  (type: 'done', fn: VoidHandler): EventerRemoveHandler;
}

class MultipartUploadEventer {
  public start = new Eventer<VoidHandler>();
  public cancel = new Eventer<VoidHandler>();
  public done = new Eventer<VoidHandler>();
  public progress = new Eventer<ProgressEventHandler>();

  constructor() {
    this.addEventListener = this.addEventListener.bind(this);
  }

  public addEventListener: MultipartUploadAddEventListener = (type, fn) => {
    switch (type) {
      case 'start':
        return this.start.addEventListener(fn as VoidHandler);
      case 'cancel':
        return this.cancel.addEventListener(fn as VoidHandler);
      case 'done':
        return this.done.addEventListener(fn as VoidHandler);
      case 'progress':
        return this.progress.addEventListener(fn);
    }
  };
}

type MultipartUploadStatus = 'INIT' | 'READY' | 'START' | 'CANCEL' | 'DONE';

export interface MultipartUploadProgress {
  max: number;
  value: number;
}

export interface MultipartUploadOptions {
  chunkSize?: number;
}

const DEFAULT_FILE_SIZE = 50 * ByteSize.MEGA;

class MultipartUpload {
  private readonly options: Required<MultipartUploadOptions>;
  private readonly events = new MultipartUploadEventer();

  #readyManualPromise?: ManualPromise<void>;
  #startManualPromise?: ManualPromise<void>;

  public fileId?: string;

  private get partNumbers() {
    const count = Math.ceil(this.namedFile.file.size / this.options.chunkSize);
    return Array.from(Array(count).keys()).map((it) => it + 1);
  }

  private multipartData?: APIFileMultiPartUploadResponse;
  private readonly etags: string[] = [];

  private readonly cancelKeys = new Map<number, CancelTokenSource>();

  public status: MultipartUploadStatus = 'INIT';
  public progress: MultipartUploadProgress = { max: this.namedFile.file.size, value: NaN };

  /**
   * Constructor for {@link MultipartUpload}
   *
   * @param namedFile    File object for uploading to s3
   * @param options Options for {@link MultipartUploadOptions}
   */
  constructor(public readonly namedFile: NamedFile, options?: MultipartUploadOptions) {
    this.options = {
      chunkSize: options?.chunkSize || DEFAULT_FILE_SIZE,
    };

    this.start = once(this.start.bind(this));
    this.cancel = once(this.cancel.bind(this));
    this.onProgress = this.onProgress.bind(this);

    this.register = this.register.bind(this);
    this.getPartHeader = this.getPartHeader.bind(this);
    this.uploadToS3 = this.uploadToS3.bind(this);
    this.completeUpload = this.completeUpload.bind(this);
  }

  /**
   * EventListener for {@link MultipartUpload}
   *
   * @param type  `start` | `cancel` | `progress`
   * @param fn    `start` and `cancel` provide {@link VoidHandler}, `progress` need {@link ProgressEventHandler}
   */
  public readonly addEventListener = this.events.addEventListener;

  public ready = async (type: 'PROJECT' | 'JOB' | 'COMPANY', id?: number) => {
    if (this.#readyManualPromise) {
      return this.#readyManualPromise.promise;
    }

    const manualPromise = new ManualPromise<void>();
    this.#readyManualPromise = manualPromise;

    try {
      await this.getFileId(type, id);
      this.multipartData = await this.register();
      this.status = 'READY';
      manualPromise.resolve();
      this.#readyManualPromise = undefined;
    } catch (error) {
      manualPromise.reject(error);
      this.#readyManualPromise = undefined;
      console.error('MultipartUpload.ready:', error);
    }
    return manualPromise;
  };

  public startImpl = async () => {
    if (!['READY', 'CANCEL'].includes(this.status)) {
      throw new Error(`MultipartUpload: Not Ready status`);
    }

    this.status = 'START';
    this.events.start.run();
    this.etags.splice(0, this.etags.length);
    for (const partNumber of this.partNumbers) {
      try {
        const offset = this.options.chunkSize * (partNumber - 1); // partNumber is start at 1
        const blob = this.namedFile.file.slice(offset, offset + this.options.chunkSize, this.namedFile.file.type);
        const buff = await blob.arrayBuffer();
        const checksum = sha256Checksum(buff);

        const headerValues = await this.getPartHeader(partNumber, checksum);
        const res = await this.uploadToS3(partNumber, blob, headerValues);
        this.etags.push(res.headers.etag);
      } catch (error) {
        if (error instanceof Error) {
          console.log('MultipartUpload.start:', error);
          throw error;
        }

        throw new Error('MultipartUpload.start: Got Error');
      }
    }

    await this.completeUpload();
    this.status = 'DONE';
    this.events.done.run();
  };

  public start = async () => {
    if (this.#startManualPromise) {
      return this.#startManualPromise.promise;
    }

    const manualPromise = new ManualPromise<void>();
    this.#startManualPromise = manualPromise;
    this.startImpl()
      .then(() => manualPromise.resolve())
      .catch((error) => manualPromise.reject(error))
      .finally(() => (this.#startManualPromise = undefined));

    return manualPromise.promise;
  };

  public cancel() {
    this.status = 'CANCEL';
    for (const cancelToken of Array.from(this.cancelKeys.values())) {
      cancelToken.cancel('User Canceled');
    }

    this.events.cancel.run();
  }

  public onProgress(partNumber: number) {
    return function (this: MultipartUpload, e: ProgressEvent) {
      if (!e.lengthComputable) {
        return;
      }

      const nextValue = this.options.chunkSize * (partNumber - 1) + e.loaded;
      this.progress = { ...this.progress, value: nextValue };
      this.events.progress.run(e);
    }.bind(this);
  }

  /**
   * 1. Generate APIFile for project or job
   *
   * POST /files
   */
  private async getFileId(type: 'PROJECT' | 'JOB' | 'COMPANY', id?: number) {
    const data: Partial<APIFileRequest> = {
      name: this.namedFile.name,
      volume: this.namedFile.file.size,
      isDirectory: false,
      [`${type.toLowerCase()}Id`]: id,
    };

    const res = await api.file.post(data as APIFileRequest);
    if (isAxiosError(res) || res.status >= 400) {
      throw res;
    }

    return (this.fileId = res.data.id);
  }

  /**
   * 2. Register Multipart Upload
   *
   * POST /files/:id/multipartUpload
   */
  private async register() {
    if (!this.fileId) {
      return;
    }

    const checksum = sha256Checksum(await this.namedFile.file.arrayBuffer());
    const params: APIFileMultipartUploadParams = {
      fileName: this.namedFile.name,
      sha256Checksum: checksum,
    };
    if (this.namedFile.file.type) {
      params.contentType = this.namedFile.file.type;
    }
    const res = await api.file.item(this.fileId).multipartUpload(params);
    if (isAxiosError(res)) {
      throw res;
    }

    return res.data;
  }

  /**
   * 3. Get Part headers for upload
   *
   * POST /files/:id/uploadPartHeaderValue
   */
  private async getPartHeader(partNumber: number, checksum: string) {
    if (!this.multipartData || !this.fileId) {
      throw Error('MultipartUpload.getPartHeader: Invalid this.multipartData');
    }

    const res = await api.file
      .item(this.fileId)
      .uploadPartHeaderValue({ ...this.multipartData, partNumber, sha256Checksum: checksum });
    if (isAxiosError(res)) {
      throw res;
    }

    return res.data;
  }

  /**
   * 4. Upload to S3
   */
  private async uploadToS3(
    partNumber: number,
    file: File | Blob | ArrayBuffer,
    headerValues: APIFilePartHeaderValueResponse
  ) {
    if (!this.multipartData) {
      throw Error('MultipartUpload.uploadToS3: Invalid this.multipartData');
    }

    const partOptions = {
      bucket: headerValues.bucketName,
      key: this.multipartData.key,
      params: { partNumber, uploadId: this.multipartData.uploadId },
      headers: {
        Authorization: headerValues.authorization,
        'x-amz-date': headerValues.date,
        'x-amz-content-sha256': headerValues.sha256Checksum,
        'content-type': 'multipart/form' as const,
      },
    };

    const cancelToken = axios.CancelToken.source();
    this.cancelKeys.set(partNumber, cancelToken);
    const axiosOptions: AxiosRequestConfig = {
      cancelToken: cancelToken.token,
      onUploadProgress: this.onProgress(partNumber),
    };

    const res = await api.utils.s3.putPart(file, partOptions, axiosOptions).finally(() => {
      this.cancelKeys.delete(partNumber);
    });
    if (isAxiosError(res) || res.status !== 200) {
      throw res;
    }

    return res;
  }

  /**
   * 5. Complete MultipartUpload
   *
   * POST /files/:id/completeMultipartUpload
   */
  private async completeUpload() {
    if (!this.multipartData || !this.fileId) {
      throw Error('MultipartUpload.completeUpload: Invalid this.multipartData');
    }

    const params: APIFileCompleteMultipartUploadRequest = {
      key: this.multipartData.key,
      uploadId: this.multipartData.uploadId,
      partNumbers: this.partNumbers,
      etags: this.etags,
    };
    const res = await api.file.item(this.fileId).completeMultipartUpload(params);
    if (isAxiosError(res)) {
      throw res;
    }

    return res;
  }
}

export default MultipartUpload;
