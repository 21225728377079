import { AxiosInstance } from 'axios';
import { User } from 'src/types/api/User';

const SIGNUP_PATH = '/api/v1/signUp';

function apiSignUp(instance: AxiosInstance) {
  return {
    requestClientAccount: (data: User) => instance.post(SIGNUP_PATH + '/requestClientAccount', data),
    googleOpenId: (data: User) => instance.post(SIGNUP_PATH + '/google-openid', data),

    isExistEmail: (email: string) =>
      instance.post<boolean>(SIGNUP_PATH + '/isExistEmail', undefined, { params: { email } }),
  };
}

export default apiSignUp;
