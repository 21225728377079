import { cilBell } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CDropdownDivider, CDropdownHeader, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react';
import { once } from '@voithru/front-core';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { Column } from 'src/components/atoms/styled/layout';
import { useNotification } from 'src/components/context/NotificationContext';
import AppPaths from 'src/constants/AppPaths';
import { EntityHistoryNotificationAPIResponse } from 'src/types/api/EntityHistory';
import AlramItem from './components/AlramItem';
import { AlramDropDownWrapper } from './styled';

const PAGE_LOADING_DISTANCE = 1;
function AlramDropDown() {
  const { fetchRecentPage, tryRefresh, hasRecentUnreadNotification, hasAnyRecentNotification } = useNotification();

  const history = useHistory();
  const location = useLocation();

  const [recentAlrams, setRecentAlrams] = React.useState<EntityHistoryNotificationAPIResponse[]>();

  const listRef = React.useRef<HTMLDivElement>(null);

  const handleMore = React.useCallback(() => {
    if (location.pathname === AppPaths.notifications.path) {
      return;
    }

    history.push(AppPaths.notifications.path);
  }, [history, location.pathname]);

  const [currentPage, setCurrentPage] = React.useState<number>(-1);

  const tryLoadPage = React.useCallback(
    async (pageInView: number, forced?: true) => {
      pageInView = Math.max(1, pageInView);
      const loadedPagesReversed: EntityHistoryNotificationAPIResponse[][] = [];
      let pageOffset = PAGE_LOADING_DISTANCE;
      while (-PAGE_LOADING_DISTANCE <= pageOffset) {
        if (!forced && pageInView === currentPage) {
          return false;
        }

        const pageToLoad = pageInView + pageOffset;

        const notifications = await fetchRecentPage(pageToLoad);

        if (0 < pageInView && pageOffset === PAGE_LOADING_DISTANCE && notifications.length === 0) {
          pageInView--;
          continue;
        }
        loadedPagesReversed.push(notifications);
        pageOffset--;
      }
      const nextNotificationList = loadedPagesReversed.reverse().flat(1);

      setCurrentPage(pageInView);
      setRecentAlrams(nextNotificationList);
      return true;
    },
    [fetchRecentPage, currentPage]
  );

  const [tryLoadDynamicListRescheduled, setTryLoadDynamicListRescheduled] = React.useState<boolean>(false);
  const tryLoadDynamicListImpl = React.useCallback(async () => {
    if (!listRef.current) {
      return;
    }

    const { scrollHeight, clientHeight, scrollTop } = listRef.current;
    const scrollAvailableAbove = Math.abs(scrollTop);
    const scrollAvailableBelow = scrollHeight - clientHeight - Math.abs(scrollTop);

    if (scrollAvailableAbove < 10 && currentPage !== 1) {
      const resched = await tryLoadPage(currentPage - 1);
      setTryLoadDynamicListRescheduled(resched);
    } else if (scrollAvailableBelow < 10) {
      const resched = await tryLoadPage(currentPage + 1);
      setTryLoadDynamicListRescheduled(resched);
    }
  }, [listRef, currentPage, tryLoadPage]);

  const tryLoadDynamicList = React.useMemo(() => once(tryLoadDynamicListImpl), [tryLoadDynamicListImpl]);

  React.useEffect(() => {
    if (!tryLoadDynamicListRescheduled) {
      return;
    }
    setTryLoadDynamicListRescheduled(false);
    tryLoadDynamicList();
  }, [tryLoadDynamicListRescheduled, tryLoadDynamicList]);

  const handleOpen = React.useCallback(async () => {
    await tryRefresh();
    await tryLoadPage(1, true);
  }, [tryRefresh, tryLoadPage]);

  return (
    <AlramDropDownWrapper onClick={handleOpen} variant="nav-item">
      <CDropdownToggle caret={false}>
        <CIcon icon={cilBell} size="lg" />
        {hasRecentUnreadNotification() && <div className={'badge'} />}
      </CDropdownToggle>
      <CDropdownMenu style={{ right: '4px' }}>
        <Column onScroll={tryLoadDynamicList} ref={listRef}>
          {!hasAnyRecentNotification() && <CDropdownItem>최근 알림이 없습니다.</CDropdownItem>}
          {hasRecentUnreadNotification() &&
            recentAlrams?.map((it) => {
              return <AlramItem item={it} key={it.id} />;
            })}
        </Column>
        <CDropdownDivider />
        <CDropdownHeader className={'bg-light fw-semibold py-2 text-center'} onClick={handleMore}>
          더보기
        </CDropdownHeader>
      </CDropdownMenu>
    </AlramDropDownWrapper>
  );
}

export default AlramDropDown;
