import React from 'react';
import api from 'src/api';
import errorLogger from 'src/api/errorLogger';
import StatusLabel from 'src/components/atoms/StatusLabel';
import { Badge, Title } from 'src/components/atoms/styled/element';
import { Column, Row } from 'src/components/atoms/styled/layout';
import { JobsResponse } from 'src/types/api/Job';
import { JobResultResponse, JobResultStatus } from 'src/types/api/JobResult';
import { ProjectResponse } from 'src/types/api/Project';
import { isAxiosError } from 'src/utils/api/axios';
import { TimeUnit } from 'src/utils/date';
import styled from 'styled-components';
import { JobSummaryCountWrapper, JobSummaryWrapper } from './styled';

type JobResultCount = Record<JobResultStatus, number | undefined>;

interface CountProps {
  title: string;
  count: number;
  status?: JobResultStatus;

  className?: string;
  children?: React.ReactNode;
}
function CountColumn(props: CountProps) {
  const { title, count, status, className, children } = props;

  return (
    <JobSummaryCountWrapper className={className}>
      <Title>{title}</Title>
      {status === 'DROP' && <Badge color={'grey'}>{'작업취소'}</Badge>}
      {status && status !== 'DROP' && <StatusLabel status={status} />}
      <span className={'count'}>{Intl.NumberFormat().format(count || 0)}</span>
      {children}
    </JobSummaryCountWrapper>
  );
}

interface Props {
  project: ProjectResponse;

  className?: string;
}

const JOB_RESULT_STATUS: JobResultStatus[] = [
  'REQUESTED',
  'REGISTERED',
  'PROCESSING',
  'REVIEWABLE',
  'REVIEW_APPROVED',
  'REVIEW_REJECTED',
  'DONE',
  'DROP',
];
function JobResultSummary(props: Props) {
  const { project, className } = props;

  const jobs = React.useMemo(() => new Map<number, JobsResponse>(), []);
  const [, forceUpdate] = React.useReducer(() => ({}), {});
  const loadJobs = React.useCallback(
    async (jobId: number) => {
      const res = await api.jobs.item(jobId).get();
      if (isAxiosError(res)) {
        throw res;
      }

      jobs.set(jobId, res.data);
      forceUpdate();
      return res.data;
    },
    [jobs]
  );

  const [jobResults, setJobResults] = React.useState<JobResultResponse[]>();
  const loadJobResults = React.useCallback(async (projectId: number) => {
    const res = await api.project.item(projectId).jobResult.get({ status: JOB_RESULT_STATUS });
    if (isAxiosError(res)) {
      throw res;
    }

    setJobResults(res.data);
    return res.data;
  }, []);

  React.useEffect(() => {
    (async () => {
      const jobresults = await loadJobResults(project.id);
      const jobIds = jobresults.reduce((set, it) => {
        set.add(it.jobId);
        return set;
      }, new Set<number>());

      jobIds.forEach((it) => loadJobs(it).catch(errorLogger.error));
    })().catch(errorLogger.error);
  }, [loadJobResults, loadJobs, project.id]);

  const counts = React.useMemo(
    () =>
      jobResults?.reduce((counts: JobResultCount, it) => {
        counts[it.status] = (counts[it.status] ?? 0) + 1;
        return counts;
      }, {} as JobResultCount),
    [jobResults]
  );

  const leastCounts = React.useMemo(
    () =>
      jobResults
        ?.filter((it) => {
          const job = jobs.get(it.jobId);
          if (!job) {
            return false;
          }

          const scheduled = job.scheduledDeadlineDateTime
            ? new Date(job.scheduledDeadlineDateTime).getTime()
            : Number.POSITIVE_INFINITY;
          return scheduled > Date.now() - TimeUnit.DAY * 3 && scheduled < Date.now();
        })
        .reduce((counts: JobResultCount, it) => {
          counts[it.status] = (counts[it.status] ?? 0) + 1;
          return counts;
        }, {} as JobResultCount),
    [jobResults, jobs]
  );

  const delayCounts = React.useMemo(
    () =>
      jobResults
        ?.filter((it) => {
          const job = jobs.get(it.jobId);
          if (!job) {
            return false;
          }

          const scheduled = job.scheduledDeadlineDateTime
            ? new Date(job.scheduledDeadlineDateTime).getTime()
            : Number.POSITIVE_INFINITY;
          return scheduled >= Date.now();
        })
        .reduce((counts: JobResultCount, it) => {
          counts[it.status] = (counts[it.status] ?? 0) + 1;
          return counts;
        }, {} as JobResultCount),
    [jobResults, jobs]
  );

  return (
    <JobSummaryWrapper className={className}>
      <Row>
        <CountColumn className={'total'} title={'총 결과물'} count={jobResults?.length ?? NaN} />
        <Column className={'group'}>
          <CountColumn title={'신규작업의뢰'} count={(counts?.['REQUESTED'] ?? 0) + (counts?.['REGISTERED'] ?? 0)} />
          <Row>
            <CountColumn title={'승인 전'} status={'REQUESTED'} count={counts?.['REQUESTED'] ?? NaN} />
            <CountColumn title={'승인 후 착수 전'} status={'REGISTERED'} count={counts?.['REGISTERED'] ?? NaN} />
          </Row>
        </Column>
        <Column className={'group'}>
          <CountColumn title={'처리중 작업'} count={(counts?.['PROCESSING'] ?? 0) + (counts?.['REVIEWABLE'] ?? 0)} />
          <Row>
            <Column className={'description'}>
              <span>전체</span>
              <span className={'error'}>3일 이내</span>
              <span className={'error'}>일정 지연</span>
            </Column>
            <CountColumn title={'진행중 작업'} status={'PROCESSING'} count={counts?.['PROCESSING'] ?? NaN}>
              <span className={'error'}>
                {leastCounts?.PROCESSING && Intl.NumberFormat().format(leastCounts.PROCESSING)}
              </span>
              <span className={'error'}>
                {delayCounts?.PROCESSING && Intl.NumberFormat().format(delayCounts.PROCESSING)}
              </span>
            </CountColumn>
            <CountColumn title={'고객검수중 작업'} status={'REVIEWABLE'} count={counts?.['REVIEWABLE'] ?? NaN}>
              <span className={'error'}>
                {leastCounts?.REVIEWABLE && Intl.NumberFormat().format(leastCounts.REVIEWABLE)}
              </span>
              <span className={'error'}>
                {delayCounts?.REVIEWABLE && Intl.NumberFormat().format(delayCounts.REVIEWABLE)}
              </span>
            </CountColumn>
            <CountColumn title={''} status={'REVIEW_REJECTED'} count={counts?.['REVIEW_REJECTED'] ?? NaN}>
              <span className={'error'}>
                {leastCounts?.REVIEW_REJECTED && Intl.NumberFormat().format(leastCounts.REVIEW_REJECTED)}
              </span>
              <span className={'error'}>
                {delayCounts?.REVIEW_REJECTED && Intl.NumberFormat().format(delayCounts.REVIEW_REJECTED)}
              </span>
            </CountColumn>
            <CountColumn title={'검수완료'} status={'REVIEW_APPROVED'} count={counts?.['REVIEW_APPROVED'] ?? NaN}>
              <span className={'error'}>
                {leastCounts?.REVIEW_APPROVED && Intl.NumberFormat().format(leastCounts.REVIEW_APPROVED)}
              </span>
              <span className={'error'}>
                {delayCounts?.REVIEW_APPROVED && Intl.NumberFormat().format(delayCounts.REVIEW_APPROVED)}
              </span>
            </CountColumn>
          </Row>
        </Column>
        <Column className={'group'}>
          <CountColumn title={'종료된 작업'} count={(counts?.['DONE'] ?? 0) + (counts?.['DROP'] ?? 0)} />
          <Row>
            <CountColumn title={'작업 종료'} status={'DONE'} count={counts?.['DONE'] ?? NaN} />
            <CountColumn title={''} status={'DROP'} count={counts?.['DROP'] ?? NaN} />
          </Row>
        </Column>
      </Row>
    </JobSummaryWrapper>
  );
}

export default styled(JobResultSummary)``;
