import { gql } from '@apollo/client';

export const GQL_PROJECT_STATUS = gql`
  query ProjectStatus($ids: [ID!]) {
    projects(ids: $ids) {
      id
      category
      name
      status
      scheduledDeadlineDateTime
      manager {
        name
      }
    }
  }
`;
