import { Eventer, EventerRemoveHandler, sleep } from '@voithru/front-core';
import { nanoid } from 'nanoid';
import api from 'src/api';
import { GoogleFile } from 'src/types/api/File';
import { GoogleFileRequest as ApiFileRequest, JobFileRequest, JobPostRequest, JobsResponse } from 'src/types/api/Job';
import { JobResultFileType } from 'src/types/api/JobResult';
import { ProductOrderId } from 'src/types/api/ProductOrder';
import { ProjectCategory, ProjectResponse } from 'src/types/api/Project';
import { isAxiosError } from 'src/utils/api/axios';
import { handlePreventWindowClose } from './dom';
import { NamedFile } from './files';
import MultipartUpload from './MultipartUpload';
import QueueTask from './QueueTask';
import { getTransactionJobResultsStorage, getTransactionOrdersStorage } from './storages';
import UploadService from './UploadService';

class Mutex<T> {
  value: T;
  isLock: Boolean;
  owner?: string;

  constructor(value: T) {
    this.value = value;
    this.isLock = false;
    this.owner = undefined;
  }

  public async lock() {
    if (!this.isLock) {
      this.isLock = true;
      this.owner = nanoid();
      return this.owner;
    }

    while (this.isLock) {
      if (this.isLock) {
        await sleep(100);
      }
    }

    this.isLock = true;
    this.owner = nanoid();
    return this.owner;
  }

  public release(owner: string) {
    if (this.owner === owner) {
      this.isLock = false;
    }
  }

  public get(owner?: string) {
    if (!this.isLock) {
      return this.value;
    }
    if (this.isLock && this.owner === owner) {
      return this.value;
    }

    throw Error('JobRegisterService: Not correct Authorization to get locked object');
  }

  public update(value: T, owner?: string) {
    if (!this.isLock) {
      this.value = value;
      return this.value;
    }
    if (this.isLock && this.owner === owner) {
      this.value = value;
      return this.value;
    }

    throw Error('JobRegisterService: Not correct Authorization to get locked object');
  }
}

export interface JobRegisterOption {
  project: ProjectResponse;
}

type StartListener = (key: string) => void;
type DoneListener = (key: string, jobId: number) => void;
type ErrorListener = (key: string, error: unknown) => void;

interface AddEventListener {
  (type: 'start', listener: StartListener): EventerRemoveHandler;

  (type: 'stop', listener: StartListener): EventerRemoveHandler;

  (type: 'done', listener: DoneListener): EventerRemoveHandler;

  (type: 'error', listener: ErrorListener): EventerRemoveHandler;
}

interface Group {
  id: string;
  index: number;
  name: string;
}

export interface FileGroup extends Group {
  files: NamedFile[];
}

export interface FileGroupWithGoogle extends Group {
  size: string;
  item: FileGroup | GoogleFile;
}

interface FileGroupUploadRequest {
  projectId: number;
  jobIndex: number;
  name: string;
  fileGroup: FileGroup;
  category: ProjectCategory;
  accountId: number;
  managerId?: number;
}

class JobRegisterService {
  public uploadService = new Map<string, UploadService>();

  #task = new QueueTask();

  private options = new Map<string, JobRegisterOption>();
  private fileGroupUploadRequests = new Map<string, FileGroupUploadRequest[]>();
  private jobsResponses: Mutex<JobsResponse[]> = new Mutex([]);

  private eventerStart = new Eventer<StartListener>();
  private eventerStop = new Eventer<StartListener>();
  private eventerDone = new Eventer<DoneListener>();
  private eventerError = new Eventer<ErrorListener>();

  constructor() {
    this.addEventListener('start', () => window.addEventListener('beforeunload', handlePreventWindowClose));
    this.addEventListener('stop', () => window.removeEventListener('beforeunload', handlePreventWindowClose));
  }

  public addEventListener: AddEventListener = (key, listener) => {
    switch (key) {
      case 'start':
        return this.eventerStart.addEventListener(listener as StartListener);
      case 'stop':
        return this.eventerStop.addEventListener(listener as StartListener);
      case 'done':
        return this.eventerDone.addEventListener(listener as DoneListener);
      case 'error':
        return this.eventerError.addEventListener(listener as ErrorListener);
    }
  };

  public getUploadService = (key: string): UploadService | undefined => {
    return this.uploadService.get(key);
  };

  public getFileGroups = (key: string): readonly FileGroup[] | undefined => {
    return this.fileGroupUploadRequests.get(key)?.map((fgr) => fgr.fileGroup);
  };

  /**
   * 0. Register Job Options for transaction
   * @param key     Transaction Key
   * @param option  Options for transaction
   */
  public registerOptions = (key: string, option: JobRegisterOption) => {
    const next = { ...this.options.get(key), ...option };
    this.options.set(key, next);
  };

  public createUploadService = (key: string) => {
    const service = this.uploadService.get(key) ?? new UploadService();
    this.uploadService.set(key, service);
    return service;
  };

  /**
   * 2. Start Service for transaction
   * @param key  Transaction Key
   */
  public start = async (
    key: string,
    fileGroups: FileGroup[],
    type: 'CREATE_JOB' | 'POST_JOB_RESULT_FILE',
    jobData?: JobsResponse
  ) => {
    const service = this.uploadService.get(key);
    if (!service) {
      return false;
    }

    let isFailed = false;
    const unsubscribe: VoidFunction[] = [];
    unsubscribe.push(
      this.eventerError.addEventListener((keyWithError, error) => {
        if (keyWithError === key) {
          isFailed = true;
        }
      })
    );
    this.eventerStart.run(key);

    if (type === 'CREATE_JOB') {
      const option = this.options.get(key);
      if (!option || !option.project) {
        unsubscribe.forEach((it) => it());
        throw new Error('JobRegisterService.createJob: Empty Project');
      }
      const fileGroupUploadRequests: FileGroupUploadRequest[] = fileGroups.map((fg) => {
        return {
          projectId: option.project.id,
          jobIndex: fg.index,
          name: fg.name,
          fileGroup: fg,
          category: option.project.category,
          accountId: option.project.accountId,
          managerId: option.project.managerId,
        } as FileGroupUploadRequest;
      });

      this.fileGroupUploadRequests.set(key, fileGroupUploadRequests);
    } else if (type === 'POST_JOB_RESULT_FILE') {
      const fileGroupUploadRequests: FileGroupUploadRequest[] = fileGroups.map((fg) => {
        return {
          fileGroup: fg,
        } as FileGroupUploadRequest;
      });

      this.fileGroupUploadRequests.set(key, fileGroupUploadRequests);
    }

    const fileIds = new Set(fileGroups.flatMap((it) => it.files).map((it) => it.id));
    if (type === 'POST_JOB_RESULT_FILE') {
      const actionPromises = service.uploaders.map((it) => {
        if (!fileIds.has(it.namedFile.id)) {
          return undefined;
        }
        unsubscribe.push(
          it.addEventListener('done', () => this.#actionServiceFnPostJobResultFile(key, it, type, jobData))
        );
        if (it.status === 'DONE') {
          return this.#actionServiceFnPostJobResultFile(key, it, type, jobData);
        }
        return undefined;
      });
      await Promise.all(actionPromises);
    } else if (type === 'CREATE_JOB') {
      const target = service.uploaders.filter((it) => fileIds.has(it.namedFile.id));
      await this.#actionServiceFnCreateJob(key, target, type, jobData);
    }

    unsubscribe.forEach((it) => it());
    return !isFailed;
  };

  public stop = (key: string) => {
    this.eventerStop.run(key);
  };

  #actionServiceFnPostJobResultFile = async (
    key: string,
    target: MultipartUpload,
    type: 'POST_JOB_RESULT_FILE',
    jobData?: JobsResponse
  ) => {
    const fileGroupUploadRequests = this.fileGroupUploadRequests.get(key);
    if (!fileGroupUploadRequests) {
      throw new Error(
        'JobRegisterService.service: Invalid service. fileGroupUploadRequests is N/A.' +
          JSON.stringify({ key, target, type, jobData })
      );
    }

    const uploadService = this.uploadService.get(key);
    if (!uploadService) {
      return;
    }

    try {
      const fileGroupRequest = fileGroupUploadRequests.find(
        (fileGroupRequest) =>
          fileGroupRequest.fileGroup.files.filter((file) => file.id === target.namedFile.id).length > 0
      );
      if (!fileGroupRequest) {
        throw new Error(
          'JobRegisterService.service: Invalid service. fileGroupRequest is N/A.' +
            JSON.stringify({ key, target, type, jobData })
        );
      }

      const jobResultWithFile = getTransactionJobResultsStorage(key).item;
      if (!jobResultWithFile || !fileGroupRequest) {
        return;
      }
      const jobResultWithFileTarget = jobResultWithFile
        .filter((it) => it.fileId === fileGroupRequest.fileGroup.files[0].id)
        .map((it) => {
          const uploader = uploadService.uploaders.find((uploader) => uploader.namedFile.id === it.fileId);
          if (!uploader?.fileId) {
            return null;
          }

          return { jobId: it.jobId, jobResultId: it.jobResultId, fileId: uploader.fileId };
        })
        .filter(Boolean)[0];
      if (!jobResultWithFileTarget) {
        return;
      }

      await this.connectJobResultWithFile(key, fileGroupRequest, jobResultWithFileTarget);
      this.eventerDone.run(key, jobResultWithFileTarget.jobId);
    } catch (error) {
      await this.eventerError.run(key, error);
    }
  };
  #actionServiceFnCreateJob = async (
    key: string,
    target: MultipartUpload[],
    type: 'CREATE_JOB',
    jobData?: JobsResponse
  ) => {
    const fileGroupUploadRequests = this.fileGroupUploadRequests.get(key);
    if (!fileGroupUploadRequests) {
      throw new Error(
        'JobRegisterService.service: Invalid service. fileGroupUploadRequests is N/A.' +
          JSON.stringify({ key, target, type, jobData })
      );
    }

    const uploadService = this.uploadService.get(key);
    if (!uploadService) {
      return;
    }

    try {
      const fileIds = new Set(target.map((it) => it.namedFile.id));
      const fileGroupRequest = fileGroupUploadRequests.find(
        (fileGroupRequest) => fileGroupRequest.fileGroup.files.filter((file) => fileIds.has(file.id)).length > 0
      );
      if (!fileGroupRequest) {
        throw new Error(
          'JobRegisterService.service: Invalid service. fileGroupRequest is N/A.' +
            JSON.stringify({ key, target, type, jobData })
        );
      }
      const fileGroupRequestsForEachJob = [
        ...new Map(
          fileGroupUploadRequests.map((fileGroupUploadRequest) => {
            const { projectId, jobIndex } = fileGroupUploadRequest;
            const jobKey = `${projectId} ${jobIndex}`;
            return [jobKey, fileGroupUploadRequest];
          })
        ).values(),
      ];

      if (fileGroupRequestsForEachJob.length !== fileGroupUploadRequests.length) {
        throw new Error(`JobRegisterService.service: found duplicated job creation request for the same job`);
      }

      const orderIds = getTransactionOrdersStorage(key).item;
      if (!orderIds) {
        return;
      }
      for (const fileGroupRequest of fileGroupRequestsForEachJob) {
        const apiFileRequets = target
          .filter((multipartUpload) => {
            const { files } = fileGroupRequest.fileGroup;
            if (files.some((file) => multipartUpload.namedFile.id === file.id)) {
              return true;
            }
            return false;
          })
          .map<ApiFileRequest>((multipartUpload) => {
            const { files } = fileGroupRequest.fileGroup;
            const index = files.findIndex((files) => files.id === multipartUpload.namedFile.id);
            const { fileId } = multipartUpload;
            if (!fileId) {
              throw new Error(
                'JobRegisterService.service: Invalid service. fileId is N/A.' +
                  JSON.stringify({ key, target: multipartUpload, type, jobData })
              );
            }
            return { fileId, index, fileType: 'CONTENTS_FILE' };
          });
        let job = await this.getJobNullThenCreateWithFilesAttached(fileGroupRequest, orderIds, jobData, apiFileRequets);
        this.eventerDone.run(key, job.id);
      }
    } catch (error) {
      await this.eventerError.run(key, error);
    }
  };

  private async getJobNullThenCreate(
    fileGroupRequest: FileGroupUploadRequest,
    orderIds: ProductOrderId[],
    jobData?: JobsResponse
  ) {
    const owner = await this.jobsResponses.lock();
    const jobs: JobsResponse[] = this.jobsResponses.get(owner);

    try {
      let job = jobs.find(
        (job) => job.index === fileGroupRequest.jobIndex && job.projectId === fileGroupRequest.projectId
      ) as JobsResponse | null;
      if (!job) {
        job = (await this.createJob(
          fileGroupRequest.projectId,
          fileGroupRequest.category,
          fileGroupRequest.jobIndex,
          fileGroupRequest.name,
          orderIds,
          fileGroupRequest.accountId,
          jobData,
          fileGroupRequest.managerId
        )) as JobsResponse;
        jobs.push(job);
      }
      return job;
    } finally {
      this.jobsResponses.release(owner!!);
    }
  }
  private async getJobNullThenCreateWithFilesAttached(
    fileGroupRequest: FileGroupUploadRequest,
    orderIds: ProductOrderId[],
    jobData?: JobsResponse,
    apiFileRequests?: ApiFileRequest[]
  ) {
    const jobName = fileGroupRequest.name;
    const owner = await this.jobsResponses.lock();
    const jobs: JobsResponse[] = this.jobsResponses.get(owner);

    try {
      let job = jobs.find(
        (job) => job.index === fileGroupRequest.jobIndex && job.projectId === fileGroupRequest.projectId
      ) as JobsResponse | null;
      if (job) {
        return job;
      }
      job = (await this.createJob(
        fileGroupRequest.projectId,
        fileGroupRequest.category,
        fileGroupRequest.jobIndex,
        jobName,
        orderIds,
        fileGroupRequest.accountId,
        jobData,
        fileGroupRequest.managerId,
        apiFileRequests
      )) as JobsResponse;
      jobs.push(job);
      return job;
    } catch (error) {
      window.alert(`job 생성에 실패했습니다.\n 이름: "${jobName}"`);
      throw error;
    } finally {
      this.jobsResponses.release(owner!!);
    }
  }

  private createJob = async (
    projectId: number,
    category: ProjectCategory,
    index: number,
    jobName: string,
    orderIds: ProductOrderId[],
    accountId: number,
    jobData?: JobsResponse,
    managerId?: number,
    jobFiles?: ApiFileRequest[]
  ) => {
    const data: JobPostRequest = {
      job: {
        name: jobName,
        projectId: projectId,
        index: index ? index : 0,
        category: category,
        status: 'REQUESTED',
        accountId: accountId,
        managerId: managerId,
      },
      productOrdersIds: orderIds,
      jobFiles: jobFiles,
    };
    if (jobData) {
      if (jobData.scheduledDeadlineDateTime) {
        data.job.scheduledDeadlineDateTime = new Date(jobData.scheduledDeadlineDateTime).toISOString();
      }
      if (jobData.managerId) {
        data.job.managerId = jobData.managerId;
      }
    }
    const res = await api.jobs.post(data);
    if (isAxiosError(res)) {
      throw res;
    }

    return res.data;
  };

  private attachFileToJob = async (
    key: string,
    jobId: number,
    fileIdAndIndexPairs: { index: number; fileId: string }[]
  ) => {
    const requests: JobFileRequest[] = fileIdAndIndexPairs.map((it) => {
      const { fileId, index } = it;
      return {
        index,
        jobId,
        fileId,
        fileType: 'CONTENTS_FILE',
      };
    });
    const res = await api.jobs.item(jobId).files.post(requests);
    if (isAxiosError(res)) {
      throw res;
    }

    return res.data;
  };
  public connectJobResultWithFile = async (
    key: string,
    fileGroup: FileGroupUploadRequest,
    jobResultWithFileTarget: { jobId: number; jobResultId: number; fileId: string }
  ) => {
    const fileType = fileGroup.fileGroup.files[0].name.split('.');
    let fileTypeUpperCase = fileType[fileType.length - 1].toUpperCase();
    const JobResultFileTypes = ['SRT', 'PSD', 'IMAGE', 'TEXT', 'VIDEO', 'OTHER'];
    if (!JobResultFileTypes.includes(fileTypeUpperCase)) {
      fileTypeUpperCase = 'OTHER';
    }

    const data = [
      {
        jobResultId: jobResultWithFileTarget.jobResultId,
        fileId: jobResultWithFileTarget.fileId,
        fileType: fileTypeUpperCase as JobResultFileType,
        index: fileGroup.fileGroup.index,
      },
    ];
    await api.jobs
      .item(jobResultWithFileTarget.jobId)
      .jobResultItem(jobResultWithFileTarget.jobResultId)
      .files.post(data)
      .catch((err) => err);
  };
}

export default JobRegisterService;
